import {Controller} from "stimulus";
import $ from "jquery";
import {checkAdvancePaymentAtMoment, checkAmountPaymentForm} from "./payment_controller";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    connect() {
/*        $('#available_vehicle').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#available_vehicle").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });*/
    }

    myToFixed( num, precision ) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

/*    updateCarData(e){
        let vehicle = $('#available_vehicle').val();
        let vehicle_url = this.data.get("url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: vehicle_url,
            data: {id: vehicle},
            success: function (res) {
                console.log(res);
                $("#change_mileage").val(res.mileage);
                $("#change_fuel").val(res.fuel);
                $("#change_mtto").val(res.mtto);
                $('#vehicle_data').text(res.vehicle);
                $('#change_manufactured_year').text(res.manufactured_year);
                $('#change_business_code').text(res.business_code);
                $('#change_basic_business_code').text(res.basic_business_code);
                $('#change-vehicle-mileage').text(res.mileage);
                $('#change-mtto').text(res.mtto);
                $('#change-ficav').text(res.humanize_status);
            },
            error: function () {
                alert("Error");
            },
        });
    }
*/

    togglePaymentForm(e){
        if($('#add-charge').is(":checked") === true) {
            this.resetPaymentForm();
            $("#nav-client_debt-form").hide();
            $('#btn-extent').removeClass('disabled');
            $("#payments").hide();
        }else{
            this.updatePaymentForm(e);
            $("#nav-client_debt-form").show();
            $('#btn-extent').addClass('disabled');
            $("#payments").show();
        }
    }
    resetPaymentForm(){
        $('#cash').val(0);
        $('#card').val(0);
        $('#moment_card').val(0);
        $('#voucher').val(0);
        $('#cheque').val(0);
        $('#other').val(0);
        $('#payment_method_total').val(0);
        $('#total').val(0);
    }
    updateDriverImport(e){
        // $('#drivers_import').val(0.0);
        // let days = parseInt($('#liquidate_days').val());
        // let drivers_import = (days * parseFloat($('#tariff_drivers_day').val()) * parseFloat($('#additional_driver_collect').val())).toFixed(2)
        // $('#drivers_import').val(drivers_import);
    }

    updateTariffCents(e){
        let additional_driver_collect = 0;
        let days = parseInt($('#liquidate_days').val());
        $('#rent_amount').val((days * $('#rent_tariff').val()).toFixed(2));
        $('#liability_amount').val((days * $('#liability_tariff').val()).toFixed(2));
        if($('#additional_driver_collect').val() != undefined){
            additional_driver_collect = parseFloat($('#additional_driver_collect').val());
        }
        // Importe del chofer adicional
        let driver_tariff_type = this.data.get('driver_tariff_type');
        let drivers_import = 0.0;
        if(driver_tariff_type === 'by_range'){
            drivers_import = (parseFloat($('#tariff_drivers_day').val()) * additional_driver_collect ).toFixed(2);
        }else{
            drivers_import = (days * parseFloat($('#tariff_drivers_day').val()) * additional_driver_collect ).toFixed(2);
        }
        $('#drivers_import').val(drivers_import).trigger('change');
        // Importe del combustible para cambio de carro
        // Se calcula la cantidad de combustible x lo que tiene el tanque
        let rent_fuel_tariff = parseFloat($('#fuel_tariff').val()).toFixed(2);
        let litters = 0;
        if($('#balance_fuel').is(":checked") === true) {
            litters = $('#amount_compensate_fuel').val() != null ?  $('#amount_compensate_fuel').val() : 0
        }else{
            litters = $('#change_fuel').val() != null ?  parseInt($('#change_fuel').val()) : 0
        }
        $('#fuel_import').val((parseFloat(rent_fuel_tariff) * litters).toFixed(2)).trigger('change');
    }

    balanceFuel(e){
        if($('#balance_fuel').is(":checked") === true) {
            $("#div_charge_fuel").show();
        }else{
            $("#div_charge_fuel").hide();
            let change_fuel = parseFloat($('#change_fuel').val());
            let fuel_tariff = parseFloat($('#fuel_tariff').val());
            let total = fuel_tariff * change_fuel
            $('#fuel_import').val(this.myToFixed(total,2)).trigger('change')
        }
    }

    updateFuelImport(e){
        let fuel_tariff = parseFloat($('#fuel_tariff').val());
        let charge_fuel = parseFloat($('#amount_compensate_fuel').val());
        let total = fuel_tariff * charge_fuel
        $('#fuel_import').val(this.myToFixed(total,2)).trigger('change')
    }

    updatePaymentForm(e){
        let extent = parseFloat($('#rent_amount').val());
        let liability_release = parseFloat($('#liability_amount').val());
        let drivers = parseFloat($('#drivers_import').val());
        let fuel = parseFloat($('#fuel_import').val());
        let other_payments = parseFloat($('#other_payments').val());
        let total = this.myToFixed(extent + liability_release + drivers + fuel + other_payments, 2);
        let payment_total_voucher = this.data.get('payment_total_voucher');
        // Verificar configuracion del tipo de renta, si tiene configurado que el cobro total del contrato para voucher
        if (payment_total_voucher === 'true') {
            $('#voucher').val(total);
            $('#payment_method_total').val(0.00);
            $('#total').val(0.00);
        } else {
            $('#payment_method_total').val(total);
            $('#total').val(total);
        }
    }

    updateTotal(e){
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        let total = cash + card + moment_card + voucher + cheque + other
        let to_distribute = parseFloat($('#total').val());
        // Cheaquando configuracion por tipo de renta total del cobros por voucher
        let payment_total_voucher = this.data.get('payment_total_voucher');
        if (payment_total_voucher === 'true') {
            $('#payment_method_total').val(0.00);
            $('#btn-extent').removeClass('disabled');
        }else {
            $('#payment_method_total').val(this.myToFixed(to_distribute - total, 2));
            if((to_distribute - total) < 0){
                alert('No puede repartir más de $' + to_distribute + ' , verifique los datos');
                $('#btn-extent').addClass('disabled');
            }
            if((to_distribute - total) === 0){
                $('#btn-extent').removeClass('disabled');
            }else {
                $('#btn-extent').addClass('disabled');
            }
        }
    }

    checkAdvancePaymentAtMoment(e) {
        if ($('#add-charge').is(":checked") === false) {
            checkAdvancePaymentAtMoment();
        } else {
            $('.advance_msg_payment').remove();
        }
    }

    checkAmountPaymentForm(e) {
        let code = e.target.dataset.code; // code payment form entrada.
        if($('#add-charge').is(":checked") === false)
            checkAmountPaymentForm(code);
    }
}

import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, myToFixed} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
    }

    /**
     * Encontrar las penalidades de una resolucion.
     * */
    findByResolution(e) {
        let url = this.data.get("url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {resolution_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $('.check_box_label').remove();
                if (res !== undefined) {
                    if (res.vehicle_penalties.length > 0) {
                        $.each(res.vehicle_penalties, function (key, penalty) {
                            $('#body_penalties').append(`
                                <tr class="check_box_label">
                                   <td>
                                    <input class="form-check-input" data-penalty="${penalty.name}" type="checkbox" 
                                        id="resolution_penalty_vehicle_penalty_ids_${penalty.id}" value="${penalty.id}"
                                        name="resolution_penalty[vehicle_penalty_ids][]">
                                   </td>
                                   <td>${penalty.name}</td>    
                                </tr>
                            `);
                        });
                        $('#clone_penalties').prop('disabled', false);
                    } else
                        $('#clone_penalties').prop('disabled', true);
                } else
                    $('#clone_penalties').prop('disabled', true);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * Calcular el importe total de las penalidades.
     */
    updatePenaltiesTotal(e){
        let amount_penalties = $("input[id*='amount_penalty_']");
        let total = 0;
        $.each(amount_penalties, function (key, penalty) {
            let amount = parseFloat(penalty.value);
            if ($.isNumeric(amount)) {
                total += amount;
            }
        });
        $("#total_penalties").prop('value', myToFixed(total, 2));
        $("#collected_penalties").prop('value', myToFixed(total, 2)).trigger('click');
    };
}
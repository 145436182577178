import {Controller} from "stimulus";
import $ from "jquery";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller{

    connect() {
        $('#place_filter').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#place_filter").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    getDestinyPlaceByGroup(e) {
        let deposits_by_groups_url = this.data.get("deposits_by_group_url");
        let parkings_by_hire_points_url = this.data.get("parkings_by_hire_points_url");
        let workshops_by_groups_url = this.data.get("workshops_by_group_url");
        let place = $('#place_filter').val();
        let group = $('#destiny_group');
        let destiny_place = $("#destiny_place");
        let basic_business = $('#basic_business_id');
        $.blockUI({message: false});
        if (place === 'deposit') {
            if (group.val()) {
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: deposits_by_groups_url,
                    data: {group_id: group.val(), basic_bussines_id: basic_business.val()},
                    success: function (res) {
                        $.unblockUI();
                        destiny_place.empty();
                        destiny_place.append('<option selected="selected" value=""> Seleccione el lugar de ubicación </option>')
                        $.each(res.deposits, function (index, deposit) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(deposit.name)
                                    .attr("value", deposit.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                            alert('Su session expiró, por favor acceda nuevamente');
                            window.location = jqXHR.getResponseHeader('ROOT_URL');
                        }else{
                            let message = "";
                            if (jqXHR.status === 0) {
                                message = 'Sin conexión: Verifique su conexión de red.';
                            } else if(jqXHR.status == 400){
                                message = ' Existen problemas en el request';
                            } else if(jqXHR.status == 401){
                                message = 'Autentiquece nuevamente.';
                            }else if (jqXHR.status == 403) {
                                message = "Autentiquece nuevamente.";
                            } else if (jqXHR.status == 404) {
                                message = 'Página no encontrada [404]';
                            } else if (jqXHR.status == 500) {
                                message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                            } else if (jqXHR.status == 503){
                                message = "Service unavailable.";
                            } else if (textStatus === 'parsererror') {
                                message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                            } else if (textStatus === 'timeout') {
                                message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                            } else if (textStatus === 'abort') {
                                message = 'Request abortado.';
                            } else {
                                message = 'Error desconocido: ' + jqXHR.responseText;
                            }
                            alert(message);
                        }
                    },
                });
                $('#parking_place').prop('disabled', true);

            } else {
                alert('Seleccione un grupo');
                group.focus();
                $.unblockUI();
            }
        } else if (place === 'parking') {
            if (group.val()) {
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: parkings_by_hire_points_url,
                    data: {id: group.val()},
                    success: function (res) {
                        $.unblockUI();
                        destiny_place.empty();
                        destiny_place.append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
                        $.each(res.hire_points, function (index, hire_point) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(hire_point.name)
                                    .attr("value", hire_point.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                            alert('Su session expiró, por favor acceda nuevamente');
                            window.location = jqXHR.getResponseHeader('ROOT_URL');
                        }else{
                            let message = "";
                            if (jqXHR.status === 0) {
                                message = 'Sin conexión: Verifique su conexión de red.';
                            } else if(jqXHR.status == 400){
                                message = ' Existen problemas en el request';
                            } else if(jqXHR.status == 401){
                                message = 'Autentiquece nuevamente.';
                            }else if (jqXHR.status == 403) {
                                message = "Autentiquece nuevamente.";
                            } else if (jqXHR.status == 404) {
                                message = 'Página no encontrada [404]';
                            } else if (jqXHR.status == 500) {
                                message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                            } else if (jqXHR.status == 503){
                                message = "Service unavailable.";
                            } else if (textStatus === 'parsererror') {
                                message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                            } else if (textStatus === 'timeout') {
                                message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                            } else if (textStatus === 'abort') {
                                message = 'Request abortado.';
                            } else {
                                message = 'Error desconocido: ' + jqXHR.responseText;
                            }
                            // responseText
                            // $.ajax({
                            //     async: true,
                            //     evalScripts:true,
                            //     type: "POST",
                            //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                            //     data: {error: jqXHR.responseText}
                            // });
                            alert(message);
                        }
                    },
                });
                $('#parking_place').prop('disabled', false);
            } else {
                $.unblockUI();
                alert('Seleccione un grupo de destino');
                group.focus();
            }
        } else if (place === 'workshop') {
            if (group.val()) {
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: workshops_by_groups_url,
                    data: {id: group.val(), basic_business_id: basic_business.val()},
                    success: function (res) {
                        $.unblockUI();
                        destiny_place.empty();
                        destiny_place.append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
                        $.each(res.workshops, function (index, workshop) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(workshop.description)
                                    .attr("value", workshop.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                            alert('Su session expiró, por favor acceda nuevamente');
                            window.location = jqXHR.getResponseHeader('ROOT_URL');
                        }else{
                            let message = "";
                            if (jqXHR.status === 0) {
                                message = 'Sin conexión: Verifique su conexión de red.';
                            } else if(jqXHR.status == 400){
                                message = ' Existen problemas en el request';
                            } else if(jqXHR.status == 401){
                                message = 'Autentiquece nuevamente.';
                            }else if (jqXHR.status == 403) {
                                message = "Autentiquece nuevamente.";
                            } else if (jqXHR.status == 404) {
                                message = 'Página no encontrada [404]';
                            } else if (jqXHR.status == 500) {
                                message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                            } else if (jqXHR.status == 503){
                                message = "Service unavailable.";
                            } else if (textStatus === 'parsererror') {
                                message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                            } else if (textStatus === 'timeout') {
                                message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                            } else if (textStatus === 'abort') {
                                message = 'Request abortado.';
                            } else {
                                message = 'Error desconocido: ' + jqXHR.responseText;
                            }
                            alert(message);
                        }
                    },
                });
                $('#parking_place').prop('disabled', true);

            } else {
                $.unblockUI();
                alert('Seleccione un grupo de destino');
                group.focus();

            }
        }
    }
}
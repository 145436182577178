import {Controller} from "stimulus";
import $ from "jquery";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");
require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')

function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Service unavailable.";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}
export default class extends Controller {
    connect() {
        $('#basic_business_id, #movement_type, #place_filte').select2({
            theme: "bootstrap",
            language: "es"
        });
        $("#basic_business_id, #movement_type, #vehicle_movement_type, #place_filter").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    getDestinyPlace(e) {
        let deposits_by_basic_business_url = this.data.get("deposits_by_basic_business_url");
        let parkings_by_hire_points_url = this.data.get("parkings_by_hire_points_url");
        let workshops_by_hire_points_url = this.data.get("workshops_by_hire_points_url");
        let place = $('#place_filter').val();
        let id =  this.data.get("id");
        if (place === 'deposit') {
            // get all deposit.
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: deposits_by_basic_business_url,
                data: {id: id},
                success: function (res) {
                    $("#destiny_place").empty();
                    $.each(res.deposits, function (index, deposit) {
                        $("#destiny_place").append(
                            $("<option>")
                                .text(deposit.name)
                                .attr("value", deposit.id)
                        );
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        } else if (place === 'parking') {
            // should exits hirepoint select
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: parkings_by_hire_points_url,
                data: {id: id},
                success: function (res) {
                    $("#destiny_place").empty();
                    console.log(res)
                    $.each(res.parkings, function (index, parking) {
                        $("#destiny_place").append(
                            $("<option>")
                                .text(parking.name)
                                .attr("value", parking.id)
                        );
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        } else if (place === 'workshop') {
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: workshops_by_hire_points_url,
                data: {id: id},
                success: function (res) {
                    $("#destiny_place").empty();
                    $.each(res.workshops, function (index, workshop) {
                        $("#destiny_place").append(
                            $("<option>")
                                .text(workshop.description)
                                .attr("value", workshop.id)
                        );
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    toggleIndicativeInput() {
        if ($('#movement_type').val() === 'car_transporter' || $('#movement_type').val() === 'wreck_car') {
            $('div.indicative').removeClass('d-none');
            $('#indicative').prop('disabled', false);
        } else {
            $('div.indicative').addClass('d-none');
            $('#indicative').prop('disabled', true);
        }
    }

    toggleCLoseIndicativeInput() {
        if ($('#vehicle_movement_type').val() === 'car_transporter' || $('#vehicle_movement_type').val() === 'wreck_car' ) {
            $('div.indicative').removeClass('d-none');
            $('#vehicle_indicative').prop('disabled', false);
        } else {
            $('div.indicative').addClass('d-none');
            $('#vehicle_indicative_indicative').prop('disabled', true);
        }
    }
}

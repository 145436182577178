import {Controller} from 'stimulus'
import $ from "jquery";

export default class extends Controller {
    static values = {
        id: String,
        content: String,
        expires: Boolean
    }

    static targets = ["link"];

    connect() {
    }

    showModal(e) {
        if (this.expiresValue) {
            e.preventDefault();
            let modal = $(`#${this.idValue}`);
            let modalContent = $('#modalContent');
            let modalAccetpButton = $("#modalAcceptButton");

            modal.modal('show');
            modalContent.get(0).innerHTML = this.contentValue;

            modalAccetpButton.on('click', () => {
                window.location.href = this.linkTarget;
            });
        }
    }
}
import {Controller} from "stimulus";
import $ from "jquery";

let send_ajax = (ref,vehicle) => new Promise((resolve, reject) =>{
    $.ajax({
        url: `${location.origin}/hire_point/show-rent-data/${ref}.json`,
        data: {
            vehicle: vehicle
        },
        type: 'GET',
        beforeSend: function () {
            document.getElementById('load_spinner').style.display = "inline-block";
        },
        success: function (data) {
           // console.log(data);
            let html = '';
           if(data.success){
               html = `<div class='alert alert-success'>${data.msg}</div>`;
           }else{
               html = `<div class='alert alert-danger'>${data.msg}</div>`;
           }
            document.getElementById('alert_response_ajax').innerHTML = html;
            document.getElementById('load_spinner').style.display = "none";
        },
        error: function (data){
            // console.log(data);
            document.getElementById('alert_response_ajax').innerHTML = '';
            document.getElementById('load_spinner').style.display = "none";
        }

    })
});

export default class extends Controller {

    connect() {

        let rent_type = $('#nom_rent_type_id option').filter(':selected');
        let root_ref = document.getElementById('root_reference');
        let vehicle_fuel = this.data.get("vehicle");
        $('#fuel_to_collect').val(vehicle_fuel);

        root_ref.addEventListener('keyup',function (e) {
            if(e.key == "Enter"){
                e.preventDefault();
                e.stopPropagation();
            }else{
                if(this.value.length > 0){
                    $('#fuel_to_collect').val(0);
                    $('#fuel_to_collect').attr('disabled','disabled');
                }else{
                    $('#fuel_to_collect').val(vehicle_fuel);
                    $('#fuel_to_collect').removeAttr('disabled');
                }

                let value = e.target.value;
                let vehicle = $('#root_reference_input').attr("data-vehicle-id");
                send_ajax(value,vehicle).then();
            }
        });

        $('#root_reference').on('blur',function (e) {

            if(e.key == "Enter"){
                e.preventDefault();
                e.stopPropagation();
            }else{
                if(this.value.length > 0){
                    $('#fuel_to_collect').val(0);
                    $('#fuel_to_collect').attr('disabled','disabled');
                }else{
                    $('#fuel_to_collect').val(vehicle_fuel);
                    $('#fuel_to_collect').removeAttr('disabled');
                }
                let value = e.target.value;
                let vehicle = $('#root_reference_input').attr("data-vehicle-id");
                send_ajax(value,vehicle).then();
            }
        });

        // $('#nom_rent_type_id').change(function (){
        //     let nom_rent_type = document.getElementById('nom_rent_type_id').selectedOptions[0].text;
        //     if(nom_rent_type == 'Renta Lineal' || nom_rent_type == 'Renta Continuada AAVV-TTOO'){
        //         document.getElementById('show_root_reference').style.display = "inline-block";
        //     }else{
        //         document.getElementById('show_root_reference').style.display = "none";
        //     }
        // });

    }

    myToFixed(num, precision ) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

    updateFuelImportToCollect(e){
        let fuel_to_collect = parseFloat($('#fuel_to_collect').val());
        let tariff = parseFloat($("#amounts_fuel_day").val());
        $('#text_fuel').text(fuel_to_collect);

        // quitar el combustible gratis
        let free_fuel = parseFloat($("#text_free_fuel").text());
        let total_fuel_to_collect =  fuel_to_collect - free_fuel;
        if (fuel_to_collect < free_fuel) {
            total_fuel_to_collect = 0;
        }
        $('#amounts_fuel').val(this.myToFixed(total_fuel_to_collect * tariff, 2));
        this.updateTotalImport(e);
    }

    updateTotalImport(e){
        //ACTUALIZAR TOTAL DE IMPORTE
        //renta
        let rent = $('#amounts_rent_import').val();
        if(rent === '' || rent === undefined || rent === null){
            rent = 0.0
        }
        //seguro
        let insurance = $('#liability_release_import').val();
        if(insurance === '' || insurance === undefined || insurance === null){
            insurance = 0.0
        }
        //combustible
        let fuel_imp = $('#amounts_fuel').val();
        if(fuel_imp === '' || fuel_imp === undefined || fuel_imp === null){
            fuel_imp = 0.0
        }
        //oficina
        let office = $('#amounts_office').val();
        if(office === '' || office === undefined || office === null){
            office = 0.0
        }
        //drop off
        let drop_off = $('#amounts_drop_off').val();
        if(drop_off === '' || drop_off === undefined || drop_off === null){
            drop_off = 0.0
        }
        //suplemento de relevo
        let relief_supplement = $('#relief_supplement_import').val();
        if(relief_supplement === '' || relief_supplement === undefined || relief_supplement === null){
            relief_supplement = 0.0
        }
        //choferes
        let drivers = $("#drivers_import").val()
        if(drivers === '' || drivers === undefined || drivers === null){
            drivers = 0.0
        }
        // Otros
        let others = $("#amounts_other_payments").val()
        if(others === '' || others === undefined || others === null){
            others = 0.0
        }
        let total_im = parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent) + parseFloat(drivers) + parseFloat(others) + parseFloat(drop_off) + parseFloat(relief_supplement)
        $("#rent_import").text(this.myToFixed(total_im, 2));
        $("#total_rent_import").val(this.myToFixed(total_im, 2));
        let rent_charge = this.myToFixed(parseFloat(total_im) - parseFloat($("#span_reservation_price").text()), 2);
        if (rent_charge > 0){
            $("#rent_charge").text(rent_charge);
            $("#rent_credit").text(0.00);
            $("#total_credit").val(0.00 );
            $("#moment_card").val(rent_charge);
        }else{
            $("#rent_charge").text(0.00);
            $("#rent_credit").text(rent_charge * -1 );
            $("#total_credit").val(rent_charge * -1);
            $("#moment_card").val(0.0);
        }
    }

}
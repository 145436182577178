import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')

// Alternativa del controller clearDate q solo busca elementos con $() en su contexto y no en el document completo, ademas incorpora la logica de maximo rango de dias
/** Comportamiento de un selector de fecha
 * todo: falta detallar esta explicacion
 * - el protagonista es el `inicio`
 *   - `fin` debe estar deshabilitado cuando `inicio` está vacío
 *   - al establecer `inicio` `fin` se borra junto y se habilita
 *   - al establecer `inicio` se establece el max de `fin` segun el maximo de  permitidos para el rango: `(to.maxDate = from.date + max_range_days)`
 * - establecer `inicio` establece min de `fin` con el mismo valor
 * - establecer `fin` establece max de `inicio` con el mismo valor
 * - limpiar `inicio` elimina el min de `fin`, ademas lo deshabilita y borra
 * - limpiar `fin` elimina el max de `inicio`
 */

export default class extends Controller {
    static targets = ['fromDate', 'toDate']
    static values = {
        maxRangeDays: Number
    }

    initialize() {
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }

    connect() {

        // Simular el readonly pero sin evitar la evaluacion del required https://stackoverflow.com/questions/12777751/html-required-readonly-input-in-form
        $(this.element).find('.readonly')
            .on('keydown paste focus', function(e){
                if(e.keyCode !== 9) // ignore tab
                    e.preventDefault();
            })
            .on('dragstart', function(e) {
                e.preventDefault();
            }, false);

        // poner valor por defecto al value
        if(this.maxRangeDaysValue === 0)
            this.maxRangeDaysValue = 30 // esta cantidad seria la deseada -1 pq al final en los rangos de fecha se inclullen los extremos
        // todo: esto estaria mejor hacerlo con values del contraldor de stimulus
        let minDate = 0
        let maxDate = 0

        if(this.data.get('min') == null){
            let expireDate = new Date();
            minDate = expireDate.setFullYear(expireDate.getFullYear() - 20);
        }
        else{
            minDate = this.data.get('min');
        }

        if(this.data.get('max') == null){
            let expireDate = new Date();
            maxDate = expireDate.setFullYear(expireDate.getFullYear() + 20);
        }
        else{
            maxDate = this.data.get('max');
        }
        // ------------------ //

        this.dateFormat = "dd/mm/yy"
        let datepicker_options = {
                dateFormat: this.dateFormat,
                defaultDate: 1,
                changeMonth: true,
                numberOfMonths: 1,
                changeYear: true,
                showButtonPanel: true,
                closeText: 'Limpiar',
                yearRange: '1920:2121',
                minDate: new Date(minDate),
                maxDate: new Date(maxDate),
            }

        /*  --- inputs e inicializar los datepicker --- */
        this.$from = $(this.fromDateTarget).datepicker(datepicker_options)
        this.$to = $(this.toDateTarget).datepicker(datepicker_options)
        this.$to.prop('disabled', true) // deshabilitar el `fin`


        if (this.$from.val() !== ''){ // si ya venia un valor de `inicio` ...
            let from = this.$from.datepicker('getDate')
            this.$to.datepicker('option', 'minDate', new Date(from)) // establecer fecha de `inicio` como min de `fin`
            from.setDate(from.getDate()+this.maxRangeDaysValue)
            this.$to.datepicker('option', 'maxDate', from) // establecer max de `fin` segun el maximo de dias permitidos
            this.$to.prop('disabled', false) // habilitar el `fin`
            this.$to.prop('required', true) // requerir el `fin`
        }
        if (this.$to.val() !== '') // si ya venia un valor de `fin` ...
            this.$from.datepicker('option', 'maxDate', this.$to.datepicker('getDate')) // establecer max de `inicio`

        /* --- onSelect --- */
        // al seleccionar `inicio`
        this.$from.datepicker('option', 'onSelect', (dateStr, datepicker) => {
            let from = this.$from.datepicker('getDate')
            from.setDate(from.getDate()+this.maxRangeDaysValue) // modifica from ponindole la fecha del ultimo dia permisible por el limite de dias del rango

            this.$to.datepicker('setDate', null) // borrar `fin`
            this.$to.datepicker('option', 'maxDate', from) // establecer max de `fin` segun el maximo de dias permitidos
            this.$to.datepicker('option', 'minDate', dateStr) // establecer fecha de `inicio` como min de `fin`
            this.$to.prop('disabled', false) // habilitar el `fin`
            this.$to.prop('required', true) // requerir el `fin`
            $(datepicker.input).trigger('change') // detonar evento `change`
        })
        // al seleccionar `fin`
        this.$to.datepicker('option', 'onSelect', (dateStr, datepicker) => {
            this.$from.datepicker('option', 'maxDate', dateStr) // establecer max de `inicio`
            $(datepicker.input).trigger('change') // detonar evento `change`
        })

        /* --- onClose --- */
        // al limpiar `inicio`
        this.$from.datepicker('option', 'onClose', (dateStr, datepicker) => {
            // limpiar el campo si el boton presionado es el de CERRAR (en este caso limpiar) del datepicker
            if ($(window.event.target).hasClass('ui-datepicker-close')) { // todo: buscar una formas mas bonita de hacer esto
                this.#setFromDate(null)
                this.#setToDate(null)
                this.$to.datepicker('option', 'maxDate', null)
                this.$to.prop('disabled', true)
                this.$to.prop('required', false) // requerir el `fin`
                // $(datepicker.input).trigger('change') // detonar evento `change`
                this.$from.trigger('change') // detonar evento `change`
                this.$to.trigger('change') // detonar evento `change`
            }
        })
        // al limpiar `fin`
        this.$to.datepicker('option', 'onClose', (dateStr, datepicker)=> {
            // limpiar el campo si el boton presionado es el de CERRAR (en este caso limpiar) del datepicker
            if ($(window.event.target).hasClass('ui-datepicker-close')) {
                this.#setToDate(null)
                $(datepicker.input).trigger('change') // detonar evento `change`
            }
        });
    }

    //##################################################### Functions ######################################################
    #setFromDate(date){ // cambia fecha de `date` pero no detona 'change'
        this.$from.datepicker('setDate', date)
        this.$to.datepicker('option', 'minDate', date)
    }

    #setToDate(date){ // cambia fecha de `date_end` pero no detona 'change'
        this.$to.datepicker('setDate', date)
        this.$from.datepicker('option', 'maxDate', date)
    }
}
import {Controller} from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
// require('block-ui/jquery.blockUI')
export default class extends Controller {
    $paginatedSelect;

    connect() {
        this.$paginatedSelect = $(".paginate_select2");

        $('.c2ec_select').select2({
            width: 'style',
            theme: 'bootstrap',
            dropdownAutoWidth: true,
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
            language: 'es'
        });

        $(".c2ec_select").on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });

        $(".ajax_select").select2({
            ajax: {
                url: "https://api.github.com/search/repositories",
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                },
                cache: true
            },
            escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
            minimumInputLength: 3

        });

        this.$paginatedSelect.select2({
            width: 'style',
            theme: 'bootstrap',
            dropdownAutoWidth: true,
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
            language: 'es',
            ajax: {
                url:  this.$paginatedSelect.prop('dataset')?.url,
                dataType: "json",
                language: "es",
                data: function (params) {
                    return {
                        search: params.term, page: params.page || 0
                    }
                },
                type: 'GET',
                processResults: function (data, params) {
                    let result = [];
                    params.page = params.page || 0;
                    data.rows.map((elem, key) => {
                        result.push({
                            id: elem.id, text: elem.name
                        })
                    })
                    if (data.total > 10) {
                        if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                            return {
                                results: result, "pagination": {
                                    "more": true
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    } else {
                        return {
                            results: result, "pagination": {
                                "more": false
                            }
                        }
                    }
                }
            }
        });
    }
}
import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {

    connect() {
    }

    check_vehicles_exist(e) {
        if (!e.target.checked) {
            $.blockUI({ message: '' });
            let url = this.data.get("url");
            let hirePointId = this.data.get("hire_point_id");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {id: hirePointId},
                success: function (res) {
                    $.unblockUI();
                    if (res.exist){
                        $('#form_check_active').prop('checked', true)
                        alert("No es posible desactivar el punto de renta, existen vehículos parqueados o trasegando hacia este")
                    }else{
                        $('#form_check_active').prop('checked', false)
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR,textStatus,errorThrown);
                },
            });
        }
    }


    get_user_operations(e) {
        let url = this.data.get("url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: e.target.value},
            success: function (res) {
                // Empty agencies select list and populate again with response.
                $("#user_id").empty();

                $.each(res.user, function (index, user) {
                    $("#user_id").append(
                        $("<option>")
                            .text(user.name)
                            .attr("value", user.id)
                    );
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                }else{
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if(jqXHR.status == 400){
                        message = ' Existen problemas en el request';
                    } else if(jqXHR.status == 401){
                        message = 'Autentiquece nuevamente.';
                    }else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503){
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    // responseText
                    // $.ajax({
                    //     async: true,
                    //     evalScripts:true,
                    //     type: "POST",
                    //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                    //     data: {error: jqXHR.responseText}
                    // });
                    alert(message);
                }
            },
        });
    }
}
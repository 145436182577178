import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
export default class extends Controller {
    connect() {
        let minDate = 0;
        let maxDate = 0;
        let $date = $(this.element).find('.date')

        if(this.data.get('min') == null){
            let expireDate = new Date();
            minDate = expireDate.setFullYear(expireDate.getFullYear() - 20);
        }
        else{
            minDate = this.data.get('min');
        }

        if(this.data.get('max') == null){
          let expireDate = new Date();
            maxDate = expireDate.setFullYear(expireDate.getFullYear() + 20);
        }
        else{
            maxDate = this.data.get('max');
        }

        if(this.data.get('clear') != null) {
            $date.wrap('<div class="input-group input-group-flat"></div>')
                .parent().append(
                    `<span class="input-group-text pl-2 pr-2 mr-0" onclick="$('#` + $date.prop('id') + `').datepicker('setDate', null)">\n` +
                    '              <a href="#" class="link-secondary text-center" title="Clear search" data-bs-toggle="tooltip">\n' +
                    '                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n' +
                    '                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />\n' +
                    '                  <line x1="18" y1="6" x2="6" y2="18" />\n' +
                    '                  <line x1="6" y1="6" x2="18" y2="18" />\n' +
                    '                </svg>\n' +
                    '              </a>\n' +
                    '            </span>\n'
                )
        }

        $('.date').datepicker({
            dateFormat: "dd/mm/yy",
            defaultDate: 1,
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showButtonPanel: true,
            yearRange: '1920:2121',
            minDate: new Date(minDate),
            maxDate: new Date(maxDate),
            onSelect: function (dateText, inst) {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            }
        });
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }
}
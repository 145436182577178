import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')

export default class extends Controller{
    connect(){

        $('.date-range-date').datepicker({
            minDate: 0,
            numberOfMonths: [12,1],
            beforeShowDay: function (date) {
               let minDate =  $.datepicker.parseDate($.datepicker._defaults.dateFormat,$('#date-min').val());
               let maxDate =  $.datepicker.parseDate($.datepicker._defaults.dateFormat,$('#date-max').val());
               return [true,minDate &&((date.getTime() == minDate.getTime()) || (maxDate && date >=minDate && date <= maxDate)) ? "dp-highlight" : ""];
            },

            onSelect: function (dateText,inst){
                let minDate =  $.datepicker.parseDate($.datepicker._defaults.dateFormat,$('#date-min').val());
                let maxDate =  $.datepicker.parseDate($.datepicker._defaults.dateFormat,$('#date-max').val());
                let selectDate = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dateText)
                   if(!minDate || maxDate){
                       $('#date-min').val(dateText);
                       $('#date-max').val("");
                       $(this).datepicker();
                   }
                   else if( selectDate < minDate){
                       $('#date-max').val($('#date-min').val());
                       $('#date-min').val(dateText);
                       $(this).datepicker();
                   } else{
                       $('#date-max').val(dateText);
                       $(this).datepicker();
                   }

                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            }
        });

        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);

    }
}
import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        $('#travel_agency').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#travel_agency").on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
    }


    /**
     * Encontrar los contratos comerciales de una Agencia de viaje
     * */
    findContractsByAgency(e) {
        // let url = this.data.get("url");
        let url = "/company/commercial/travel_agencies/find_commercial_contracts";
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {c2e_agency_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#contract_code").empty();
                $("#contract_code").append('<option selected="selected" value=""> Seleccione el contrato comercial</option>')
                if (res !== undefined) {
                    $.each(res.commercial_contracts, function (index, commercial_contract) {
                        $("#contract_code").append(
                            $("<option>")
                                .text(commercial_contract.name)
                                .attr("value", commercial_contract.code)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status === 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status === 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status === 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status === 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status === 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status === 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
    }

    findTravelAgencyFromSDA(e) {
        if (e.target.value !== '' && e.target.value !== null) {
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: this.data.get('url'),
                data: {entity_id: e.target.value},
                success: function (res) {
                    $.unblockUI();
                    $("#sda_agency_id").empty();
                    if (res !== undefined) {
                        $.each(res.sda_agencies, function (index, agency) {
                            $("#sda_agency_id").append(
                                $("<option>")
                                    .text(agency.name)
                                    .attr("value", agency.id)
                            );
                        });
                    }
                    if (res.status === 'FAIL'){
                        toastr_message('error', res.error_msg);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    foreignAccountActive(e){
        let currency = e.target.value;
        if (currency === ''){
            $('#foreign_account_name').attr('readonly', true);
            $('#foreign_account_number').attr('readonly', true);
            $('#foreign_account_number').attr('required', false);
            $('#foreign_account_name').val(null);
            $('#foreign_account_number').val(null);
        } else {
            $('#foreign_account_number').attr('required', true);
            $('#foreign_account_name').attr('readonly', false);
            $('#foreign_account_number').attr('readonly', false);
        }
    }
}
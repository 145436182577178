import {Controller} from "stimulus"
import $ from 'jquery';
import {ajaxErrorMessages} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');
export default class extends Controller {
    connect() {}

    hostTestPort(e){
        let url = this.data.get("url");
        let host_id = e.target.value;
        let test_port = $(`#test_port_${host_id}`).val();
        if (host_id !== undefined && test_port !== undefined) {
            $.blockUI({ message:false });
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: { id: host_id, test_port: test_port },
                success: function (res) {
                    $.unblockUI();
                    $(`#telnet_msg_${host_id}`).text(res.output);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }
}
import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');

function myToFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export default class extends Controller {
    connect() {
    }

    previewAdvance(e) {
        this.block($('#table_preview'), 'Vista previa: Factura de Riesgo ...');
        let btn_advance = $("#btn-advance");
        let contracts_with_release = btn_advance.data('contracts_with_release');
        let contracts_only_rent = btn_advance.data('contracts_only_rent');
        let period = btn_advance.data('period');
        let release_not_executed = btn_advance.data('release_not_exec');
        let cup_rate = btn_advance.data('cup_rate');
        let tr_preview = $('#tr_preview');
        //-------------------------------------
        $('#td_modality')[0].innerText = 'RIESGO'
        $('#td_period')[0].innerText = period
        // Si existe limpiar la sección dinámica
        $('.tr-dynamic-section').remove();
        tr_preview.after(`<tr class="tr-dynamic-section"></tr>`);
        //--------------------------------------
        let total_import_cents = 0;
        let flag_release = false;
        if (contracts_with_release !== undefined) {
            //AGREGANDO ENCABEZADO
            $('.tr-dynamic-section:last').after(`
               <tr class="tr-dynamic-section strong text-center">
                  <td class="col-1"><h6>CÓDIGO</h6></td>
                  <td class="col-2" colspan="3"><h6>DESCRIPCIÓN</h6></td>
                  <td><h6>UM</h6></td>
                  <td><h6>CANTIDAD</h6></td>
                  <td><h6>PRECIO USD</h6></td>
                  <td><h6>IMPORTE USD</h6></td>
                  <td><h6>IMPORTE CUP</h6></td>
               </tr>
            `);
            let contract_import_cents = 0;
            $.each(contracts_with_release, function (sck_key, stock) {
                contract_import_cents += stock.import_usd.cents;
                $('.tr-dynamic-section:last').after(`
                  <tr class="tr-dynamic-section">
                     <td class="col-1 text-center">${stock.tariff_code}</td>
                     <td class="col-2" colspan="3">${stock.description}</td>
                     <td class="text-center">días renta</td>
                     <td class="text-center">${stock.amount}</td>
                     <td class="text-right">${myToFixed(stock.price.cents / 100, 2)}</td>
                     <td class="text-right">${myToFixed(stock.import_usd.cents / 100, 2)}</td>
                     <td class="text-right">${myToFixed(stock.import_usd.cents / 100 * cup_rate, 2)}</td>
                  </tr>
                `);
            });
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
              <tr class="tr-dynamic-section">
                <td class="text-center strong" colspan="7">TOTAL, O SUB TOTAL DE LA FACTURA CON SEGURO</td>
                <td class="text-right strong">${myToFixed(contract_import_cents / 100, 2)}</td>
                <td class="text-right strong">${myToFixed(contract_import_cents / 100 * cup_rate, 2)}</td>
              </tr>
            `);
            total_import_cents += contract_import_cents;
        }
        // Relevo no ejecutado, disminuirlo del total de la factura
        if (release_not_executed > 0) {
            flag_release = true;
            $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section">
                  <td class="text-center strong" colspan="7">RELEVO DE RESPONSABILIDAD NO EJECUTADO</td>
                  <td class="text-right strong">${myToFixed(release_not_executed / 100, 2)}</td>
                  <td class="text-right strong">${myToFixed(release_not_executed / 100 * cup_rate, 2)}</td>
                </tr>
            `);
            total_import_cents -= release_not_executed;
        }
        if ((contracts_with_release !== undefined && contracts_only_rent !== undefined) || flag_release) {
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section">
                  <td class="text-center strong" colspan="7">TOTAL DE LA FACTURA</td>
                  <td class="text-right strong">${myToFixed(total_import_cents / 100, 2)}</td>
                  <td class="text-right strong">${myToFixed(total_import_cents / 100 * cup_rate, 2)}</td>
                </tr>
            `);
        }
        $('#table_preview').unblock();
    }

    previewOverdraft(e) {
        this.block($('#table_preview'), 'Vista previa: Sobregiro ...');
        let btn_overdraft = $("#btn-overdraft");
        let store = btn_overdraft.data('store');
        let period = btn_overdraft.data('period');
        let cup_rate = btn_overdraft.data('cup_rate');
        let tr_preview = $('#tr_preview');
        //-------------------------------------
        $('#td_modality')[0].innerText = 'SOBREGIRO'
        $('#td_period')[0].innerText = period
        // Si existe limpiar la sección dinámica
        $('.tr-dynamic-section').remove();
        tr_preview.after(`<tr class="tr-dynamic-section"></tr>`);
        //--------------------------------------
        let total_import_cents = 0;
        $.each(store, function (key, payment) {
            let contract = JSON.parse(payment.contract);
            let tariff = JSON.parse(payment.tariff);
            let overdraft_services = payment.overdraft_services;
            // AGREGANDO ENCABEZADO
            $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section strong text-center">
                    <td class="col-1"><h6>CÓDIGO</h6></td>
                    <td class="col-2" colspan="3"><h6>DESCRIPCIÓN</h6></td>
                    <td><h6>UM</h6></td>
                    <td><h6>CANTIDAD</h6></td>
                    <td><h6>PRECIO USD</h6></td>
                    <td><h6>IMPORTE USD</h6></td>
                    <td><h6>IMPORTE CUP</h6></td>
                </tr>
            `);
            let contract_import_cents = 0;
            $.each(overdraft_services, function (s_key, service) {
                contract_import_cents += service.advance_price.cents * service.overdraft_days;
                $('.tr-dynamic-section:last').after(`
                   <tr class="tr-dynamic-section">
                        <td class="col-1 text-center">${tariff.code}</td>
                        <td class="col-2" colspan="3">${service.service_name}</td>
                        <td class="text-center">días renta</td>
                        <td class="text-center">${service.overdraft_days}</td>
                        <td class="text-right">${myToFixed(service.advance_price.cents / 100, 2)}</td>
                        <td class="text-right">${myToFixed((service.advance_price.cents * service.overdraft_days) / 100, 2)}</td>
                        <td class="text-right">${myToFixed((service.advance_price.cents * service.overdraft_days) / 100 * cup_rate, 2)}</td>
                    </tr>
               `);
            });
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
                   <tr class="tr-dynamic-section">
                        <td class="text-center strong" colspan="7">TOTAL, O SUB TOTAL DE LA FACTURA ${contract.advance_insurance_bill === true ? "CON SEGURO" : "SIN SEGURO"}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100, 2)}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100 * cup_rate, 2)}</td>
                    </tr>
               `);
            total_import_cents += contract_import_cents;
        });
        if (store.length > 1) {
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
              <tr class="tr-dynamic-section">
                <td class="text-center strong" colspan="7">TOTAL DE LA FACTURA</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100, 2)}</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100 * cup_rate, 2)}</td>
              </tr>
           `);
        }
        $('#table_preview').unblock();
    }

    previewAvailability() {
        this.block($('#table_preview'), 'Vista previa: Disponibilidad ...');
        let btn_availability = $("#btn-availability");
        let store = btn_availability.data('store');
        let period = btn_availability.data('period');
        let cup_rate = btn_availability.data('cup_rate');
        let tr_preview = $('#tr_preview');
        //--------------------------------
        $('#td_modality')[0].innerText = 'DISPONIBILIDAD'
        $('#td_period')[0].innerText = period
        $('.tr-dynamic-section').remove(); // Si existe limpiar la sección dinámica
        tr_preview.after(`<tr class="tr-dynamic-section"></tr>`);
        //----------------------------------
        // AGREGANDO ENCABEZADO
        let total_import_cents = 0
        $.each(store, function (key, payment) {
            let contract = JSON.parse(payment.contract);
            let group_services = JSON.parse(payment.group_services);
            // AGREGANDO ENCABEZADO
            $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section strong text-center">
                    <td class="col-1"><h6>CÓDIGO</h6></td>
                    <td class="col-2" colspan="4"><h6>DESCRIPCIÓN</h6></td>
                    <td><h6>UM</h6></td>
                    <td><h6>CANTIDAD</h6></td>
                    <td><h6>IMPORTE USD</h6></td>
                    <td><h6>IMPORTE CUP</h6></td>
                </tr>
            `);
            let contract_import_cents = 0;
            $.each(group_services, function (s_key, service) {
                contract_import_cents += service.total_rent_import_cents + service.total_release_import_cents
                $('.tr-dynamic-section:last').after(`
                   <tr class="tr-dynamic-section">
                        <td class="col-1 text-center">${service.tariff_code}</td>
                        <td class="col-2" colspan="4">${service.service_name}</td>
                        <td class="text-center">días renta</td>
                        <td class="text-center">${service.rent_days}</td>
                        <td class="text-right">${myToFixed((service.total_rent_import_cents + service.total_release_import_cents) / 100, 2)}</td>
                        <td class="text-right">${myToFixed((service.total_rent_import_cents + service.total_release_import_cents) / 100 * cup_rate, 2)}</td>
                    </tr>
                `);
            });
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
                   <tr class="tr-dynamic-section">
                        <td class="text-center strong" colspan="7">TOTAL, O SUB TOTAL DE LA FACTURA ${contract.advance_insurance_bill === true ? "CON SEGURO" : "SIN SEGURO"}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100, 2)}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100 * cup_rate, 2)}</td>
                    </tr>
               `);
            total_import_cents += contract_import_cents;
        });
        if (store.length > 1) {
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
              <tr class="tr-dynamic-section">
                <td class="text-center strong" colspan="7">TOTAL DE LA FACTURA</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100, 2)}</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100 * cup_rate, 2)}</td>
              </tr>
           `);
        }
        $('#table_preview').unblock();
    }

    // Para facturas de Otros cobros, ya sea para contratos de Riesgo como de Disponibilidad.
    previewOtherPayments(e) {
        this.block($('#table_preview'), 'Vista previa: Factura de Otros Cobros ...');
        let btn_other_payments = $(`#${this.data.get("btn_id")}`);
        let store = btn_other_payments.data('store');
        let period = btn_other_payments.data('period');
        let cup_rate = btn_other_payments.data('cup_rate');
        let tr_preview = $('#tr_preview');
        //-------------------------------------
        $('#td_modality')[0].innerText = 'OTROS COBROS'
        $('#td_period')[0].innerText = period
        // Si existe limpiar la sección dinámica
        $('.tr-dynamic-section').remove();
        tr_preview.after(`<tr class="tr-dynamic-section"></tr>`);
        //--------------------------------------
        let total_import_cents = 0;
        $.each(store, function (key, payment) {
            let contract = JSON.parse(payment.contract);
            let group_concepts = JSON.parse(payment.group_concepts);
            // AGREGANDO ENCABEZADO
            $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section strong text-center">
                    <td class="col-1"><h6>No.</h6></td>
                    <td class="col-3" colspan="4"><h6>DESCRIPCIÓN</h6></td>
                    <td><h6>UM</h6></td>
                    <td><h6>CANTIDAD</h6></td>
                    <td><h6>IMPORTE USD</h6></td>
                    <td><h6>IMPORTE CUP</h6></td>
                </tr>
            `);
            let i = 1;
            let contract_import_cents = 0;
            $.each(group_concepts, function (group_key, concept) {
                contract_import_cents += concept.import.cents
                $('.tr-dynamic-section:last').after(`
                 <tr class="tr-dynamic-section">
                   <td class="col-1 text-center">${i}</td>
                   <td class="col-3" colspan="4">${concept.description}</td>
                   <td class="text-center">${concept.um}</td>
                   <td class="text-center">${concept.count}</td>
                   <td class="text-right">${myToFixed(concept.import.cents / 100, 2)}</td>
                   <td class="text-right">${myToFixed(concept.import.cents / 100 * cup_rate, 2)}</td>
                 </tr>
              `);
                i++;
            });
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
                   <tr class="tr-dynamic-section">
                        <td class="text-center strong" colspan="7">TOTAL, O SUB TOTAL DE LA FACTURA ${contract.advance_insurance_bill === true ? "CON SEGURO" : "SIN SEGURO"}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100, 2)}</td>
                        <td class="text-right strong">${myToFixed(contract_import_cents / 100 * cup_rate, 2)}</td>
                    </tr>
            `);
            total_import_cents += contract_import_cents;
        });
        if (store.length > 1) {
            // TOTAL o SUB-TOTAL DE LA FACTURA
            $('.tr-dynamic-section:last').after(`
              <tr class="tr-dynamic-section">
                <td class="text-center strong" colspan="7">TOTAL DE LA FACTURA</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100, 2)}</td>
                <td class="text-right strong">${myToFixed(total_import_cents / 100 * cup_rate, 2)}</td>
              </tr>
           `);
        }
        $('#table_preview').unblock();
    }

    // Para facturas manuales
    previewManual(e) {
        this.block($('#table_preview'), 'Vista previa: Factura Manual ...');
        let btn_manual = $("#btn_manual");
        let modality = $('#modality option:selected').text().toUpperCase();
        let tr_preview = $('#tr_preview');
        //-------------------------------------
        $('#td_modality')[0].innerText = modality
        $('#td_period')[0].innerText = "";
        // Si existe limpiar la sección dinámica
        $('.tr-dynamic-section').remove();
        tr_preview.after(`<tr class="tr-dynamic-section"></tr>`);
        //-------------------------------------
        // AGREGANDO ENCABEZADO
        $('.tr-dynamic-section:last').after(`
                <tr class="tr-dynamic-section strong text-center">
                    <td class="col-1"><h6>CÓDIGO</h6></td>
                    <td class="col-3" colspan="4"><h6>DESCRIPCIÓN</h6></td>
                    <td><h6>UM</h6></td>
                    <td><h6>CANTIDAD</h6></td>
                    <td><h6>PRECIO</h6></td>
                    <td><h6>IMPORTE USD</h6></td>
                    <td><h6>IMPORTE CUP</h6></td>
                </tr>
        `);
        let total_import_cents = 0;
        $.each($('.rows'), function (key, row) {
            $('.tr-dynamic-section:last').after(`
                 <tr class="tr-dynamic-section">
                   <td class="col-1 text-center">${$(`#code_${key + 1}`)[0].value}</td>
                   <td class="col-3" colspan="4">${$(`#desc_${key + 1}`)[0].value}</td>
                   <td class="text-center">${$(`#um_${key + 1}`)[0].value}</td>
                   <td class="text-center">${$(`#cant_${key + 1}`)[0].value}</td>
                   <td class="text-center">${$(`#price_usd_${key + 1}`)[0].value}</td>
                   <td class="text-right">${$(`#amount_usd_${key + 1}`)[0].value}</td>
                   <td class="text-right">${$(`#amount_cup_${key + 1}`)[0].value}</td>
                 </tr>
              `);
        });
        // TOTAL o SUB-TOTAL DE LA FACTURA
        $('.tr-dynamic-section:last').after(`
           <tr class="tr-dynamic-section">
               <td class="text-center strong" colspan="8">TOTAL, O SUB TOTAL DE LA FACTURA</td>
               <td class="text-right strong">${$("#sub_total_usd").text()}</td>
               <td class="text-right strong">${$("#sub_total_cup").text()}</td>
           </tr>
        `);
        $('#table_preview').unblock();
    }

    block(element, message) {
        element.block({
            message: message,
            css: {
                border: 'none',
                padding: '15px',
                backgroundColor: '#000',
                '-webkit-border-radius': '10px',
                '-moz-border-radius': '10px',
                opacity: .5,
                color: '#fff'
            }
        });
    }

    // Factura mannual
    addManualBillingItems(e) {
        const items = $("#bill-manuals-items");
        const kids = items.children();
        items.append(`
            <tr id='tr-${kids.length + 1}' class="rows">
                <td class="p-0"><input id="code_${kids.length + 1}" name="rows[${kids.length + 1}][code]" class="form-control p-1 font-smaller items" required="true"/></td> 
                <td class="p-0"><input id="desc_${kids.length + 1}" name="rows[${kids.length + 1}][desc]" class="form-control p-1 font-smaller items" required="true"/></td>
                <td class="p-0"><input id="um_${kids.length + 1}" name="rows[${kids.length + 1}][um]" class="form-control p-1 font-smaller items" required="true"/></td>
                <td class="p-0"><input id="cant_${kids.length + 1}" name="rows[${kids.length + 1}][amount]" class="form-control p-1 font-smaller text-center" required="true" pattern="[0-9.]+" data-action="change->billing#calImport"/></td>
                <td class="p-0"><input id="price_usd_${kids.length + 1}" name="rows[${kids.length + 1}][price_usd]" class="form-control p-1 font-smaller text-center" required="true" pattern="[0-9.]+" data-action="change->billing#calImport"/></td>
                <td class="p-0"><input id="price_cup_${kids.length + 1}" name="rows[${kids.length + 1}][price_cup]" value="0" class="form-control p-1 font-smaller text-center" disabled required="true" pattern="[0-9.]+" data-action="change->billing#calImport"/></td>
                <td class="p-0"><input id="amount_usd_${kids.length + 1}" class="form-control usd_item p-1 font-smaller text-right"  disabled="true" value="0.00" /></td>
                <td class="p-0"><input id="amount_cup_${kids.length + 1}" class="form-control cup_item p-1 font-smaller text-right" disabled="true" value="0.00" /></td>
                <td class="p-0"><input id="amount_cl_${kids.length + 1}" name="rows[${kids.length + 1}][cl]" class="form-control cl_item p-1 font-smaller text-right" pattern="[0-9.]+" value="0.00" data-action="change->billing#totalManualCl"/></td>
                <td class="p-0" style="display: flex;
              padding-bottom: 0 !important;
              align-items: center;
              justify-content: center;
              height: 34px;">
                  <button id="remove_item_${kids.length + 1}" data-action="click->billing#removeManualBillingItems" data-value='tr-${kids.length + 1}'
                          type="button" class="border-0 btn btn-outline-danger btn-icon font-smaller" 
                          style="height: 100%;">
                          <span class="fa fa-trash" data-value='tr-${kids.length + 1}'></span>
                  </button>
                </td>
          </tr>
        `);
    }

    removeManualBillingItems(e) {
        let row_item = e.target.dataset.value;
        if (row_item === undefined) {
            parent = e.target.parentNode;
            row_item = parent.dataset.value;
        }
        $(`#${row_item}`).remove();
        this.totalUsd();
        this.totalCup();
        this.totalManualCl();
    }

    totalUsd() {
        let totalUsd = 0.00;
        $.each($('.usd_item'), function (key, item) {
            totalUsd += parseFloat(item.value)
        });
        $("#sub_total_usd").html(myToFixed(totalUsd, 2));
    }

    totalCup() {
        let totalCup = 0.00;
        $.each($('.cup_item'), function (key, item) {
            totalCup += parseFloat(item.value);
        });
        $("#sub_total_cup").html(myToFixed(totalCup, 2));
    }

    totalManualCl() {
        let totalCl = 0.00;
        $.each($('.cl_item'), function (key, item) {
            totalCl += parseFloat(item.value);
        });
        $("#sub_total_cl").html(myToFixed(totalCl, 2));
    }

    calImport(e) {
        let currency_to_bill = $("#table_manual").data('currency_to_bill');
        let cup_rate = parseFloat($("#table_manual").data('cup_rate'));
        let price = 0.0
        let arr_suffix = e.target.id.split('_');
        let row_suffix = arr_suffix[arr_suffix.length - 1]
        let cant = $(`#cant_${row_suffix}`).val()
        let price_usd = $(`#price_usd_${row_suffix}`).val()
        let price_cup = 0;
        if (currency_to_bill === 'CUP') {
            if ($.isNumeric(price_usd))
                price_cup = myToFixed(parseFloat(price_usd) * cup_rate, 2);
            $(`#price_cup_${row_suffix}`).val(price_cup)
        }
        if ($.isNumeric(cant) && $.isNumeric(price_usd)) {
            price = parseFloat(cant) * parseFloat(price_usd);
            $(`#amount_usd_${row_suffix}`).val(myToFixed(price, 2));
        } else {
            $(`#amount_usd_${row_suffix}`).val(0.0);
        }
        if ($.isNumeric(cant) && $.isNumeric(price_cup)) {
            price = parseFloat(cant) * parseFloat(price_cup);
            $(`#amount_cup_${row_suffix}`).val(myToFixed(price, 2));
        } else {
            $(`#amount_cup_${row_suffix}`).val(0.0);
        }
        this.totalUsd();
        this.totalCup();
    }

    totalCl(e) {
        let totalCl = 0.00;
        let suffix = e.target.dataset.contract;
        $.each($(`.form_cl_${suffix}`), function (key, item) {
            if ($.isNumeric(item.value)) {
                totalCl += parseFloat(item.value);
            }
        });
        $(`#total_cl_${suffix}`).html(myToFixed(totalCl, 2));
    }

    showPreview(e) {
        document.getElementById('full_view').classList.toggle('d-none');
    }

    overdraftCl(e) {
        let totalCl = 0.00;
        let suffix = e.target.dataset.contract;
        $.each($(`.form_cl_risk_overdraft_${suffix}`), function (key, item) {
            if ($.isNumeric(item.value)) {
                totalCl += parseFloat(item.value);
            }
        });
        $("#total_cl_risk").html(myToFixed(totalCl, 2));
    }

    otherPaymentCl(e) {
        let totalCl = 0.00;
        let suffix = e.target.dataset.contract;
        $.each($(`.form_cl_other_payment_${suffix}`), function (key, item) {
            if ($.isNumeric(item.value)) {
                totalCl += parseFloat(item.value);
            }
        });
        $(`#total_cl_other_payment${suffix}`).html(myToFixed(totalCl, 2));
    }

    availabilityCl(e) {
        let totalCl = 0.00;
        let suffix = e.target.dataset.contract;
        $.each($(`.form_cl_availability_${suffix}`), function (key, item) {
            if ($.isNumeric(item.value)) {
                totalCl += parseFloat(item.value);
            }
        });
        $(`#total_availability${suffix}`).html(myToFixed(totalCl, 2));
    }

    /**
     * Construir factura de disponibilidad
     */
    buildAvailability(e) {
        let url = this.data.get('url_availability');
        let clicked = e.target.dataset.tab_clicked;
        if (clicked === 'false') {
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                success: function (res) {
                    $.unblockUI();
                    if (res.status === 'ERROR') {
                        toastr_message('error', res.message, 'Filtro incorrecto')
                    } else if (res.status === 'OK') {
                        // Marcar como clickeado el tab para no reconsultar por gusto
                        e.target.dataset.tab_clicked = 'true';
                        /**
                         * ---------------------------------------------------------------------------------------------
                         * FACTURA DISPONIBILIDAD
                         * ---------------------------------------------------------------------------------------------
                         */
                        let body_tr_availability = $('#body_tr_availability');
                        let availability_billing = res.availability_billing;
                        let availability_data = JSON.parse(availability_billing.group_availability_services);
                        let availability_errors = JSON.parse(availability_billing.errors);
                        //----------------------------------------------------------------------------------------------
                        // Limpiar la sección de errores si no está vacio OTROS COBROS
                        if (JSON.stringify(availability_errors) !== "{}") {
                            let div_errors = $('#div_warning_availability');
                            div_errors.children().remove();
                            div_errors.addClass("alert alert-warning alert-dismissible fade show");
                            $.each(availability_errors, function (key, error) {
                                div_errors.append(`<ul><li><strong>${key}</strong> ${error[0]}</li></ul>`);
                            });
                        }
                        if (availability_billing.is_billing) {
                            // Si existe limpiar la sección dinámica
                            $('.tr-dynamic-section_availability').remove();
                            body_tr_availability.after(`<tr class="tr-dynamic-section_availability"></tr>`);
                            //--------------------------------------------------------------------------------------------
                            $.each(availability_data, function (key, service) {
                                $('.tr-dynamic-section_availability:last').after(`
                                   <tr class="tr-dynamic-section_availability">
                                      <td>${service.tariff_code}</td>
                                      <td>${service.description}</td>
                                      <td>${service.um}</td>
                                      <td>${service.amount}</td>
                                      <td>${myToFixed(service.import_usd.cents / 100, 2)}</td>
                                      <td>0.00</td>
                                  </tr>
                                `);
                            });
                            // Totalizando
                            $('#td_total_availability_import_usd')[0].innerText = availability_billing.import_usd;
                            $('#td_total_availability_import_cup')[0].innerText = availability_billing.import_cup;
                        }
                        /**
                         * ---------------------------------------------------------------------------------------------
                         * FACTURA DE OTROS COBROS DE DISPONIBILIDAD
                         * ---------------------------------------------------------------------------------------------
                         * */
                        let tr_availability_other_preview = $('#tr_availability_other_preview');
                        let availability_other_billing = res.availability_other_billing;
                        let availability_other_data = JSON.parse(availability_other_billing.other_contract_details);
                        let availability_other_errors = JSON.parse(availability_other_billing.errors);
                        //----------------------------------------------------------------------------------------------
                        // Limpiar la sección de errores si no está vacio OTROS COBROS
                        if (JSON.stringify(availability_other_errors) !== "{}") {
                            let div_errors = $('#div_warning_availability_other');
                            div_errors.children().remove();
                            div_errors.addClass("alert alert-warning alert-dismissible fade show");
                            $.each(availability_other_errors, function (key, error) {
                                div_errors.append(`<ul><li><strong>${key}</strong> ${error[0]}</li></ul>`);
                            });
                        }
                        if (availability_other_billing.is_billing) {
                            //------------------------------------------------------------------------------------------
                            // Si existe limpiar la sección dinámica
                            $('.tr-dynamic-section_availability_other').remove();
                            tr_availability_other_preview.after(`<tr class="tr-dynamic-section_availability_other"></tr>`);
                            //------------------------------------------------------------------------------------------
                            // Llenar la tabla de importes
                            let i = 1;
                            $.each(availability_other_data, function (key, concept) {
                                $('.tr-dynamic-section_availability_other:last').after(`
                                <tr class="tr-dynamic-section_availability_other">
                                    <td>${i}</td>
                                    <td>${concept.description}</td>
                                    <td>${concept.um}</td>
                                    <td>${concept.amount}</td>
                                    <td>${myToFixed(concept.import_usd.cents / 100, 2)}</td>
                                    <td>0.00</td>
                                </tr>
                            `);
                                i++;
                            });
                            // Totalizando
                            $('#td_total_availability_other_import_usd')[0].innerText = availability_other_billing.import_usd;
                            $('#td_total_availability_other_import_cup')[0].innerText = availability_other_billing.import_cup;
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    buildRiskOther(e) {
        let url = this.data.get('url_risk_other');
        let clicked = e.target.dataset.tab_clicked;
        if (clicked === 'false') {
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                success: function (res) {
                    $.unblockUI();
                    if (res.status === 'ERROR') {
                        toastr_message('error', res.message, 'Filtro incorrecto')
                    } else if (res.status === 'OK') {
                        // Marcar como clickeado el tab para no reconsultar por gusto
                        e.target.dataset.tab_clicked = 'true';
                        /**
                         * ---------------------------------------------------------------------------------------------
                         * FACTURA OTROS COBROS DE RIESGO
                         * ---------------------------------------------------------------------------------------------
                         */
                        let tr_other_preview = $('#tr_other_preview');
                        let risk_other_billing = res.risk_other_billing;
                        let risk_other_data = JSON.parse(risk_other_billing.other_contract_details);
                        let risk_other_errors = JSON.parse(risk_other_billing.errors);
                        //----------------------------------------------------------------------------------------------
                        // Limpiar la sección de errores si no está vacio OTROS COBROS
                        if (JSON.stringify(risk_other_errors) !== "{}") {
                            let div_errors = $('#div_warning_risk_other');
                            div_errors.children().remove();
                            div_errors.addClass("alert alert-warning alert-dismissible fade show");
                            $.each(risk_other_errors, function (key, error) {
                                div_errors.append(`<ul><li><strong>${key}</strong> ${error[0]}</li></ul>`);
                            });
                        }
                        if (risk_other_billing.is_billing) {
                            //------------------------------------------------------------------------------------------
                            // Si existe limpiar la sección dinámica
                            $('.tr-dynamic-section_other').remove();
                            tr_other_preview.after(`<tr class="tr-dynamic-section_other"></tr>`);
                            //------------------------------------------------------------------------------------------
                            // Llenar la tabla de importes
                            let i = 1;
                            $.each(risk_other_data, function (key, concept) {
                                $('.tr-dynamic-section_other:last').after(`
                                <tr class="tr-dynamic-section_other">
                                    <td>${i}</td>
                                    <td>${concept.description}</td>
                                    <td>${concept.um}</td>
                                    <td>${concept.amount}</td>
                                    <td>${myToFixed(concept.import_usd.cents / 100, 2)}</td>
                                    <td>0.00</td>
                                </tr>
                            `);
                                i++;
                            });
                            // Totalizando
                            $('#td_total_contract_import_usd')[0].innerText = risk_other_billing.import_usd;
                            $('#td_total_contract_import_cup')[0].innerText = risk_other_billing.import_cup;
                        }
                        /**
                         * ---------------------------------------------------------------------------------------------
                         * FACTURA DE SOBREGIRO
                         * ---------------------------------------------------------------------------------------------
                         * */
                        let body_tr_overdraft = $('#body_tr_overdraft');
                        let overdraft_billing = res.overdraft_billing;
                        let risk_overdraft_data = overdraft_billing.overdraft_contract_details;
                        let risk_overdraft_errors = JSON.parse(overdraft_billing.errors);
                        //----------------------------------------------------------------------------------------------
                        // Limpiar la sección de errores si no está vacio
                        if (JSON.stringify(risk_overdraft_errors) !== "{}") {
                            let div_errors = $('#div_warning_risk_overdraft');
                            div_errors.children().remove();
                            div_errors.addClass("alert alert-warning alert-dismissible fade show");
                            $.each(risk_overdraft_errors, function (key, error) {
                                div_errors.append(`<ul><li><strong>${key}</strong> ${error[0]}</li></ul>`);
                            });
                        }
                        if (overdraft_billing.is_billing) {
                            // Si existe limpiar la sección dinámica
                            $('.tr-dynamic-section_overdraft').remove();
                            body_tr_overdraft.after(`<tr class="tr-dynamic-section_overdraft"></tr>`);
                            //--------------------------------------------------------------------------------------------
                            $.each(risk_overdraft_data, function (key, payment) {
                                // let contract = payment.contract;
                                let tariff = payment.tariff;
                                let overdraft_services = payment.overdraft_services;
                                $.each(overdraft_services, function (s_key, service) {
                                    $('.tr-dynamic-section_overdraft:last').after(`
                                       <tr class="tr-dynamic-section_overdraft">
                                            <td>${tariff !== undefined ? tariff.code : service.tariff_code}</td>
                                            <td>${service.service_name}</td>
                                            <td>días renta</td>
                                            <td>${service.overdraft_days}</td>
                                            <td>${myToFixed(service.advance_price.cents / 100, 2)}</td>
                                            <td>${myToFixed((service.advance_price.cents * service.overdraft_days) / 100, 2)}</td>
                                            <td>0.00</td>
                                        </tr>
                                   `);
                                });
                            });
                            // Totalizando
                            $('#td_total_overdraft_import_usd')[0].innerText = overdraft_billing.import_usd;
                            $('#td_total_overdraft_import_cup')[0].innerText = overdraft_billing.import_cup;
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

}
import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
export default class extends Controller {
    connect() {
        $('.month_year_date').datepicker({
            dateFormat: "mm-yy",
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            onClose: function(dateText, inst) {
                function isDonePressed(){
                    return ($('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
                }
                if (isDonePressed()){
                    let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                    let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                    $(this).datepicker('setDate', new Date(year, month, 1)).trigger('change');
                    $('.month_year_date').focusout();
                }
                },
            beforeShow : function(datestr, inst) {
                inst.dpDiv.addClass('month_year_datepicker')
                if ((datestr = $(this).val()).length > 0) {
                    let year = datestr.substring(datestr.length-4, datestr.length);
                    let month = datestr.substring(0, 2);
                    $(this).datepicker('option', 'defaultDate', new Date(year, month-1, 1));
                    $(this).datepicker('setDate', new Date(year, month-1, 1));
                    $(".ui-datepicker-calendar").hide();
                }
                }
        });

        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            weekHeader: 'Sm',
            dateFormat: 'mm-yy',
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }
}
import {Controller} from "stimulus";
import $ from "jquery";
import {atob} from "buffer";
require('block-ui/jquery.blockUI');

function isEmpty(value) {
    return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
}
function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intente nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intente nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}
function dirty_change(el) {
    var price_str = $(el).data('price');
    var val_str = $(el).val();
    var price = parseFloat(price_str);
    var val = parseFloat(val_str);
    if (price_str != val_str && price != val) {
        $(el).addClass('dirty-change');
    } else {
        $(el).removeClass('dirty-change');
    }
}
export default class extends Controller {
    connect() {
        /**
         * Función que cuando cambia los imput le pone la clase.
         */
        $('#prices-sheet .price-rule input').on('input', function () {
            dirty_change(this);
        });

    }

    /**
     * FUNCIÓN PARA REFRESCAR LOS CAMPOS QUE SE HAN MODIFICADO.
     * @param e
     */
    refresh(e){
        let code = this.data.get("code");
        let price_rules = $('#' + code + ' .price-rule input');
        price_rules.each(function (idx, el) {
            let input = $(el);
            let price = input.data('price');
            input.val(price);
        });
        price_rules.removeClass('dirty-change errors');
    }

    /**
     * FUNCIÓN QUE MARCA TODOS LOS CHECK DE ENLAZAR.
     * @param e
     */
    linkAll(e){
        let checkBox = document.getElementById("checkAll");
        if (checkBox.checked == true) {
            $('.entangle').prop('checked', true);
        }else{
            $('.entangle').prop('checked', false);
        }
    }

    /**
     * FUNCIÓN QUE ENLAZA LOS DIFERENTES OPCIONES SELECCIONADAS.
     * @param e
     */
    linkedFields(e){
        let code = this.data.get("code");
        let field = this.data.get("field");
        let season = this.data.get("season");
        let service = this.data.get("service");
        let modality = this.data.get("modality");
        let range = this.data.get("range");
        let data = $('#'+code).val();
        if ($('.entangle:checked').length > 0) {
            console.log(code)
            let price_rules = $('.price-rule input[data-field="' + field + '"]');

            if ($('.entangle.season:checked').length > 0) {
                price_rules = price_rules.filter('[data-season="' + season + '"]');
                console.log(price_rules);
            }
            if ($('.entangle.service:checked').length > 0) {
                price_rules = price_rules.filter('[data-service="' + service + '"]');
            }
            if ($('.entangle.modality:checked').length > 0) {
                price_rules = price_rules.filter('[data-modality="' + modality + '"]');
            }
            if ($('.entangle.range:checked').length > 0) {
                price_rules = price_rules.filter('[data-range="' + range + '"]');
            }
            price_rules.val(data).each(function () {
                dirty_change(this);
            });
        }
    }

    /**
     * ACTALIZA LOS PRECIOS DE UNA TARIFA
     * @param e
     */
    savePrices(e) {
        let url = this.data.get("url");
        let code = this.data.get("code");
        let changed_rules = $('#' + code + ' .price-rule input.dirty-change');
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: changed_rules.serialize(),
            success: function (response) {
                if (response.success) {
                    changed_rules.each(function (idx, el) {
                        let input = $(el);
                        let new_price = input.val().trim();
                        input.data('price', new_price);
                    });
                    changed_rules.removeClass('dirty-change');
                    changed_rules.removeClass('errors');
                } else {
                    let list = response.list;
                    changed_rules.each(function (idx, el) {
                        let input = $(el);
                        let code = input.data('code');
                        let new_price = input.val().trim();
                        if (list.indexOf(code) > -1) {
                            input.addClass('errors');
                        } else {
                            input.data('price', new_price);
                            input.removeClass('dirty-change');
                            input.removeClass('errors');
                        }
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                changed_rules.addClass('errors');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * ACTALIZA TODOS LOS PRECIOS DE UNA TARIFA, EL BOTÓN SALVAR ALL.
     * @param e
     */
    saveAllPrices(e) {
        let url = this.data.get("url");
        let code = this.data.get("code");
        let changed_rules = $(code + ' .price-rule input.dirty-change');
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: changed_rules.serialize(),
            success: function (response) {
                if (response.success) {
                    changed_rules.each(function (idx, el) {
                        let input = $(el);
                        let new_price = input.val().trim();
                        input.data('price', new_price);
                    });
                    changed_rules.removeClass('dirty-change');
                    changed_rules.removeClass('errors');
                } else {
                    let list = response.list;
                    changed_rules.each(function (idx, el) {
                        let input = $(el);
                        let code = input.data('code');
                        let new_price = input.val().trim();
                        if (list.indexOf(code) > -1) {
                            input.addClass('errors');
                        } else {
                            input.data('price', new_price);
                            input.removeClass('dirty-change');
                            input.removeClass('errors');
                        }
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                changed_rules.addClass('errors');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

}

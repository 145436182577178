import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, myToFixed} from "./globalFunctions_controller";
import {toastr_message} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');
require('inputmask/dist/jquery.inputmask');
import {detailsSegmentHtmlBuilder} from "./payment_details_controller";

export let checkAmountPaymentForm = (code) => {
    let payment_form = $(`.${code}_payment_form`);
    let total = 0;
    // Sumar todos los cobros de la forma de pago en cuestion.
    $.each(payment_form, function (key, payment) {
        let amount = parseFloat(payment.value);
        if ($.isNumeric(amount)) {
            total += amount;
        }
    });
    // Actualizando pie de formas de pagos de los cobros
    let span_payment = $(`#${code}_span_payment`);
    if (span_payment.length === 0) {
        $("#tmp_total_payment").append(`
            <div class="col-md-3 col-sm-12 col-xs-12">
                <span id="${code}_span_payment" class="strong"></span>
            </div>
        `);
        span_payment = $(`#${code}_span_payment`);
    }

    // Compararlo con el importe total que tiene en la renta la forma de pago segun sea el caso.
    $(`.${code}_msg_payment`).remove();
    let msg_payment = `Ha excedido el importe, por favor revise los datos de cobros con respecto al monto especificado en la forma de pago.`
    switch (code) {
        case 'EFE':
            let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
            if (cash < total) {
                $("#msg_payment").after(`<div class='alert alert-warning alert-dismissible fade show ${code}_msg_payment' role="alert">
                                                <strong>Efectivo: </strong> ${msg_payment}
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                             </div>`);
            }
            span_payment.text(`Efectivo: ${total}`);
            break;
        case 'TM':
            let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
            if (moment_card < total) {
                $("#msg_payment").after(`<div class='alert alert-warning alert-dismissible fade show ${code}_msg_payment' role="alert">
                                                <strong>Tarjeta al momento: </strong> ${msg_payment}
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                             </div>`);
            }
            span_payment.text(`Tarjeta al momento: ${total}`);
            break;
        case 'CHT':
            let cheque_transfer = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
            if (cheque_transfer < total) {
                $("#msg_payment").after(`<div class='alert alert-warning alert-dismissible fade show ${code}_msg_payment' role="alert">
                                                <strong>Cheque o Transferencia: </strong> ${msg_payment}
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                             </div>`);
            }
            span_payment.text(`Cheque o transferencia: ${total}`);
            break;
        case 'OTR':
            let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
            if (other < total) {
                $("#msg_payment").after(`<div class='alert alert-warning alert-dismissible fade show ${code}_msg_payment' role="alert">
                                                <strong>Otros: </strong> ${msg_payment}
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                             </div>`);
            }
            span_payment.text(`Otros: ${total}`);
            break;
        default:
            break;
    }
    checkAdvancePaymentAtMoment();
}
// Sumar todos los cobros y compararlo con el total del anticipo cobrado al momento.
export let checkAdvancePaymentAtMoment = () => {
    /**
     * Buscando todos los importes de cobros que tienen una clase que termina con *_payment_form
     * y contienen la palabra amount en el id
     * */
    let amount_payments = $("input[class$='_payment_form'][id*='amount']");
    let advance = $(".charge_at_moment").val();
    let total = 0;
    $.each(amount_payments, function (key, payment) {
        let amount = parseFloat(payment.value);
        if ($.isNumeric(amount)) {
            total += amount;
        }
    });
    $('.advance_msg_payment').remove();
    if (total > advance) {
        $("#msg_payment").after(`<div class='alert alert-danger alert-dismissible fade show advance_msg_payment' role="alert">
                                    <strong>ERROR: </strong> Ha excedido el importe total cobrado al momento. 
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                               </div>`);
    } else if (total < advance) {
        $("#msg_payment").after(`<div class='alert alert-warning alert-dismissible fade show advance_msg_payment' role="alert">
                                    <strong>Precaución: </strong> Debe recoger el total del importe cobrado al momento en la sección de cobros.
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                               </div>`);
    }
}
export let deleteAllPayment = () => {
    let msg_payments = $("div[class$='_msg_payment'][class*='alert']");
    let nav_tabs_payments = $("#nav_tabs_payments").children();
    let tab_content_payments = $("#tab_content_payments").children();
    let tmp_total_payment = $("#tmp_total_payment").children();
    // Eliminando los titulos de cada tab
    $.each(nav_tabs_payments, function (key, nav) {
        nav.remove();
    });
    // Eliminando el contenido de los tab
    $.each(tab_content_payments, function (key, tab) {
        tab.remove();
    });
    // Eliminando el total de los cobros
    $.each(tmp_total_payment, function (key, tmp) {
        tmp.remove();
    });
    // Eliminando los mensajes de alertas
    $.each(msg_payments, function (key, msg) {
        msg.remove();
    });
}

function resetFieldPayment(e){
    let code_line = e.target.id.split('_sda_reference_');
    let sda_reference = $(`#${code_line[0]}_sda_reference_${code_line[1]}`);
    let amount = $(`#${code_line[0]}_amount_${code_line[1]}`);
    let first_name = $(`#${code_line[0]}_first_name_${code_line[1]}`);
    let last_name = $(`#${code_line[0]}_last_name_${code_line[1]}`);
    let passport = $(`#${code_line[0]}_passport_${code_line[1]}`);
    let trans_ref = $(`#${code_line[0]}_transaction_reference_${code_line[1]}`);
    let email = $(`#${code_line[0]}_email_${code_line[1]}`);
    e.target.dataset.old_reference = null
    sda_reference[0].value = '';
    amount.attr('value', 0.00);
    amount.trigger('click');
    first_name.attr('value', '');
    last_name.attr('value', '');
    passport.attr('value', '');
    trans_ref.attr('value', '');
    email.attr('value', '');
}
export default class extends Controller {
    connect() {
        $('.rent_id').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#rent_id").on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
        this.operation = $('#payments').data('operation');
    }

    update_client_by_reference(e) {
        let url = this.data.get("url");
        let rent_id = $("#rent_id").val();
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: rent_id},
            success: function (res) {
                if (res !== undefined && res.status === 'OK') {
                    let rent = res.data;
                    $('#nom_client_id').val(rent.client_id).trigger('change');
                    $('#email').val(rent.client_email)
                    $('#identification_number').val(rent.client_dni)
                    $('#phone').val(rent.phone)
                    $('#nom_country_id').val(rent.client_country).trigger('change')
                    $('#name').val(rent.client_name)
                    $('#last_name').val(rent.client_last_name)
                    $('#nationality_id').val(rent.client_nationality).trigger('change');
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }


    /**
     * BUSCA UNA RENTA POR LA REFERENCIA.
     */
    rent_by_reference() {
        let url = this.data.get("url");
        let reference = $("#rent_reference").val();
        if (reference!=""){
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {reference: reference},
                success: function (res) {
                    console.log(res)
                    if (res !== undefined && res.status === 'OK') {
                        $('#search_create_client').prop('disabled',true);
                        let rent = res.data;
                        $('#passport').val(rent.client_dni);
                        $('#rent_name').val(rent.client_name);
                        $('#surname').val(rent.client_last_name);
                        $('#rent_id').val(rent.id);
                         $('#nom_client_id').val(rent.client_id);
                        $('#email').val(rent.client_email);
                        // Validando para cuando exite la deuda en el sistema
                        if ($.isNumeric(rent.debit_amount)) {
                            if (rent.debit_amount > 0) {
                                $('#amount').val(rent.debit_amount);
                                $('#amount').attr("max",rent.debit_amount);
                                toastr_message('info', "Se detectó una deuda registrada en el sistema.", "Deuda por Cobrar");
                            } else if (rent.debit_amount === 0)
                                toastr_message('info', "No se encontró una deuda por cobrar de la renta. Al realizar esta operación automáticamente se generará una deuda con su correspondiente operación de pago.");
                        }
                        $('#client_debit_balance_id').val(rent.client_debit_balance_id);

                        // $('#nom_client_id').val(rent.client_id).trigger('change');
                        // $('#identification_number').val(rent.client_dni);
                        // $('#phone').val(rent.phone);
                        // $('#nom_country_id').val(rent.client_country).trigger('change');
                        // $('#name').val(rent.client_name);
                        // $('#last_name').val(rent.client_last_name);
                        // $('#nationality_id').val(rent.client_nationality).trigger('change');
                    }
                    if (res !== undefined && res.status === 'ERROR') {
                        // $("#rent_reference").val("");

                        toastr_message('warning', 'No se encontró la renta por favor, verifique la referencia.', "Referencia");
                        $('#passport').val('');
                        $('#rent_name').val('');
                        $('#surname').val('');
                        $('#rent_id').val('');
                        $('#nom_client_id').val('');
                        $('#email').val('');
                        $('#search_create_client').prop('disabled',false);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }else {
            toastr_message('warning', 'Debe proporcionar una referencia.', "Referencia");
        }

    }

    search_create_client() {
        if ($('#search_create_client').is(":checked") === true) {
            $('#client_form').css('display', 'inline');
            $('#client_passport').prop('required', true);
            $('#client_name').prop('required', true);
            $('#client_last_name').prop('required', true);
            $('#birth_date').prop('required', true);
            $('#client_email').prop('required', true);
            $('#client_phone').prop('required', true);
            $('#client_driver_license').prop('required', true);
            $('#due_date').prop('required', true);
            $('#license_date').prop('required', true);
            $('#nom_country_id').prop('required', true);
            $('#nationality_id').prop('required', true);
            $('#address').prop('required', true);

        } else {
            $('#client_form').css('display', 'none');
            $('#client_passport').prop('required', false);
            $('#client_name').prop('required', false);
            $('#client_last_name').prop('required', false);
            $('#birth_date').prop('required', false);
            $('#client_email').prop('required', false);
            $('#client_phone').prop('required', false);
            $('#client_driver_license').prop('required', false);
            $('#due_date').prop('required', false);
            $('#license_date').prop('required', false);
            $('#nom_country_id').prop('required', false);
            $('#nationality_id').prop('required', false);
            $('#address').prop('required', false);
        }
    }

    /**
     * BUSCA UN CLIENTE POR PASSPORT.
     */
    client_by_passport() {
        let url = this.data.get("url");
        let passport = $("#client_passport").val();
        let nom_country =  $('#nom_country_id');
        let nationality =  $('#nationality_id');
        if (passport!=""){
            $("#create_client" ).addClass( "disabled" )
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {passport: passport},
                success: function (res) {
                    if (res !== undefined && res.status === 'OK') {
                        let client = res.data;
                        $('#nom_client_id').val(client.nom_client_id);
                        $('#client_name').val(client.name);
                        $('#client_last_name').val(client.last_name);
                        $('#birth_date').val(client.birth_date);
                        $('#client_email').val(client.email);
                        $('#client_phone').val(client.phone);
                        $('#client_driver_license').val(client.driver_license);
                        $('#permanent').prop('checked', client.permanent);
                        $('#due_date').val(client.due_date);
                        $('#license_date').val(client.license_date);
                        nom_country.val(client.nom_country_id);
                        nom_country.trigger('change');
                        nationality.val(client.nationality_id);
                        nationality.trigger('change');
                        $('#address').val(client.address);
                    }
                    if (res !== undefined && res.status === 'ERROR') {
                        $( "#create_client" ).removeClass( "disabled" )
                        toastr_message('warning', 'No se encontró el cliente.', "Pasaporte/CI");
                        $("#client_passport").val();
                        $('#nom_client_id').val('');
                        $('#client_name').val('');
                        $('#client_last_name').val('');
                        $('#birth_date').val('');
                        $('#client_email').val('');
                        $('#client_phone').val('');
                        $('#client_driver_license').val('');
                        $('#permanent').prop('checked', false);
                        $('#due_date').val('');
                        $('#license_date').val('');
                        nom_country.val('');
                        nom_country.trigger('change');
                        nationality.val('');
                        nationality.trigger('change');
                        $('#address').val('');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }else {
            toastr_message('warning', 'Debe proporcionar un pasaporte o ci.', "Pasaporte/CI");
        }

    }

    /**
     * Encontrar las modalidades de una forma de pago.
     * */
    findModalitiesByPaymentForm(e) {
        let url = this.data.get("modality_url");
        let payment_form = $("#payment_form_code").val();
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {payment_form_code: payment_form},
            success: function (res) {
                $.unblockUI();
                $("#payment_modality_code").empty();
                $("#payment_modality_code").append('<option selected="selected" value=""> Seleccione modalidad de cobro </option>')
                if (res !== undefined) {
                    $.each(res.modalities, function (index, modality) {
                        $("#payment_modality_code").append(
                            $("<option>")
                                .text(modality.name)
                                .attr("value", modality.code)
                                .attr("request_sda_payment", modality.request_sda_payment)
                                .attr("sda_code", modality.sda_code)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown)
            },
        });
    }

    /**
     * Crea los tab por modalidades de forma dinamica, cada modalida cambia en cuanto a contenido.
     * */
    addItemsPayment(e) {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let payment_form_code = payment_form.val();
        let modality = $('#payment_modality_code option').filter(':selected');
        let modality_code = modality.val();
        let nav_tabs_payments = $('#nav_tabs_payments');
        let tab_content_payments = $('#tab_content_payments');
        let selected_tab = null;

        /**
         * Permitir agregar un cobro para la forma de pago, solo si existe un importe mayor que cero para el mismo.
         * */
        switch (payment_form_code) {
            case 'EFE':
                let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
                if (cash === 0.0) {
                    alert("INFO: No existe un monto definido para la forma de pago.");
                    return;
                }
                break;
            case 'TM':
                let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
                if (moment_card === 0.0) {
                    alert("INFO: No existe un monto definido para la forma de pago.");
                    return;
                }
                break;
            case 'CHT':
                let cheque_transfer = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
                if (cheque_transfer === 0.0) {
                    alert("INFO: No existe un monto definido para la forma de pago.");
                    return;
                }
                break;
            case 'OTR':
                let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
                if (other === 0.0) {
                    alert("INFO: No existe un monto definido para la forma de pago.");
                    return;
                }
                break;
            default:
                alert("INFO: Forma de pago no configurada en esta sección.");
                return;
        }

        if (modality_code !== '' && modality_code !== undefined) {
            switch (modality_code) {
                case 'FMEX':
                    let fmex_tab = nav_tabs_payments.find('.FMEX-tab');
                    if (fmex_tab.length === 0) {
                        this.addTabPane(nav_tabs_payments, tab_content_payments, 'FMEX', modality.text(),
                            this.createPaymentTableFmex());
                    } else {
                        selected_tab = 'FMEX-tab';
                    }
                    this.addPaymentItemFmex();
                    // CAMBIAR EL VALUE DE LOS INPUTS, PARA QUE NO SE PIERDAN AL AGREGAR NUEVOS TAB
                    let fmex_items = $("input[class*='items'][name*='payments[FMEX]']");
                    fmex_items.on('keyup', function (e) {
                        e.target.setAttribute('value', e.target.value);
                    });
                    break;
                case 'BDI':
                    let bdi_tab = nav_tabs_payments.find('.BDI-tab');
                    if (bdi_tab.length === 0) {
                        this.addTabPane(nav_tabs_payments, tab_content_payments, 'BDI', modality.text(),
                            this.createPaymentTableBDI('BDI'));
                    } else {
                        selected_tab = 'BDI-tab';
                    }
                    this.addPaymentItemBDI('BDI');
                    // CAMBIAR EL VALUE DE LOS INPUTS, PARA QUE NO SE PIERDAN AL AGREGAR NUEVOS TAB
                    let bdi_items = $("input[class*='items'][name*='payments[BDI]']");
                    bdi_items.on('keyup', function (e) {
                        e.target.setAttribute('value', e.target.value);
                    });
                    break;
                case 'TB':
                    let tb_tab = nav_tabs_payments.find('.TB-tab');
                    if (tb_tab.length === 0) {
                        this.addTabPane(nav_tabs_payments, tab_content_payments, 'TB', modality.text(),
                            this.createPaymentTableTB('TB'));
                    } else {
                        selected_tab = 'TB-tab';
                    }
                    this.addPaymentItemTB('TB');
                    // CAMBIAR EL VALUE DE LOS INPUTS, PARA QUE NO SE PIERDAN AL AGREGAR NUEVOS TAB
                    let tb_items = $("input[class*='items'][name*='payments[TB]']");
                    tb_items.on('keyup', function (e) {
                        e.target.setAttribute('value', e.target.value);
                    });
                    break;
                case 'CH':
                    let ch_tab = nav_tabs_payments.find('.CH-tab');
                    if (ch_tab.length === 0) {
                        this.addTabPane(nav_tabs_payments, tab_content_payments, 'CH', modality.text(),
                            this.createPaymentTableCH('CH'));
                    } else {
                        selected_tab = 'CH-tab';
                    }
                    this.addPaymentItemCH('CH');
                    // CAMBIAR EL VALUE DE LOS INPUTS, PARA QUE NO SE PIERDAN AL AGREGAR NUEVOS TAB
                    let ch_items = $("input[class*='items'][name*='payments[CH]']");
                    ch_items.on('keyup', function (e) {
                        e.target.setAttribute('value', e.target.value);
                    });
                    break;
                default:
                    /**
                     * Para los codigos no contemplados aqui por default se recoge:
                     * Oficialmente es lo que se recoge para Enzona y Transfermovil
                     * - Forma de pago
                     * - Numero de referencia de la transaccion
                     * - Importe
                     * - Fecha
                     * - Hora
                     */
                    let any_tab = nav_tabs_payments.find(`.${modality_code}-tab`);
                    if (any_tab.length === 0) {
                        this.addTabPane(nav_tabs_payments, tab_content_payments, modality_code, modality.text(),
                            this.createPaymentTableDefault(modality_code));
                    } else {
                        selected_tab = `${modality_code}-tab`;
                    }
                    this.addPaymentItemDefault(modality_code);
                    // CAMBIAR EL VALUE DE LOS INPUTS, PARA QUE NO SE PIERDAN AL AGREGAR NUEVOS TAB
                    let items = $(`input[class*='items'][name*='payments[${modality_code}]']`);
                    items.on('keyup', function (e) {
                        e.target.setAttribute('value', e.target.value);
                    });
                    break;
            }
            // Aplicando mascara al campo time
            $(`.payment_time`).inputmask({"mask": "99:99"});

            // VALIDACION DE QUIEN SE ACTIVA Y QUIEN NO
            let tabs = nav_tabs_payments.find('a[data-toggle=tab]');
            let tabs_content = tab_content_payments.find('div.tab-pane');

            tabs.map(elem => {
                tabs[elem].classList.remove('active')
            })

            tabs_content.map(elem => {
                tabs_content[elem].classList.remove('show');
                tabs_content[elem].classList.remove('active');
            })

            if (selected_tab === null) {
                tabs[(parseInt(tabs.length) - 1)].classList.add('active');
                tabs_content[(parseInt(tabs_content.length) - 1)].classList.add('show');
                tabs_content[(parseInt(tabs_content.length) - 1)].classList.add('active');
            } else {
                $(`li.${selected_tab}`).find('a')[0].classList.add('active');
                let content_tab = $(`div.${selected_tab}-content`);
                content_tab[0].classList.add('show');
                content_tab[0].classList.add('active');
            }
        } else {
            alert("ERROR: Debe seleccionar una modalidad.");
        }
    }

    /**
     * Agregar nuevo li al selector del nav_tab ex:(ul class="nav nav-tabs" id="nav_tabs_payments") y
     * ademas agregar nuevo contenido al selector de tab_content ex: (div class="tab-content" id="tab_content_payments")
     * @param nav_tab ex: $('#nav_tabs_payments')
     * @param tab_content ex: $('#tab_content_payments')
     * @param code string sin espacios
     * @param title string con el titulo del tab
     * @param content contenido del nuevo tab
     * @return void
     * */
    addTabPane(nav_tab, tab_content, code, title, content) {
        nav_tab.append(`<li class="nav-item ${code}-tab">
                            <a href="#tab-top-${code}" class="nav-link strong" data-toggle="tab">${title}</a>
                        </li>`)
        tab_content.append(`<div id="tab-top-${code}" class="card card-body tab-pane ${code}-tab-content">${content}</div>`)
    }

    //-----------------------------------------------------------------------------------------------------------------/
    /**
     * Crear tabla para default: oficialmente lo usa Enzona, Transfermovil.
     * - Forma de pago
     * - Numero de referencia de la transaccion
     * - Importe
     * - Fecha
     * - Hora
     * */
    createPaymentTableDefault(code) {
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_reference = '' ;
        if(m_request_sda_payment === 'true')
            sda_reference = '<th class="col-1" rowspan="2" style="width: 70px;">Referencia SDA</th>';
        return `<div class="table-responsive">
                       <table class="table table-striped responsive-utilities jambo_table bulk_action table-condensed table-bordered table-hover border-0" id="${code}_table">
                         <thead style="text-align: center">
                            <tr class="headings">
                                <th class="col-1" rowspan="2" style="width: 70px;">Forma de pago</th>
                                ${sda_reference}
                                <th class="col-1" rowspan="2" style="width: 70px;">Referencia Transacción</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Importe</th>
                                <th class="col-1" rowspan="2" style="width: 50px;">Fecha</th>
                                <th class="col-1" rowspan="2" style="width: 30px;">Hora</th>
                                <th class="col-1" rowspan="2"></th>
                            </tr>
                         </thead>
                         <tbody id='${code}-body-items' data-incremental-id="0"></tbody>
                       </table>`;
    }

    /**
     * Adicionar Items a la tabla de ENZONA, TRANSFERMOVIL.
     * */
    addPaymentItemDefault(code) {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_code = modality.attr('sda_code');
        let code_body = $(`#${code}-body-items`);
        let time_current = $('#time_current').val().split('-');

        if (code_body.length > 0) {
            // const kids = code_body.children();
            const id = Number(code_body.data('incremental-id'))+1; // capturar y aumentar el id incremental
            code_body.data('incremental-id', id) // actualizarlo en el HTML
            // Chequeando configuracion de la modalidad
            let sda_reference = '' ;
            let readonly = ''
            let client_first_name = '';
            if ($(`.client_name`).val()!=''){
                 client_first_name = $(`.client_name`).val();
            }else{
                client_first_name = $(`.frm_client_name`).val();
            }
            let client_last_name = '';
            if ($(`.client_surname`).val()!=''){
                 client_last_name = $(`.client_surname`).val();
            }else{
                client_last_name = $(`.frm_client_last_name`).val();
            }
            let client_passport = '';
            if ($(`.client_passport`).val()!=''){
                 client_passport = $(`.client_passport`).val();
            }else{
                client_passport = $(`.frm_client_passport`).val();
            }
            let client_email = '';
            if ($(`.client_email`).val()!=''){
                 client_email = $(`.client_email`).val();
            }else{
                client_email = $(`.frm_client_email`).val();
            }
            let table_columns_number = 9 // varaiable para saber cuanto habria q ponerle a un `colspan` de un <td> para q ocupe toda la fila
            if (m_request_sda_payment === 'true') {
                table_columns_number = 10
                sda_reference = `<td class="p-0">
                                    <input id="${code}_sda_reference_${id}" name="payments[${code}][${id}][sda_reference]" data-sda_code="${sda_code}"
                                           class="form-control p-1 items" required autocomplete="off" data-action="blur->payment#get_payment_by_reference" value="" />
                                </td>`;
                readonly = 'readonly';
                client_first_name = '';
                client_last_name = '';
                client_passport = '';
                client_email = '';
            }
            let tr_id = `${code}-tr-${id}`
            code_body.append(`
                <tr id='${tr_id}' class="rows">
                    <td class="p-0" rowspan="2">
                        <span class="form-control p-1 font-smaller">${payment_form.text()}</span>
                        <input id="${code}_payment_form_code_${id}" name="payments[${code}][${id}][payment_form_code]" 
                               type="hidden" value="${payment_form.val()}" class="items" />
                        <input id="${code}_json_${id}" name="payments[${code}][${id}][json]" type="hidden" class="items" />  
                    </td>
                    ${sda_reference}
                    <td class="p-0"><input id="${code}_transaction_reference_${id}"
                                           name="payments[${code}][${id}][transaction_reference]"
                                           class="form-control p-1 items" required autocomplete="off"/></td>
                    <td class="p-0"><input id="${code}_amount_${id}" name="payments[${code}][${id}][amount]"
                                           type="number" step="any" class="form-control p-1 items ${payment_form.val()}_payment_form" ${readonly} required autocomplete="off"
                                           data-action="change->payment#checkAmountPaymentForm click->payment#checkAmountPaymentForm change->adjust#checkAdjustImportsDifferences" data-code="${payment_form.val()}"/></td>
                    <td class="p-0">
                        <div data-controller="date">
                            <input id="${code}_date_${id}"
                                name="payments[${code}][${id}][date]"
                                class="form-control date"
                                autocomplete="off"
                                value="${time_current[0]}"
                                readonly="readonly"
                            />
                        </div>
                    </td>
                    <td class="p-0"><input id="${code}_time_${id}" name="payments[${code}][${id}][time]" 
                                           class="form-control p-1 items payment_time" required value="${time_current[1]}" 
                                           autocomplete="off" pattern="[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9]" 
                                           placeholder="00:00" />
                    </td>
                    <td class="p-0 text-center">
                        <button id="${code}_remove_item_${id}" data-action="click->payment#removePaymentItem"
                                data-value='${tr_id}' data-tr-client="${tr_id}_client" data-tr-details="${tr_id}_details" data-code="${payment_form.val()}" type="button" 
                                class="btn btn-ghost-danger w-full" style="display: table-cell">
                            <span class="fa fa-trash" data-value='${tr_id}' data-tr-client="${tr_id}_client" data-code="${payment_form.val()}"></span>
                        </button>
                    </td>
                </tr>
                <tr id="${tr_id}_client">
                  <td class="p-0"><input id="${code}_first_name_${id}" name="payments[${code}][${id}][client][first_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Nombre" title="Nombre del cliente que paga" value="${client_first_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_last_name_${id}" name="payments[${code}][${id}][client][last_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Apellidos" title="Apellidos" value="${client_last_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_passport_${id}" name="payments[${code}][${id}][client][passport]"
                                           class="form-control p-1 items" required placeholder="Pasaporte/CI" title="Pasaporte/CI" value="${client_passport}"
                                           autocomplete="off"/>
                    </td>
                     <td class="p-0" colspan="2"><input id="${code}_email_${id}" name="payments[${code}][${id}][client][email]" ${readonly}
                                           class="form-control p-1 items" placeholder="Email" title="Email" value="${client_email}"
                                           autocomplete="off" />
                    </td>
                </tr>
                ${detailsSegmentHtmlBuilder(id, code, table_columns_number, this.operation)}
            `);
        }
    }

    //----------------------------------------------------------------------------------------------------------------/

    /**
     * Crear tabla para FINCIMEX
     * - Forma de pago
     * - Número de STAN
     * - Número de operación
     * - Número de autorizo
     * - Máscara de la tarjeta (formato: 1234XXXXXXXX1234)
     * - Importe
     * - Fecha
     * - Hora
     * */
    createPaymentTableFmex() {
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_reference = '' ;
        if(m_request_sda_payment === 'true')
            sda_reference = '<th class="col-1" rowspan="2" style="width: 70px;">Referencia SDA</th>';

        return `<div class="table-responsive">
                       <table class="table table-striped responsive-utilities jambo_table bulk_action table-condensed table-bordered table-hover border-0" id="FMEX_table">
                         <thead style="text-align: center">
                            <tr class="headings">
                                <th class="col-1" rowspan="2" style="width: 70px;">Forma de pago</th>
                                ${sda_reference}
                                <th class="col-1" rowspan="2" style="width: 70px;">STAN</th>
                                <th class="col-1" rowspan="2" style="width: 70px;">No. Operación</th>
                                <th class="col-1" rowspan="2" style="width: 70px;">No. Autorizo</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Máscara de la tarjeta</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Importe</th>
                                <th class="col-1" rowspan="2" style="width: 50px;">Fecha</th>
                                <th class="col-1" rowspan="2" style="width: 30px;">Hora</th>
                                <th class="col-1" rowspan="2"></th>
                            </tr>
                         </thead>
                         <tbody id='FMEX-body-items' data-incremental-id="0"></tbody>
                       </table>`;
    }

    /**
     * Adicionando Items a la tabla de FINCIMEX
     * */
    addPaymentItemFmex() {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let fmex_body = $('#FMEX-body-items');
        let time_current = $('#time_current').val().split('-');
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_code = modality.attr('sda_code');
        let code = 'FMEX'

        if (fmex_body.length > 0) {
            // const kids = fmex_body.children();
            const id = Number(fmex_body.data('incremental-id'))+1; // capturar y aumentar el id incremental
            fmex_body.data('incremental-id', id) // actualizarlo en el HTML
            // Cheaqueando configuracion de la modalidad
            let sda_reference = '' ;
            let readonly = ''
            let client_first_name = '';
            if($(`.client_name`).val()!=''){
                client_first_name = $(`.client_name`).val()
            }else{
                client_first_name = $(`.frm_client_name`).val()
            }

            let client_last_name = '';
            if($(`.client_surname`).val()!=''){
                 client_last_name = $(`.client_surname`).val();
            }else{
                 client_last_name = $(`.frm_client_last_name`).val();
            }

            let client_passport = '';
            if ($(`.client_passport`).val()!=''){
                 client_passport = $(`.client_passport`).val();
            }else{
                 client_passport = $(`.frm_client_passport`).val();
            }

            let client_email = '';
            if ($(`.client_email`).val()!=''){
                 client_email = $(`.client_email`).val();
            }else{
                client_email = $(`.frm_client_email`).val();
            }
            let table_columns_number = 9 // varaiable para saber cuanto habria q ponerle a un `colspan` de un <td> para q ocupe toda la fila
            if(m_request_sda_payment === 'true'){
                table_columns_number = 10
                sda_reference = `<td class="p-0">
                                    <input id="FMEX_sda_reference_${id}" name="payments[FMEX][${id}][sda_reference]" data-sda_code="${sda_code}"
                                    class="form-control p-1 items" required autocomplete="off" data-action="blur->payment#get_payment_by_reference" value=""/>
                                </td>`;
                readonly = 'readonly';
                client_first_name = '';
                client_last_name = '';
                client_passport = '';
                client_email = '';
            }
            let tr_id = `FMEX-tr-${id}`
            fmex_body.append(`
                <tr id='${tr_id}' class="rows">
                    <td class="p-0" rowspan="2">
                        <span class="form-control p-1 font-smaller items">${payment_form.text()}</span>
                        <input id="FMEX_payment_form_code_${id}" name="payments[FMEX][${id}][payment_form_code]" 
                               type="hidden" value="${payment_form.val()}" class="items" />
                        <input id="${code}_json_${id}" name="payments[${code}][${id}][json]" type="hidden" class="items" />  
                    </td>
                    ${sda_reference}
                    <td class="p-0"><input id="FMEX_stan_${id}" name="payments[FMEX][${id}][stan]"
                                           class="form-control p-1 items" required autocomplete="off" /></td>
                    <td class="p-0"><input id="FMEX_operation_${id}" name="payments[FMEX][${id}][operation]"
                                           class="form-control p-1 items" required autocomplete="off"/></td>
                    <td class="p-0"><input id="FMEX_authorization_${id}" name="payments[FMEX][${id}][authorization]"
                                           class="form-control p-1 items" required autocomplete="off" /></td>
                    <td class="p-0"><input id="FMEX_card_mask_${id}" name="payments[FMEX][${id}][card_mask]" 
                                           class="form-control p-1 items payment_card_mask" required autocomplete="off" 
                                           pattern="[0-9]{4}XXXXXXXX[0-9]{4}" /></td> 
                    <td class="p-0"><input id="FMEX_amount_${id}" name="payments[FMEX][${id}][amount]"
                                           type="number" step="any" class="form-control p-1 items ${payment_form.val()}_payment_form" ${readonly} required autocomplete="off"
                                           data-action="change->payment#checkAmountPaymentForm click->payment#checkAmountPaymentForm change->adjust#checkAdjustImportsDifferences" data-code="${payment_form.val()}"/></td>
                    <td class="p-0">
                      <div data-controller="date">
                        <input id="FMEX_date_${id}"
                            name="payments[FMEX][${id}][date]"
                            class="form-control date"
                            autocomplete="off"
                            value="${time_current[0]}"
                            readonly="readonly" />
                       </div>
                    </td>
                    <td class="p-0"><input id="FMEX_time_${id}" name="payments[FMEX][${id}][time]" 
                                           class="form-control p-1 items payment_time" required value="${time_current[1]}" 
                                           autocomplete="off" pattern="[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9]" 
                                           placeholder="00:00"/>
                    </td>
                    <td class="p-0 text-center">
                        <button id="FMEX_remove_item_${id}" data-action="click->payment#removePaymentItem" 
                                data-value='FMEX-tr-${id}' data-tr-client="${tr_id}_client" data-tr-details="${tr_id}_details" data-code="${payment_form.val()}" type="button" 
                                class="btn btn-ghost-danger w-full" style="display: table-cell">
                            <span class="fa fa-trash" data-value='FMEX-tr-${id}' data-tr-client="${tr_id}_client" data-code="${payment_form.val()}"></span>
                        </button>
                    </td>
                </tr>
                <tr id="${tr_id}_client">
                    <td class="p-0" colspan="2"><input id="${code}_first_name_${id}" name="payments[${code}][${id}][client][first_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Nombre" title="Nombre del cliente que paga" value="${client_first_name}"
                                           autocomplete="off" />
                    </td>
                     <td class="p-0" colspan="2"><input id="${code}_last_name_${id}" name="payments[${code}][${id}][client][last_name]" ${readonly} 
                                           class="form-control p-1 items" required placeholder="Apellidos" title="Apellidos" value="${client_last_name}"
                                           autocomplete="off" />
                    </td>
                     <td class="p-0"><input id="${code}_passport_${id}" name="payments[${code}][${id}][client][passport]"
                                           class="form-control p-1 items" required placeholder="Pasaporte/CI" title="Pasaporte/CI" value="${client_passport}"
                                           autocomplete="off"/>
                    </td>
                     <td class="p-0" colspan="3"><input id="${code}_email_${id}" name="payments[${code}][${id}][client][email]" ${readonly} 
                                           class="form-control p-1 items" placeholder="Email" title="Email" value="${client_email}"
                                           autocomplete="off" />
                    </td>
                </tr>
                ${detailsSegmentHtmlBuilder(id, code, table_columns_number, this.operation)}
            `);
            $(`.payment_card_mask`).inputmask({"mask": "9999XXXXXXXX9999"});
        }
    }

    //-----------------------------------------------------------------------------------------------------------------/

    /**
     * Crear tabla para Bidaiondo
     * - Forma de pago
     * - Número de solicitud
     * - Número de referencia de la transacción
     * - Importe
     * - Fecha
     * - Hora
     * */
    createPaymentTableBDI(code) {
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_reference = '' ;
        if(m_request_sda_payment === 'true')
            sda_reference = '<th class="col-1" rowspan="2" style="width: 70px;">Referencia SDA</th>';
        return `<div class="table-responsive">
                       <table class="table table-striped responsive-utilities jambo_table bulk_action table-condensed table-bordered table-hover border-0" id="${code}_table">
                         <thead style="text-align: center">
                            <tr class="headings">
                                <th class="col-1" rowspan="2" style="width: 70px;">Forma de pago</th>
                                ${sda_reference}
                                <th class="col-1" rowspan="2" style="width: 70px;">Referencia Transacción</th>
                                <th class="col-1" rowspan="2" style="width: 70px;">Número de solicitud</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Importe</th>
                                <th class="col-1" rowspan="2" style="width: 50px;">Fecha</th>
                                <th class="col-1" rowspan="2" style="width: 30px;">Hora</th>
                                <th class="col-1" rowspan="2"></th>
                            </tr>
                         </thead>
                         <tbody id='${code}-body-items' data-incremental-id="0"></tbody>
                       </table>`;
    }

    /**
     * Agregar items a la tabla BDI
     * */
    addPaymentItemBDI(code) {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let code_body = $(`#${code}-body-items`);
        let time_current = $('#time_current').val().split('-');
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_code = modality.attr('sda_code');

        if (code_body.length > 0) {
            // const kids = code_body.children();
            const id = Number(code_body.data('incremental-id'))+1; // capturar y aumentar el id incremental
            code_body.data('incremental-id', id) // actualizarlo en el HTML
            // Chequeando configuracion de la modalidad
            let sda_reference = '' ;
            let readonly = '';
            let client_first_name = '';
            if ($(`.client_name`).val()!=''){
                client_first_name = $(`.client_name`).val();
            }else{
                client_first_name = $(`.frm_client_name`).val();
            }
            let client_last_name = '';
            if ($(`.client_surname`).val()!=''){
                client_last_name = $(`.client_surname`).val();
            }else{
                client_last_name = $(`.frm_client_last_name`).val();
            }
            let client_passport = '';
            if ($(`.client_passport`).val()!=''){
                 client_passport = $(`.client_passport`).val();
            }else{
                 client_passport = $(`.frm_client_passport`).val();
            }
            let client_email = '';
            if ($(`.client_email`).val()!=''){
                 client_email = $(`.client_email`).val();
            }else{
                 client_email = $(`.frm_client_email`).val();
            }
            let table_columns_number = 9 // varaiable para saber cuanto habria q ponerle a un `colspan` de un <td> para q ocupe toda la fila
            if(m_request_sda_payment === 'true'){
                table_columns_number = 10
                sda_reference = `<td class="p-0">
                                    <input id="${code}_sda_reference_${id}" name="payments[${code}][${id}][sda_reference]" data-sda_code="${sda_code}"
                                           class="form-control p-1 items" required autocomplete="off" data-action="blur->payment#get_payment_by_reference" value=""/>
                                </td>`;
                readonly = 'readonly';
                client_first_name = '';
                client_last_name = '';
                client_passport = '';
                client_email = '';
            }
            let tr_id = `${code}-tr-${id}`
            code_body.append(`
                <tr id='${tr_id}' class="rows">
                    <td class="p-0" rowspan="2">
                        <span class="form-control p-1 font-smaller items">${payment_form.text()}</span>
                        <input id="${code}_payment_form_code_${id}" name="payments[${code}][${id}][payment_form_code]" 
                               type="hidden" value="${payment_form.val()}" class="items" />
                        <input id="${code}_json_${id}" name="payments[${code}][${id}][json]" type="hidden" class="items" />  
                    </td>
                    ${sda_reference}
                    <td class="p-0"><input id="${code}_transaction_reference_${id}" name="payments[${code}][${id}][transaction_reference]"
                                           class="form-control p-1 items" required autocomplete="off"/></td>
                    <td class="p-0"><input id="${code}_request_number_${id}" name="payments[${code}][${id}][request_number]"
                                           class="form-control p-1 items" required autocomplete="off" /></td>
                    <td class="p-0"><input id="${code}_amount_${id}" name="payments[${code}][${id}][amount]"
                                           type="number" step="any" class="form-control p-1 items ${payment_form.val()}_payment_form" ${readonly} required autocomplete="off"
                                           data-action="change->payment#checkAmountPaymentForm click->payment#checkAmountPaymentForm change->adjust#checkAdjustImportsDifferences" data-code="${payment_form.val()}"/></td>
                    <td class="p-0">
                      <div data-controller="date">
                        <input id="${code}_date_${id}"
                            name="payments[${code}][${id}][date]"
                            class="form-control date"
                            autocomplete="off"
                            value="${time_current[0]}"
                            readonly="readonly" />
                       </div>
                    </td>
                    <td class="p-0"><input id="${code}_time_${id}" name="payments[${code}][${id}][time]" 
                                           class="form-control p-1 items payment_time" required value="${time_current[1]}" 
                                           autocomplete="off" pattern="[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9]" 
                                           placeholder="00:00" />
                    </td>
                    <td class="p-0 text-center">
                        <button id="${code}_remove_item_${id}" data-action="click->payment#removePaymentItem" 
                                data-value='${tr_id}' data-tr-client="${tr_id}_client" data-tr-details="${tr_id}_details" data-code="${payment_form.val()}" type="button" 
                                class="btn btn-ghost-danger w-full" style="display: table-cell">
                            <span class="fa fa-trash" data-value='${tr_id}' data-tr-client="${tr_id}_client" data-code="${payment_form.val()}"></span>
                        </button>
                    </td>
                </tr>
                <tr id="${tr_id}_client">
                  <td class="p-0"><input id="${code}_first_name_${id}" name="payments[${code}][${id}][client][first_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Nombre" title="Nombre del cliente que paga" value="${client_first_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_last_name_${id}" name="payments[${code}][${id}][client][last_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Apellidos" title="Apellidos" value="${client_last_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_passport_${id}" name="payments[${code}][${id}][client][passport]"
                                           class="form-control p-1 items" required placeholder="Pasaporte/CI" title="Pasaporte/CI" value="${client_passport}"
                                           autocomplete="off"/>
                    </td>
                     <td class="p-0" colspan="2"><input id="${code}_email_${id}" name="payments[${code}][${id}][client][email]" ${readonly}
                                                        class="form-control p-1 items" placeholder="Email" title="Email" value="${client_email}"
                                                        autocomplete="off" />
                    </td>
                </tr>
                ${detailsSegmentHtmlBuilder(id, code, table_columns_number, this.operation)}
            `);
        }
    }

    //-----------------------------------------------------------------------------------------------------------------/

    /**
     * Crear tabla para Cheque.
     * - Forma de pago
     * - Número de cheque
     * - Importe
     * - Fecha
     * - Hora
     * */
    createPaymentTableCH(code) {
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_reference = '' ;
        if(m_request_sda_payment === 'true')
            sda_reference = '<th class="col-1" rowspan="2" style="width: 70px;">Referencia SDA</th>';
        return `<div class="table-responsive">
                       <table class="table table-striped responsive-utilities jambo_table bulk_action table-condensed table-bordered table-hover border-0" id="${code}_table">
                         <thead style="text-align: center">
                            <tr class="headings">
                                <th class="col-1" rowspan="2" style="width: 70px;">Forma de pago</th>
                                ${sda_reference}
                                <th class="col-1" rowspan="2" style="width: 70px;">Número de cheque</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Importe</th>
                                <th class="col-1" rowspan="2" style="width: 50px;">Fecha</th>
                                <th class="col-1" rowspan="2" style="width: 30px;">Hora</th>
                                <th class="col-1" rowspan="2"></th>
                            </tr>
                         </thead>
                         <tbody id='${code}-body-items' data-incremental-id="0"></tbody>
                       </table>`;
    }

    /**
     * Agregar items a la tabla para CHEQUE
     * */
    addPaymentItemCH(code) {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_code = modality.attr('sda_code');
        let code_body = $(`#${code}-body-items`);
        let time_current = $('#time_current').val().split('-');
        if (code_body.length > 0) {
            // const kids = code_body.children();
            const id = Number(code_body.data('incremental-id'))+1; // capturar y aumentar el id incremental
            code_body.data('incremental-id', id) // actualizarlo en el HTML
            // Chequeando configuracion de la modalidad
            let sda_reference = '' ;
            let readonly = '';
            let client_first_name = '';
            if ($(`.client_name`).val()!= ''){
                 client_first_name = $(`.client_name`).val();
            }else{
                client_first_name = $(`.frm_client_name`).val();
            }
            let client_last_name = '';
            if ($(`.client_surname`).val()!= ''){
                 client_last_name = $(`.client_surname`).val();
            }else{
                client_last_name = $(`.frm_client_last_name`).val();
            }
            let client_passport = '';
            if ($(`.client_passport`).val()!= ''){
                client_passport = $(`.client_passport`).val();
            }else{
                client_passport = $(`.frm_client_passport`).val();
            }
            let client_email = '';
            if ($(`.client_email`).val()!= ''){
                 client_email = $(`.client_email`).val();
            }else{
                client_email = $(`.frm_client_email`).val();
            }
            let table_columns_number = 9 // varaiable para saber cuanto habria q ponerle a un `colspan` de un <td> para q ocupe toda la fila
            if(m_request_sda_payment === 'true') {
                table_columns_number = 10
                sda_reference = `<td class="p-0">
                                    <input id="${code}_sda_reference_${id}" name="payments[${code}][${id}][sda_reference]" data-sda_code="${sda_code}"
                                           class="form-control p-1 items" required autocomplete="off" data-action="blur->payment#get_payment_by_reference" value=""/>
                                </td>`;
                readonly = 'readonly';
                client_first_name = '';
                client_last_name = '';
                client_passport = '';
                client_email = '';
            }
            let tr_id = `${code}-tr-${id}`
            code_body.append(`
                <tr id='${tr_id}' class="rows">
                    <td class="p-0" rowspan="2">
                        <span class="form-control p-1 font-smaller items">${payment_form.text()}</span>
                        <input id="${code}_payment_form_code_${id}" name="payments[${code}][${id}][payment_form_code]" 
                               type="hidden" value="${payment_form.val()}" class="items"/>
                        <input id="${code}_json_${id}" name="payments[${code}][${id}][json]" type="hidden" class="items" />  
                    </td>
                    ${sda_reference}
                    <td class="p-0"><input id="${code}_check_number_${id}" name="payments[${code}][${id}][check_number]"
                                           class="form-control p-1 items" required autocomplete="off"/></td>
                    <td class="p-0"><input id="${code}_amount_${id}" name="payments[${code}][${id}][amount]"
                                           type="number" step="any" class="form-control p-1 items ${payment_form.val()}_payment_form" ${readonly} required autocomplete="off"
                                           data-action="change->payment#checkAmountPaymentForm click->payment#checkAmountPaymentForm change->adjust#checkAdjustImportsDifferences" 
                                           data-code="${payment_form.val()}"/></td>
                    <td class="p-0">
                      <div data-controller="date">
                        <input id="${code}_date_${id}"
                            name="payments[${code}][${id}][date]"
                            class="form-control date"
                            autocomplete="off"
                            value="${time_current[0]}"
                            readonly="readonly" />
                      </div>
                    </td>
                    <td class="p-0"><input id="${code}_time_${id}" name="payments[${code}][${id}][time]" 
                                           class="form-control p-1 items payment_time" required value="${time_current[1]}" 
                                           autocomplete="off" pattern="[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9]" 
                                           placeholder="00:00" />
                    </td>
                    <td class="p-0 text-center">
                        <button id="${code}_remove_item_${id}" data-action="click->payment#removePaymentItem" 
                                data-value='${tr_id}' data-tr-client="${tr_id}_client" data-tr-details="${tr_id}_details" data-code="${payment_form.val()}" type="button" 
                                class="btn btn-ghost-danger w-full" style="display: table-cell">
                            <span class="fa fa-trash" data-value='${tr_id}' data-tr-client="${tr_id}_client" data-code="${payment_form.val()}"></span>
                        </button>
                    </td>
                </tr>
                <tr id="${tr_id}_client">
                  <td class="p-0"><input id="${code}_first_name_${id}" name="payments[${code}][${id}][client][first_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Nombre" title="Nombre del cliente que paga" value="${client_first_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_last_name_${id}" name="payments[${code}][${id}][client][last_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Apellidos" title="Apellidos" value="${client_last_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_passport_${id}" name="payments[${code}][${id}][client][passport]"
                                           class="form-control p-1 items" required placeholder="Pasaporte/CI" title="Pasaporte/CI" value="${client_passport}"
                                           autocomplete="off"/>
                    </td>
                     <td class="p-0" colspan="2"><input id="${code}_email_${id}" name="payments[${code}][${id}][client][email]" ${readonly}
                                           class="form-control p-1 items" placeholder="Email" title="Email" value="${client_email}"
                                           autocomplete="off" />
                    </td>
                </tr>
                ${detailsSegmentHtmlBuilder(id, code, table_columns_number, this.operation)}
            `);
        }
    }

    //-----------------------------------------------------------------------------------------------------------------/

    /**
     * Crear tabla para Cheque.
     * - Forma de pago
     * - Número de referencia de la transferencia
     * - Importe
     * - Fecha
     * - Hora
     * */
    createPaymentTableTB(code) {
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_reference = '' ;
        if(m_request_sda_payment === 'true')
            sda_reference = '<th class="col-1" rowspan="2" style="width: 70px;">Referencia SDA</th>';
        return `<div class="table-responsive">
                       <table class="table table-striped responsive-utilities jambo_table bulk_action table-condensed table-bordered border-0" id="${code}_table">
                         <thead style="text-align: center">
                            <tr class="headings">
                                <th class="col-1" rowspan="2" style="width: 70px;">Forma de pago</th>
                                ${sda_reference}
                                <th class="col-1" rowspan="2" style="width: 70px;">Referencia de la Transferencia</th>
                                <th class="col-1" rowspan="2" style="width: 80px;">Importe</th>
                                <th class="col-1" rowspan="2" style="width: 50px;">Fecha</th>
                                <th class="col-1" rowspan="2" style="width: 30px;">Hora</th>
                                <th class="col-1" rowspan="2"></th>
                            </tr>
                         </thead>
                         <tbody id='${code}-body-items' data-incremental-id="0"></tbody>
                       </table>`;
    }

    /**
     * Agregar items a la tabla de Transferencia Bancaria
     * */
    addPaymentItemTB(code) {
        let payment_form = $('#payment_form_code option').filter(':selected');
        let modality = $('#payment_modality_code option').filter(':selected');
        let m_request_sda_payment = modality.attr('request_sda_payment');
        let sda_code = modality.attr('sda_code');
        let code_body = $(`#${code}-body-items`);
        let time_current = $('#time_current').val().split('-');
        if (code_body.length > 0) {
            // const kids = code_body.children();
            const id = Number(code_body.data('incremental-id'))+1; // capturar y aumentar el id incremental
            code_body.data('incremental-id', id) // actualizarlo en el HTML
            // Chequeando configuracion de la modalidad
            let sda_reference = '' ;
            let readonly = '';
            let client_first_name = '';
            if ($(`.client_name`).val()!=''){
                 client_first_name = $(`.client_name`).val();
            }else{
                 client_first_name = $(`.frm_client_name`).val();
            }
            let client_last_name = '';
            if ($(`.client_surname`).val()!=''){
                 client_last_name = $(`.client_surname`).val();
            }else{
                client_last_name = $(`.frm_client_last_name`).val();
            }
            let client_passport = '';
            if ($(`.client_passport`).val()!=''){
                 client_passport = $(`.client_passport`).val();
            }else{
                client_passport = $(`.frm_client_passport`).val();
            }
            let client_email = '';
            if ($(`.client_email`).val()!=''){
                client_email = $(`.client_email`).val();
            }else{
                client_email = $(`.frm_client_email`).val();
            }
            let table_columns_number = 9 // varaiable para saber cuanto habria q ponerle a un `colspan` de un <td> para q ocupe toda la fila
            if(m_request_sda_payment === 'true'){
                table_columns_number = 10
                sda_reference = `<td class="p-0">
                                    <input id="${code}_sda_reference_${id}" name="payments[${code}][${id}][sda_reference]"
                                           class="form-control p-1 items" required autocomplete="off" data-sda_code="${sda_code}" value=""
                                            data-action="blur->payment#get_payment_by_reference"/>
                                </td>`;
                readonly = 'readonly';
                client_first_name = '';
                client_last_name = '';
                client_passport = '';
                client_email = '';
            }
            let tr_id = `${code}-tr-${id}`
            code_body.append(`
                <tr id='${tr_id}' class="rows">
                    <td class="p-0" rowspan="2">
                        <span class="form-control p-1 font-smaller">${payment_form.text()}</span>
                        <input id="${code}_payment_form_code_${id}" name="payments[${code}][${id}][payment_form_code]" class="items"
                               type="hidden" value="${payment_form.val()}"/>
                        <input id="${code}_json_${id}" name="payments[${code}][${id}][json]" type="hidden" class="items"/>  
                    </td>
                    ${sda_reference}
                    <td class="p-0"><input id="${code}_transfer_reference_${id}" name="payments[${code}][${id}][transfer_reference]"
                                           class="form-control p-1 items" required autocomplete="off"/></td>
                    <td class="p-0"><input id="${code}_amount_${id}" name="payments[${code}][${id}][amount]"
                                           type="number" step="any" class="form-control p-1 items ${payment_form.val()}_payment_form" ${readonly} required autocomplete="off"
                                           data-action="change->payment#checkAmountPaymentForm click->payment#checkAmountPaymentForm change->adjust#checkAdjustImportsDifferences" data-code="${payment_form.val()}"/></td>
                    <td class="p-0">
                      <div data-controller="date">
                        <input id="${code}_date_${id}"
                            name="payments[${code}][${id}][date]"
                            class="form-control date"
                            autocomplete="off"
                            value="${time_current[0]}"
                            readonly="readonly" />
                      </div>
                    </td>
                    <td class="p-0"><input id="${code}_time_${id}" name="payments[${code}][${id}][time]" 
                                           class="form-control p-1 items payment_time" required value="${time_current[1]}" 
                                           autocomplete="off" pattern="[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9]" 
                                           placeholder="00:00" />
                    </td>
                    <td class="p-0 text-center">
                        <button id="${code}_remove_item_${id}" data-action="click->payment#removePaymentItem" 
                                data-value='${tr_id}' data-tr-client="${tr_id}_client" data-tr-details="${tr_id}_details" data-code="${payment_form.val()}" type="button" 
                                class="btn btn-ghost-danger w-full" style="display: table-cell">
                            <span class="fa fa-trash" data-value='${tr_id}' data-tr-client="${tr_id}_client"  data-code="${payment_form.val()}"></span>
                        </button>
                    </td>
                </tr>
                  <tr id="${tr_id}_client">
                    <td class="p-0"><input id="${code}_first_name_${id}" name="payments[${code}][${id}][client][first_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Nombre" title="Nombre del cliente que paga" value="${client_first_name}"
                                           autocomplete="off" />       
                    </td>
                    <td class="p-0"><input id="${code}_last_name_${id}" name="payments[${code}][${id}][client][last_name]" ${readonly}
                                           class="form-control p-1 items" required placeholder="Apellidos" title="Apellidos" value="${client_last_name}"
                                           autocomplete="off" />
                    </td>
                    <td class="p-0"><input id="${code}_passport_${id}" name="payments[${code}][${id}][client][passport]"
                                           class="form-control p-1 items" required placeholder="Pasaporte/CI" title="Pasaporte/CI" value="${client_passport}"
                                           autocomplete="off" />
                    </td>
                     <td class="p-0" colspan="2"><input id="${code}_email_${id}" name="payments[${code}][${id}][client][email]" ${readonly}
                                           class="form-control p-1 items" placeholder="Email" title="Email" value="${client_email}"
                                           autocomplete="off" />
                    </td>
                </tr>
                ${detailsSegmentHtmlBuilder(id, code, table_columns_number, this.operation)}
            `);
        }
    }

    //-----------------------------------------------------------------------------------------------------------------/
    removePaymentItem(e) {
        // Eliminar la fila
        let row_item = e.target.dataset;
        if (!row_item.hasOwnProperty('value')) {
            parent = e.target.parentNode;
            row_item = parent.dataset;
        }
        $(`#${row_item.value}`).remove();
        checkAmountPaymentForm(row_item.code);

        if(row_item.hasOwnProperty('trClient')){
            $(`#${row_item.trClient}`).remove();
        }

        // eliminar detalles del payment (FINCIMEX)
        let $data_container = $(e.currentTarget) // elemento q tiene el action (y ademas los datas)
        if($data_container.data('tr-details')){
            let $details = $(`#${$data_container.data('tr-details')}`)
            $details.next().remove() // eliminar el <tr> separador q viene despues de los deatalles para dsitinguir mejor cada pago
            $details.remove()
        }

    }

    checkAmountPaymentForm(e) {
        let code = e.target.dataset.code; // code payment form entrada.
        checkAmountPaymentForm(code);
    }

    checkAdvancePaymentAtMoment(){
        checkAdvancePaymentAtMoment();
    }

    get_payment_by_reference(e){
        let reference = e.target.value;
        let old_reference = e.target.dataset.old_reference;
        let sda_code = e.target.dataset.sda_code;
        let code_line = e.target.id.split('_sda_reference_');
        /**
         * Chequea solo si no esta vacio el campo y existe cambio en el value.
         * */
        if (reference !== '' && reference !== old_reference) {
            e.target.dataset.old_reference = reference
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: '/api/v1/get_payment_by_reference',
                data: {reference: reference},
                success: function (res) {
                    $.unblockUI();
                    let sda_reference = $(`#${code_line[0]}_sda_reference_${code_line[1]}`);
                    let amount = $(`#${code_line[0]}_amount_${code_line[1]}`);
                    let request_number = $(`#${code_line[0]}_request_number_${code_line[1]}`);
                    let transaction_reference = $(`#${code_line[0]}_transaction_reference_${code_line[1]}`);
                    let date = $(`#${code_line[0]}_date_${code_line[1]}`);
                    let time = $(`#${code_line[0]}_time_${code_line[1]}`);
                    let first_name = $(`#${code_line[0]}_first_name_${code_line[1]}`);
                    let last_name = $(`#${code_line[0]}_last_name_${code_line[1]}`);
                    let email = $(`#${code_line[0]}_email_${code_line[1]}`);
                    let json = $(`#${code_line[0]}_json_${code_line[1]}`);

                    if (res.status === 'OK') {
                        // Verificar si viene la pasarela en el pago
                        if (res.gateway === "") {
                            alert("Noticia: No se pudo identificar la pasarela del pago, por favor verifique que está asignando el cobro en la modalidad correcta.");
                            resetFieldPayment(e);
                        }else if (sda_code !== res.gateway){
                            // Verificar que coincida el code_sda de la modalidad con el gateway del pago
                            alert("Noticia: El pago pertenece a una modalidad de cobro diferente a la que está agregando. Por favor verifique la modalidad de cobro con respecto al pago del SDA (ser_code: " + sda_code + ", sda_code: " + res.gateway + ")");
                            resetFieldPayment(e);
                        }else{
                            sda_reference.addClass('is-valid');
                            json.attr('value', JSON.stringify(res));
                            amount.attr('value', res.sale_price / 100);
                            amount.trigger('click');
                            amount[0].dispatchEvent(new Event('change', {bubble: true, cancelable: true})) // asegurarme de q el `payment-details` controller resiba el evento
                            date.attr('value', res.collected_at_day);
                            time.attr('value', res.collected_at_hour);
                            first_name.attr('value', res.first_name);
                            last_name.attr('value', res.last_name);
                            email.attr('value', res.email);
                            transaction_reference.attr('value', res.kontaktid)
                            request_number.attr('value', res.werbecode)

                            // Bloquear si esta OK
                            amount.prop('readonly', true);
                            first_name.prop('readonly', true);
                            last_name.prop('readonly', true);
                            transaction_reference.prop('readonly', true);
                            request_number.prop('readonly', true);
                        }
                    } else {
                        //Revisar codigo para resetear los campos solo si:
                        // 101 - No existe el pago
                        // 102 - El estado no es collected
                        if (res.code === 101 || res.code === 102 ){
                            //Reset campos
                            resetFieldPayment(e);
                        } else {
                            //En cualquier otro caso dejar seguir
                            amount.prop('readonly', false);
                            first_name.prop('readonly', false);
                            last_name.prop('readonly', false);
                            email.prop('readonly', false);
                        }
                        sda_reference.removeClass('is-valid');
                        json.attr('value', '');
                        alert("ERROR: " + res.error_msg);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    /**
     * Funcion para actualizar el importe total de la operacion rent_additional_payment (Otros Cobros)
     * */
    updateTotalImportAdditionalPayment(e){
        let payment_total_voucher = this.data.get('payment_total_voucher');
        let total = $('#amount_total');
        let rent = 0;
        let liability_release = 0;
        let drivers = 0;
        let other_amount = 0;
        let relief_supplement = 0;
        let fuel = 0;
        let office = 0;
        if($('#rent_import').val() != undefined && $('#rent_import').val() != ''){
            rent = parseFloat($('#rent_import').val());
        }
        if($('#liability_release_import').val() != undefined && $('#liability_release_import').val() != ''){
            liability_release = parseFloat($('#liability_release_import').val());
        }
        if($('#drivers_import').val() != undefined && $('#drivers_import').val() != ''){
            drivers = parseFloat($('#drivers_import').val());
        }
        if($('#other_amount').val() != undefined && $('#other_amount').val() != ''){
            other_amount = parseFloat($('#other_amount').val());
        }
        if($('#relief_supplement_import').val() != undefined && $('#relief_supplement_import').val() != ''){
            relief_supplement = parseFloat($('#relief_supplement_import').val());
        }
        if($('#fuel_import').val() != undefined && $('#fuel_import').val() != ''){
            fuel = parseFloat($('#fuel_import').val());
        }
        if($('#office_import').val() != undefined && $('#office_import').val() != ''){
            office = parseFloat($('#office_import').val());
        }
        total.val(myToFixed(rent + liability_release + drivers + other_amount + relief_supplement + fuel + office, 2))
        // Verificar configuracion del tipo de renta, si tiene configurado que el cobro total del contrato para voucher
        if (payment_total_voucher !== 'true') {
            total.trigger('click');
        }
    }
}
import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
import {ajaxErrorMessages} from "./globalFunctions_controller";

// require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $(".js-example-responsive").select2({
            width: '100' // need to override the changed default
        });

        $('.mc2ec_select').select2({
            width: '100%',
            multiple: true,
            language: "es",
            theme: "default",
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
        });

        $("#multi_businesses").on('select2:select select2:unselect', this.findBasicBusinessByBusiness.bind(this));
    }

    disconnect() {
        $(".js-example-responsive").select2({
            width: '100' // need to override the changed default
        });

        $('.mc2ec_select').select2({
            width: '100%',
            multiple: true,
            language: "es",
            theme: "default",
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
        });

        const url = this.data.get("url");
        let csrf = document.querySelector('meta[name="csrf-token"]').content;

        $.blockUI({message: false});
        $.ajax({
            type: "POST",
            url: 'reports/find_basic_business_and_hirepoint',
            headers: {'X-CSRF-Token': csrf},
            data: {business_id: [], basic_business_id: []},
            success: function (res) {
                $.unblockUI();
                $("#basic_businesses").empty();
                $("#hire_points").empty();
                if (res !== undefined) {
                    $.each(res.basic_businesses, function (index, basic_business) {
                        $("#basic_businesses").append(
                            $("<option>")
                                .text(basic_business.name)
                                .attr("value", basic_business.id)
                        );
                    });

                    $.each(res.hire_points, function (index, hire_point) {
                        $("#hire_points").append(
                            $("<option>")
                                .text(hire_point.name)
                                .attr("value", hire_point.id)
                        );
                    });
                }

                $("#multi_businesses").off('select2:select select2:unselect');
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        })
    }

    findBasicBusinessByBusiness() {
        const url = this.data.get("url");
        const businesses = $('#multi_businesses').val();
        const b_businesses = $('#basic_businesses').val();
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        let contador = 0;
        $.blockUI({message: false});
        $.ajax({
            type: "POST",
            url: url,
            headers: {'X-CSRF-Token': csrf},
            data: {business_id: businesses, basic_business_id: b_businesses},
            success: function (res) {
                $.unblockUI();
                $("#basic_businesses").empty();
                $("#hire_points").empty();
                if (res !== undefined) {
                    $.each(res.basic_businesses, function (index, basic_business) {
                        $("#basic_businesses").append(
                            $("<option>")
                                .text(basic_business.name)
                                .attr("value", basic_business.id)
                        );
                    });

                    $.each(res.hire_points, function (index, hire_point) {
                        $("#hire_points").append(
                            $("<option>")
                                .text(hire_point.name)
                                .attr("value", hire_point.id)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        })
    }
}
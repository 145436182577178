/**
 # ¿Que hace?
 Este controller forma parte de la logica de gestion de cobros (Payments) y se coloca en el `<tr>` de detalles de cada cobro
 Nota: Concept y Detail hace referencia en si a lo mismo, aunq con Details suele referirse a los conceptos pero dentro de un cobro.

 # ¿Que encontraras aqui?
 - AUXILIARS: variables auxiliares necesaris para los builders y dentro del controller
 - HTML Builders: es con lo q se genera el HTML necesario para los detalles en el `payment-controller`
 - Controller:
     - Targets
     - Actions: Las dos acciones q detonan las validaciones, una para validar desde dentro del cobro y otra para validar desde fuera
     - Validators Managers: la distincion entre los q se valida por cambios dentro del cobro (validaciones normales | validaciones minimas) y lo q se valida por cambios q afectan a todos los cobros
     - Validators: Pequeñas validaciones encapsuladas en su propia funcion para mas limpieza en el codigo
     - Auxiliar Methods
     - Providers: metodos especializados en como se obtiene el monto de un concepto se gun la operacion en la q se esta

 #### Por cada concepto hay:
 - su nombre en el `humanized_concepts`
 - su variante snake_case en `concept_to_snake_case`
 - un target para su `<input>` en el controller
 - otro target para su tag de Maximo valor, terminado en `MaxAmount`
 - se genera un {} auxiliar con los datos necesarios para validar el concepto en `auxiliarDataForValidation`
 - un Provider (metodo privado) que se encarga de saber como devolver el monto a cobrar de ese concepto en la operacion dada (no se usa para validaciones minimas)

 #### Por cada operacion se tiene:
 - los conceptos q tiene asociado. En `operationsAndTheirConcepts`
 - si esta está aplica o no validaciones minimas en `operationHasMinimalValidation()`
 - los external Listeners en `#initExternalListeners()`
 - especificacion en cada Provider() de como obtener el monto a desglosar del concepto esn esa operacion especifica

 ## Algunas Pautas de su uso o funcionamiento
 - El payment-controller usa el HTML Builder para generar las filas (`<tr>`) de detalles y ya alli se carga este controller
 - Algunas cosas como los conceptos q se muestran varia segun la operacion en la q se detecte q estamos
 - Si la operacion no esta listada en `operationsAndTheirConcepts` se concidera q no lleva desglose por concepto y el Builder no genera el HTML
 - ...
 ## ¿Que son las validaciones minimas o MinimalValidations?
 En una situacion normal se tienen un monto a pagar en uno o varios cobros y ese monto esta dividido por concepotos particulares
 de la operacion en la q se esta, y por tanto en cada cobro se debe desglosar cuanto este esta cubriendo de cada concepto.
 Eso requiere validaciones normales (Las individuales de cada cobro, y las Generales por concepto entre todos los cobros).
 Pero hay operaciones en las q no se tiene un monto a desglosar ya dividido por conceptos, por lo q solo tienes cobros cuyos
 montos distribuiras por concepto libremente, manteniendo las validaciones individuales minimas para esto.

 ## ¿Como agregar esta funcionalidad a una operacion con pagos (osea q ya usa el `payment_controller`)?
 1. agregar el contenedor para las notificaciones debajo de el del `payment_controller` q ya debe estar (su id es `msg_payment`)
 ```html
 <!-- Cobros -->
 <div id="msg_payment"></div> <!-- Para mostrar mensajes de error o de informacion -->
 <div id="msg_payment_details"></div> <!-- Contener mensajes de error o alerta -->
 ```
 2. agregarle el `data-operation` con el identificador de la operacion (debe aparecer aqui en `operationsAndTheirConcepts`) al `<div>` con id `payments`
 ```html
 <div class="form-group mb-3 row" id="payments" data-operation="rentAppendDriver">
 ```
 3. asegurarse q en `operationsAndTheirConcepts` viene el identificador de la operacion en cuestion y los identificadores de sus conceptos
 4. Si es de Validacion Minima (mas arriba digo lo q significa) !!! NO hacer los siguientes pasos
 5. asegurarse de poner  su identificador en la lista de `operationHasMinimalValidations`
 6. debes en cada uno de los Providers q estan casi al final de este controller y que corresponden con los conceptos q la operacion maneja agregar el `case` con el identificador de la operacion y dentro la forma de obtener el monto a desglosar de ese concepto
 7. agregar los listener externos q detonaran la validacion completa de todos los detalles ante cambios externos....ver  `#initExternalListeners()`
 */
import {Controller} from "stimulus";
import {myToFixed} from "./globalFunctions_controller";
import $ from "jquery";

// // todo: ignorar esto ... talvez a futuro
// /**
//  * Funcion que devuelve `true` si un callback es de Action o `true` de Value, y si es de Action actualizar el value pasado por parametro
//  * @param param parametro por el cual se sabra segun su tipo a q tipo de callback pertenece
//  * @param stimulusValue value de stimulus q se debe actualizar si el callback era de tipo Action
//  * @param stimulusTarget <input> del DOM asociado al value
//  */
// let isActionCallback = (param, stimulusValue, stimulusTarget) =>{
//     if(param instanceof Event){
//         let value =  Number(param.target.value) // esto de paso convertiria el '' en 0
//         stimulusValue = value // actualizar el value del controller (si es el mismo q ya tenia no se detona el callback del value)
//         console.log(`rentValueUpdated: ${value}`);
//         return true
//     }else if (param !== (stimulusTarget.value)){ // si lo q viene (param) es un Number y no coincide con lo q hay en el input
//         // se actualiza el input
//         stimulusTarget.value = stimulusValue;
//         // esto lanza otro evento `change` q subirá por el DOM y el controller lo capturará, ejecutandose la primera sentencia del if, pero como el Value y el <input> tendran lo mismo no se ejecutara nada despues
//     }
//     return false
// }

// ##################################################### AUXILIARS #####################################################

let add = (a,b) => Number(myToFixed((a+b),2))
let sub = (a,b) => Number(myToFixed((a-b),2))

// Ayuda para las notificaciones, para obtener el nombre de un concepto
export let humanized_concepts = {
    rent: 'Renta',
    liabilityRelease: 'Relevo de responsabilidad',
    reliefSupplement: 'Suplemento por relevo',
    additionalDriver: 'Conductor adicional',
    fuel: 'Combustible',
    office: 'Cargo por oficina',
    dropOff: 'Drop Off',
    others: 'Otros',
    penalties: 'Penalidades',
    extraDay: 'Dia extra',
    transitNotification: 'Notificaciones de tránsito'
}


/** Devuelve la variente snake_case del ide del concepto (q de porsi ya estan en camelCase) ... todo: estaria bien usar una funcion q lo genere y un macheo uno a uno
  */
export let concept_to_snake_case = {
    rent: 'rent',
    liabilityRelease: 'liability_release',
    additionalDriver: 'additional_driver',
    reliefSupplement: 'relief_supplement',
    fuel: 'fuel',
    office: 'office',
    dropOff: 'drop_off',
    others: 'others',
    penalties: 'penalties',
    extraDay: 'extra_day',
    transitNotification: 'transit_notification'
}
// Esto podria ser el sustituto de lo anterior
/** Dado un string en `camelCase` devuelve su variente en `snake_case`
 * @param str
 * @returns {*}
 */
export let toSnakeCase = str=>str.replace(/([A-Z])/g, (match)=>('_'+match.toLowerCase()))

// Objeto auxiliar q contiene operaciones y sus conceptos. La q no aparece aqui es pq no tiene desglose por conceptos.
// Ej.: (b2cAdjustments clientDebitBalance)
// Conceptos : ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'others', 'penalties', 'extraDay',  'transitNotification'];
// NOTA IMPORTANTE: si la operacion no aparece aqui, entonces en los cobros de dicha operacio no habra desglose por concepto !!!
export let operationsAndTheirConcepts = {
    // Renta
    rent: ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'others'],
    // Adicionar chofer
    rentAppendDriver: ['additionalDriver', 'others'],
    // Prorroga
    rentExtend: ['additionalDriver', 'others'],
    // Cambio de carro
    rentCarChange: ['additionalDriver', 'fuel', 'others'],
    // Pagos adicionales en una renta
    rentAdditionalPayment: ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office',  'others'],
    // Pago pordeuda
    clientDebitBalance: ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'penalties', 'extraDay',  'transitNotification', 'others'],
    // Liquidacion
    rentLiquidation: ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'penalties', 'extraDay',  'transitNotification', 'others'],
    // Ajuste de renta
    rentNew: ['rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'others']
}

/** Devuelve la lista de conceptos segun la Operacion dada
 * @param operation :string (rent | ...) todo... poner el resto de operaciones
 * @returns {[string]}
 */
let conceptsByOperation = operation => operationsAndTheirConcepts[operation]

/** Para saber si la operacion es de validaciones minimas... osea q no tiene un monto por concepto a desglosar. Solo debe distribuir el monto del cobro entre los conceptos.
 * Todas las operacioines q deban aplicar validaciones minimas deben aparecer en la lista de este codigo.
 * */
let operationHasMinimalValidations = operation => ['clientDebitBalance', 'rentLiquidation', 'rentNew'].includes(operation)

// ################################################### HTML BUILDERS ###################################################

/** Devuelve el segmento HTML con la estructura necesaria para recoger los detalles de un cobro, en conjunto con el Stimulus controller `payments-details`.
 * Pero solo si este tiene Conceptos asociados en `operationsAndTheirConcepts`
 * @param id : integer Número q identifica al cobro y a sus elementos de otros cobros.
 * @param code : string Código de la modalidad de pago.
 * @param table_columns_number : integer Cantidad de columnas q se le debe asignar al `colspan` de un `<td>` para q ocupe toda la fila.
 * @param operation : string Identificador de la operacion.
 * @param payment_amount_source_id :string `id` de la etiqueta del documento del cual se obtendra el importe del cobro.
 * @returns string
 */
export let detailsSegmentHtmlBuilder = (id, code, table_columns_number, operation, payment_amount_source_id = `${code}_amount_${id}`) => (Object.keys(operationsAndTheirConcepts).includes(operation)) ?
    `           <!-- DESGLOSE POR CONCEPTO -->
                <tr id="${code}-tr-${id}_details" data-controller="payment-details" 
                data-amount-input-id="${payment_amount_source_id}"
                data-id="${id}"
                data-code="${code}"
                data-operation="${operation}"
                data-table-columns-amount="${table_columns_number}"><!-- estos datas le dan informacion vital al controller para trabajar -->
                    <td colspan="${table_columns_number}">
                        
                        <!-- Detalles (Conceptos) -->
                        <div class="row gx-1">
                            <span class=" col-12 mt-1 mb-2 hr-text">Desglose por concepto</span>
                            ${conceptsByOperation(operation).map(concept_id=>detailHtmlBuilder(concept_id, code, id)).join('')}  
                        </div>
                           
                        <!-- Mensajes/Errores del cobro -->
                        <div class="alert alert-danger mb-0 mt-1" style="display: none"
                             data-payment-details-target="totalDeatailsAmountMsg">
                            <strong>ERROR: </strong> La suma de los conceptos debe igualar el monto del cobro. <span class="ml-1"></span>
                        </div>
                        
                    </td>
                    <tr class="border-0"> <!-- SEPARADOR para distinguir bien un payment de otro -->
                        <td colspan="${table_columns_number}" class="border-0 border-top-wide border-secondary pt-0 pb-2"></td>
                    </tr>
                </tr>`
    :
    ''


/** Construye el HTML necesario para capturar un concepto. Auxiliar del metodo `detailsSegmentHtmlBuilder`
 * @param concept_id : string
 * @param code : string
 * @param id : integer
 * @returns {string}
 */
let detailHtmlBuilder = (concept_id, code, id) => `
    <div class="col d-flex flex-column justify-content-start">
        <label class="text-muted font-weight-bold small text-truncate ml-1" title="${humanized_concepts[concept_id]}" for="payment_detail_rent">${humanized_concepts[concept_id]}</label>
        <input type="number" 
         name="payments[${code}][${id}][details][${concept_to_snake_case[concept_id]}]" id="${code}_detail_${concept_to_snake_case[concept_id]}_${id}" 
         value="0" class="form-control" required="required" min="0" max="0" step="any" disabled
         data-payment-details-target="${concept_id}"
         data-action="change->payment-details#checkThisPaymentDetails">
        <span class="align-self-end small py-0 mt-1 badge bg-dark-lt" style="display: none"
         data-payment-details-target="${concept_id}MaxAmount" title="El desglose del concepto no debe superar el monto del cobro ni el monto del concepto">max:&nbsp;0</span>
    </div>
`
// ##################################################### CONTROLLER ####################################################

export default class extends Controller {
    static targets = [
        // --- Mensajes ---
        'totalDeatailsAmountMsg', // mesaje para si no coincide el total de detalles con el monto del cobro
        // --- Details ---
        // inputs
        'rent', 'liabilityRelease', 'additionalDriver', 'reliefSupplement', 'fuel', 'office', 'dropOff', 'others',  'penalties', 'extraDay',  'transitNotification',
        // etiquetas de maximo valor
        'rentMaxAmount', 'liabilityReleaseMaxAmount', 'additionalDriverMaxAmount', 'reliefSupplementMaxAmount', 'fuelMaxAmount', 'officeMaxAmount', 'dropOffMaxAmount', 'othersMaxAmount',  'penaltiesMaxAmount', 'extraDayMaxAmount',  'transitNotificationMaxAmount'
    ]

    /** Secapturan los datos necesarios y se crean los atributos q se usaran a lo largo del controller
     */
    connect() {
        // `this.element` como jQuery
        this.$element = $(this.element)

        // datos necesarios
        this.operation = this.$element.data('operation')
        this.has_minimal_validations = operationHasMinimalValidations(this.operation)
        this.concepts = conceptsByOperation(this.operation)  // conceptos q se van a manejar aen este controller
          // todo: pensar en si realmente hacen falta estos dos (hasta ahora no se usan en el controller)
        this.code = this.$element.data('code')
        this.id = this.$element.data('id')

        // contenerdor de los mensajes de validacion
        this.$messages_container = $('#msg_payment_details')

        // input o elemento con el importe para este pago q se puede obtener con el `.val()` de jQuery
        this.$amount_source_element = $(`#${this.$element.data('amount-input-id')}`);
        this.$amount_source_element.on('change', ()=>this.checkThisPaymentDetails())  // capturar los cambios en el mismo todo: habria q tener en cuenta q algun tipo de cobro podria no se un `<input>` al cual le captura el cambio con `change`

        this.#testTargetsConnection() // todo: se podria borrar cuando ya no haga falta pero es bueno para testear errores


        // Capturar cambios externos q requieran revalidar todos los detalles de los cobros.
        if(!this.has_minimal_validations) // solo si la operacion no es de validaciones minimas ya q a estas ningun cambio externo les afecta y por tanto no necesita esos listeners...
            this.#initExternalListeners()
    }

    // AL ELIMINARSE EL COBRO
    disconnect() {
        if(!this.has_minimal_validations) // pq en validaciones minimas los cambios de un cobro y sus detalles no afecta a otros
            this.checkAllPaymentsDetails()
    }

    // #################################################### ACTIONS ####################################################

    /** ACTION q ejecuta validaciones detonadas por cambios en este cobro.
     * Este se llama solo para cambios q afecten a este payment y no a los demas.
     * Ej.: Cambios en el monto del cobro o los detalles del mismo.
     * Tiene dos variantes,
     * - la Normal: que necesita el monto por concepto a desglosar y el monto del cobro a desglosar
     * - la Minima: que no posee monto por concepto y solo valida el desglose del monto del cobro
     */
    checkThisPaymentDetails() {
        let aux_data = this.#auxiliarDataForValidation() // para llamarlo una sola vez y usarlo en todos
        if(this.has_minimal_validations){
            this.paymentDetailsMinimalValidations(aux_data)
        }else{
            this.paymentDetailsSpecificValidations(aux_data)
            this.paymentDetailsGeneralValidations(aux_data)
        }
    }

    /** ACTION q ejecuta las validaciones individuales de todos los cobros y luego las generales.
     * Es este el q debe llamarse al relaizarse cambios q afecten a todos los cobros y por tanto se deban revalidar de forma individual, para luego efectuar las validaciones generales.
     * Ej.: Cambios en el monto total a desglosar de un concepto. Tambien al elminar un cobro.
     */
    checkAllPaymentsDetails() {
        // capturar los detalles de todos los pagos
        let $all_payment_details = $('[data-controller="payment-details"]')
        if($all_payment_details.length > 0){ // si hay alguno ...

            $all_payment_details.each((i, element)=> {
                // obtener su controller y ejecutar las validaciones individuales
                this.application.getControllerForElementAndIdentifier(element, 'payment-details').paymentDetailsSpecificValidations()
            })
            // luego efectual las validaciones generales
            this.paymentDetailsGeneralValidations()
        }else{  // si no, limpiar los mensajes
            this.$messages_container.empty()
        }
    }

    // ----------------------------------------------- Validators Managers ---------------------------------------------
    // Dos funciones, las cuales usan los Validator, y dividen las validaciones internas de los detalles de UN cobro, con las externas q inplican a TODOS los cobros

    /** Resposable de hacer las validaciones y modificaciones especificas de este payment.
     * - la suma de los conceptos de un cobro debe igualar el monto del cobro
     * - el monto de un detalle no puede superar el monto del cobro ni el monto del su concepto a desglosar (Esto segundo no aplica si `operationHasGeneralValidations` devuelve false)
     * @param aux_data Arreglo auxiliar con datos y elementos por concepto necesarios para las validaciones de cada concepto (es OPCIONAL por si en el contextro en q se usa el metodo ya se tiene su valor y no es necesario volver a ejecutar el codigo, a modo de optimizacion)
     */
    paymentDetailsSpecificValidations(aux_data = this.#auxiliarDataForValidation()){
        // monto del cobro
        let payment_amount = Number(this.$amount_source_element.val())
        // auxiliar para comporbar suma de los detalles
        let details_total = Number(0)
        // auxiliar donde se acumularan los inputs de los conceptos q allan quedado habilitados para modificar
        this.$available_concepts = $('')
        // --- Ajustar cada detail ---
        aux_data.forEach(concept=>{ // iterar por cada concepto
            //  agregar al total
            details_total = add(details_total, Number(concept['$input'].val()))
            // lo q hay q pagar al momento por este concepto
            let to_pay_per_concept = concept['amount']

            if( to_pay_per_concept <= 0 ){ // si el concepto no generó una diferencia positiva (osea no se cobrará más por ese concepto)
                // deshabilitar el concepto, limitarlo y ponerle valor 0
                concept['$input']
                    .prop('disabled', true)
                    .prop('max', 0)
                    .val(0)
                // ocultar etiqueta de `maximo`
                concept['$max'].hide()
            }else{ // sí genera diferencia positiva y por tanto es editable ...
                // agregar a al selector de conceptos editables
                this.$available_concepts = $.merge( this.$available_concepts, concept['$input'])

                // maximo valor del detalle: (el minimo entre: el monto del cobro y el monto total a recoger por este concepto)
                let max = Math.min(to_pay_per_concept, payment_amount)

                // habilitar y estableser su maximo
                concept['$input']
                    .prop('disabled', false)
                    .prop('max', max)

                // mostrar y actualizar la etiqueta de `maximo`
                concept['$max']
                    .show()
                    .html(`max:&nbsp;${max}`)

                // poner el tag de Maximo valor en rojo si se supera
                if(Number(concept['$input'].val()) > max)
                    concept['$max'].removeClass('bg-dark-lt').addClass('bg-red-lt');
                else
                    concept['$max'].removeClass('bg-red-lt').addClass('bg-dark-lt');
            }
        })

        // --- Validar suma de los conceptos del cobro ---
        this.#validateDetailsSum(details_total , payment_amount)
    }

    /** Resposable de las validaciones generales de los conceptos entre todos los paymentes
     * - la suma de un concepto entre todos los cobros debe igualar la cantidad a pagar al momento por dicho cobro
     * @param aux_data Arreglo auxiliar con datos y elementos por concepto necesarios para las validaciones de cada concepto (es OPCIONAL por si en el contextro en q se usa la cuncion ya se tiene su valor y no es necesario volver a ejecutar el codigo,a modo de optimizacion)
     */
    paymentDetailsGeneralValidations(aux_data = this.#auxiliarDataForValidation()){
        this.$messages_container.empty() // borar los mensajes
        let devolution_amount = aux_data.filter(data => data['amount'] < 0).reduce((sum, data) => add(sum, (data['amount']*-1)), 0)
        // auxiliares
        let total_to_pay = 0 // total q se debe desglosar
        let total_collected = 0 // total recogido al desglosar
        // quedarnos con los q si generaron algo para desglosar ...
        aux_data.filter(data => data['amount'] > 0).forEach(concept=>{ // y por dada uno...
            // Acumular el total recogido de este concepto entre todos los cobros.
            // capturarando todos los `<inputs>` con el `data-payment-details-target` de este concepto (osea capturar este mismo concepto entre todos los cobros) ... y sumar su valor
            let total_collected_among_all_payments = $(`[data-payment-details-target="${ concept['id'] }"]`).toArray().reduce((sum ,concept_input)=>add(sum, Number($(concept_input).val())), 0)

            total_collected = add(total_collected, total_collected_among_all_payments)
            total_to_pay = add(total_to_pay, concept['amount'])

            // --- Validar q debe coincidir total recogido de este concepto con el monto total a pagar del mismo ---
            this.#validateConceptAmountAmongPayments(
                concept['id'],
                devolution_amount > 0,
                total_collected_among_all_payments,
                concept['amount'])
        })
        // mostrar mensaje sobre el tema `devolucion` mientras haya un monto de devolucion y este no se haya distribuido correctamente esta dentro del monto de los conceptos
        if(devolution_amount > 0 && add(total_collected, devolution_amount) !== total_to_pay) this.$messages_container.append(
            `<div class='alert alert-warning' role="alert">
                  <strong>Precaución: </strong>
                  Se generó un monto de devolucion de <b>${devolution_amount.toFixed(2)} USD</b>, la distribución del mismo y de los importes por concepto quedarán a concideración del usuario.
            </div>`)
    }

    paymentDetailsMinimalValidations(aux_data = this.#auxiliarDataForValidation()){
        // monto del cobro
        let payment_amount = Number(this.$amount_source_element.val())
        // auxiliar para comporbar suma de los detalles
        let details_total = Number(0)
        // auxiliar donde se acumularan los `<inputs>` de los conceptos
        this.$available_concepts = $('')
        // --- Ajustar cada detail ---
        aux_data.forEach(concept=>{ // iterar por cada concepto
            //  agregar al total
            details_total = add(details_total, Number(concept['$input'].val()))
            // asegurar q este habilidatdo y establecer el maximo
            concept['$input']
                .prop('disabled', false)
                .prop('max', payment_amount)
            // agregar a al selector de conceptos editables (en las validaciones minimas son todos)
            this.$available_concepts = $.merge( this.$available_concepts, concept['$input'])
        })

        // --- Validar suma de los conceptos del cobro ---
        this.#validateDetailsSum(details_total , payment_amount)
    }

    // ################################################ Private Methods ################################################
    /** Dada la `operacion` establece el listner o los listeners a aquellos elementos exteriores q detonaran el metodo `this.checkAllPaymentsDetails()`.
     * Osea q su cambio implica la necesidad de una revalidacion de todos los detalles.
     * NOTA!!! Es importante tener en cuenta q los cambios q se hacen en los values de los `<input>` en JS no detona Eventos, por lo q si alguno de estos elementos a los q se les agrega el listener es modificado con JS recordar lanzar el evento con `.trigger('change')` para q los listeners q se agregan en este metodo se ejecuten por ese cambio tambien.
     */
    #initExternalListeners(){
        switch (this.operation) {
            case 'rent': $('#amounts_rent_import, #liability_release_import, #drivers_import, #relief_supplement_import, #amounts_fuel, #amounts_office, #amounts_drop_off, #amounts_other_payments').on('change', ()=>this.checkAllPaymentsDetails()); break;
            case 'rentAppendDriver': $('#drivers_import, #other_payments').on('change', ()=>this.checkAllPaymentsDetails()); break;
            case 'rentCarChange': $('#drivers_import, #fuel_import, #other_payments').on('change', ()=>this.checkAllPaymentsDetails()); break;
            case 'rentAdditionalPayment': $('#rent_import, #liability_release_import, #relief_supplement_import, #fuel_import, #drivers_import, #office_import, #other_amount').on('change', ()=>this.checkAllPaymentsDetails()); break;
            // todo: Continuar con las demas operaciones ...
        }
    }

    // --------------------------------------------------- Validators --------------------------------------------------
    // Pequeñas porciones de codigo q separan simples validaciones para mejor comprencion.

    /** Valida si un concepto dado cuando (no ha alcanzado/sobrepasa) la cantidad a pagar al momento por ese concepto.
     * (Muestra/Oculta/Remplaza) el mensage de (Warning/Danger) segun las dos sircunstancias anteriores.
     * @param concept_id : string Clave q identifica al concepto
     * @param to_pay_per_concept : number Monto a recoger por concepto (es OPCIONAL por si en el contextro en q se usa la cuncion ya se tiene su valor y no es necesario volver a ejecutar el codigo,a modo de optimizacion)
     * @param exist_devolution : boolean Si se generó o no un monto de devolucion.
     * @param total_collected_among_all_payments : number Total recogido de ese concepto entre todos los payments
     */
    #validateConceptAmountAmongPayments(concept_id,exist_devolution , total_collected_among_all_payments, to_pay_per_concept = this.#getConceptAmount(concept_id)){
        // ### POSIBLE RESULTADO 1 (Nada)
        // si el mono del concepto no es positivo no se hace nada (Realmente esto no deberia pasar...pero eso ya son validaciones responsabilidad de payment-controller)
        if(to_pay_per_concept <= 0) return

       // comparar lo recogido con el objetivo
        let difference = sub(total_collected_among_all_payments, to_pay_per_concept)

        // ### POSIBLE RESULTADO 2 (Nada)
        if(difference === 0) return // OK, es exactamente el monto !!!

        // ### POSIBLE RESULTADO 3 (Poner mensaje)
        // si aun no llegas al monto y no hay devolucion q desglosar... (la segunda condicion es pq al tener q distribuir la devolucion el monto final del concepto puede variar y por tanto si hay devolucion el mensaje de `faltante` no se muestra)
        if (difference < 0 && !exist_devolution) this.$messages_container.append(
            `<div class='alert alert-warning'
                data-concept="${concept_id}" role="alert">
                <strong>Concepto - ${humanized_concepts[concept_id]}: </strong>
                Debe recoger en los cobros el total por concepto de <b>${humanized_concepts[concept_id]}</b> a pagar al momento. (${total_collected_among_all_payments}/${to_pay_per_concept})
            </div>`)
        // o te pasaste ...
        if (difference > 0) this.$messages_container.append(
            `<div class='alert alert-danger'
                data-concept="${concept_id}" role="alert">
                <strong>Concepto - ${humanized_concepts[concept_id]}: </strong>
                Ha excedido el total por concepto de <b>${humanized_concepts[concept_id]}</b> a pagar al momento. (${total_collected_among_all_payments}/${to_pay_per_concept})
            </div>`)
    }

    /** Valida q la suma de los detalles (conceptos) de un cobro sea igual al monto del cobro.
     * (Muestra/Oculta/Actualiza) el mensage para esta validacion dentro del cobro.
     * @param details_total : number Suma de los detalles del cobro
     * @param payment_amount : number Monto del cobro
     */
    #validateDetailsSum(details_total , payment_amount){
        let $msg =  $(this.totalDeatailsAmountMsgTarget) // mensaje
        let $counter =  $msg.children().last() // fragmento q muestra: (suma / monto del cobro)
        if (details_total !== payment_amount){ // si no se desglosa exactamente el monto del cobro ...
            $counter.text(`(${details_total}/${payment_amount})`)
            $msg.show()
            this.$available_concepts.addClass('border-wide border-danger') // poner rojo los inputs
        }else{
            $msg.hide()
            $counter.text('')
            this.$available_concepts.removeClass('border-wide border-danger') // poner normal los inputs
        }
    }

    // ------------------------------------------------ Auxiliar Methods -----------------------------------------------

    /** Devuelve la lista auxiliar de objetos por cada concepto de la operacion actual necesaria para hacer las validaciones
     */
    #auxiliarDataForValidation = () => this.concepts.map(concept_id=>{
        return {
            id: concept_id,
            amount: this[`${concept_id}AmountProvider`](), // monto total a recoger por este concepto //todo: a lo mejor se podria quitar
            $input: $(this[`${concept_id}Target`]), // input del concepto (detalle) en el cobro
            $max: $(this[`${concept_id}MaxAmountTarget`]) // etiqueta de Maximo valor de este concepto en el cobro
        }
    })


    // ----------------------------------------------- Concept Providers -----------------------------------------------
    // Estos metodos devuelven el monto a desglosar del concepto X de la forma en q este se obtiene en el tipo de operacion actual.
    // Osea, se especializan en saber como obtener el monto del concepto segun la operacion en la q se está.
    // Nota: queria q fueran privados pero entonces no podria llamarlos de forma dinamica con `this['methodnName']()`

    /** Devuelve el monto a pagar del concepto dado, llamando a su respectivo Provider
     * @param concept_id Clave q identifica al concepto
     * @returns {number}
     */
    #getConceptAmount = (concept_id) => this[`${concept_id}AmountProvider`]()

    // Renta
    rentAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#amounts_rent_import').val()), Number($('#reservation_rent_collected').val()))
            case 'rentAdditionalPayment':
                return Number($('#rent_import').val());
            default: return 0
        }
    }
    // Relevo
    liabilityReleaseAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#liability_release_import').val()), Number($('#reservation_liability_release_collected').val()))
            case 'rentAdditionalPayment':
                return Number($('#liability_release_import').val());
            default: return 0
        }
    }
    // Choferes Adicionales
    additionalDriverAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#drivers_import').val()), Number($('#reservation_additional_driver_collected').val()))
            case 'rentAppendDriver':
            case 'rentCarChange':
            case 'rentAdditionalPayment':
                return Number($('#drivers_import').val());
            case 'rentExtend':
                return sub(Number($('#drivers_import').val()), Number($('#data_drivers_import').val()))
            default: return 0
        }
    }
    // Suplemento
    reliefSupplementAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#relief_supplement_import').val()), Number($('#come2e_relief_import').text())) //Number($('#reservation_relief_supplement_collected').val())
            case 'rentAdditionalPayment':
                return Number($('#relief_supplement_import').val())
            default: return 0
        }
    }
    // Combustible
    fuelAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#amounts_fuel').val()), Number($('#reservation_fuel_collected').val()))
            case 'rentCarChange':
            case 'rentAdditionalPayment':
                return Number($('#fuel_import').val())
            default: return 0
        }
    }
    // Oficina
    officeAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#amounts_office').val()), Number($('#reservation_office_collected').val()))
            case 'rentAdditionalPayment':
                return Number($('#office_import').val())
            default: return 0
        }
    }
    // Drop-Off
    dropOffAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#amounts_drop_off').val()), Number($('#reservation_drop_off_collected').val()))
            default: return 0
        }
    }
    // Penalidades
    penaltiesAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return 0 // no pertenece a esta operacion
            default: return 0
        }
    }
    // Dia extra
    extraDayAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return 0 // no pertenece a esta operacion
            default: return 0
        }
    }
    // Notificaciones de tránsito
    transitNotificationAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return 0 // no pertenece a esta operacion
            default: return 0
        }
    }
    // Otros
    othersAmountProvider(){
        switch (this.operation) {
            case 'rent':
                return sub(Number($('#amounts_other_payments').val()), Number($('#reservation_other_payments_collected').val()))
            case 'rentAppendDriver':
            case 'rentCarChange':
                return Number($('#other_payments').val())
            case 'rentAdditionalPayment':
                return Number($('#other_amount').val())
            case 'rentExtend':
                return Number($('#other_payments').val())
            default: return 0
        }
    }

    // ----------------------------------------------------- Tests -----------------------------------------------------
    // todo: esto es temporal para probar la utilizacion pprogramatica correcta de los Trgets y sus metodos.. se podria borar luego
    #titlelize = (str)=>str[0].toUpperCase()+str.slice(1) // pone en mayuscula la primera letra
    // comprueba q se generaron y cargaron correctamente los targes necesarios para la operacion en la q se esta
    #testTargetsConnection = () => this.concepts.forEach(target => ( this[`has${this.#titlelize(target)}Target`] ) ?  console.info(target+' OK') : console.error(target))


    // --- UpdateValue CallBacks ---
    // son los callbacks de los values al actualizarse... pero adaptados para tambien servir para los `actions` distinguiendo el tipo de parametro q reciven
    // si el parametro es un Number es pq se ejecuto como callback de la actualizacion de un value, si no, es la llamada de un action
    // rentValueChanged(value){
    //     if (isActionCallback(value,  this.rentValue, this.rentTarget)) return
    //     // Codigo como ValueChanged callback...
    //     console.log(`rentValueChanged: ${value}`);
    // }
}
import {Controller} from "stimulus";
import $ from "jquery";

export default class extends Controller {
    connect() {  }

    toggleMaxNumberDaysInput() {
        if ($('.request_reservation').is(":checked") === true) {
            $('#label_max_number_days').removeClass('required');
            $('.max_number_days').prop('required',false);
            $('.max_number_days').prop('readonly',true);
            $('.max_number_days').val(0);
        } else {
            $('#label_max_number_days').addClass('required');
            $('.max_number_days').prop('required',true);
            $('.max_number_days').prop('readonly',false);
        }
    }


    toggleMaxNumberRentsInput() {
        if ($('.titular_multiple_active_rents').is(":checked") === false) {
            $('#label_max_number_rents').removeClass('required');
            $('.max_number_rents').prop('required',false);
            $('.max_number_rents').prop('readonly',true);
            $('.max_number_rents').val(0);
        } else {
            $('#label_max_number_rents').addClass('required');
            $('.max_number_rents').prop('required',true);
            $('.max_number_rents').prop('readonly',false);
        }
    }
}
import {Controller} from "stimulus"
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
    }

    checkWhoMakesRobVehicleLiquidation(e) {
        if($('.liquidate').is(':checked')){
            $('#liquidation_user').show();
            // $('#autorize_liquidation').show();
        }else {
            $('#liquidation_user').val();
            $('#liquidation_user').hide();
            // $('#autorize_liquidation').hide();
        }

    }

    compareValuesInComplaintForm(e){
        // e.preventDefault();
        let initialValues = $('#rob_complaint_operation_initial_value_cents');
        let residualValues = $('#rob_complaint_operation_residual_value_cents');

        residualValues.on('change', function () {
            if(residualValues.val() > initialValues.val()){
                alert('El valor residual no debe ser mayor que el valor inicial.');
                residualValues.val(0.00);
            }
        });

    }

    compareDatesInComplaintForm(e){
        e.preventDefault();
        let robberyDate = $('#rob_complaint_operation_robbery_date');
        let complaintDate = $('#rob_complaint_operation_complaint_date');

        robberyDate.on('change', function () {
            if(complaintDate.val()!==""){
                if(robberyDate.val() > complaintDate.val()){
                    alert("La fecha del hecho no debe ser posterior a la fecha de la denuncia");
                    robberyDate.val("");
                    complaintDate.val("");
                }
            }
        });

        complaintDate.on('change', function () {
            if(robberyDate.val()!==""){
                if(complaintDate.val() < robberyDate.val()){
                    alert("La fecha del hecho no debe ser posterior a la fecha de la denuncia");
                    complaintDate.val("");
                    robberyDate.val("");
                }
            }
        });
    }
}
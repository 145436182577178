// Stimulus para permitir forzadamente hacer submit() al presionar Enter en los formularios de un contenedor.
// Es una contramedida al controller `preventSubmitController.js`
// Se le asigna este controler al padre q contenga los formularios en los q kieres permitir la accion Enter para hacer submit.
import { Controller } from "stimulus"
import $ from 'jquery';

// Connects to data-controller="force-submit-on-enter"
export default class extends Controller {
  connect() {
    $(this.element).find('form').on('keydown', function (e) {
      if (e.key === 'Enter') {
        $(this).submit()
      }
    });
  }
}

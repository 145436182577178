import {Controller} from "stimulus";
import $ from "jquery";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Service unavailable.";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}
export default class extends Controller {
    connect() {
        $('.c2ec_select').select2({// Empty agencies select list and populate again with response.
            theme: "bootstrap",
            language: "es",
        });

        $(".vehicle, .department, .nom_group_fleet").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    getVehicleInfo(e) {
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {enrollment: e.target.value},
                success: function (res) {
                    if (!res.vehicle) {
                        alert('Matrícula no válida. Por favor escriba una matrícula válida')
                        $('#enrollment').val('')
                    }else if(res.op === undefined || res.op == null ){
                        alert('El Vehículo no tiene operaciones')
                        let status = 'Estado Inválido'
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text('');
                        $('#operation-destination').text('');
                        $('#operation-date').text('');
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-indicative').text(res.vehicle.indicative);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                    // Las rentas hay que liquidarlas.
                    //  && res.op.source_type !== 'Rent'
                    else if(res.op.source_type !== 'RackingOperation'){
                        alert('El vehículo no está "Trasegando" por lo que no se puede parquear de forma excepcional')
                        $('#enrollment').val('')
                    } else {
                        let status = ''
                        if(res.op.source_type === 'RackingOperation' ){
                            status = 'Trasegando'
                        // }else if (res.op.source_type === 'Rent') {
                        //     status = 'Rentado'
                        }else{
                            status = 'Estado Inválido'
                        }
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text(res.origin);
                        $('#operation-destination').text(res.destination);
                        $('#operation-date').text(res.date);
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                        // Kilometraje
                        $('#mileage').prop('min', res.vehicle.mileage);
                        $('#mileage').val(res.vehicle.mileage);
                        if ($.isNumeric(res.vehicle_max_mileage)) {
                            $('#mileage').prop('max', res.vehicle_max_mileage);
                        }else{
                            $('#mileage').removeAttr('max');
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleDataForMtto(e) {
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {enrollment: e.target.value},
                success: function (res) {
                    if (!res.vehicle) {
                        alert('Matrícula no válida. Por favor escriba una matrícula válida.');
                        $('#enrollment').val('')
                    }else if(res.vehicle.status != 'rent') {
                        alert('Función no permitida, solo para vehículos rentado.');
                        $('#enrollment').val('')
                    } else {
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-basic-business-name').text(res.basic_business_name);
                        $('#vehicle-department-name').text(res.department_name);
                        $('#vehicle-department-code').text(res.department_code);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-ficav').text(res.vehicle_ficav);
                        $('#vehicle-next-mtto').text(res.vehicle.next_maintenance);
                        $('#vehicle_data').text(res.vehicle_data);
                        $('#vehicle-data').css('display', 'block');
                        // Validando kilometraje maximo
                        if ($.isNumeric(res.vehicle_max_mileage)) {
                            $('#next_maintenance').prop('max', res.vehicle_max_mileage);
                        }else{
                            $('#next_maintenance').removeAttr('max');
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleDataByIndicativeNumber(e){
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {indicative: e.target.value},
                success: function (res) {
                    if (!res.vehicle) {
                        alert('No se encontró ninguna coincidencia con el indicativo suministrado.');
                        $('#indicative').val('')
                    }else if(res.vehicle.status != 'rent' && res.coincidences === null) {
                        alert('Función no permitida, solo para vehículos rentado.');
                        $('#indicative').val('')
                    } else if (res.coincidences != null){
                        $('#indicative').val('');
                        alert(`Con el indicativo suministrado existen ${res.coincidences} coincidencias.`);
                    } else {
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-basic-business-name').text(res.basic_business_name);
                        $('#vehicle-department-name').text(res.department_name);
                        $('#vehicle-department-code').text(res.department_code);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-ficav').text(res.vehicle_ficav);
                        $('#vehicle-next-mtto').text(res.vehicle.next_maintenance);
                        $('#vehicle_data').text(res.vehicle_data);
                        $('#vehicle-data').css('display', 'block');
                        // Validando kilometraje maximo
                        if ($.isNumeric(res.vehicle_max_mileage)) {
                            $('#next_maintenance').prop('max', res.vehicle_max_mileage);
                        }else{
                            $('#next_maintenance').removeAttr('max');
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleInfoForParkingByIndicative(e){
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {indicative: e.target.value},
                success: function (res) {
                    if (!res.vehicle) {
                        alert('Indicativo no válido. Por favor escriba un indicativo válido.')
                        $('#indicative').val('')
                    }else if(res.op === undefined || res.op == null ){
                        alert('El Vehículo no tiene operaciones')
                        let status = 'Estado Inválido'
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text('');
                        $('#operation-destination').text('');
                        $('#operation-date').text('');
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                        // Las rentas hay que liquidarlas.
                    //  && res.op.source_type !== 'Rent'
                    else if(res.op.source_type !== 'RackingOperation'){
                        alert('El vehículo no está "Trasegando" por lo que no se puede parquear de forma excepcional')
                        $('#enrollment').val('')
                    }else if (res.coincidences != null){
                        $('.indicative').val('');
                        alert(`Con el indicativo suministrado existen ${res.coincidences} coincidencias.`);
                    } else {
                        let status = ''
                        if(res.op.source_type === 'RackingOperation' ){
                            status = 'Trasegando'
                            // }else if (res.op.source_type === 'Rent') {
                            //     status = 'Rentado'
                        }else{
                            status = 'Estado Inválido'
                        }
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text(res.origin);
                        $('#operation-destination').text(res.destination);
                        $('#operation-date').text(res.date);
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                        // Kilometraje
                        $('#mileage').prop('min', res.vehicle.mileage);
                        $('#mileage').val(res.vehicle.mileage);
                        if ($.isNumeric(res.vehicle_max_mileage)) {
                            $('#mileage').prop('max', res.vehicle_max_mileage);
                        }else{
                            $('#mileage').removeAttr('max');
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleStolenInfoByIndicative(e){
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {indicative: e.target.value},
                success: function (res) {
                    console.log(res)
                    if (!res.vehicle) {
                        $('#parking_operation_enrollment').val('');
                        $('#save_stolen_button').addClass('disabled');
                        $('#workshop_operation_indicative').val('');
                        alert('No se encontró un vehículo con el indicativo suministrado.');
                    }else if (res.coincidences != null){
                        $('.indicative').val('');
                        alert(`Con el indicativo suministrado existen ${res.coincidences} coincidencias.`);
                    }else if(res.op === undefined || res.op == null ){
                        alert('El Vehículo no tiene operaciones')
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-origin').text('');
                        $('#operation-destination').text('');
                        $('#operation-date').text('');
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-indicative').text(res.vehicle.indicative);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                        $('#save_stolen_button').removeClass('disabled')
                    }else if(res.vehicle.status !== 'stolen' && res.vehicle.status !== 'reported_by_robbery'){
                        alert('El vehículo no está "Robado" por lo que no se puede ejecutar esta opción. Verifique el vehículo.')
                        $('#enrollment').val('')
                        $('#save_stolen_button').addClass('disabled')
                    }else {
                        $('#save_stolen_button').removeClass('disabled')
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-origin').text(res.origin);
                        $('#operation-destination').text(res.destination);
                        $('#operation-date').text(res.date);
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-indicative').text(res.vehicle.indicative);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleStolenInfo(e) {
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {enrollment: e.target.value},
                success: function (res) {
                    console.log(res)
                    if (!res.vehicle) {
                        $('#parking_operation_enrollment').val('')
                        $('#save_stolen_button').addClass('disabled')
                        alert('Matrícula no válida. Por favor escriba una matrícula válida')
                    }else if(res.op === undefined || res.op == null ){
                        alert('El Vehículo no tiene operaciones')
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-origin').text('');
                        $('#operation-destination').text('');
                        $('#operation-date').text('');
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-indicative').text(res.vehicle.indicative);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                        $('#save_stolen_button').removeClass('disabled')
                    }else if(res.vehicle.status !== 'stolen' && res.vehicle.status !== 'reported_by_robbery'){
                        alert('El vehículo no está "Robado" por lo que no se puede ejecutar esta opción. Verifique el vehículo.')
                        $('#enrollment').val('')
                        $('#save_stolen_button').addClass('disabled')
                    }else {
                        $('#save_stolen_button').removeClass('disabled')
                        $('#vehicle-status').text(res.humanize_status);
                        $('#vehicle-origin').text(res.origin);
                        $('#operation-destination').text(res.destination);
                        $('#operation-date').text(res.date);
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-indicative').text(res.vehicle.indicative);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    getVehicleBySpecs(e) {
        let url = this.data.get("url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: e.target.value},
            success: function (res) {
                $("#smcode").val(res.smcode);
                $("#technical_sheet").text("Ficha Técnica de: " + res.smcode);
                $("#vehicle_type").val(res.vehicle_type);
                $("#country").val(res.country);
                $("#nom_bodywork").val(res.nom_bodywork);
                $("#category").val(res.category);
                $("#nom_subcategory").val(res.nom_subcategory);

                // Empty agencies select list and populate again with response.


            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    checkDepartment() {
        let url = this.data.get("url");
        // let id = $('#department_id').val();
        // let drivers_url = this.data.get("drivers");
        // let driver_id = this.data.get("driver_id");
        // let place_id = this.data.get("origin_place_id");
        // let movement_type = this.data.get("movement_type");
        // if (id) {
        //     this.getData(id, url, this.renderParkings);
        //     this.getData(id, drivers_url, (res) => this.renderDrivers(res, driver_id, place_id, movement_type));
        // }

    }

    getParkingByHirePoint(e) {
        let url = this.data.get("url");
        let drivers_url = this.data.get("drivers");

        if (e.target.value || $('#department_id').val()) {
            this.getData(e.target.value, url, this.renderParkings);
            this.getData(e.target.value, drivers_url, this.renderDrivers);
        }
    }

    getDepartamentByFleet(e){
        let url = this.data.get("url");
        let id = $("#nom_group_fleet_id option:selected").val();
        let department = $("#department_id");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: id},
            success: function (response) {
                $.unblockUI();
                department.empty();
                department.append('<option selected="selected" value=""> Seleccione el departamento</option>');
                if (response.departments !== undefined && response.departments.length > 0) {
                    $.each(response.departments, function (index, department) {
                        $("#department_id").append(
                            $("<option>")
                                .text(department.name)
                                .attr("value", department.id)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    renderParkings(res) {
        $("#parking_id").empty().prop('disabled', false);

        $.each(res.parkings, function (index, parking) {
            $("#parking_id").append(
                $("<option>")
                    .text(parking.name)
                    .attr("value", parking.id)
            );
        });
    }

    renderDrivers(res, driver_id, place_id, movement_type) {
        $("#nom_driver_id").empty().prop('disabled', false);

        $.each(res.drivers, function (index, driver) {
            $("#nom_driver_id").append(
                $("<option>")
                    .text(driver.name + ' ' + driver.last_name)
                    .attr("value", driver.id)
                    .prop("selected", driver.id == driver_id)
            );
        });

        if (place_id) {
            $('#parking_id option').each(function (index, el) {
                $(el).prop("selected", el.value == place_id)
            })
        }

        if (movement_type) {
            $('#type option').each(function (index, el) {
                $(el).prop("selected", el.value == movement_type)
            })
        }
    }

    getData(id, url, callBack) {
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: id},
            success: callBack,
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    select_deposit() {
        let place = this.data.get("place");
        if (place === "parking") {
            $("#deposit_id").prop('disabled', true).empty()
            $("#parking_id").prop('disabled', false)
            this.emptyItems()
        } else {
            // Deposit
            $("#deposit_id").prop('disabled', false)
            $("#parking_id").prop('disabled', true)

            let url = this.data.get("url");
            // call the api para obtener los depositos del departamento
            let id = $('#department_id').val();
            if (id) {
                this.getData(id, url, this.renderDeposits)
            }
        }
    }

    emptyItems() {
        $("#deposit_id").append(
            $("<option>")
                .text('Seleccione el deposito')
                .attr("value", "")
        );
    }

    renderDeposits(res) {
        $.each(res.deposits, function (index, deposit) {
            $("#deposit_id").append(
                $("<option>")
                    .text(deposit.name)
                    .attr("value", deposit.id)
            );
        });
    }

    getVehicleInfoTmp(e) {
        $('#vehicle-data').css('display', 'none');
        if (e.target.value) {
            let url = this.data.get("url");
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {enrollment: e.target.value},
                success: function (res) {
                    if (!res.vehicle) {
                        alert('Matrícula no válida. Por favor escriba una matrícula válida')
                        $('#enrollment').val('')
                    }else if(res.op === undefined || res.op == null ){
                        alert('El Vehículo no tiene operaciones')
                        let status = 'Estado Inválido'
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text('');
                        $('#operation-destination').text('');
                        $('#operation-date').text('');
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                        // Las rentas hay que liquidarlas.
                    //  && res.op.source_type !== 'Rent'
                    else if(res.op.source_type == 'Rent' ||res.op.source_type == 'NewCarOperation' || res.op.source_type == 'StolenOperation' ){
                        alert('El vehículo está "Estado" que no se puede parquear de forma excepcional')
                        $('#enrollment').val('')
                    }
                    else {
                        let status = ''
                        if(res.op.source_type === 'RackingOperation' ){
                            status = 'Trasegando'
                            // }else if (res.op.source_type === 'Rent') {
                            //     status = 'Rentado'
                        }else{
                            status = 'Estado Inválido'
                        }
                        $('#vehicle-status').text(status);
                        $('#vehicle-origin').text(res.origin);
                        $('#operation-destination').text(res.destination);
                        $('#operation-date').text(res.date);
                        $('#vehicle-engine-number').text(res.vehicle.engine_number);
                        $('#vehicle-vin-number').text(res.vehicle.vin_number);
                        $('#vehicle-mileage').text(res.vehicle.mileage);
                        $('#vehicle-business-name').text(res.business_name);
                        $('#vehicle-data').css('display', 'block');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    toggleSaveButton() {
        if( $('.operation-confirm').prop('checked') ){
            $('.save-button').prop('disabled', false)
        } else {
            $('.save-button').prop('disabled', true)
        }
    }

    checkVehicleMaxMileage(){
        let km_input = $('.input-mileage').val();
        let max_mileage = this.data.get("maxMileage");
        console.log(max_mileage);
        let vehicle_mileage = this.data.get("mileage");
        let msg = "Ha excedido el kilometraje máximo permitido para este vehículo."
        let html = '';

        if((parseInt(km_input) > parseInt(max_mileage)) && max_mileage != null){
            html = `<div class='alert alert-danger' role="alert">${msg}<button type="button" class="close" data-dismiss="alert" aria-label="Close" style="float: right;">
                                                    <span aria-hidden="true">&times;</span>
                                                </button></div>`;
            document.getElementById('this_alert').innerHTML = html;
            $('.input-mileage').val(vehicle_mileage);
        }
    }
}

import {Controller} from "stimulus";
import $ from "jquery";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {}

    /**
     * Encontrar los cantratos legales por Agencia de viaje
     * */
    findLegalContractsByAgency(e) {
        let url = this.data.get("url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {frame_agency_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#legal_contract").empty();
                if ($("#legal_contract").hasClass('include_blank')){
                    $("#legal_contract").prop("include_blank", false);
                }else{
                    $("#legal_contract").append('<option selected="selected" value=""> Seleccione el contrato legal</option>')
                }

                if (res !== undefined) {
                    $.each(res.legal_contracts, function (index, legal_contract) {
                        $("#legal_contract").append(
                            $("<option>")
                                .text(legal_contract.description)
                                .attr("value", legal_contract.id)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status === 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status === 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status === 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status === 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status === 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status === 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
    }
}
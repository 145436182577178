import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const changeValidationState = (element, state='valid') => {
      let color = state === 'valid' ? '#5eba00' : '#cd201f';
      element.style.border = `1px solid ${color}`;
      element.style.borderRadius = '3px';
    };
    const forms = this.element.querySelectorAll('form');
    forms.forEach(form => {
      form.classList.add('needs-validation');
      form.setAttribute('novalidate', '');

      form.addEventListener('submit', e => {
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();

          Array.from(form.elements).forEach(el => {
            if (['INPUT', 'SELECT'].some(type => el.nodeName === type)) {
              if (el.nodeName === 'SELECT') {
                el.addEventListener('change', event => {
                  if (el.validity.valid) {
                    changeValidationState(el.parentElement.querySelector('.select2-container'), 'valid');
                  }
                  else {
                    changeValidationState(el.parentElement.querySelector('.select2-container'), 'invalid');
                  }
                })
              }
              if (!el.validity.valid) {
                let msg = el.validationMessage;
                if (el.parentElement.querySelectorAll('.invalid-feedback').length === 0) {
                  const FEEDBACK_HTML = `<div class="invalid-feedback">
                                                ${msg}
                                              </div>`;
                  el.parentElement.insertAdjacentHTML('beforeend', FEEDBACK_HTML);
                }
                else {
                  el.parentElement.querySelector('.invalid-feedback').innerText = msg;
                }
                if (el.nodeName === 'SELECT') {
                  changeValidationState(el.parentElement.querySelector('.select2-container'), 'invalid');
                }
              }
              else {
                if (el.nodeName === 'SELECT') {
                  changeValidationState(el.parentElement.querySelector('.select2-container'), 'valid');
                }
              }
            }
          });
        }
        form.classList.add('was-validated');
      }, false);
    })
  }
}

import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    connect() {
        $("#report_type").on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
    }
    /**
     * Para los reportes de Comercial, habilitar o no los componentes segun tipo de reporte
     * */
    enableComponentsByReportType(){
        let from_date_min = $("#from_date_min");
        let from_date_max = $("#from_date_max");

        let report_type = $("#report_type").val();
        let business = $("#business_id");
        let owner_entity_owner = $("#owner_entity_owner");
        let owner_entity_rent = $("#owner_entity_rent");
        let nom_subcomercial_id = $("#nom_subcomercial_id");
        let country_id = $("#country_id");
        let nationality_id = $("#nationality_id");
        let label_business = $("#label_business");
        let label_country = $("#label_country");
        let label_nationality = $("#label_nationality");
        let income = $("#income");
        this.defaultComponents();
        switch (report_type){
            case 'market_analysis':
                from_date_min.prop('disabled', false);
                from_date_max.prop('disabled', false);
                nom_subcomercial_id.prop('disabled', false);
                label_country.removeClass( "required" );
                country_id.prop('disabled', false);
                label_nationality.removeClass( "required" );
                nationality_id.prop('disabled', false);
                label_business.removeClass( "required" );
                business.prop('disabled', true);
                break;
            case 'car_change':
                from_date_min.prop('disabled', false);
                from_date_max.prop('disabled', false);
                business.prop('disabled', false);
                business.prop('required', true);
                owner_entity_owner.prop('disabled', false);
                owner_entity_rent.prop('disabled', false);
                break;
            case 'unpleasent_client':
                label_country.removeClass( "required" );
                label_nationality.removeClass( "required" );
                label_business.removeClass( "required" );
                break;
            case 'national_report':
                nom_subcomercial_id.prop('disabled', false);
                label_country.addClass("required");
                country_id.prop('disabled', false);
                country_id.prop('required', true);
                label_nationality.addClass("required");
                nationality_id.prop('disabled', false);
                nationality_id.prop('required', true);
                business.prop('disabled', false);
                business.prop('required', false);
                label_business.removeClass( "required" );
                break;
            case 'plus_client':
                income.prop('disabled', false);
                // income.prop('required', true);
                break;
            default:
                break;
        }
    }

    defaultComponents(){
        let from_date_min = $("#from_date_min");
        let from_date_max = $("#from_date_max");
        let business = $("#business_id");
        let label_business = $("#label_business");
        let owner_entity_owner = $("#owner_entity_owner");
        let owner_entity_rent = $("#owner_entity_rent");
        let nom_subcomercial_id = $("#nom_subcomercial_id");
        let country_id = $("#country_id");
        let nationality_id = $("#nationality_id");
        let income = $("#income");
        from_date_min.prop('disabled', false);
        from_date_max.prop('disabled', false);
        business.prop('disabled', true);
        business.prop('required', false);
        label_business.addClass( "required" );
        owner_entity_owner.prop('disabled', true);
        owner_entity_rent.prop('disabled', true);
        nom_subcomercial_id.prop('disabled', true);
        country_id.prop('disabled', true);
        country_id.prop('required', false);
        nationality_id.prop('disabled', true);
        nationality_id.prop('required', false);
        income.prop('disabled', true);
        // income.prop('required', false);
    }
}
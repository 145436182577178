import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {}

    findTariffFromSDA(e) {
        if (e.target.value !== '' && e.target.value !== null) {
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: this.data.get('url'),
                data: {entity_id: e.target.value},
                success: function (res) {
                    $.unblockUI();
                    $("#sda_tariff_ids").empty();
                    if (res !== undefined) {
                        $.each(res.sda_entity_tariff, function (index, tariff) {
                            $("#sda_tariff_ids").append(
                                $("<option>")
                                    .text(tariff.name)
                                    .attr("value", tariff.id)
                            );
                        });
                    }
                    if (res.status === 'FAIL'){
                        toastr_message('error', res.error_msg);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }
}
import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    static targets = [ "source", "email", "phone", "key", "logged", "expired"]
    // Copiar al clipboard el target source
    copy() {
        navigator.clipboard.writeText(this.sourceTarget.value)
    }

    copy_email(){
        navigator.clipboard.writeText(this.emailTarget.value)
    }

    copy_phone(){
        navigator.clipboard.writeText(this.phoneTarget.value)
    }

    copy_key(){
        navigator.clipboard.writeText(this.keyTarget.value)
    }

    copy_last_logged(){
        navigator.clipboard.writeText(this.loggedTarget.value)
    }
    copy_user_password_expired_at(){
        navigator.clipboard.writeText(this.expiredTarget.value)
    }
}
import {Controller} from "stimulus"
import $ from "jquery";

let send_form = false;
let tab_object = {};

function set_input_value(obj, class_toogle, parent = null) {
    $(obj).on('change', function () {
        if (!$(this).val().isEmpty()) {
            $(this)[0].classList.remove(class_toogle);
            if (parent !== null) {
                parent[0].classList.remove(class_toogle);
            }

            if ($(obj)[0].dataset.hasOwnProperty('tab')) {
                get_required_input_for_tab($(obj)[0].dataset.tab, $(obj)[0].id, 'evaluate');
            }

        } else {
            $(this).classList.add(class_toogle)
            if (parent !== null) {
                parent[0].classList.add(class_toogle)
            }
        }
    });
}

function get_required_input_for_tab(obj, elem = null, action = 'add') {
    let tab = $(`#${obj}`).find('.required');

    if (!tab_object.hasOwnProperty(obj)) {
        tab_object[obj] = [];
    }

    switch (action) {
        case 'add':
            tab.map((index, el) => {
                if (el.tagName === 'SELECT' || el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
                    if (!el.id.isEmpty() && el.value.isEmpty()) {
                        if (!tab_object[obj].includes(el.id)) {
                            tab_object[obj].push(el.id);
                        }

                    }
                }
            })
            break;
        case 'evaluate':
            if (tab_object[obj].length > 0) {
                tab_object[obj].map((value, index) => {
                    if (value === elem) {
                        tab_object[obj].splice(parseInt(index), 1);

                        if (tab_object[obj].length === 0) {
                            $(`a.nav-link[href="#${obj}"]`)[0].classList.remove('tab-error');
                        }
                    }
                })
            }
            break;
    }

}

export default class extends Controller {
    initialize() {
        /*
        * Para usar la validacion del formulario
        *
        * En caso de no querer validar el formulario, agregar el atributo data: {"not-validate": true} o data-not-validate = "" al mismo.
        * Adicionar al campo que se desea validar la clase .required
        * En caso de que el campo a validar sea un >SELECT2 hay que adicionarle un ID a el DIV padre y adicionar un data-target="" con el ID del DIV padre al elemento que se convertira posteriormente en SELECT2
        * En caso de que el input este dentro de una vista con tab y se quiera hacer foco cuando haya error al tab, añadir al input data-tab="" con el ID del TAB padre
        * */
        // let form = $('form');
        // if (form.length > 0 && !form[0].dataset.hasOwnProperty('notValidate')) {
        //     form.on('submit', function (e) {
        //         if (!send_form) {
        //             e.preventDefault();
        //             e.stopPropagation();
        //             let required_field = $('.required');
        //             let error = 0;
        //             let scroll = 0;
        //             let anchor_to_active = '';
        //             let nav_link = $('a.nav-link.tab-error');
        //
        //             if (nav_link.length > 0) {
        //                 nav_link.map((index, elem) => {
        //                     $(elem)[0].classList.remove('tab-error');
        //                 })
        //             }
        //
        //             if (required_field.length > 0) {
        //                 required_field.map((index, elem) => {
        //                     if (elem.tagName === 'SELECT' || elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
        //                         if (elem.value.isEmpty()) {
        //                             error = error + 1;
        //                             if (scroll === 0) {
        //                                 scroll = elem.offsetTop;
        //                             }
        //
        //                             if (elem.dataset.hasOwnProperty('target')) {
        //                                 let target = $(`#${elem.dataset.target}`);
        //                                 let copy_target = '';
        //                                 if ($(elem).hasClass('select2-hidden-accessible')) {
        //                                     copy_target = target.find('span.select2-container--bootstrap');
        //                                     if (copy_target.length === 0) {
        //                                         copy_target = target.find('span.select2-container--default')
        //                                     }
        //
        //                                     if (copy_target.length > 0) {
        //                                         copy_target[0].classList.add('input-error');
        //                                     }
        //                                     set_input_value(elem, 'input-error', copy_target);
        //                                 } else {
        //                                     target[0].classList.add('input-error');
        //                                     set_input_value(elem, 'input-error', target);
        //                                 }
        //
        //                                 if (elem.dataset.hasOwnProperty('tab')) {
        //                                     get_required_input_for_tab(elem.dataset.tab);
        //
        //                                     let anchor = $(`a.nav-link[href="#${elem.dataset.tab}"]`);
        //                                     anchor[0].classList.add('tab-error');
        //
        //                                     if (anchor_to_active.isEmpty()) {
        //                                         anchor_to_active = 'true';
        //                                         anchor.trigger('click');
        //                                     }
        //
        //                                 }
        //
        //
        //                             } else {
        //                                 elem.classList.add('input-error');
        //                                 set_input_value(elem, 'input-error');
        //                             }
        //                             // toastr.error('Verifique los campos obligatorios');
        //                         }
        //
        //                     }
        //                 })
        //
        //                 if (error > 0) {
        //                     window.scrollTo(0, scroll);
        //                 } else {
        //                     send_form = true;
        //                     $('form').submit();
        //                 }
        //             } else {
        //                 send_form = true;
        //                 $('form').submit();
        //             }
        //         }
        //     })
        // }
    }

    connect() {
        $('form').keydown(function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        });
    }
}

import {Controller} from "stimulus";
import $ from "jquery";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        $('#business_id, #basic_business_id').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#business_id, #basic_business_id").on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
    }

    /**
     * Encontrar las agencias a partir de las sucursales
     * */
    findBasicBusinessByBusiness(e) {
        let url = this.data.get("url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {business_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#basic_business_id").empty();
                $("#basic_business_id").append('<option selected="selected" value=""> Seleccione una Agencia </option>')
                if (res !== undefined) {
                    $.each(res.basic_businesses, function (index, basic_business) {
                        $("#basic_business_id").append(
                            $("<option>")
                                .text(basic_business.name)
                                .attr("value", basic_business.id)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status === 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status === 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status === 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status === 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status === 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status === 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
    }

    findFunctionaryByBusiness(e) {
        let url = this.data.get("url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {business_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#functionary_id").empty();
                $("#functionary_id").append('<option selected="selected" value="">Seleccione un Funcionario</option>')
                $.each(res.user, function (index, user) {
                    $("#functionary_id").append(
                        $("<option>")
                            .text(user.name)
                            .attr("value", user.id)
                    );
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status === 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status === 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status === 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status === 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status === 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status === 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
    }
}
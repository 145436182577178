import { Controller } from "stimulus"
import $ from 'jquery';

// Connects to data-controller="reservation"
/** Controla las considiciones generales en los filtros de la seccion `Reservas`
 * - si no se especifica una `referencia` los 3 rangos de fecha seran requeridos, hasta q al meno uno de ellos se especifique y entonces ya dejaran de ser requeridos.
 * Osea si los tres rangos estan vacios y no se especifica una referencia estos seran requeridos.
 * */
export default class extends Controller {
  connect() {
    /* --- Elementos --- */
    let $reference = $('#reference')

    let $from_date_min = $('#from_date_min');
    let $from_date_max = $('#from_date_max');
    let $to_date_min = $('#to_date_min');
    let $to_date_max = $('#to_date_max');
    let $created_at_min = $('#created_at_min');
    let $created_at_max = $('#created_at_max');

    let $all = $('#reference, #from_date_min, #from_date_max, #to_date_min, #to_date_max, #created_at_min, #created_at_max')
    /* --- Validadores --- */
    let no_references = ()=> $reference.val() === ''
    let no_from_date = ()=> $from_date_min.val() === '' && $from_date_max.val() === '';
    let no_to_date = ()=> $to_date_min.val() === '' && $to_date_max.val() === '';
    let no_crated_date = ()=> $created_at_min.val() === '' && $created_at_max.val() === '';

    /* --- Eventos --- */
    $all.on('change input',()=>{
      let some_date_is_required = no_references() && no_from_date() && no_to_date() && no_crated_date()
      $from_date_min.prop('required', some_date_is_required)
      $to_date_min.prop('required', some_date_is_required)
      $created_at_min.prop('required', some_date_is_required)
    })
  }
}

import consumer from "./consumer"

  consumer.subscriptions.create("LocationsChannel", {
    connected() {
      console.log("Conectado al canal LocationsChannel");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      let map = $('#map')[0];
      map.setAttribute('data-latitude', data['latitude']);
      map.setAttribute('data-longitude', data['longitude']);
      map.setAttribute('data-speed', data['speed']);
      map.setAttribute('data-time', data['time']);
      new google.maps.event.trigger( $('#map'), 'resize');
      console.log(map);
      // };
      // }
    }
  });
import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        // $('#basic_business_id').select2({
        //     theme: "bootstrap",
        //     language: "es"
        // });
        //
        // $("#basic_business_id").on('select2:select', function () {
        //     let event = new Event('change', {bubbles: true})
        //     this.dispatchEvent(event);
        // });
        if($('.client_items').is(':checked')) {
            $('#btn_save_client').removeClass('disabled');
        }

        if($('.client_assig_items').is(':checked')) {
            $('#btn_delete_client').removeClass('disabled');
        }
    }

    approvalDateChange(e) {
        let contract_date = $('#rent_card_client_contract_date');
        let approval_date= $('#rent_card_client_approval_date');
        if (contract_date.val() !== "") {
            if (approval_date.val() > contract_date.val()) {
                alert("La fecha de aprobación no debe ser posterior a la fecha del contrato");
                approval_date.val(contract_date.val());
            }
        }
    }

    enableBtnSaveClient(e){
        if($('.client_items').is(':checked')){
            $('#btn_save_client').removeClass('disabled');
        }else {
            $('#btn_save_client').addClass('disabled');
        }
    }
    enableBtnDeleteClient(e){
        if($('.client_assig_items').is(':checked')){
            $('#btn_delete_client').removeClass('disabled');
        }else {
            $('#btn_delete_client').addClass('disabled');
        }
    }

    enableBtnSaveDriver(e){
        if($('.driver_items').is(':checked')){
            $('#btn_save_driver').removeClass('disabled');
        }else {
            $('#btn_save_driver').addClass('disabled');
        }
    }

    enableBtnDeleteDriver(e){
        if($('.driver_assig_items').is(':checked')){
            $('#btn_delete_drivers').removeClass('disabled');
        }else {
            $('#btn_delete_drivers').addClass('disabled');
        }
    }

    enableBtnSaveTariff(e){
        if($('.tariff_items').is(':checked')){
            $('#btn_save_tariff').removeClass('disabled');
        }else {
            $('#btn_save_tariff').addClass('disabled');
        }
    }

    enableBtnDeleteTariff(e){
        if($('.tariff_assig_items').is(':checked')){
            $('#btn_delete_tariff').removeClass('disabled');
        }else {
            $('#btn_delete_tariff').addClass('disabled');
        }
    }

    enableBtnSaveCommerCateg(e){
        if($('.commercial_catg_items').is(':checked')){
            $('#btn_save_commercial_categ').removeClass('disabled');
        }else {
            $('#btn_save_commercial_categ').addClass('disabled');
        }
    }

    enableBtnDeleteCommerCateg(e){
        if($('.commercial_categ_items').is(':checked')){
            $('#btn_delete_commercial_categ').removeClass('disabled');
        }else {
            $('#btn_delete_commercial_categ').addClass('disabled');
        }
    }

    enableBtnSavePaymentMod(e){
        if($('.payment_mod_items').is(':checked')){
            $('#btn_save_payment_mod').removeClass('disabled');
        }else {
            $('#btn_save_payment_mod').addClass('disabled');
        }
    }

    enableBtnDeletePaymentMod(e){
        if($('.payment_mod_items').is(':checked')){
            $('#btn_delete_payment_mod').removeClass('disabled');
        }else {
            $('#btn_delete_payment_mod').addClass('disabled');
        }
    }


}
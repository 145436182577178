import {Controller} from "stimulus";
import $ from "jquery";
import {checkAdvancePaymentAtMoment, checkAmountPaymentForm, deleteAllPayment} from "./payment_controller";
import {toastr_message} from "./globalFunctions_controller";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    connect() {
        $('#cash').attr('disabled','disabled');
        let is_Stolen = this.data.get("vehicleStolen");
        let mileageToLiquidate = this.data.get("vehicleKms");
        let mileage = $('#mileage');
        let tariff = $("#amounts_fuel_day");
        let fuel_amount = $("#fuel_amount");
        /*si el vehículo esta robado predefinir el valor del campo de combustible en 1 para que recalcule según ese parámetro*/
        /*En el caso del kilometraje del vehiculo se le suman 2 km al kilometraje con el cual venia antes de liquidar*/
        if(is_Stolen === 'true'){
            $('#fuel_amount').val(1);
            $('#import_fuel').val((tariff.val() * fuel_amount.val()).toFixed(2) );
            mileage.val(parseFloat(mileageToLiquidate) + 2);
            $('#traveled_mileage').val((mileage.val() - mileageToLiquidate).toFixed(2));//predefiniendo valor del input que calcula los kms recorridos
        }

    }

    myToFixed( num, precision ) {
        return parseFloat((+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision));
    }

    // Total a devolver.
    credit(){
        let rent = 0;    //Importe por Renta
        let release = 0; //Importe por Relevo de responsabilidad
        let driver = 0;  //Importe por Chófer adicional
        let fuel = 0;    //Importe por Combustible
        let relief_supplement = 0; //Importe por Suplemento del relevo de responsabilidad
        let drop_off = 0    //Importe por drop off

        if($('#returned_rent').val() != undefined && $('#returned_rent').val() != ''){
            rent = parseFloat($('#returned_rent').val());
        }
        if($('#returned_release').val() !== undefined && $('#returned_release').val() !== ''){
            release = parseFloat($('#returned_release').val());
        }
        if($('#returned_additional_driver').val() !== undefined && $('#returned_additional_driver').val() !== ''){
            driver = parseFloat($('#returned_additional_driver').val());
        }
        if($('#returned_fuel').val() !== undefined && $('#returned_fuel').val() !== ''){
            fuel = parseFloat($('#returned_fuel').val());
        }
        if($('#returned_relief_supplement').val() !== undefined && $('#returned_relief_supplement').val() !== ''){
            relief_supplement = parseFloat($('#returned_relief_supplement').val());
        }
        if($('#returned_drop_off').val() !== undefined && $('#returned_drop_off').val() !== ''){
            drop_off = parseFloat($('#returned_drop_off').val());
        }
        return this.myToFixed(rent + release + driver + fuel + relief_supplement + drop_off, 2)
    }

    // Total a cobrar
    toPay(){
        let rent = 0; //Importe por renta
        let release = 0; //Importe por release
        let driver = 0; //Importe por conductores
        let drop_off = 0; //Importe por drop off
        let penalties = 0; //Importe por penalidades
        let others = 0 //Importe por otros
        let extra_day = 0 // Importe por día extra
        let transit_notifications = 0; //Importe por notificaciones de trasito
        let fuel = 0; //Importe por combustible
        let relief_supplement = 0; //Importe por suplemento de relevo de responsabilidad

        if($('#collected_rent_import').val() !== undefined && $('#collected_rent_import').val() !== ''){
            rent = parseFloat($('#collected_rent_import').val());
        }
        if($('#collected_release_import').val() !== undefined && $('#collected_release_import').val() !== ''){
            release = parseFloat($('#collected_release_import').val());
        }
        if($('#collected_additional_driver').val() !== undefined && $('#collected_additional_driver').val() !== ''){
            driver = parseFloat($('#collected_additional_driver').val());
        }
        if($('#collected_drop_off').val() !== undefined && $('#collected_drop_off').val() !== ''){
            drop_off = parseFloat($('#collected_drop_off').val());
        }
        if($('#collected_penalties').val() !== undefined && $('#collected_penalties').val() !== ''){
            penalties = parseFloat($('#collected_penalties').val());
        }
        if($('#collected_others').val() !== undefined && $('#collected_others').val() !== ''){
            others = parseFloat($('#collected_others').val());
        }
        if($('#collected_extra_day').val() !== undefined && $('#collected_extra_day').val() !== ''){
            extra_day = parseFloat($('#collected_extra_day').val());
        }
        if($('#collected_transit_notifications').val() !== undefined && $('#collected_transit_notifications').val() !== ''){
            transit_notifications = parseFloat($('#collected_transit_notifications').val());
        }
        if($('#collected_fuel_import').val() !== undefined && $('#collected_fuel_import').val() !== ''){
            fuel = parseFloat($('#collected_fuel_import').val());
        }
        if($('#collected_relief_supplement_import').val() !== undefined && $('#collected_relief_supplement_import').val() !== ''){
            relief_supplement = parseFloat($('#collected_relief_supplement_import').val());
        }

        return this.myToFixed(rent + release + driver + drop_off + penalties + others + extra_day + transit_notifications + fuel + relief_supplement, 2)
    }

    // Total de metodos de pago
    paymentMethod(){
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        return this.myToFixed(cash + card + moment_card + voucher + cheque + other, 2)
    }

    // Actualizar el monto total a desglosar por las formas de pago
    updatePaymentMethodTotal(){
        // Total a desglosar (A cobrar - devolver)
        // let toPay = parseFloat($('.totals_to_pay').text());
        // let credit = parseFloat($('.return_totals').text());
        // let total_to_pay = (toPay - credit) > 0 ? (toPay - credit) : 0
        let total_to_pay = parseFloat($('#total_to_pay').text());
        let payment_total_voucher = $('#payment_total_voucher').val();
        // Verificar configuracion del tipo de renta, si tiene configurado que el cobro total del contrato para voucher
        if (payment_total_voucher === 'true') {
            $('#voucher').val(total_to_pay);
            $('#payment_method_total').val(0.00);
            $('#total').val(0.00);
        } else {
            $('#payment_method_total').val(this.myToFixed(total_to_pay, 2));
            $('#total').val(this.myToFixed(total_to_pay, 2)); // Total a desglosar hidden
            checkAdvancePaymentAtMoment();
            this.updateTotal();
        }

    }

    // Actualizar el total a cobrar según los conceptos
    updateToPay(e){
        let toPay = this.toPay(); // Total A cobrar en la liquidacion
        let total_credit = parseFloat($('.return_totals').text()); // Total a devolver
        let hidden_total_contract = parseFloat($('#hidden_total_contract').val()); // Real de Contrato Anticipado

        $('#rent_sell_off_debit').text('');
        $('#rent_sell_off_debit').text(toPay); // Total en la sección A Cobrar.
        $('.totals_to_pay').text(toPay); // Fila Total seccion COBRAR en la tabla del desglose.

        // Total del contrato (Cobrado + A Cobrar)
        $('#total_contract').text(this.myToFixed(hidden_total_contract + toPay, 2));
        let total_contract = parseFloat($('#total_contract').text());   // Total del contrato

        $('#total_sale').text(this.myToFixed(total_contract - total_credit, 2) );  // Total de Venta (total general del contrato menos lo devuelto)
        $('.totals_sale').text(this.myToFixed(total_contract - total_credit, 2) );   //Fila Total de la tabla resumen columna VENTA

        this.resumeToPayCredit();
        this.updatePaymentMethodTotal();
    }

    resumeToPayCredit(){
        let total_sale= parseFloat($('#total_sale').text());
        let total_advance = parseFloat($('#total_advance').text());
        let payment = total_sale - total_advance

        // // Total A COBRAR en la tabla del desglose Y // Total A DEVOLVER en la tabla del desglose
        let real_to_pay = payment > 0 ? payment : 0.00
        let real_credit = payment < 0 ? payment * -1 : 0.00
        $('#total_to_pay').text(this.myToFixed(real_to_pay, 2)); // Lo real a cobrar
        $('#to_collect').val(this.myToFixed(real_to_pay, 2));
        $('#total_credit').text(this.myToFixed(real_credit, 2)); // Lo real a devolver
        $('#to_return').val(this.myToFixed(real_credit, 2));
    }

    // Actualizar el total a devolver según los conceptos que se puede devolver por menos días.
    updateCredit(e){
        // Total de devolver en la sección de Reembolsos
        $('#rent_sell_off').text('');
        $('#rent_sell_off').text(this.credit());
        $('.return_totals').text(this.credit()); // Fila Total seccion DEVOLVER en la tabla resumen.
    }

    // Actualizar lo que tenga que ver con la renta por concepto de devolucion y venta
    rentCredit(e){
        let returned_rent = 0;
        let payment_rent = 0;
        let rent_to_pay = 0;
        // A Devolver
        if($('#returned_rent').val() !== undefined && $('#returned_rent').val() !== ''){
            returned_rent = parseFloat($('#returned_rent').val());
        }
        // Cobrado
        if($('.payment_rent').text() !== undefined && $('.payment_rent').text() !== ''){
            payment_rent = parseFloat($('.payment_rent').text());
        }
        // A Cobrar
        if($('#collected_rent_import').val() !== undefined && $('#collected_rent_import').val() !== ''){
            rent_to_pay = parseFloat($('#collected_rent_import').val());
        }
        let total = payment_rent + rent_to_pay - returned_rent
        $('.return_rent').text(this.myToFixed(returned_rent,2));
        $('.sale_rent').text(this.myToFixed(total,2));
        this.total_to_credit();
    }

    // Actualizar lo que tenga que ver con el suplemento de relevo de responsabilidad por concepto de devolucion y venta
    reliefSupplementCredit(e){
        let returned_relief = 0;
        let payment_relief = 0;
        let relief_to_pay = 0;
        // A Devolver
        if($('#returned_relief_supplement').val() !== undefined && $('#returned_relief_supplement').val() !== ''){
            returned_relief = parseFloat($('#returned_relief_supplement').val());
        }
        // Cobrado
        if($('.payment_relief_supplement').text() !== undefined && $('.payment_relief_supplement').text() !== ''){
            payment_relief = parseFloat($('.payment_relief_supplement').text());
        }
        // A Cobrar
        if($('#collected_relief_supplement_import').val() !== undefined && $('#collected_relief_supplement_import').val() !== ''){
            relief_to_pay = parseFloat($('#collected_relief_supplement_import').val());
        }
        let total = payment_relief + relief_to_pay - returned_relief
        $('.return_relief_supplement').text(this.myToFixed(returned_relief,2));
        $('.sale_relief_supplement').text(this.myToFixed(total,2));
        this.total_to_credit();
    }

    // Actualizar lo que tenga que ver con el drop offpor concepto de devolucion y venta
    dropOffCredit(e){
        let returned_drop_off = 0;
        let payment_drop_off = 0;
        let drop_off_to_pay = 0;
        // A Devolver
        if($('#returned_drop_off').val() !== undefined && $('#returned_drop_off').val() !== ''){
            returned_drop_off = parseFloat($('#returned_drop_off').val());
        }
        // Cobrado
        if($('.payment_drop_off').text() !== undefined && $('.payment_drop_off').text() !== ''){
            payment_drop_off = parseFloat($('.payment_drop_off').text());
        }
        // A Cobrar
        if($('#collected_drop_off').val() !== undefined && $('#collected_drop_off').val() !== ''){
            drop_off_to_pay = parseFloat($('#collected_drop_off').val());
        }
        let total = payment_drop_off + drop_off_to_pay - returned_drop_off
        $('.return_drop_off').text(this.myToFixed(returned_drop_off,2));
        $('.sale_drop_off').text(this.myToFixed(total,2));
        this.total_to_credit();
    }

    // Actualizar lo que tenga que ver con relevo de responsabilidad por concepto de devolucion y venta
    liabilityCredit(e){
        let returned_release = 0;
        let payment_liability = 0;
        let liability_to_pay = 0;
        // A Devolver
        if($('#returned_release').val() !== undefined && $('#returned_release').val() !== ''){
            returned_release = parseFloat($('#returned_release').val());
        }
        // Cobrado
        if($('.payment_liability').text() !== undefined && $('.payment_liability').text() !== ''){
            payment_liability = parseFloat($('.payment_liability').text());
        }
        // A Cobrar
        if($('#collected_release_import').val() !== undefined && $('#collected_release_import').val() !== ''){
            liability_to_pay = parseFloat($('#collected_release_import').val());
        }
        let total = payment_liability + liability_to_pay - returned_release
        $('.return_liability').text(this.myToFixed(returned_release,2));
        $('.sale_liability').text(this.myToFixed(total,2));
        this.total_to_credit();
    }

    // Actualizar lo que tenga que ver con Chófer adicional
    driversCredit(e){
        let return_drivers = 0;
        let payment_drivers = 0;
        let drivers_to_pay = 0;
        // A devolver
        if($('#returned_additional_driver').val() !== undefined && $('#returned_additional_driver').val() !== ''){
            return_drivers = parseFloat($('#returned_additional_driver').val());
        }
        // Cobrado
        if($('.payment_drivers').text() !== undefined && $('.payment_drivers').text() !== ''){
            payment_drivers = parseFloat($('.payment_drivers').text());
        }
        // A Cobrar
        if($('#collected_additional_driver').val() !== undefined && $('#collected_additional_driver').val() !== ''){
            drivers_to_pay = parseFloat($('#collected_additional_driver').val());
        }
        let total = payment_drivers + drivers_to_pay - return_drivers
        $('.return_drivers').text(this.myToFixed(return_drivers, 2));
        $('.sale_drivers').text(this.myToFixed(total,2));
        this.total_to_credit();
    }
    // Actualizar lo que tenga que ver con combustible por concepto de devolucion y venta
    fuelCredit(e){
        let returned_fuel = 0;
        let payment_fuel = 0;
        let fuel_to_pay = 0;
        // A Devolver
        if($('#returned_fuel').val() !== undefined && $('#returned_fuel').val() !== ''){
            returned_fuel = parseFloat($('#returned_fuel').val());
        }
        // Cobrado
        if($('.payment_fuel').text() !== undefined && $('.payment_fuel').text() !== ''){
            payment_fuel = parseFloat($('.payment_fuel').text());
        }
        // A Cobrar
        if($('.fuel_to_pay').text() !== undefined && $('.fuel_to_pay').text() !== ''){
            fuel_to_pay = parseFloat($('.fuel_to_pay').text());
        }
        let total = payment_fuel + fuel_to_pay - returned_fuel
        $('.return_fuel').text(this.myToFixed(returned_fuel,2));
        $('.sale_fuel').text(this.myToFixed(total,2));
        this.total_to_credit();
    }

    // Cobro de renta, deuda del cambio de carro
    rent_payments(e){
        let rent = 0;
        let payment_rent = parseFloat($('.payment_rent').text());
        let credit_rent = parseFloat($('.return_rent').text());
        if($('#collected_rent_import').val() !== undefined && $('#collected_rent_import').val() !== ''){
            rent = parseFloat($('#collected_rent_import').val());
        }
        // Está mal, a lo que tenía se le suma el valor nuevo.
        $('.rent_to_pay').text(this.myToFixed(rent,2));
        $('.sale_rent').text(this.myToFixed(rent + payment_rent - credit_rent,2));
        //this.payments_total();
    }

    // Cobro de relevo de responsabilidad, deuda del cambio de carro
    liability_payments(e){
        let release = 0
        let payment_liability = parseFloat($('.payment_liability').text());
        let credit_liability = parseFloat($('.return_liability').text());
        if($('#collected_release_import').val() !== undefined && $('#collected_release_import').val() !== ''){
            release = parseFloat($('#collected_release_import').val());
        }
        // Está mal, a lo que tenía se le suma el valor nuevo.
        $('.liability_to_pay').text(this.myToFixed(release,2));
        $('.sale_liability').text(this.myToFixed(release + payment_liability - credit_liability,2));
        //this.payments_total();
    }

    // Cobro del suplemento de relevo de responsabilidad
    relief_supplement_payments(e){
        let relief = 0
        let payment_relief = parseFloat($('.payment_relief_supplement').text());
        let credit_relief = parseFloat($('.return_relief_supplement').text());
        if($('#collected_relief_supplement_import').val() !== undefined && $('#collected_relief_supplement_import').val() !== ''){
            relief = parseFloat($('#collected_relief_supplement_import').val());
        }
        $('.relief_supplement_to_pay').text(this.myToFixed(relief,2));
        $('.sale_relief_supplement').text(this.myToFixed(relief + payment_relief - credit_relief,2));
        //this.payments_total();
    }

    drivers_payments(e){
        let drivers = 0
        let payment_drivers = parseFloat($('.payment_drivers').text());
        let credit_drivers = parseFloat($('.return_drivers').text());
        if($('#collected_additional_driver').val() !== undefined && $('#collected_additional_driver').val() !== ''){
            drivers = parseFloat($('#collected_additional_driver').val());
        }
        // Está mal, a lo que tenía se le suma el valor nuevo.
        $('.drivers_to_pay').text(this.myToFixed(drivers,2));
        $('.sale_drivers').text(this.myToFixed(drivers + payment_drivers - credit_drivers,2));
        //this.payments_total();
    }

    // Actualizar lo que tenga que ver con el penalidades
    extra_day_payments(e){
        let extra_day = 0;
        if($('#collected_extra_day').val() !== undefined && $('#collected_extra_day').val() !== ''){
            extra_day = parseFloat($('#collected_extra_day').val());
        }
        $('.sale_extra_day').text(this.myToFixed(extra_day,2));
        $('.extra_day_to_pay').text(this.myToFixed(extra_day,2));
        //this.payments_total();
    }

    // Actualizar lo que tenga que ver con el drop off
    drop_off_payments(e){
        let drop_off = 0
        let payment_drop_off = parseFloat($('.payment_drop_off').text());
        let credit_drop_off = parseFloat($('.return_drop_off').text());
        if($('#collected_drop_off').val() !== undefined && $('#collected_drop_off').val() !== ''){
            drop_off = parseFloat($('#collected_drop_off').val());
        }
        $('.drop_off_to_pay').text(this.myToFixed(drop_off,2));
        $('.sale_drop_off').text(this.myToFixed(drop_off + payment_drop_off - credit_drop_off,2));
        //this.payments_total();
    }

    // Actualizar lo que tenga que ver con las penalidades
    penalities_payments(e){
        let penalties = 0;
        if($('#collected_penalties').val() !== undefined && $('#collected_penalties').val() !== ''){
            penalties = parseFloat($('#collected_penalties').val());
        }
        $('.sale_penalties').text(this.myToFixed(penalties,2));
        $('.penalties_to_pay').text(this.myToFixed(penalties,2));
        //this.payments_total();
    }

    // Actualizar lo que tenga que ver con notificaciones de transito
    transit_notifications_payments(e){
        let transit_notifications = 0;
        if($('#collected_transit_notifications').val() !== undefined && $('#collected_transit_notifications').val() !== ''){
            transit_notifications = parseFloat($('#collected_transit_notifications').val());
        }
        $('.sale_transit_notifications').text(this.myToFixed(transit_notifications,2));
        $('.transit_notifications_to_pay').text(this.myToFixed(transit_notifications,2));
        //this.payments_total();
    }

    // Actualizar lo que tenga que ver con otros cobros
    other_payments(e){
        let others = 0;
        let payment_others = parseFloat($('.payment_others').text());
        let credit_other = parseFloat($('.return_others').text());
        if($('#collected_others').val() !== undefined && $('#collected_others').val() !== ''){
            others = parseFloat($('#collected_others').val());
        }
        $('.sale_others').text(this.myToFixed(others + payment_others - credit_other,2));
        $('.others_to_pay').text(this.myToFixed(others,2));
    }

    // Actualizar lo que tenga que ver con el combustible
    fuel_payments(e){
        let fuel = 0;
        let payment_fuel = parseFloat($('.payment_fuel').text());
        let credit_fuel = parseFloat($('.return_fuel').text());
        if($('#collected_fuel_import').val() !== undefined && $('#collected_fuel_import').val() !== ''){
            fuel = parseFloat($('#collected_fuel_import').val());
        }
        console.log(fuel);
        console.log(payment_fuel)
        console.log(credit_fuel)
        $('.sale_fuel').text(this.myToFixed(fuel + payment_fuel - credit_fuel,2));
        $('.fuel_to_pay').text(this.myToFixed(fuel,2));
    }

    // Actualiza el importe del combustible que liquida.
    updateFuelImport(e){
        let tariff = $("#amounts_fuel_day").val();
        let fuel_amount = $("#fuel_amount").val();
        $('#import_fuel').val((tariff * fuel_amount).toFixed(2) );
    }

    updateCollectedFuelImport(e){
        let tariff = parseFloat(this.data.get('fuel_tariff'));
        let fuel = parseFloat($('#fuel_to_collect').val());
        $('#collected_fuel_import').val(this.myToFixed(tariff * fuel, 2));
    }

    updateReturnFuelImport(e){
        let tariff = parseFloat(this.data.get('fuel_tariff'));
        let fuel = parseFloat($('#fuel_to_returned').val());
        $('#returned_fuel').val(this.myToFixed(tariff * fuel, 2));
    }

    // Calcula el total de combustible
    updateFuel(e){
        let end_fuel_amount = $("#fuel_amount").val();
        let end_calculate_fuel = 0
        let text = ''
        if($("#end_calculate_fuel_debit").text() != ''){
            end_calculate_fuel =  parseInt($("#fuel_calculate").val());
        }else{
            end_calculate_fuel =  parseInt($("#fuel_calculate_credit").val());
        }
        let total = end_calculate_fuel - end_fuel_amount;
        if(total < 0 ){
            text = 'Total de combustible a devolver:';
        }else{
            text = 'Total de combustible cobrado:';
        }
        $('#end_fuel').text(end_fuel_amount);
        if($("#end_calculate_fuel_debit").text()!= ''){
            $('#end_calculate_fuel_debit').text(total);
        }else{
            $('#end_calculate_fuel_credit').text(total * -1 );
        }
        $('#text_calculate_fuel').text(text);
    }

    // Se actualiza la cantidad de KM
    traveledMileage(e){
        let out_mileage = parseFloat($("#out_mileage").text());
        let mileage = $("#mileage").val();
        $('#traveled_mileage').val((mileage - out_mileage).toFixed(0));
    }

    // Total a pagar por las formas de pago.
    updateTotal(e){
        let total = this.paymentMethod();
        let to_distribute = parseFloat($('#total').val());
        $('#payment_method_total').val((to_distribute - total).toFixed(2));
        if((to_distribute - total) < 0){
            alert('Para activar el botón de Liquidación debe verificar que el monto del TOTAL A DESGLOSAR ($'+  to_distribute.toFixed(2) +'), coincida el desgloce en las diferentes formas de pago.')
            $('#btn-liquidate').addClass('disabled');
        }
        if((to_distribute - total) === 0){
            $('#btn-liquidate').removeClass('disabled');
        }else {
            $('#btn-liquidate').addClass('disabled');
        }
    }

    // Método para mantener actualizado el total a devolver.
    total_to_credit(){
        let credit = this.credit();
        let toPay = this.toPay();
        // let real_credit = (credit - toPay) > 0 ? ( credit - toPay) : 0
        // let real_to_pay = (toPay - credit) > 0 ? (toPay - credit) : 0
        // $('#total_credit').text(this.myToFixed(real_credit, 2)); // Lo real a devolver
        // $('#total_to_pay').text(this.myToFixed(real_to_pay, 2)); // Lo real a cobrar
        $('.return_totals').text(credit); // Total columna Devolucion
        //$('#hidden_total_credit').text(credit); // No se usa

        this.total_sale();
        this.resumeToPayCredit();
        this.updatePaymentMethodTotal();
    }

    // Actualizar el total de venta
    total_sale(){
        let total_contract = parseFloat($('#total_contract').text());
        let total_credit = parseFloat($('.return_totals').text());
        let total = this.myToFixed(total_contract - total_credit, 2);
        $('#total_sale').text(total);
        $('.totals_sale').text(total);//Fila Total de la tabla resumen columna VENTA
        // $('#hidden_total_sale').val(total);
    }

    checkDebitBalance(){
        // Activar el check de cuenta por cobrar si existe total a cobrar
        let total = parseFloat($('#total').val()); //total a cobrar
        let payment_method_total = parseFloat($('#payment_method_total').val()); //total a desglosar
        if ($("#debit_balance").is(':checked')) {
            if (total === 0){
                toastr_message('warning', 'No es posible crear una cuenta por cobrar. Debe existir un importe a cobrar al momento para generar una cuenta por cobrar.', "Cuenta por cobrar");
                $('#debit_balance').prop('checked', false);
                checkAdvancePaymentAtMoment();
                return;
            } else if (total !== payment_method_total) {
                toastr_message('warning', 'No es posible crear una cuenta por cobrar. El importe a cobrar al momento no debe estar desglosado en las formas de pago', "Cuenta por cobrar");
                $('#debit_balance').prop('checked', false);
                checkAdvancePaymentAtMoment();
                return;
            }
            // Identificar cobros, si existe solicitar eliminarlos
            let tab_content_payments = $("#tab_content_payments").children();
            if (tab_content_payments.length > 0) {
                let delete_payment = confirm("¿Está seguro que desea crear una cuenta por cobrar? Esta acción elimina los cobros al momento.");
                if (delete_payment) {
                    deleteAllPayment();
                }else{
                    $('#debit_balance').prop('checked', false);
                    return;
                }
            }else{
                // Borrar alerta por default
                $('.advance_msg_payment').remove();
                let tmp_total_payment = $("#tmp_total_payment").children();
                // Eliminando el total de los cobros
                $.each(tmp_total_payment, function (key, tmp) {
                    tmp.remove();
                });
            }
            // reset componentes de seleccion de cobros
            $('#payment_form_code').val(null).trigger('change');
            $('#payment_modality_code').val(null).trigger('change');
            $('#btn-liquidate').removeClass('disabled');
        } else {
            checkAdvancePaymentAtMoment();
            this.enableFormBottom();
        }
    }

    enableFormBottom(){
        let total = this.paymentMethod();
        let to_distribute = parseFloat($('#total').val());
        $('#payment_method_total').val((to_distribute - total).toFixed(2));
        if ((to_distribute - total) === 0){
            $('#btn-liquidate').removeClass('disabled');
        }else {
            $('#btn-liquidate').addClass('disabled');
        }
    }
    checkAmountPaymentForm(e){
        let code = e.target.dataset.code; // code payment form entrada.
        if (!$("#debit_balance").is(':checked'))
            checkAmountPaymentForm(code);
    }

    activeFailureTypes(e){
        let nom_failure_types = $('#nom_failure_types');
        if($('#not_operative').is(":checked") === true) {
            nom_failure_types.prop('disabled', false);
            nom_failure_types.prop('required', true);
            $('#failure_type_label').addClass('required');
        } else {
            nom_failure_types.prop('disabled', true);
            nom_failure_types.prop('required', false);
            $('#failure_type_label').removeClass('required');
        }
    }

    // Método para mantener actualizado el total de venta
    // total_sale(){
    //     let sale_rent = parseFloat($('.sale_rent').text());
    //     let sale_liability = parseFloat($('.sale_liability').text());
    //     let sale_drivers = parseFloat($('.sale_drivers').text());
    //     let sale_office = parseFloat($('.sale_office').text());
    //     let sale_fuel = parseFloat($('.sale_fuel').text());
    //     let sale_extra_day = parseFloat($('.sale_extra_day').text());
    //     let drop_off = parseFloat($('.sale_drop_off').text());
    //     let penalties = parseFloat($('.sale_penalties').text());
    //     let others = parseFloat($('.sale_others').text());
    //     let total= this.myToFixed(sale_rent + sale_liability + sale_drivers + sale_office + sale_fuel + sale_extra_day + drop_off + penalties + others, 2);
    //     $('.return_totals').text(total);
    //     this.total_sale();
    // }

    // payments_total(){
    //     let payment_rent = parseFloat($('.payment_rent').text());
    //     let payment_liability = parseFloat($('.payment_liability').text());
    //     let payment_drivers = parseFloat($('.payment_drivers').text());
    //     let payment_fuel = parseFloat($('.payment_fuel').text());
    //     let payment_extra_day = parseFloat($('.payment_extra_day').text());
    //     let payment_drop = parseFloat($('.payment_drop').text());
    //     let payment_penalties = parseFloat($('.payment_penalties').text());
    //     let payment_others = parseFloat($('.payment_others').text());
    //     let payment_office = parseFloat($('.payment_office').text());
    //     let total= this.myToFixed(payment_rent + payment_liability + payment_office + payment_drivers + payment_fuel + payment_extra_day + payment_drop + payment_penalties + payment_others, 2);
    //     $('#total_contract').text(total);
    //     //$('#hidden_total_contract').val(total);
    //     this.total_sale();
    // }

    // Se calcula lo que se va a cobrar.
    // updatePayments(e){
    //     let rent = $('#collected_rent_import').val(); //Importe por Renta
    //     let release = $('#collected_release_import').val(); //Importe por Relevo de responsabilidad
    //     let driver = $('#collected_additional_driver').val(); //Importe por Chófer adicional
    //     let office = $('#collected_office_import').val(); //Importe por oficina
    //     let fuel = $('#collected_fuel_import').val(); //Importe por combustible
    //     let extra_day = $('#collected_extra_day').val(); //Importe por día extra
    //     let drop_off = $('#collected_drop_off').val(); //Importe por drop off
    //     let penalties = $('#collected_penalties').val(); //Importe por penalidades
    //     let others = $('#collected_others').val(); //Importe por otros
    //     $('#rent_total').text('');
    //     $('#rent_sell_off').text('');
    //     let total = parseFloat(rent) + parseFloat(release) + parseFloat(driver) + parseFloat(office) + parseFloat(fuel)+ parseFloat(extra_day) + parseFloat(drop_off) + parseFloat(penalties) + parseFloat(others)
    //     $('#rent_total').text(total.toFixed(2) + ' ' + 'USD' );
    //     let rent_advance = parseFloat($('#rent_advance').text()).toFixed(2)
    //     $('#rent_sell_off').text( (total - rent_advance).toFixed(2) + ' ' + 'USD');
    //     $('#collected_advance').val(rent_advance)
    //     let collected_to_liquidate = (total - rent_advance).toFixed(2)
    //     $('#collected_to_liquidate').val(collected_to_liquidate)
    //     $('#payment_method_total').val((total - rent_advance).toFixed(2))
    //     // Se comenta puede ser 0
    //     // todo analizar con calma
    //     // if((total - rent_advance) > 0){
    //     //     $('#btn-liquidate').addClass('disabled');
    //     // }else{
    //     //     $('#btn-liquidate').addClass('disabled');
    //     // }
    //     $('#total').val(total.toFixed(2) - rent_advance)
    // }

    // Actualizar lo que tenga que ver con combustible
    // fuelCredit(e) {
    //     let fuel = 0;
    //     let payment_fuel = parseFloat($('.payment_fuel').text());
    //     if ($('#return_fuel').is(':checked')) {
    //         if ($('#end_calculate_fuel_credit').text() !== undefined && $('#end_calculate_fuel_credit').text() !== '') {
    //             fuel = parseFloat($('#end_calculate_fuel_credit').text());
    //         }
    //         // Actualizar seccion combustible, columna devolucion en la tabla resumen
    //         let fuel_tariff = parseFloat($('#amounts_fuel_day').val());
    //         $('.return_fuel').text(this.myToFixed(fuel * fuel_tariff, 2));
    //         let current_fuel = parseFloat($('.return_fuel').text());
    //         let total = this.myToFixed((payment_fuel - current_fuel), 2)
    //         if (total < 0) {
    //             $('.sale_fuel').text(0.00);
    //         } else {
    //             $('.sale_fuel').text(total)
    //         }
    //         let total_credit = this.credit()
    //         $('.return_totals').text(this.myToFixed(parseFloat(total_credit) + parseFloat($('.return_fuel').text()), 2))
    //     } else {
    //         $('.return_fuel').text(0.00);
    //         $('.sale_fuel').text($('.payment_fuel').text());
    //         $('.return_totals').text(this.myToFixed(parseFloat(this.credit()), 2));
    //     }
    //     this.total_to_credit();
    // }
}

import { Controller } from "stimulus"
import $ from 'jquery';

require("timepicker/jquery.timepicker.css")
require("timepicker/jquery.timepicker.js")
import reservationContract from "select2"
// require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $('.c2ec_time').timepicker({
            'step': 5,
            'timeFormat': 'H:i:s',
            dynamic: false,
            dropdown: true,
            // useSelect: true,
            // className: 'form-select c2ec_select'
        });
        $('.c2ec_rtime').timepicker({
            'step': 5,
            'timeFormat': 'H:i:s',
            useSelect: false,
            className: 'form-select c2ec_select disabled'
        });

        $('.c2ec_select').select2({
            theme: "bootstrap",
            language: "es",
            focus: true
        });



    }
}
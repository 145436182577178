import {Controller} from "stimulus";
import $ from "jquery";
import {toastr_message} from "./globalFunctions_controller";

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return day + '/' + month + '/' + year;
}

export default class extends Controller {
    connect() {
        $('#cash').attr('disabled','disabled');
        $('#card').attr('disabled','disabled');
        $('#moment_card').attr('disabled','disabled');
        $('#cheque').attr('disabled','disabled');
        $('#other').attr('disabled','disabled');
        $('#voucher').attr('disabled','disabled');
        let minDate = this.data.get('min');
        $('.extent-date').datepicker({
            dateFormat: "dd/mm/yy",
            defaultDate: 1,
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showButtonPanel: true,
            yearRange: '1950:2121',
            showOn: "off",
            minDate: new Date(minDate),
            beforeShow: function (input, inst) {
                setTimeout(function () {
                    inst.dpDiv.css({
                        top: $(".extent-date").offset().top + 35,
                        left: $(".extent-date").offset().left
                    });
                }, 0);
            },
            onSelect: function (dateText, inst) {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            }
        });
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
        this.ajaxPaymentFormsByRentType(parseInt(20));

        $("#extent_date").click(function(event){
            event.stopPropagation();
        });
    }

    myToFixed(num, precision ) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }
    // updateExtentDays(e){
    //     let to_date =  new Date(this.data.get('min'));
    //     let extend_date = $('#extent_date').datepicker('getDate');
    //     let ficav_date =  new Date(this.data.get('ficav'));
    //     let due_date =  new Date(this.data.get('due')); //Fecha de vencimiento de la licencia del cliente
    //     if (ficav_date <= extend_date) {
    //         alert("INFO: La fecha FICAV del vehículo vence antes de la fecha de entrega de la prórroga. Por favor, verifique la fecha seleccionada o realice un cambio de vehículo.");
    //     }
    //     if (due_date <= extend_date){
    //         alert("INFO: La licencia del cliente caducó o la fecha de vencimiento se encuentra dentro del período que solicita de prórroga.");
    //     }
    //     let millisBetween = extend_date.getTime() - to_date.getTime();
    //     let days = millisBetween / (1000 * 3600 * 24);
    //     days = parseInt(days)
    //     $('#extent_days').val(days);
    //     $('#amount_extent').val((days * $('#extent_tariff').val()).toFixed(2));
    //     $('#liability_release_import').val((days * $('#liability_release_tariff_day').val()).toFixed(2));
    //     $('#relief_supplement_import').val((days * $('#relief_supplement_tariff_day').val()).toFixed(2));
    //     this.updateDriverImport(e);
    // }


    updateExtentDays(e){
        let days = parseInt($('#extend_days').val());
        new Date($('#extent_date').val())
        let to_date =  new Date(this.data.get('min'));
        // let current_date = $('#extent_date').datepicker('getDate');
        //let extend_date = new Date();
        let extend_date = to_date.setDate(to_date.getDate() + days);
        $('#extent_date').val(getFormattedDate(to_date));

        let ficav_date =  new Date(this.data.get('ficav'));
        let due_date =  new Date(this.data.get('due')); //Fecha de vencimiento de la licencia del cliente
        if (ficav_date <= extend_date) {
            alert("INFO: La fecha FICAV del vehículo vence antes de la fecha de entrega de la prórroga. Por favor, verifique la fecha seleccionada o realice un cambio de vehículo.");
        }
        if (due_date <= extend_date){
            alert("INFO: La licencia del cliente caducó o la fecha de vencimiento se encuentra dentro del período que solicita de prórroga.");
        }
        // let millisBetween = extend_date.getTime() - to_date.getTime();
        // let days = millisBetween / (1000 * 3600 * 24);
        // days = parseInt(days)
        $('#extent_days').val(days);
        $('#amount_extent').val((days * $('#extent_tariff').val()).toFixed(2));
        $('#liability_release_import').val((days * $('#liability_release_tariff_day').val()).toFixed(2));
        $('#relief_supplement_import').val((days * $('#relief_supplement_tariff_day').val()).toFixed(2));
        this.updateDriverImport(e);
    }

    updateDriverImport(e){
        let driver_tariff_type = this.data.get('driver_tariff_type');
        let driver_tariff_extent = parseFloat(this.data.get('driver_tariff_extent'));
        let element_drivers_import = $('#drivers_import');
        element_drivers_import.val(0.0);
        let today =  new Date($('#today').val());
        let extend_date = $('#extent_date').datepicker('getDate');
        let charged_drivers = $('#charged_drivers').val() != undefined ? $('#charged_drivers').val() : 0;

        // calcular importe de los nuevos choferes a partir del momento de prorroga
        //let millisBetween = extend_date.getTime() - today.getTime();
        //let days_new_drivers = millisBetween / (1000 * 3600 * 24);
        let days_new_drivers = parseInt($('#extend_days').val());
        let tariff_drivers_day = $('#tariff_drivers_day').val() != undefined ? $('#tariff_drivers_day').val() : 0;
        let additional_driver_collect = $('#additional_driver_collect').val() != undefined ? $('#additional_driver_collect').val() : 0;

        // Calcular importe de los choferes ya cobrados durante la renta, para estos solo cobrar los dias prorrogados
        let days = parseInt($('#extent_days').val());
        let import_charged_drivers = 0.0;
        let import_new_drivers = 0.0;
        // Optar por la configuracion del tipo de renta para cuando no viene de la prorroga la tarifa
        if (driver_tariff_type === 'by_range' && driver_tariff_extent === 0 ){
            import_charged_drivers = (parseFloat($('#tariff_drivers_day').val()) * parseFloat(charged_drivers)).toFixed(2);
            import_new_drivers = (parseFloat(tariff_drivers_day) * parseFloat(additional_driver_collect)).toFixed(2);
        } else {
            import_charged_drivers = (days * parseFloat($('#tariff_drivers_day').val()) * parseFloat(charged_drivers)).toFixed(2);
            import_new_drivers = (days_new_drivers * parseFloat(tariff_drivers_day) * parseFloat(additional_driver_collect)).toFixed(2);
        }
        // Importe total
        element_drivers_import.val(parseFloat(import_new_drivers) + parseFloat(import_charged_drivers));

        // Desactivar el boton de prorroga
        $('#btn-extent').addClass('disabled');
    }

    updatePaymentForm(e){
        let extent = parseFloat($('#amount_extent').val());
        let liability_release = parseFloat($('#liability_release_import').val());
        let drivers = parseFloat($('#drivers_import').val());
        let other_payments = parseFloat($('#other_payments').val());
        let relief_supplement = parseFloat($('#relief_supplement_import').val());
        let total = (extent + liability_release + drivers + other_payments + relief_supplement).toFixed(2)
        let payment_total_voucher = this.data.get('payment_total_voucher');
        // Verificar configuracion del tipo de renta, si tiene configurado que el cobro total del contrato para voucher
        if (payment_total_voucher === 'true') {
            $('#voucher').val(total);
            $('#payment_method_total').val(0.00);
            $('#total').val(0.00);
        } else {
            $('#payment_method_total').val(total)
            $('#total').val(total)
            this.to_distribute();
        }
    }

    updateTotal(e) {
        let total = this.total_payment_form();
        let to_distribute = parseFloat($('#total').val());
        let payment_total_voucher = this.data.get('payment_total_voucher');
        if (payment_total_voucher === 'true') {
            $('#payment_method_total').val(0.00);
            $('#btn-extent').removeClass('disabled');
        } else {
            $('#payment_method_total').val(to_distribute - total);
            if ((to_distribute - total) < 0) {
                // alert('No puede repartir más de $' + to_distribute + ', verifique los datos');
                toastr_message('warning', 'No puede repartir más de $' + to_distribute + ', verifique los datos');
                $('#btn-extent').addClass('disabled');
            }
            if ((to_distribute - total) === 0) {
                $('#btn-extent').removeClass('disabled');
            } else {
                $('#btn-extent').addClass('disabled');
            }
        }
    }

    total_payment_form(){
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        return cash + card + moment_card + voucher + cheque + other
    }

    to_distribute(e){
        let payment_advance_card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0; // Lo reservado en la prorroga
        let extent_import = parseFloat($('#total').val());
        $('#to_distribute').val(extent_import - payment_advance_card);
    }

    ajaxPaymentFormsByRentType(operation){
        $.blockUI({ message:false });
        jQuery.ajax({
            async:true,
            evalScripts: true,
            type: 'GET',
            contentType: "application/x-www-form-urlencoded",
            url: `${location.origin}/hire_point/payments_form/${operation}.json`,
            data: {rent_operation: operation},
            success: function (pays){
                $.unblockUI();
                if(pays.payment_forms.length > 0){
                    let moment_card = $('#moment_card');
                    let card = $('#card');
                    let cash = $('#cash');
                    let voucher = $('#voucher');
                    let cheque = $('#cheque');
                    let other = $('#other');

                    for(var i = 0; i < pays.payment_forms.length ;i++){

                        if(pays.payment_forms[i].code === 'EFE')
                            cash.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TM')
                            moment_card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'VOU')
                            voucher.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TAN')
                            card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'CHT')
                            cheque.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'OTR')
                            other.removeAttr("disabled");
                        console.log("formas de pago definidas: " + pays.payment_forms[i].code);

                    }

                }else{
                    console.log("ESTA OPERACION DE RENTA NO TIENE FORMAS DE PAGO DEFINIDAS");
                }

            },
            error: function () {
                $.unblockUI();
                console.log("Existen errores procesando esta peticion.")
            },
        });
    };
}
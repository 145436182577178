import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        let lot_type = $('#lot_type');
        let modality = $('#modality');
        let adjust = $('#rent_adjustment');
        let lot = $('#lot');
        let b2b_type = $('#pre_billed');
        let basic_business = $('#basic_business');

        lot_type.val() === 'b2b_lot' || lot_type.val() === 'income_lot' ? modality.prop('disabled', true) : modality.prop('disabled', false);

        if (lot_type.val() === 'b2c_lot') {
            adjust.prop('disabled', true);
            this.fetchB2CLots(lot_type[0].dataset.b2c, modality.val(), modality[0].dataset.lot);
        } else if (lot_type.val() === 'b2b_lot') {
            adjust.prop('disabled', false);
            if (typeof basic_business.val() !== "undefined") {
                this.fetchB2BLots(lot_type[0].dataset.b2b, lot[0].dataset.lot);
            }
        }

        lot_type.change(function () {
            if ($(this).val() === 'b2c_lot') {
                modality.prop('disabled', false)
                adjust.prop('disabled', true);
                if (typeof b2b_type.val() !== "undefined") {
                    b2b_type.prop('disabled', true);
                }
            } else {
                if (typeof b2b_type.val() !== "undefined") {
                    b2b_type.prop('disabled', false);
                }
                modality.prop('disabled', true);
                adjust.prop('disabled', false);
                modality.val("").trigger('change');
            }
        });

        if (typeof lot_type.val() === "undefined") {
            modality.val() ? lot.prop('disabled', false) : lot.prop('disabled', true);
            modality.change(() => {
                lot.prop('disabled', false);
            });
        }

        if (typeof basic_business.val() !== "undefined") {
            basic_business.change(() => {
                if (lot_type.val() === "b2b_lot") {
                    this.fetchB2BLots(lot_type[0].dataset.b2b);
                } else if (lot_type.val() === "b2c_lot") {
                    modality.prop('disabled', false);
                    modality.val() ? this.fetchB2CLots(lot_type[0].dataset.b2c, modality.val(), "") : "";
                }
            });
        }
    }

    fetchLots(event) {
        let b2b = event.target.dataset.b2b;
        let b2c = event.target.dataset.b2c;
        let income = event.target.dataset.income;

        if ($('#lot_type').val() === 'b2b_lot') {
            this.fetchB2BLots(b2b)
        }
        else if ( $('#lot_type').val() === 'b2c_lot'){
            this.fetchB2CLots(b2c, "", "");
        }
        else{
            this.fetchIncomeLots(income)
        }
        // $('#lot_type').val() === 'b2b_lot' ? this.fetchB2BLots(b2b) : this.fetchB2CLots(b2c, "", "");
    }

    fetchB2BLots(route, lot_id = "") {
        let basic_business = $('#basic_business').val();
        let pre_billed = $('#pre_billed').val();
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        this.ajaxRequest(route, " ", csrf, lot_id, basic_business, pre_billed);
    }

    fetchB2CLots(route, modality = "", lot_id) {
        let basic_business = $('#basic_business').val();
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        this.ajaxRequest(route, modality, csrf, lot_id, basic_business);
    }

    fetchIncomeLots(route, lot_id = "") {
        let basic_business = $('#basic_business').val();
        let pre_billed = $('#pre_billed').val();
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        this.ajaxRequest(route, " ", csrf, lot_id, basic_business, pre_billed);
    }

    fetchB2CLotsByModality(event) {
        let modality = event.target.value;
        let basic_business = $('#basic_business').val();
        let url = event.target.dataset.b2c;
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        this.ajaxRequest(url, modality, csrf, "", basic_business);
    }

    ajaxRequest(url, modality, csrf, lot_id = "", basic_business = "", pre_billed = "") {
        $.ajax({
            url: url,
            method: 'post',
            data: {modality: modality, basic_business: basic_business, pre_billed: pre_billed},
            headers: {
                'X-CSRF-Token': csrf
            },
            success: (result) => {
                let lotSelect = $('#lot');
                lotSelect.empty();
                lotSelect.append('<option selected="selected" value="">Seleccione un lote</option>');
                if (result !== undefined) {
                    $.each(result.lots, function (index, lot) {
                        lotSelect.append(
                            $("<option>")
                                .text(lot[0])
                                .attr("value", lot[1])
                                .prop("selected", lot_id !== "" && parseInt(lot_id) === lot[1] && modality !== "")
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        });
    }
}
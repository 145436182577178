import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    connect() {
        $('#place_filter').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#place_filter").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    getDestinyPlaceByHirePoint(e) {
        let deposits_by_basic_business_url = this.data.get("deposits_by_basic_business_url");
        let parkings_by_hire_points_url = this.data.get("parkings_by_hire_points_url");
        let workshops_by_hire_points_url = this.data.get("workshops_by_hire_points_url");
        let place = $('#place_filter').val();


        if (place === 'deposit') {
            if ($('#basic_business_id').val()) {
                // get all deposit.
                $.blockUI({ message: false });
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: deposits_by_basic_business_url,
                    data: {id: $('#basic_business_id').val()},
                    success: function (res) {
                        $.unblockUI();
                        $("#destiny_place").empty();
                        $("#destiny_place").append('<option selected="selected" value=""> Seleccione el lugar de ubicación </option>')
                        $.each(res.deposits, function (index, deposit) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(deposit.name)
                                    .attr("value", deposit.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        ajaxErrorMessages(jqXHR,textStatus,errorThrown);
                    },
                });
                $('#parking_place').prop('disabled', true);

            } else {
                alert('Seleccione una agencia');
                $('#basic_business_id').focus();
            }
        } else if (place === 'parking') {
            // should exits hirepoint select
            if ($('#destiny_group').val()) {
                $.blockUI({ message: false });
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: parkings_by_hire_points_url,
                    data: {id: $('#destiny_group').val()},
                    success: function (res) {
                        $.unblockUI();
                        $("#destiny_place").empty();
                        $("#destiny_place").append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
                        $.each(res.hire_points, function (index, hire_point) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(hire_point.name)
                                    .attr("value", hire_point.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        ajaxErrorMessages(jqXHR,textStatus,errorThrown);
                    },
                });
                $('#parking_place').prop('disabled', false);
            } else {
                alert('Seleccione un groupo de destino');
                $('#destiny_group').focus();
            }
        } else if (place === 'workshop') {
            if ($('#basic_business_id').val()) {
                $.blockUI({ message: false });
                jQuery.ajax({
                    async: true,
                    evalScripts: true,
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    url: workshops_by_hire_points_url,
                    data: {id: $('#basic_business_id').val()},
                    success: function (res) {
                        $.unblockUI();
                        $("#destiny_place").empty();
                        $("#destiny_place").append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
                        $.each(res.workshops, function (index, workshop) {
                            $("#destiny_place").append(
                                $("<option>")
                                    .text(workshop.description)
                                    .attr("value", workshop.id)
                            );
                        });
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.unblockUI();
                        ajaxErrorMessages(jqXHR,textStatus,errorThrown);
                    },
                });
                $('#parking_place').prop('disabled', true);

            } else {
                alert('Seleccione un groupo de destino');
                $('#destiny_group').focus();
            }
            // should exits hirepoint select
            // get all workshop
        }
    }
}
import {Controller} from "stimulus";

require('block-ui/jquery.blockUI');
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

export default class extends Controller {
    connect() {
    }

    /**
     * Ajax para mandar a crear un Job que permitira exportar el reporte de diferencia de precios
     * */
    export_price_differences() {
        fetch('/reports/price_difference_job')
            .then(() => {
                // $.blockUI({message: false});
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            })
            .catch((error) => {
                // $.unblockUI();
                console.error('Error:', error);
            });
    }

    export_by_url(e){
        let url = this.data.get("url");
        fetch(url)
            .then(() => {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 40000);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    export_old_vehicle_url(e){
        let url = this.data.get("url");
        fetch(url)
            .then(() => {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    export_liquidations() {
        fetch('/business/liquidations/liquidation_job')
            .then(() => {
                // $.blockUI({message: false});
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            })
            .catch((error) => {
                // $.unblockUI();
                console.error('Error:', error);
            });
    }

    externals_agency_rents_fleet_survey(e) {
        let date = $("#report_date").val();
        let basic_businesses = $("#basic_businesses").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if (basic_businesses.length === 0){
            alert("Para este reporte debe seleccionar al menos una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/externals_agency_rents_fleet_survey',
            data: {
                date: date,
                multi_bb: basic_businesses,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 30000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                // alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });

    }

    agency_fleet_survey() {
        let basic_business = $("#basic_business").val();
        // si no se selecciono al menos una agencia...
        if(basic_business === '') {
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/agency_complete_fleet_survey',
            data: {
                basic_bussines: basic_business
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 3000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    agency_rents_fleet_survey() {
        let basic_business = $("#basic_business").val();
        // si no se selecciono al menos una agencia...
        if (basic_business === '') {
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/agency_rents_fleet_survey',
            data: {
                basic_bussines: basic_business
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 3000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    email_survey_with_import() {
        let date = $("#report_date").val();
        let basic_business = $("#basic_business").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if (basic_business === '') {
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/email_survey_with_import',
            data: {
                date: date,
                basic_bussines: basic_business,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                // $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    internals_agency_rents_other_fleets() {
        let date = $("#report_date").val();
        let basic_businesses = $("#basic_businesses").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if (basic_businesses === '') {
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/internals_agency_rents_other_fleets',
            data: {
                date: date,
                multi_bb: basic_businesses,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                // $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    in_off_survey(){
        let basic_business = $("#basic_business").val();
        let in_off = $("input[name='survey_selector']:checked").val();
        // si no se selecciono al menos una agencia...
        if(basic_business === ''){
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/in_off_survey',
            data: {
                basic_bussines: basic_business,
                filter_in_off: in_off
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 3000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }


    contracts_amount_details(){
        let basic_business = $("#basic_business").val();
        let date = $("#report_date").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if(basic_business === ''){
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/contracts_amount_details',
            data: {
                basic_business: basic_business,
                date: date,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    daily_amount_operations() {

        let date = $("#report_date").val();
        let basic_businesses = $("#basic_businesses").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if (basic_businesses.length === 0){
            alert("Para este reporte debe seleccionar al menos una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/daily_amount_operations',
            data: {
                date: date,
                multi_bb: basic_businesses,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                // $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    traveled_month_kms_by_agency(){
        let basic_business = $("#basic_business").val();
        let month_date = $('.month_year_date').val();
        // si no se selecciono al menos una agencia...
        if (basic_business.length === 0){
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        // si no se selecciono un mes...
        if (month_date === ''){
            alert("Para este reporte debe seleccionar un mes y un año. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/month_total_kms',
            data: {
                basic_business: basic_business,
                month_date: month_date,
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    nationality_rent_status(){
        let min_date = $("#date-min").val()
        let max_date = $("#date-max").val()
        let business_selector = $('input[name="RadioBusiness"]:checked').val();
        if(min_date === '' || max_date === ''){
                alert("Para este reporte debe seleccionar un rango de fecha. Vuelva a intentarlo");
                $('.export').prop('disabled', false);
                return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/nationality_rent_status',
            data: {
                begin_date: min_date,
                end_date: max_date,
                business_selector: business_selector
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 50000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }


    rent_operation_by_client(e) {
        let date = $("#report_date").val();
        let basic_business = $("#basic_business").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if (basic_business.length === 0){
            alert("Para este reporte debe seleccionar una agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/report_rents_operations_by_clients',
            data: {
                date: date,
                basic_business: basic_business,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 15000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                // alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });

    }

    amount_operations_by_date_operation(e) {
        let date = $("#report_date").val();
        let businesses = $("#multi_businesses").val();
        let basic_businesses = $("#basic_businesses").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        // si no se selecciono al menos una agencia...
        if ( !businesses.length === 0 || !basic_businesses.length === 0 ){
            alert("Para este reporte debe seleccionar al menos una sucursal o agencia. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }

        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/report_amount_operations_by_hire_and_date',
            data: {
                date: date,
                multi_b: businesses,
                multi_bb: basic_businesses,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                today_or_yesterday: radio
            },
            success: function (res) {
                $.unblockUI();
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 30000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // alert('ERROR');
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });

    }

    daily_rent_operations_by_hire_point(){
        let since = $("#report_date").val();
        let multi_hp = $("#hire_points").val();
        let basic_businesses = $("#basic_businesses").val();
        let rent_op = $("#rent_operation").val();
        let range_min_date = $("#date-min").val();
        let range_max_date = $("#date-max").val();
        let radio = $('input[name="Radio"]:checked').val();
        let chk_agency_enroll = $("#agency_enrollment").val();
        let chk_active = $("#active").val()
        let no_multi_agency_elected = () =>$('#basic_businesses').val().length === 0;
        let no_multi_hirepoints_elected = () =>$('#hire_points').val().length === 0;
        // [Operaciones diarias por oficinas de ventas]
        if(no_multi_agency_elected() && no_multi_hirepoints_elected()){
            alert("Para este reporte debe seleccionar al menos una agencia o una oficina de ventas. Vuelva a intentarlo");
            $('.export').prop('disabled', false);
            return
        }
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/reports/daily_rent_operations_by_hire_point',
            data: {
                multi_hp: multi_hp,
                multi_bb: basic_businesses,
                rent_op: rent_op,
                range_min_date: range_min_date,
                range_max_date: range_max_date,
                since: since,
                radio: radio,
                chk_agency_enroll: chk_agency_enroll,
                chk_active: chk_active,
            },
            success: function (res) {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 20000);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('ERROR');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }



}

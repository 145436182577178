import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";

export default class extends Controller {

    connect() {
        // let checkbox = $('#nom_client_unpleasant_customer');
        //
        // checkbox.on('change', function () {
        //    console.log($(this).is(':checked'))
        // })
    }

    onChangeUnpleasantCustomer(e) {
        let unpleasantCustomerObj = $("#" + e.target.id);
        let phone = $('#nom_client_phone');
        let phoneLabel = $('#nom_client_phone_label');
        let licenseLabel = $('#nom_client_license');
        let license = $('#nom_client_driver_license');

        if (unpleasantCustomerObj.prop('checked')) {
            phone.attr('required', false);
            phoneLabel.removeClass('required');
            licenseLabel.removeClass('required');
            license.attr('required', false);
        } else {
            phone.attr('required', true);
            phoneLabel.addClass('required');
            licenseLabel.addClass('required');
            license.attr('required', true);
        }
    }

    validAgeToRent(e) {
        let birth_date_id = e.target.id;
        let url = this.data.get("valid_age_url");
        let birth_date = $(`#${birth_date_id}`).val();
        let span_age_id = this.data.get("span_age_id");
        // El tipo de renta puede recogerse mediante el value un componente o pasarse en el data para casos donde no existe
        // este campo en el formulario
        let rent_type_id = $('#nom_rent_type_id').val();
        if (rent_type_id === undefined) rent_type_id = this.data.get("rent_type_id");

        if (birth_date !== undefined && birth_date !== ''){
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {nom_rent_type_id: rent_type_id, birth_date: birth_date},
                success: function (res) {
                    if (res.status === 'ERROR') {
                        toastr_message('error', res.msg, res.title);
                        $(`#${birth_date_id}`).val('');
                    }
                    if (res.age !== undefined) $(`#${span_age_id}`).text(res.age)
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR,textStatus,errorThrown);
                },
            });
        }
    }
    validateAge(e){
        let birth_date = $('#birth_date').val();
        let arrDate = birth_date.split('/')
        let end = new Date();
        let start = new Date(arrDate[2]+'-'+arrDate[1]+'-'+arrDate[0]);
        let diff = new Date(end - start);
        let days = diff/1000/60/60/24;
        days = (days/365)
        let birth_days = Math.round(days)
        if (birth_days < 18 || birth_days > 100){
            toastr_message('error', 'La edad del cliente esta fuera de rango permitido.');
            let birth_date = $('#birth_date').val('');
        }
    }
}
import { Controller } from "stimulus"
import $ from 'jquery';

// Connects to data-controller="rent-by-hire-point-date"
export default class extends Controller {
  connect() {
    $("#div_filter_selector").on('change', () => {
          if ($('input[name="Radio"]:checked').val() === 'option3' ){
            this.rangeDate()
          }
          if ($('input[name="Radio"]:checked').val() === 'option4' ){
            this.fromDate()
          }
    }
    )
  }

  rangeDate(){
    let now_date = new Date()
    let f_now_date = this.formatDate(now_date);

    let old_date = new Date()
    old_date.setMonth(old_date.getMonth())
    let f_old_date = this.formatDate(old_date);

    $('#date-min').val(f_old_date)
    $('#date-max').val(f_now_date)
  }

  fromDate(){
    let old_date = new Date()
    old_date.setDate(old_date.getDate())
    let f_old_date = this.formatDate(old_date);

    $('#report_date').val(f_old_date)
  }
  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}

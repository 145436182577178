import { Controller } from "stimulus"
import $ from "jquery";
require('block-ui/jquery.blockUI');

export default class extends Controller {
  connect() {
      // Al comenzar la carga de la pagina
      $(document).ready(function() {
          $('#iframe_embed').block({
              message: 'Cargando el contrato ...',
              css: {
                  border: 'none',
                  padding: '15px',
                  backgroundColor: '#000',
                  '-webkit-border-radius': '10px',
                  '-moz-border-radius': '10px',
                  opacity: .5,
                  color: '#fff'
              }
          });
      });
      // Al terminar de cargar la pagina
      $(window).on("load", function () {
          $('#iframe_embed').unblock();
      });
  }
}

import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
export default class extends Controller {
    connect() {
        let minDate = this.data.get('min');
        let maxDate = this.data.get('max');
        $('.date-range').datepicker({
            dateFormat: "dd/mm/yy",
            defaultDate: 1,
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showButtonPanel: true,
            minDate: minDate, //new Date(minDate),
            maxDate: new Date(maxDate),
            onSelect: function (dateText, inst) {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            }
        });
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }
}
import consumer from "./consumer"

consumer.subscriptions.create("AppearanceChannel", {
  initialized() {
    // console.log(' ----- initializer ----- ');
  },

  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("Connected AppearanceChannel");
    // // Cuando el usuario pierde el foco o sale de tu pestaña (sitio web)
    // window.addEventListener("blur", () => {
    //   document.title = "Breakup";
    // });
    //
    // // Cuando el enfoque del usuario vuelve a tu pestaña (sitio web) nuevamente
    // window.addEventListener("focus", () => {
    //   document.title = "Patch Up";
    // });
    // this.online();
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log("Disconnected AppearanceChannel");
    // this.offline();
  },

  rejected() {},

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Connected AppearanceChannel");
    console.log('recieve -------- ');
    console.log(data.message)
  },

  online() {
    console.log("online");
    this.perform("online");
  },

  offline() {
    console.log("offline")
    this.perform("offline")
  }
});

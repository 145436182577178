import {Controller} from "stimulus"
import $ from 'jquery';
import {myToFixed} from "./globalFunctions_controller";

export default class extends Controller {
    connect() {
        $("#rent_op").on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
        let op_id = (($('#rent_op option:selected')[0]).attributes.id.value)
        if (op_id != null) {
            $("#op_id").val(op_id);
            this.set_components_by_op_types();
        }
    }

    set_components_by_op_types(){
        let op_type = $("#rent_op").val();
        let other_amount = $("#other_amount");
        let relief_supplement_import = $("#relief_supplement_import");
        let liability_release_import = $("#liability_release_import");
        let rent_import = $("#rent_import");
        let fuel_import = $("#fuel_import");
        let drivers_import = $("#drivers_import");
        let office_import = $("#office_import");
        let drop_off = $("#drop_off");
        switch (op_type){
            case 'RentAppendDriver':
                drivers_import.prop('disabled', false);
                other_amount.prop('disabled', false);
                break;
            case 'RentCarChange':
                drivers_import.prop('disabled', false);
                fuel_import.prop('disabled', false);
                other_amount.prop('disabled', false);
                break;
            case 'RentExtend':
                other_amount.prop('disabled', false);
                break;
            case 'RentAdditionalPayment':
                rent_import.prop('disabled', false);
                liability_release_import.prop('disabled', false);
                relief_supplement_import.prop('disabled', false);
                fuel_import.prop('disabled', false);
                drivers_import.prop('disabled', false);
                office_import.prop('disabled', false);
                other_amount.prop('disabled', false);
                break;
            case 'Rent':
                rent_import.prop('disabled', false);
                liability_release_import.prop('disabled', false);
                relief_supplement_import.prop('disabled', false);
                fuel_import.prop('disabled', false);
                drivers_import.prop('disabled', false);
                office_import.prop('disabled', false);
                other_amount.prop('disabled', false);
                drop_off.prop('disabled', false);
                break;
            default:
                rent_import.prop('disabled', false);
                liability_release_import.prop('disabled', false);
                relief_supplement_import.prop('disabled', false);
                fuel_import.prop('disabled', false);
                drivers_import.prop('disabled', false);
                office_import.prop('disabled', false);
                other_amount.prop('disabled', false);
                drop_off.prop('disabled', false);
                break;
        }
    }
    /**
     * Para habilitar o desabilitar los componentes segun tipo de operacion
     * */
    enableComponentsByOpType(){
        let op_id = (($('#rent_op option:selected')[0]).attributes.id.value)
        $("#op_id").val(op_id);
        this.defaultComponents();
        this.set_components_by_op_types();
    }

    defaultComponents(){
        let rent_import = $("#rent_import");
        let liability_release_import = $("#liability_release_import");
        let drivers_import = $("#drivers_import");
        let office_import = $("#office_import");
        let relief_supplement_import = $("#relief_supplement_import");
        let fuel_import = $("#fuel_import");
        let other_amount = $("#other_amount");
        let drop_off = $("#drop_off");

        rent_import.prop('disabled', true);
        rent_import.val(0.0);
        liability_release_import.prop('disabled', true);
        liability_release_import.val(0.0);
        drivers_import.prop('disabled', true);
        drivers_import.val(0.0);
        office_import.prop('disabled', true);
        office_import.val(0.0);
        relief_supplement_import.prop('disabled', true);
        relief_supplement_import.val(0.0);
        fuel_import.prop('disabled', true);
        fuel_import.val(0.0);
        other_amount.prop('disabled', true);
        other_amount.val(0.0);
        drop_off.prop('disabled', true);
        drop_off.val(0.0);
    }

    updateTotalImport(e){
        let rent_import = 0;
        let liability_release_import = 0;
        let drivers_import = 0;
        let office_import = 0;
        let relief_supplement_import = 0;
        let fuel_import = 0;
        let other_amount = 0;
        let drop_off = 0;

        if($('#rent_import').val() != undefined && $('#rent_import').val() != ''){
            rent_import = parseFloat($('#rent_import').val());
        }
        if($('#liability_release_import').val() != undefined && $('#liability_release_import').val() != ''){
            liability_release_import = parseFloat($('#liability_release_import').val());
        }
        if($('#drivers_import').val() != undefined && $('#drivers_import').val() != ''){
            drivers_import = parseFloat($('#drivers_import').val());
        }
        if($('#office_import').val() != undefined && $('#office_import').val() != ''){
            office_import = parseFloat($('#office_import').val());
        }
        if($('#relief_supplement_import').val() != undefined && $('#relief_supplement_import').val() != ''){
            relief_supplement_import = parseFloat($('#relief_supplement_import').val());
        }
        if($('#fuel_import').val() != undefined && $('#fuel_import').val() != ''){
            fuel_import = parseFloat($('#fuel_import').val());
        }
        if($('#other_amount').val() != undefined && $('#other_amount').val() != ''){
            other_amount = parseFloat($('#other_amount').val());
        }

        if($('#drop_off').val() != undefined && $('#drop_off').val() != ''){
            drop_off = parseFloat($('#drop_off').val());
        }

        $('#amount_total').val(myToFixed(rent_import + liability_release_import + drivers_import + office_import +
            relief_supplement_import + fuel_import + drop_off + other_amount, 2)).trigger('click');
    }
}
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/js/all";
// import '@fortawesome/fontawesome-free/css/all.min.css'
///import '@fortawesome/fontawesome-free/css/v4-shims.min.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import "trix/dist/trix.css";
// import "vue-pnotify/dist/vue-pnotify.css"
import "jquery-ui-dist/jquery-ui.min";
import "jquery-ui-dist/jquery-ui.structure.min";
import 'toastr/build/toastr.min';
import '../stylesheets/app.scss'

var Trix  = require("trix");
require("@rails/actiontext");
require("@rails/activestorage").start();

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
global.moment = require('moment');
import toastr from 'toastr'
window.toastr = toastr

require('bootstrap/dist/js/bootstrap.min');
require('popper.js/dist/popper.min');
require("controllers");
require('moment/dist/moment');
require('moment/dist/locale/es');
require("select2/dist/css/select2.min.css");
require("select2-bootstrap-theme/dist/select2-bootstrap.min.css");
require('block-ui/jquery.blockUI');
require('./libraries/particles')

import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
import VuejsDialog from "vuejs-dialog"
import VueSanitize from "vue-sanitize"
// import VuePNotify from 'vue-pnotify'
import Loading from 'vue-loading-overlay';
import basicTable from './components/basicTable.vue';
import userTable from "./components/userTable.vue";
import $ from 'jquery';
import "chartkick/highcharts"

Rails.start()

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}
Array.prototype.isEmpty = function () {
    return this.length === 0 ;
}

ActiveStorage.start()
Vue.use(VueResource);
Vue.use(VuejsDialog);
Vue.use(VueSanitize);
// Vue.use(VuePNotify);
// componente tabla
document.addEventListener('turbo:load', () => {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    let table = document.querySelector('#basic-table');
    let table1 = document.querySelector('#basic-table-1');
    if (table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('loading', Loading);
        const app = new Vue({
            el: table,
            data:{
                lists: JSON.parse(table.dataset.lists).results,
            },
            template: "<basicTable :results='lists' @update-lists ='update' />",
            components: {
                basicTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if (table1 != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('loading', Loading);
        const app = new Vue({
            el: table1,
            data:{
                lists: JSON.parse(table1.dataset.lists).results,
            },
            template: "<basicTable :results='lists' @update-lists ='update' />",
            components: {
                basicTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
});

//componente para usuarios
document.addEventListener('turbo:load', () => {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var user_table = document.querySelector('#user-table');
    if (user_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        //Vue.component('confirm-dialog', ConfirmDialog);
        Vue.component('loading', Loading);
        const app = new Vue({
            el: user_table,
            data:{
                lists: JSON.parse(user_table.dataset.lists).results,
            },
            template: "<userTable :results='lists' @update-lists ='update' />",
            components: {
                userTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
});
import "controllers"

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ){
        if ( ns.includes("noPreventDefault") ) {
            this.addEventListener("touchstart", handle, { passive: false });
        } else {
            this.addEventListener("touchstart", handle, { passive: true });
        }
    }
};

window.initMap = function() {
    var loc = {lat: $('#map').data('latitude'), lng: $('#map').data('longitude')};
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 18, // Tweak this to your needs
        center: loc
    });
    var contentString = '<p><strong>Speed: ' + $('#map').data('speed') + 'km/hr</strong>' +
        '<p><strong>Time: ' + $('#map').data('time') + '</strong>'
    var infowindow = new google.maps.InfoWindow({
        content: contentString
    });

    // Custom icon if you want one
    var icon = {
        url: $('#map').data('image'),
        scaledSize: new google.maps.Size(25, 25), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0), // anchor
    };

    var marker = new google.maps.Marker({
        position: loc,
        map: map,
        icon: icon
    });

    marker.addListener('click', function() {
        infowindow.open(map, marker);
    });

    // Poll the data attributes for the latest info every 30 seconds
    setInterval(function(e) {
        if (loc) {
            var contentString = '<p><strong>Velocidad: ' + $('#map').data('speed') + 'km/hr</strong>' +
                '<p><strong>Hora: ' + $('#map').data('time') + '</strong>'
            marker.setPosition(loc) // This will move the marker to the new location
            infowindow.setContent(contentString) // Set the content of the info window with the latest details
        };
    }, 3000)
}
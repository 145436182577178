import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {}

    findCommercialContractFromSDA(e) {
        if (e.target.value !== '' && e.target.value !== null) {
            $.blockUI({message: false});
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: this.data.get('url'),
                data: {entity_id: e.target.value},
                success: function (res) {
                    $.unblockUI();
                    $("#sda_contract_id").empty();
                    if (res !== undefined) {
                        $.each(res.sda_entity_contracts, function (index, contract) {
                            $("#sda_contract_id").append(
                                $("<option>")
                                    .text(contract.name)
                                    .attr("value", contract.id)
                            );
                        });
                    }
                    if (res.status === 'FAIL'){
                        toastr_message('error', res.error_msg);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    findCommercialContractByCateg(e) {
        let url = this.data.get("url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {category: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#contract").empty();
                $("#contract").append('<option selected="selected" value=""> Seleccione el contrato </option>')
                if (res !== undefined) {
                    $.each(res.contracts, function (index, contracts) {
                        $("#contract").append(
                            $("<option>")
                                .text(contracts.name)
                                .attr("value", contracts.code)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status === 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status === 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status === 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status === 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status === 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status === 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
    }
}
import {Controller} from "stimulus";
import $ from "jquery";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        alert(message);
    }
}
export default class extends Controller {
    connect() {
        $('#movement_type').select2({
            theme: "bootstrap",
            language: "es"
        });
        $(" #movement_type, #vehicle_movement_type").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
        // Al agregar nuevo No.T2 del elemento seleccionado
        $('#multiple_vehicles').on('select2:select', function (e) {
            let id = e.params.data.id;
            let vehicle_data = e.params.data.text;
            const t2_body = $('.t2_body');
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "GET",
                url: '/hire_point/get-t2-vehicle',
                data: {id: id},
                success: function (res) {
                    t2_body.append(`
                      <div class="form-group mb-3 row" id="row_t2_${id}">
                         <label class="form-label col-3 col-form-label required text-right" id="label_t2_${id}">No.T2</label>
                            <div class="col-2">
                              <input class="form-control" name="t2[${id}]" required="true" id="t2_${id}" />
                            </div>
                          ${vehicle_data}
                      </div>
                    `);
                    $(`#t2_${id}`).val(res.vehicle_t2);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        });
        //Quitar el No.T2 de los vehiculos desmarcados
        $('#multiple_vehicles').on('select2:unselect', function (e) {
            $(`#row_t2_${e.params.data.id}`).remove();
        });
    }

    // getGroupsByHirePoints(e) {
    //     let url = this.data.get("groups_url");
    //     jQuery.ajax({
    //         async: true,
    //         evalScripts: true,
    //         type: "POST",
    //         contentType: "application/x-www-form-urlencoded",
    //         url: url,
    //         data: {id: e.target.value},
    //         success: function (res) {
    //             $("#destiny_group").empty();
    //             $("#destiny_group").append('<option selected="selected" value=""> Seleccione el Grupo de destino </option>')
    //             $.each(res.groups, function (index, group) {
    //                 $("#destiny_group").append(
    //                     $("<option>")
    //                         .text(group.name)
    //                         .attr("value", group.id)
    //                 );
    //             });
    //         },
    //         error: function () {
    //             alert("Error");
    //         },
    //     });
    // }
    //
    // getDestinyPlaceByHirePoints(e) {
    //     let deposits_by_basic_business_url = this.data.get("deposits_by_basic_business_url");
    //     let parkings_by_hire_points_url = this.data.get("parkings_by_hire_points_url");
    //     let workshops_by_hire_points_url = this.data.get("workshops_by_hire_points_url");
    //     let place = $('#place_filter').val();
    //
    //     if (place === 'deposit') {
    //         if ($('#basic_business_id').val()) {
    //             // get all deposit.
    //             jQuery.ajax({
    //                 async: true,
    //                 evalScripts: true,
    //                 type: "POST",
    //                 contentType: "application/x-www-form-urlencoded",
    //                 url: deposits_by_basic_business_url,
    //                 data: {id: $('#basic_business_id').val()},
    //                 success: function (res) {
    //                     $("#destiny_place").empty();
    //                     $("#destiny_place").append('<option selected="selected" value=""> Seleccione el lugar de ubicación </option>')
    //                     $.each(res.deposits, function (index, deposit) {
    //                         $("#destiny_place").append(
    //                             $("<option>")
    //                                 .text(deposit.name)
    //                                 .attr("value", deposit.id)
    //                         );
    //                     });
    //                 },
    //                 error: function () {
    //                     alert("Error");
    //                 },
    //             });
    //             $('#parking_place').prop('disabled', true);
    //
    //         } else {
    //             alert('Seleccione una agencia');
    //             $('#basic_business_id').focus();
    //         }
    //     } else if (place === 'parking') {
    //         // should exits hirepoint select
    //         if ($('#destiny_group').val()) {
    //             jQuery.ajax({
    //                 async: true,
    //                 evalScripts: true,
    //                 type: "POST",
    //                 contentType: "application/x-www-form-urlencoded",
    //                 url: parkings_by_hire_points_url,
    //                 data: {id: $('#destiny_group').val()},
    //                 success: function (res) {
    //                     $("#destiny_place").empty();
    //                     $("#destiny_place").append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
    //                     $.each(res.hire_points, function (index, hire_point) {
    //                         $("#destiny_place").append(
    //                             $("<option>")
    //                                 .text(hire_point.name)
    //                                 .attr("value", hire_point.id)
    //                         );
    //                     });
    //                 },
    //                 error: function () {
    //                     alert("Error");
    //                 },
    //             });
    //             $('#parking_place').prop('disabled', false);
    //         } else {
    //             alert('Seleccione un groupo de destino');
    //             $('#destiny_group').focus();
    //         }
    //     } else if (place === 'workshop') {
    //         if ($('#basic_business_id').val()) {
    //             jQuery.ajax({
    //                 async: true,
    //                 evalScripts: true,
    //                 type: "POST",
    //                 contentType: "application/x-www-form-urlencoded",
    //                 url: workshops_by_hire_points_url,
    //                 data: {id: $('#basic_business_id').val()},
    //                 success: function (res) {
    //                     $("#destiny_place").empty();
    //                     $("#destiny_place").append('<option selected="selected" value="">Seleccione el lugar de ubicación</option>')
    //                     $.each(res.workshops, function (index, workshop) {
    //                         $("#destiny_place").append(
    //                             $("<option>")
    //                                 .text(workshop.description)
    //                                 .attr("value", workshop.id)
    //                         );
    //                     });
    //                 },
    //                 error: function () {
    //                     alert("Error");
    //                 },
    //             });
    //             $('#parking_place').prop('disabled', true);
    //
    //         } else {
    //             alert('Seleccione un groupo de destino');
    //             $('#destiny_group').focus();
    //         }
    //         // should exits hirepoint select
    //         // get all workshop
    //     }
    // }
    //
    // getParkingByPlace(e){
    //     let parking_by_place = this.data.get("parking_by_place_url");
    //     // Si lo seleccionado en el tipo de ubicación es un parqueo se llenan los parqueos del punto de renta
    //     // en otro caso se pone disabled
    //     if ($('#place_filter').val() == 'parking'){
    //         $('#parking_place').prop('disabled', false);
    //         jQuery.ajax({
    //             async: true,
    //             evalScripts: true,
    //             type: "POST",
    //             contentType: "application/x-www-form-urlencoded",
    //             url: parking_by_place,
    //             data: {id: $('#destiny_place').val()},
    //             success: function (res) {
    //                 $("#parking_place").empty();
    //                 $.each(res.parkings, function (index, parking) {
    //                     $("#parking_place").append(
    //                         $("<option>")
    //                             .text(parking.name)
    //                             .attr("value", parking.id)
    //                     );
    //                 });
    //             },
    //             error: function () {
    //                 alert("Error");
    //             },
    //         });
    //     }else{
    //         $("#parking_place").empty();
    //         $('#parking_place').prop('disabled', true);
    //     }
    // }

    toggleIndicativeInput() {
        if ($('#movement_type').val() === 'car_transporter' || $('#movement_type').val() === 'wreck_car') {
            $('div.indicative').removeClass('d-none');
            $('#indicative').prop('disabled', false);
        } else {
            $('div.indicative').addClass('d-none');
            $('#indicative').prop('disabled', true);
        }
    }

    toggleCLoseIndicativeInput() {
        if ($('#vehicle_movement_type').val() === 'car_transporter' || $('#vehicle_movement_type').val() === 'wreck_car' ) {
            $('div.indicative').removeClass('d-none');
            $('#vehicle_indicative').prop('disabled', false);
        } else {
            $('div.indicative').addClass('d-none');
            $('#vehicle_indicative_indicative').prop('disabled', true);
        }
    }
}

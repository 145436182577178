import {Controller} from "stimulus"
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');


export default class extends Controller {
    /** @param includeBlank define si se agrega o no la option `Todos` en el select de municipios ... se agrega a modo de opcion para no interferir con el codigo antiguo
     *  @param slectedMunicipality a este se le debe asignar desde el HTML el 'id' municipio q debe estar seleccionado cuando se carge por primera vez la pagina. Asi poder recargar o filtrar sin perder la informacion.
     */
    static values = {
        includeBlank: Boolean,
        slectedMunicipality: String,
    };

    connect() {
        this.findMunicipalitiesByProvince() // para garantizar q al filtrar o recargar la pagina el municipio se muestre correctamente
    }

    findMunicipalitiesByProvince(e) {
        if(e) e.preventDefault(); // prevenir solo si se recibe un evento
        let url = `${location.origin}${this.data.get("provinceUrl")}`;
        let province = $('#province').val();
        $.blockUI({message: false});
        $("#municipality").empty();
        $.ajax({
            type: "POST",
            url: url,
            data: { province: province },
            success: res => {
                 // $("#municipality").append('<option selected="selected" value=""> Seleccione un municipio </option>');
                if (res !== undefined) {
                    // Agregar la opcion de `Todos` si se definió en `true` el Value `includeBlank`
                    if(this.includeBlankValue) $("#municipality").append($('<option>').text('Todos los municipios').attr('value',''));
                    // agregar el resto de los Municipios
                    $.each(res.municipalities, function (index, municipality) {
                        console.info(municipality.name + ' ' + municipality.code);
                        $("#municipality").append($('<option>').text(municipality.name).attr("value", municipality.id));
                    });
                }
                // (solo pasa al conectarse pq es donde unico se llama sin el parametro `e`)
                if(!e) $("#municipality").val(this.slectedMunicipalityValue) // seleccionar el municipio q el Value dice q deberia estar seleccionado. Esto es un soporte para poder mantener seleccionado el municipio al recargarse la pagina... y al filtrar.
                // desbloquear la pantalla
                $.unblockUI();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                console.log(errorThrown);
                ajaxErrorMessages(jqXHR, textStatus, errorThrown)
            }
        });
    }


}

import {Controller} from "stimulus";
import $ from "jquery";
require('block-ui/jquery.blockUI');

export default class extends Controller{
    connect() {
        super.connect();
    }

    unpleasementCostumerIndexValidations(e){
        let select = $('#unpleasant_customer');
        let checkbox = $('#plus_clients_selector');

        select.on('change', function () {
            if ($(this).find("option:selected").val() !== "") {
                checkbox.attr('disabled', true);
            } else {
                checkbox.attr('disabled', false);
            }
        });
    }

    plusClientsIndexValidations(e){
        let select = $('#unpleasant_customer');
        let checkbox = $('#plus_clients_selector');
        let with_debit = $('#with_debit');


            if (checkbox.is(':checked')) {
                select.attr('disabled',true);
                with_debit.attr('disabled',true);
            }else{
                select.attr('disabled',false);
                with_debit.attr('disabled',false);
            }

    }
}
import { Controller } from "stimulus"
import $ from "jquery";
require('block-ui/jquery.blockUI');

function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}

export default class extends Controller {
    connect() {
    }

    findNomDriver(e){
        let all_drivers_url = this.data.get("find_all_drivers_url");
        let drivers_agency_url = this.data.get("find_drivers_agency");
        $.blockUI({ message:false });
       if($('#all_drivers').is(":checked") === true) {
           // Buscar todos los choferes del pais
           jQuery.ajax({
               async: true,
               evalScripts: true,
               type: "POST",
               contentType: "application/x-www-form-urlencoded",
               url: all_drivers_url,
               success: function (res) {
                   $.unblockUI();
                   $('.nom_driver').empty();
                   $('.nom_driver').append('<option selected="selected" value="">Seleccione (Choferes del país).</option>')
                   $.each(res.drivers, function (index, driver) {
                       $('.nom_driver').append(
                           $("<option>")
                               .text(driver.name_for_racking)
                               .attr("value", driver.id)
                       );
                   });
               },
               error: function (jqXHR, textStatus, errorThrown) {
                   $.unblockUI();
                   ajaxErrorMessages(jqXHR, textStatus, errorThrown);
               },
           });
        }else{
           // Buscar solo los choferes de la agencia
           jQuery.ajax({
               async: true,
               evalScripts: true,
               type: "POST",
               contentType: "application/x-www-form-urlencoded",
               url: drivers_agency_url,
               success: function (res) {
                   $.unblockUI();
                   $('.nom_driver').empty();
                   $('.nom_driver').append('<option selected="selected" value="">Seleccione (Choferes de la agencia)</option>')
                   $.each(res.drivers, function (index, driver) {
                       $('.nom_driver').append(
                           $("<option>")
                               .text(driver.name_for_racking)
                               .attr("value", driver.id)
                       );
                   });
               },
               error: function (jqXHR, textStatus, errorThrown) {
                   $.unblockUI();
                   ajaxErrorMessages(jqXHR, textStatus, errorThrown);
               },
           });
        }
    }
}

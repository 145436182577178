import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
export default class extends Controller {
    connect() {
        let minDate = 0;
        let maxDate = 0;
        if(this.data.get('min') == null){
            let expireDate = new Date();
            minDate = expireDate.setFullYear(expireDate.getFullYear() - 20);
        }
        else{
            minDate = this.data.get('min');
        }

        if(this.data.get('max') == null){
            let expireDate = new Date();
            maxDate = expireDate.setFullYear(expireDate.getFullYear() + 20);
        }
        else{
            maxDate = this.data.get('max');
        }

        $('.date').datepicker({
            dateFormat: "dd/mm/yy",
            defaultDate: 1,
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showButtonPanel: true,
            yearRange: '1920:2121',
            minDate: new Date(minDate),
            maxDate: new Date(maxDate),
            onSelect: function (dateText, inst) {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            }
        });
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }

    updateDays(e) {
        let from_date = this.parseDate($('#from_date').val());
        let to_date = this.parseDate($('#to_date').val());
        let pick_up_time = $('#pick_up_time').val();
        let drop_off_time = $('#drop_off_time').val();
        let days_or_hours = 0;
        // Verificar tipo de servicio de tiempo
        if ($('#reservation_product_time_type').val() === 'hour') {
            if (this.isValidFormatTime(drop_off_time) && this.isValidFormatTime(pick_up_time)) {
                let hour_from_date = new Date(`${from_date.getFullYear()}-${from_date.getMonth()}-${from_date.getDate()} ${pick_up_time}`);
                let hour_to_date = new Date(`${to_date.getFullYear()}-${to_date.getMonth()}-${to_date.getDate()} ${drop_off_time}`);
                days_or_hours = Math.ceil((hour_to_date - hour_from_date) / (1000 * 60 * 60)); //Horas de la renta, se redondea por exceso.
                // Si la diferencia de horas da negativa o nula, alertar y solicitar actualizarla
                if(days_or_hours <= 0){
                    alert("ERROR: Tipo de servicio por hora. La fecha y hora de recogida no debe ser menor o igual que la fecha y hora de entrega. Por favor, actualice el momento de entrega.");
                    $('#drop_off_time').val('');
                    days_or_hours = 0;
                } else {
                    if(days_or_hours > $('#reservation_days').val()) {
                        alert("ERROR: Tipo de servicio por hora. La fecha y hora de recogida con respecto a la entrega no debe sobrepasar la cantidad de horas reservadas. Por favor, actualice el momento de entrega.");
                        $('#drop_off_time').val('');
                        days_or_hours = 0;
                    }
                }
            }
        } else { // en otro caso seria por dia
            days_or_hours = Math.round((to_date - from_date) / (1000 * 60 * 60 * 24))
        }
        $('#text_days').text(days_or_hours);
        $('#amounts_days').val(days_or_hours);
        // this.updateTotalImport(e);
        // SI LA RESERVA ES DE VOUCHER, ENTONCES LOS IMPORTES COBRADOS NO SE PUEDEN MODIFICAR
        // A MENOS QUE SE MODIFIQUE LA FECHA DE ENTREGA POR EL FUNCIONARIO.
        // TAREA #1005
        $('#amounts_rent_tariff_day').attr('readonly', false);
        $('#amounts_rent_import').attr('readonly', false);
        $('#amounts_liability_release_tariff_day').attr('readonly', false);
        $('#liability_release_import').attr('readonly', false);
        $('#relief_supplement_tariff').attr('readonly', false);
        $('#relief_supplement_import').attr('readonly', false);
        $('#amounts_office').attr('readonly', false);
        $('#amounts_drop_off').attr('readonly', false);
        $('#amounts_other_payments').attr('readonly', false);
    }

    /**
     * Validar expresion de tiempo con formato de hora militar 00:00
     * */
    isValidFormatTime(time) {
        const format_exp = /^[0-1][0-9]\:[0-5][0-9]|2[0-3]\:[0-5][0-9]$/;
        return format_exp.test(time);
    }

    updateTotalImport(e) {
        //ACTUALIZAR TOTAL DE IMPORTE
        let days = $('#amounts_days').val();
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let rent_type_driver_tariff = $('#rent_type_driver_tariff').val();
        //renta
        let amounts_rent_tariff_day = $('#amounts_rent_tariff_day').val();
        //let rent = $('#amounts_rent_import').val();
        let rent = 0.00;

        //seguro
        let amounts_liability_release_tariff_day = $('#amounts_liability_release_tariff_day').val();
        // let insurance = $('#liability_release_import').val();
        let insurance = 0.00;

        //suplemento de relevo
        let relief_supplement_tariff = $('#relief_supplement_tariff').val();
        //let relief_supplement = $('#relief_supplement_import').val();
        let relief_supplement = 0.00;

        //choferes
        let amounts_drivers_day = $('#amounts_drivers_day').val();
        let amounts_drivers = parseFloat($("#amounts_drivers").val()); // cantidad de choferes a cobrar
        let drivers = 0.0;

        //combustible
        let fuel_imp = $('#amounts_fuel').val();
        if (fuel_imp === '' || fuel_imp === undefined || fuel_imp === null) {
            fuel_imp = 0.0
        }

        //drop off
        let drop_off = $('#amounts_drop_off').val();
        if(drop_off === '' || drop_off === undefined || drop_off === null){
            drop_off = 0.0
        }

        //oficina
        let office = $('#amounts_office').val();
        if(office === '' || office === undefined || office === null){
            office = 0.0
        }

        // Otros
        let others = $("#amounts_other_payments").val()
        if(others === '' || others === undefined || others === null){
            others = 0.0
        }

        // Calcular precio por concepto de chofer adicional segun tipo de calculo de la tarifa
        switch (tariff_calc_type) {
            case 'by_full_range':
                rent = parseFloat(amounts_rent_tariff_day);
                insurance = parseFloat(amounts_liability_release_tariff_day);
                relief_supplement = parseFloat(relief_supplement_tariff);
                fuel_imp = 0.00;
                if (amounts_drivers > 0) {
                    drivers = amounts_drivers_day * amounts_drivers
                }
                break;
            default:
                rent = amounts_rent_tariff_day * days;
                insurance = amounts_liability_release_tariff_day * days;
                relief_supplement = relief_supplement_tariff * days;
                // Verificar si configuración del tipo de renta es por rango, cuando no viene la tarifa en la reserva
                if (amounts_drivers > 0)
                    if (rent_type_driver_tariff === 'by_range')
                        drivers = amounts_drivers_day * amounts_drivers
                    else
                        drivers = amounts_drivers_day * days * amounts_drivers
                break;
        }
        if ( rent === undefined || rent === null)
            rent = 0.00;
        if ( insurance === undefined || insurance === null)
            insurance = 0.00;
        if ( relief_supplement === undefined || relief_supplement === null)
            relief_supplement = 0.00

        // Asignar los valores
        $('#amounts_rent_import').val(rent.toFixed(2))
        $('#liability_release_import').val(insurance);
        $('#relief_supplement_import').val(relief_supplement.toFixed(2));
        $("#drivers_import").val(drivers);

        let total_im = parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent) + parseFloat(drivers) + parseFloat(others) + parseFloat(drop_off) + parseFloat(relief_supplement)
        $("#rent_import").text(total_im);
        $("#total_rent_import").val(total_im);
        let rent_charge = parseFloat(total_im) - parseFloat($("#span_reservation_price").val());

        if (rent_charge > 0){
            $("#rent_charge").text(rent_charge.toFixed(2));
            $("#rent_credit").text(0.00);
            $("#total_credit").val(0.00 );
            $("#moment_card").val(rent_charge.toFixed(2));
        }else{
            $("#rent_charge").text(0.00);
            $("#rent_credit").text(rent_charge.toFixed(2) * -1 );
            $("#total_credit").val(rent_charge.toFixed(2) * -1 );
            $("#moment_card").val(0.00);
        }
    }

    // new Date("dateString") is browser-dependent and discouraged, so we'll write
    // a simple parse function for U.S. date format (which does no error checking)
    parseDate(str) {
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[1] - 1 , mdy[0] );
    }

}
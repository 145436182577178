import {Controller} from "stimulus"
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

export default class extends Controller {
    connect() {
        $('#findRentsBtn').on('click', () => {
            let operation = $('#source_type');
            let reference = $('#reference');

            if (operation[0].reportValidity() && reference[0].reportValidity()) {
                let url = operation.get(0).dataset.url;
                this.findRents(operation, reference, url);
            }
        });
    }

    findRents(operation, reference, url) {
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        $.blockUI({message: false});
        $.ajax({
            url: url,
            method: 'post',
            data: {
                source_type: operation.val(),
                reference: reference.val()
            },
            headers: {
                'X-CSRF-Token': csrf
            },
            success: (result) => {
                $.unblockUI();
                this.createTable(result.rent_info, result.rents, result.hire_points);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        });
    }

    getPaymentForms(url) {
        let operation = $('#source_type');
        let csrf = document.querySelector('meta[name="csrf-token"]').content;
        $.ajax({
            url: url,
            method: 'post',
            data: {
                source_type: operation.val()
            },
            headers: {
                'X-CSRF-Token': csrf
            },
            success: (result) => {
                let payment_form = $('#payment_form_code');
                payment_form.empty();
                payment_form.append('<option selected="selected" value="">Seleccione forma de pago</option>');
                if (result !== undefined) {
                    $.each(result.payment_form, function (index, form) {
                        payment_form.append(
                            $("<option>")
                                .text(form.name)
                                .attr("value", form.code)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        });
    }

    createTable(rent_info, rents, hire_points) {
        let tableContainer = $('#table-container');

        tableContainer.removeClass('d-none');

        if (rents.length) {
            tableContainer.get(0).innerHTML = `<div class="hr-text text-blue">Operaciones</div>`;

            let table = document.createElement('table');
            tableContainer.get(0).appendChild(table);

            table.className = "table table-hover table-striped";

            let thead = table.createTHead();
            thead.className = "thead-light";

            let headRow = thead.insertRow();
            headRow.innerHTML = `<th scope="col"></th>
                                 <th scope="col">Fecha y hora</th>
                                 <th scope="col">Punto de Renta</th>
                                 <th scope="col">Matrícula</th>
                                 <th scope="col" class="text-center">Indicativo</th>
                                 <th scope="col">Funcionario</th>
                                 <th scope="col" class="text-right">Operaciones</th>`;

            let tbody = table.createTBody();
            rents.forEach(rent => {
                let row = tbody.insertRow();
                if (!hire_points.includes(rent.hire_point.id)) {
                    row.title = "Esta operación de renta no se realizó en uno de los puntos de renta de la agencia.";
                }
                row.innerHTML = `<td>
                                   <input 
                                     type="radio" 
                                     name="rent" 
                                     id="op_${rent.id}" 
                                     ${rents.length === 1 ? 'checked' : ''} 
                                     value="${rent.id}" 
                                     ${!hire_points.includes(rent.hire_point.id) ? 'disabled' : ''}
                                     required>
                                 </td>
                                 <td>${rent.date}</td>
                                 <td>${rent.hire_point.name || ""}</td>
                                 <td>${rent.enrollment || ""}</td>
                                 <td class="text-center">${rent.indicative || ""}</td>
                                 <td>${rent.user || ""}</td>
                                 <td title="Exportar contrato" class="text-right">
                                   <a href="${rent.contract_url}" target="_blank"><i class="fa fa-download"></i></a>
                                 </td>`;
            });

            let infoContainer = document.createElement('div');
            infoContainer.className = 'row mb-3';
            infoContainer.innerHTML = `<div class="col-lg-3 col-md-6 mb-3 pr-md-3 pr-sm-0 pl-0">
                                         <label for="name" class="form-label">Nombre:</label>
                                         <input type="text" disabled value="${rent_info.name}" class="form-control client_name" id="rent_name">
                                       </div>
                                       <div class="col-lg-3 col-md-6 mb-3 pr-lg-3 pr-sm-0 pr-md-0 pl-0">
                                         <label for="last_name" class="form-label">Apellidos:</label>
                                         <input type="text" disabled value="${rent_info.last_name}" class="form-control client_surname" id="surname">
                                       </div>
                                       <div class="col-lg-3 col-md-6 mb-3 pr-md-3 pr-sm-0 pl-md-0 pl-0">
                                         <label for="passport" class="form-label">Pasaporte/C.I:</label>
                                         <input type="text" disabled value="${rent_info.passport}" class="form-control client_passport" id="passport">
                                       </div>
                                       <div class="col-lg-3 col-md-6 mb-3 pr-md-0 pr-sm-0 pl-0">
                                         <label for="email" class="form-label">Correo:</label>
                                         <input type="text" disabled value="${rent_info.email}" class="form-control client_email" id="email">
                                       </div>`;
            tableContainer.get(0).insertBefore(infoContainer, table);

            $('#btn-build_modality').prop('disabled', false);
        } else {
            tableContainer.get(0).innerHTML = `<div class="alert alert-warning" role="alert">
                                                 No se encontraron operaciones.
                                               </div>`;
        }
    }
}
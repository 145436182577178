import {Controller} from "stimulus";
import $ from "jquery";
import {atob} from "buffer";
import rentType_controller from "./rentType_controller";
import {checkAdvancePaymentAtMoment} from "./payment_controller";
import {myToFixed, toastr_message} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

function isEmpty(value) {
    return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
}
function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intente nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intente nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}

export let updatePriceSer = (data) => {
    // Renta
    $('#ser_rent_tariff').text(data.ser_rent_tariff);
    $('#ser_rent_import').text(data.ser_rent_import);
    // Relevo
    $('#ser_release_tariff').text(data.ser_release_tariff);
    $('#ser_release_import').text(data.ser_release_import);
    // Suplemento
    $('#ser_relief_tariff').text(data.ser_relief_tariff);
    $('#ser_relief_import').text(data.ser_relief_import);
    // Choferes Adicionales
    $('#ser_drivers_tariff').text(data.ser_drivers_tariff);
    $('#ser_drivers_import').text(data.ser_drivers_import);
    // Oficina, Drop-Off, Combustible
    $('#ser_office_import').text(data.ser_office_import);
    $('#ser_drop_off_import').text(data.ser_drop_off_import);
    //NO setear el precio del combustible, si no lo requiere
    if($.isNumeric(data.ser_fuel_import)){
        $('#ser_fuel_import').text(data.ser_fuel_import);
    }
}

export let updatePriceCome2e = (data) => {
    // Renta
    $('#come2e_rent_tariff').text(data.payment_rent_tariff);
    $('#come2e_rent_import').text(data.payment_rent);
    // Relevo
    $('#come2e_release_tariff').text(data.payment_liability_release_tariff);
    $('#come2e_release_import').text(data.payment_liability_release);
    // Suplemento
    $('#come2e_relief_tariff').text(data.payment_relief_supplement_tariff);
    $('#come2e_relief_import').text(data.payment_relief_supplement);
    // Choferes Adicionales
    $('#come2e_drivers_tariff').text(data.payment_additional_driver_tariff);
    $('#come2e_drivers_import').text(data.payment_additional_driver);
    // Combustible, Oficina, Drop-Off
    $('#come2e_fuel_import').text(data.payment_initial_fuel);
    $('#come2e_office_import').text(data.payment_office_supplement);
    $('#come2e_drop_off_import').text(data.payment_drop_off);
}

export let alert_additional_driver = () => {
    // Quitar la alerta de driver de la reserva si se llega al importe
    let reservation_driver_collect = parseFloat($('#reservation_driver_collect').val());
    let driver_import = 0;
    if ($.isNumeric($('#drivers_import').val())){
        driver_import = parseFloat($('#drivers_import').val());
    }
    if (reservation_driver_collect <= driver_import)
        $('#alert_additional_driver').hide();
    else
        $('#alert_additional_driver').show();

    // $(`#drivers_import`).trigger('click');
}

function alert_amounts_fuel(){
    let res_payment_initial_fuel = parseFloat($('#reservation_fuel_collected').val());
    let amounts_fuel = 0;
    if ($.isNumeric($('#amounts_fuel').val())){
        amounts_fuel = parseFloat($('#amounts_fuel').val());
    }
    if (res_payment_initial_fuel > amounts_fuel) {
        $('#alert_initial_fuel').show();
    } else {
        $('#alert_initial_fuel').hide();
    }
}

function eventUpdatePaymentsFormReserve(){
    let moment_card = $('#moment_card');
    let card = $('#card');
    let cash = $('#cash');
    let voucher = $('#voucher');
    let cheque = $('#cheque');
    let other = $('#other');
    let release_money = $('#release_money');
    let release_money_sum = 0;
    let moment_card_val = parseFloat(moment_card.val());
    let card_val = parseFloat(card.val());
    let cash_val = parseFloat(cash.val());
    let voucher_val = parseFloat(voucher.val());
    let cheque_val = parseFloat(cheque.val());
    let other_val = parseFloat(other.val());

    if(moment_card.attr("disabled") && moment_card.val() > 0){
        release_money_sum = release_money_sum + moment_card_val;
        moment_card.val(0.0);
    }
    if(card.attr("disabled") && card.val() > 0){
        release_money_sum = release_money_sum + card_val;
        card.val(0.0);
    }
    if(cash.attr("disabled") && cash.val() > 0){
        release_money_sum = release_money_sum + cash_val;
        cash.val(0.0);
    }
    if(voucher.attr("disabled") && voucher.val() > 0){
        release_money_sum = release_money_sum + voucher_val;
        voucher.val(0.0);
    }
    if(cheque.attr("disabled") && cheque.val() > 0){
        release_money_sum = release_money_sum + cheque_val;
        cheque.val(0.0);
    }
    if(other.attr("disabled") && other.val() > 0){
        release_money_sum = release_money_sum + other_val;
        other.val(0.0);
    }


    moment_card.attr("data-value",moment_card_val);
    card.attr("data-value",card_val);
    cash.attr("data-value",cash_val);
    voucher.attr("data-value",voucher_val);
    cheque.attr("data-value",cheque_val);
    other.attr("data-value",other_val);

    release_money.val(parseFloat(release_money_sum));
    release_money.attr("data-value",release_money.val());


};

function updateMostUsePaysInputs(){
    let moment_card = $('#moment_card');
    let card = $('#card');
    let cash = $('#cash');
    let voucher = $('#voucher');
    let cheque = $('#cheque');
    let other = $('#other');
    let release_money = $('#release_money');
    let release_money_sum = 0;
    let moment_card_changing_value = 0;
    let moment_card_val = parseFloat(moment_card.attr("data-value"));
    let card_val = parseFloat(card.attr("data-value"));
    let cash_val = parseFloat(cash.attr("data-value"));
    let voucher_val = parseFloat(voucher.attr("data-value"));
    let cheque_val = parseFloat(cheque.attr("data-value"));
    let other_val = parseFloat(other.attr("data-value"));

    if(moment_card.attr("disabled") && moment_card.val() > 0){
        release_money_sum = release_money_sum + parseFloat(moment_card.val());
        moment_card.val(0.00);
    }else if(!moment_card.attr("disabled") && moment_card.val() > 0){
        moment_card_changing_value = moment_card_changing_value + parseFloat(moment_card.val());
    }
    if(card.attr("disabled") && card_val > 0){
        release_money_sum = release_money_sum + card_val;
        if(card.val() > 0)
            card.val(0.00);
    }
    if(cash.attr("disabled") && cash_val > 0){
        release_money_sum = release_money_sum + cash_val;
        if(cash.val() > 0)
            cash.val(0.00);
    }
    if(voucher.attr("disabled") && voucher_val > 0){
        release_money_sum = release_money_sum + voucher_val;
        if(voucher.val() > 0)
            voucher.val(0.00);
    }
    if(cheque.attr("disabled") && cheque_val > 0){
        release_money_sum = release_money_sum + cheque_val;
        if(cheque.val() > 0)
            cheque.val(0.00);
    }
    if(other.attr("disabled") && other_val > 0){
        release_money_sum = release_money_sum + other_val;
        if(other.val() > 0)
            other.val(0.0);
    }
    let difference =parseFloat(moment_card.val()) - parseFloat(moment_card.attr("data-value"));

    if((parseFloat(moment_card.val()) +
        parseFloat(card.val()) +
        parseFloat(cash.val()) +
        parseFloat(voucher.val()) +
        parseFloat(cheque.val()) +
        parseFloat(other.val())) == (moment_card_val + card_val + cash_val + voucher_val + cheque_val + other_val) + difference){
        release_money.val(0.00);
        // release_money.attr("data-value",0.00);
    }else if((parseFloat(moment_card.val()) +
        parseFloat(card.val()) +
        parseFloat(cash.val()) +
        parseFloat(voucher.val()) +
        parseFloat(cheque.val()) +
        parseFloat(other.val())) + parseFloat(release_money.val()) == (moment_card_val + card_val + cash_val + voucher_val + cheque_val + other_val) + difference){
        release_money.val(parseFloat(release_money.val()));
    }else{
        release_money.val(parseFloat(release_money_sum));
        release_money.attr("data-value",parseFloat(release_money.val()));
    }


}

export let checkNextMtto = () =>{
    let next_mtto = $('#next_maintenance');
    let next_mtto_val = next_mtto.val();
    let vehicle_mileage = next_mtto.attr('min');

    if (next_mtto.prop('validity').valid) {
        let difference = next_mtto_val - vehicle_mileage;
        if (difference <= 300) {
            next_mtto.val(0);
            toastr_message('error', `El mantenimiento establecido con respecto al kilometraje del vehículo tiene una diferencia de ${difference}km, el mínimo permitido es de 300 km`, 'Próximo mantenimiento');
        } else if (difference < 1000) {
            toastr_message('warning', `El próximo mantenimiento establecido con respecto al kilometraje del vehículo tiene una diferencia de ${difference}km`, 'PRECAUCIÓN');
        }
    }
}


/**
 * CHEQUEAR DEUDAS DEL CLIENTE.
 * */
export let checkClientDebitBalance = (e) => {
    $.blockUI({ message:false });
    jQuery.ajax({
        async: true,
        evalScripts: true,
        type: "POST",
        contentType: "application/x-www-form-urlencoded",
        url: '/hire_point/check_client_debit',
        data: {passport: $('#passport').val()},
        success: function (res){
            $.unblockUI();
            if(res.status === 'ERROR'){
                toastr_message('error', res.msg,'Cliente con deuda');
                $('#passport').val('');
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            $.unblockUI();
            ajaxErrorMessages(jqXHR, textStatus, errorThrown);
        },
    });
}

export default class extends Controller {
    connect() {
        // Para evitar que escriban en los campos de fechas
        // $('#birth_date').keydown(function (e){
        //     e.preventDefault();
        // });
        $('#license_date').keydown(function (e){
            e.preventDefault();
        });
        $('#due_date').keydown(function (e){
            e.preventDefault();
        });

        $('#cash').attr('disabled','disabled');
        $('#card').attr('disabled','disabled');
        $('#moment_card').attr('disabled','disabled');
        $('#cheque').attr('disabled','disabled');
        $('#other').attr('disabled','disabled');
        $('#voucher').attr('disabled','disabled');
    }

    checkVehicleMaxMileage(){
        let nextMaintenance = $('#next_maintenance').val();
        let max_mileage = this.data.get("maxMileage");
        let vehicle_maintenance = this.data.get("maintenance");
        // let vehicle_mileage = this.data.get("vehicleMileage");
        let msg = "Ha excedido el kilometraje máximo permitido para este vehículo."
        let html = '';

        if(nextMaintenance > max_mileage){
            html = `<div class='alert alert-danger' role="alert">${msg}<button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button></div>`;
            document.getElementById('this_alert').innerHTML = html;
            $('#next_maintenance').val(vehicle_maintenance)
        }
    }

    /**
     * FUNCIÓN QUE REDONDEA UN FLOAT CON LA PRESICIÓN DESPUÉS DE LA COMA
     * @param num
     * @param precision
     * @returns {string}
     */
    myToFixed(num, precision ) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

    /**
     *  FUNCIÓN PARA HABILITAR O NO EL BOTÓN DE BUSQUEDA DE LA RESERVA PARA HACER LA RENTA.
     * @param e
     */
    showSearchButton(e){
        // console.log('------------------------- showSearchButton')
        let type = $('#nom_rent_type_id option').filter(':selected').val()
        if((type != '' && type != null && type != undefined) && $('#references').val().length > 5){
            $("#btn-search").removeClass('disabled');
        }else{
            $("#btn-search").addClass('disabled');
        }
    }

    /**
     * FUNCIÓN DEPRECADA
     * TODO ELIMINAR Y PROBAR EN PRÓXIMAS VERSIONES
     * @param e
     */
    showSearchClientButton(e){
        if($('#passport').val().trim().length >= 3){
            $("#btn-search").removeClass('disabled');
        }else{
            $("#btn-search").addClass('disabled');
        }
    }

    /**
     * @param e
     * FUNCIÓN DEPRECADA
     * TODO ELIMINAR Y PROBAR EN PRÓXIMAS VERSIONES
     */
    // toggleFormNewClient(e) {
    //     e.preventDefault()
    //     $(".new_client").fadeToggle("slow", "linear");
    //     $(".new_client").toggleClass("showed");
    //     if ($('.new_client').hasClass('showed')) {
    //         $("#nom_client_id").prop('disabled', true);
    //         $(".new_client").find(':input').prop('disabled', false);
    //         $('.new_client_button').text('Ocultar formulario de nuevo cliente')
    //         //$("#btn-back").removeClass('ml-auto');
    //     } else {
    //         $("#nom_client_id").prop('disabled', false);
    //         $('.new_client_button').text('Agregar nuevo cliente');
    //         $(".new_client").find(':input').prop('disabled', true);
    //         //$("#btn-back").addClass('ml-auto');
    //         // todo Limpiar los inputs del formulario
    //     }
    // }

    /**
     * FUNCIÓN QUE HACE EL TOOGLE DE LOS CHÓFERES ADICIONALES,
     * SÓLO MUESTRA O NO LA SECCIÓN, PARA HACER LA PÁGINA MÁS CORTA.
     * @param e
     */
    toogleDrivers(e){
        let container = this.data.get("container");
        if($('#drivers_switch').is(":checked") === true) {
            $("#additional_drivers_body").show();
        }else{
            let arr = $('#drivers_switch').val().split(';');  //let uniqueArr = [...new Set(arr)]
            for (let i = 0; i < arr.length; i++) {
                if ($('#hs_' + arr[i]).is(":checked") === true) {
                    if (!this.isAdditionalDriverValid(arr[i])) {
                        $('#drivers_switch').prop('checked', true);
                        return;
                    }
                }
            }
            $("#additional_drivers_body").hide();
        }
    }

    /**
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param e
     */
    toogleSingelDriver(e) {
        let container = this.data.get("container");
        if (container !== undefined || container !== null) {
            if ($('#hs_' + container).is(":checked") === true) {
                $("#additional_drivers_body_" + container).show();
                $('#drivers_switch').val($('#drivers_switch').val() + ';' + container);
            } else {
                if (!this.isAdditionalDriverValid(container)) {
                    $('#hs_' + container).prop('checked', true);
                    return;
                }
                $("#additional_drivers_body_" + container).hide();
            }
        }
    }

    /**
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param container
     * @returns {boolean}
     */
    isAdditionalDriverValid(container){
        let identity = $("#identity_card_"+ container).val();
        if(identity != null && identity !== "" && identity !== undefined) {
            if ($(`#name_driver_${container}`).val() === "" ||
                $(`#last_name_${container}`).val() === "" ||
                $(`#birth_date_${container}`).val() === "" ||
                $(`#driver_license_${container}`).val() === "" ||
                $(`#license_date${container}`).val() === "" ||
                $(`#country_driver_${container}`).val() === "") {
                alert("Existen campos del chofer adicional #" + container + " requeridos. Si ya no desea agregar al chofer, por favor indíquelo limpiando el campo identidad.");
                return false;
            }
        }
        return true;
    }

    /**
     * ACTUALIZA LOS DATOS DE UN CLIENTE.
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param e
     */
    getClientData(e) {
        const value = $("#passport").val();
        let url = this.data.get("url");

        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: value},
            success: function (res) {
                if (res !== undefined && res.client !== null) {
                    let client = res.client;
                    $('#code').val(client.code)
                    $('#name').val(client.name)
                    $('#first_name').val(client.last_name)
                    $('#last_name').val(client.last_name)
                    $('#address').val(client.address)
                    $('#passport').val(client.passport)
                    $('#phone').val(client.phone)
                    $('#driver_license').val(client.driver_license)
                    $('#observation').val(client.observation)
                    $('#customer_id').val(client.customer_id)
                    $('#category').val(client.category)
                    $('#user').val(client.user)
                    $('#business_id').val(client.business_id)
                    $('#nom_country_id').val(client.nom_country_id).trigger('change');
                    $('#nationality').val(client.nationality_id).trigger('change');
                    $('#authorized').val(client.authorized)
                    $('#distinguished').val(client.distinguished)

                    if(res.client_due_date !== null ){
                        $('#due_date').val(res.client_due_date);
                    }
                    if(res.client_birth_date !== null ){
                        $('#birth_date').val(res.client_birth_date);
                    }
                    if(res.client_license_date !== null ){
                        $('#license_date').val(res.client_license_date);
                    }
                    $('#client_permanent').prop('checked', client.permanent);
                    let reservation_to_date = $('#to_date').val();
                    if (client.permanent){
                        $('#due_date').prop('disabled', true);
                        let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));// para tener el valor de la suma de todas las formas de pago del formulario
                        let release_money_default_value = parseFloat($('#release_money').attr("data-value"));
                        if((release_money_default_value - inputs_sum) == 0 || $('#release_money').val() == 0)
                            $('#save_rent').removeClass('disabled');
                    } else if (res.client_due_date === null) {
                        alert('ERROR: No se encontró registrada la fecha de vencimiento de la licencia del cliente, por favor, actualice el campo para activar el botón del contrato.');
                        $('#save_rent').addClass('disabled');
                    } else if (reservation_to_date !== null) {
                        let ac_due_date = res.client_due_date.split('/');
                        let c_due_date = new Date(ac_due_date[1] + '/' + ac_due_date[0] + '/' + ac_due_date[2]);
                        let ar_to_date = reservation_to_date.split('/');
                        let r_to_date = new Date(ar_to_date[1] + '/' + ar_to_date[0] + '/' + ar_to_date[2]);
                        if (c_due_date <= r_to_date) {
                            alert('ERROR: La fecha de la licencia del cliente registrado (' + res.client_due_date + ') está vencida o se encuentra dentro del período que dura la renta. Por favor, actualice el campo para activar el botón del contrato.');
                            $('#save_rent').addClass('disabled');
                        } else {
                            let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));// para tener el valor de la suma de todas las formas de pago del formulario
                            let release_money_default_value = parseFloat($('#release_money').attr("data-value"));
                            if((release_money_default_value - inputs_sum) == 0 || $('#release_money').val() == 0)
                                $('#save_rent').removeClass('disabled');
                            $('#due_date').val(res.client_due_date);
                        }
                    }

                    $('#technical_sheet').text('Cliente registrado');
                    // $("#destiny_group").empty();
                    //
                    // $.each(res.hire_points, function (index, hire_point) {
                    //     $("#destiny_group").append(
                    //         $("<option>")
                    //             .text(hire_point.name)
                    //             .attr("value", hire_point.id)
                    //     );
                    // });
                } else {
                    $('#technical_sheet').text('Nuevo cliente');
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * FUNCIÓN QUE PINTA LOS CHÓFERES ADICIONALES SEGÚN LA CONFUGRACIÓN DEL TIPO DE RENTA
     * @param e
     */
    showAdditionalDrivers(e) {
        // console.log("------------------------------ showAdditionalDrivers ");
        let length; let min_age_to_rent; let max_age_to_rent; let years_experience; let drivers_to_charges;
        $('#nom_rent_type_id option').each(function (el) {
            if ($(this).val() === e.target.value) {
                length = $(this).attr('number_additional_driver');
                min_age_to_rent = $(this).attr('min_age_to_rent');
                max_age_to_rent = $(this).attr('max_age_to_rent');
                years_experience = $(this).attr('years_experience');
                drivers_to_charges = $(this).attr('drivers_to_charges');
            }
        })
        let free_drivers = parseInt(length) - parseInt(drivers_to_charges)
        $("#text_free_drivers").text(free_drivers > 0 ? free_drivers : 0);

        let maxDate = new Date(); let minDate = new Date(); let yearsDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - min_age_to_rent);
        if (max_age_to_rent === 0) { //Sin restriccion pero no mas de un siglo limite establecido 1920 en el controller date
            minDate.setFullYear(minDate.getFullYear() - 100);
        } else {
            minDate.setFullYear(minDate.getFullYear() - max_age_to_rent);
            //Cambiando opciones de la fecha de nacimiento del cliente
            $('#birth_date').datepicker('option', { minDate: new Date(minDate),
                maxDate: new Date(maxDate) });
            //$('#birth_date').datepicker('setDate', new Date(maxDate));
        }
        //Cambiando maximo permitido seleccionar para la fecha de emision de la licencia
        yearsDate.setFullYear(yearsDate.getFullYear() - years_experience)
        $('#license_date').datepicker('option', 'maxDate', new Date(yearsDate));
        //$('#license_date').datepicker('setDate', new Date(yearsDate));

        const rentTypeRef = $('.rent_type')
        rentTypeRef.empty();
        if (e.target.value) {
            $('.additional_drivers_section').fadeIn("slow")
            // const drivers = JSON.parse(this.data.get("drivers"))
            const countries = JSON.parse(this.data.get("countries"))
            // const length = $(this).attr('number_additional_driver');
            for (let i = 1; i <= length; i++) {
                if(i === 1){
                    rentTypeRef.append(`
                    <div class="row form-group ${i < length ? 'mb-3' : ''} driver_${i}_container">
                        <!--<div class="col-auto">
                             <select id="driver_${i}" name="driver[${i}][id]" class="c2ec_select form-select"></select>
                        </div> -->
                        <div class="form-group mt-3 mb-3 row driver_${i}_form">
                            <div class="col">
                                <div style="position: relative;">
                                    <div class="fieldset-header">
                                        <h4 id="technical_sheet">
                                            Chofer adicional ${i}
                                            <label class="form-check ml-1" style="float: right;" data-controller='rent' data-rent-container="${i}">
                                              <input class="form-check-input" type="checkbox" checked="" id="hs_${i}" data-action="click->rent#toogleSingelDriver">
                                            </label>
                                        </h4>
                                    </div>
                                    <div class="fieldset-body-extend p-4" id="additional_drivers_body_${i}">
                                        <div class="row">
                                            <!-- Identidad -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Identidad</label>
                                              <div class="input-group mb-2" data-controller="rent" 
                                                                            data-rent-additional_driver_container="${i}">
                                                <input class="form-control identity_driver" placeholder="Identidad" 
                                                id="identity_card_${i}" name="driver[${i}][identity_card]" autocomplete="off"
                                                 maxlength="20" pattern="^[a-zA-Z0-9]{3,15}$" title="Carnet de indentidad o pasaporte no válido" 
                                                 data-action="change->rent#getAdditionalDriverData"/>
                                                <button class="btn btn-white" type="button" id="sip_${i}">S.I.P</button>
                                              </div>
                                            </div>
                                            <!-- Name -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Nombre</label>
                                                <input class="form-control" id="name_driver_${i}" name="driver[${i}][name]" placeholder="Nombre" autocomplete="off" maxlength="50" />
                                            </div>
                                            <!-- Apellido -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Apellidos</label>
                                                <input class="form-control" placeholder="Apellidos" id="last_name_${i}" name="driver[${i}][last_name]" autocomplete="off" maxlength="50" />
                                            </div>
                                           <!-- Fecha de nacimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de nacimiento
                                                     <span class="form-check-description">
                                                        (Edad: <span id="driver_age_${i}"></span>)
                                                     </span>
                                                </label>
                                                <div data-controller='inputmask nomClient' 
                                                     data-nomClient-valid_age_url="/hire_point/validate_client_age"
                                                     data-nomClient-span_age_id="driver_age_${i}">
                                                    <input id="birth_date_${i}" 
                                                        data-action="blur->nomClient#validAgeToRent"
                                                        name="driver[${i}][birth_date]" 
                                                        class="form-control dmY_date"
                                                        placeholder="01/01/2000"
                                                        data-mask="01/01/2000"
                                                        pattern='[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]'
                                                        title='Formato de Fecha, ej: 01/01/2000, 17/05/1984'
                                                        autocomplete="off"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Telefono -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label">Teléfono</label>
                                                <input class="form-control" placeholder="Telefono" id="phone_${i}" name="driver[${i}][phone]" autocomplete="off" maxlength="20" />
                                            </div>
                                            <!-- Licencia -->
                                            <div class="col-md-3 col-sm-12 col-xs-12" data-controller='addDrivers'>
                                              <label class="form-label required">Licencia</label>
                                                <input class="form-control" placeholder="Licencia" id="driver_license_${i}" name="driver[${i}][license]" autocomplete="off" maxlength="20"/>
                                                <label class="form-check form-check-inline" style="margin-right: 0px;">
                                                    <input class="form-check-input" type="checkbox" value="0" name="driver[${i}][permanent]" id="driver_permanent_${i}" 
                                                           data-action="change->addDrivers#licencePermanent" data-container="${i}">
                                                    <span class="form-check-label">¿Permanente?</span>
                                                </label>
                                            </div>
                                            <!-- Fecha de vencimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de vencimiento</label>
                                                <div data-controller='date addDrivers'>
                                                    <input id="due_date_${i}"
                                                        data-container="${i}"
                                                        name="driver[${i}][due_date]" 
                                                        class="form-control date" 
                                                        autocomplete="off" 
                                                        readonly="true"
                                                        data-action="change->addDrivers#checkDueDate"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required"'>
                                                Fecha de emisión
                                              </label>
                                              <div data-controller='date rent'>
                                                <input id="license_date${i}"
                                                    name="driver[${i}][license_date]" 
                                                    class="form-control license_date_datepicker date" 
                                                    autocomplete="off" 
                                                    readonly="true" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                        <!-- Email -->
                                        <div class="col-md-3 col-sm-12 col-xs-12">
                                            <label class="form-label">Correo eléctronico</label>
                                            <input id="email_driver_${i}" name="driver[${i}][email]" autocomplete="off" class="form-control"/>
                                        </div>
                                         <!-- Pais -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Nacionalidad</label>
                                                <select id="country_driver_${i}" name="driver[${i}][country_driver_id]" class="c2ec_select form-select nationalities"></select>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12">
                                                <label class="form-label">Dirección</label>
                                                <input class="form-control" placeholder="Dirección" id="address_${i}" autocomplete="off" name="driver[${i}][address]" maxlength="200" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                        <!--<div id="add_additional_driver_${i}" class="col-auto text-right" data-controller="rent" data-rent-container="${i}">
                            <button class="btn btn-outline-primary" data-action="click->rent#addDriver">
                                Agregar Chofer
                            </button>
                        </div> -->
                    </div>
                `);
                }else{
                    rentTypeRef.append(`
                    <div class="row form-group ${i < length ? 'mb-3' : ''} driver_${i}_container">
                        <!--<div class="col-auto">
                             <select id="driver_${i}" name="driver[${i}][id]" class="c2ec_select form-select"></select>
                        </div> -->
                        <div class="form-group mt-3 mb-3 row driver_${i}_form">
                            <div class="col">
                                <div style="position: relative;">
                                    <div class="fieldset-header">
                                        <h4 id="technical_sheet">
                                            Chofer adicional ${i}
                                            <label class="form-check ml-1" style="float: right;" data-controller='rent' data-rent-container="${i}">
                                              <input class="form-check-input" type="checkbox" id="hs_${i}" data-action="click->rent#toogleSingelDriver">
                                            </label>
                                        </h4>
                                    </div>
                                    <div class="fieldset-body-extend p-4" id="additional_drivers_body_${i}" style="display: none;">
                                        <div class="row">
                                            <!-- Identidad -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Identidad</label>
                                              <div class="input-group mb-2" data-controller="rent" 
                                                                            data-rent-additional_driver_container="${i}">
                                                <input class="form-control identity_driver" placeholder="Identidad" id="identity_card_${i}" 
                                                       autocomplete="off" name="driver[${i}][identity_card]" maxlength="20" pattern="^[a-zA-Z0-9]{3,15}$" 
                                                       title="Carnet de indentidad o pasaporte no válidos" data-action="change->rent#getAdditionalDriverData"/>
                                                <button class="btn btn-white" type="button" id="sip_${i}">S.I.P</button>
                                              </div>
                                            </div>
                                            <!-- Name -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Nombre</label>
                                                <input class="form-control" id="name_driver_${i}" name="driver[${i}][name]" placeholder="Nombre" maxlength="50" />
                                            </div>
                                            <!-- Apellido -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Apellidos</label>
                                                <input class="form-control" placeholder="Apellidos" id="last_name_${i}" name="driver[${i}][last_name]" maxlength="50" />
                                            </div>
                                           <!-- Fecha de nacimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de nacimiento
                                                <span class="form-check-description">
                                                    (Edad: <span id="driver_age_${i}"></span>)
                                                </span>
                                                </label>
                                                <div data-controller='inputmask nomClient'
                                                           data-nomClient-valid_age_url="/hire_point/validate_client_age"
                                                           data-nomClient-span_age_id="driver_age_${i}">
                                                    <input id="birth_date_${i}" 
                                                        data-action="blur->nomClient#validAgeToRent"
                                                        name="driver[${i}][birth_date]" 
                                                        class="form-control dmY_date"
                                                        placeholder="01/01/2000"
                                                        data-mask="01/01/2000"
                                                        pattern='[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]'
                                                        title='Formato de Fecha, ej: 01/01/2000, 17/05/1984'
                                                        autocomplete="off"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Telefono -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label">Teléfono</label>
                                                <input class="form-control" placeholder="Telefono" id="phone_${i}" autocomplete="off" name="driver[${i}][phone]" maxlength="20" />
                                            </div>
                                            <!-- Licencia -->
                                            <div class="col-md-3 col-sm-12 col-xs-12" data-controller='addDrivers'>
                                              <label class="form-label required">Licencia</label>
                                                <input class="form-control" placeholder="Licencia" id="driver_license_${i}" name="driver[${i}][license]" autocomplete="off" maxlength="20"/>
                                                <label class="form-check form-check-inline" style="margin-right: 0px;">
                                                    <input class="form-check-input" type="checkbox" value="0" name="driver[${i}][permanent]" id="driver_permanent_${i}"
                                                    data-action="change->addDrivers#licencePermanent" data-container="${i}">
                                                    <span class="form-check-label">¿Permanente?</span>
                                                </label>
                                            </div>
                                            <!-- Fecha de vencimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de vencimiento</label>
                                                <div data-controller='date addDrivers'>
                                                    <input id="due_date_${i}"
                                                           data-container="${i}"
                                                           name="driver[${i}][due_date]"
                                                           class="form-control date"
                                                           autocomplete="off"
                                                           readonly="true"
                                                           data-action="change->addDrivers#checkDueDate"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required"'>
                                                Fecha de emisión
                                              </label>
                                              <div data-controller='date'>
                                                <input id="license_date${i}"
                                                    name="driver[${i}][license_date]" 
                                                    class="form-control license_date_datepicker date" 
                                                    autocomplete="off" 
                                                    readonly="true" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                         <!-- Pais -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Nacionalidad</label>
                                                <select id="country_driver_${i}" name="driver[${i}][country_driver_id]" class="c2ec_select form-select nationalities"></select>
                                            </div>
                                            <div class="col-md-9 col-sm-12 col-xs-12">
                                                <label class="form-label">Dirección</label>
                                                <input class="form-control" placeholder="Dirección" id="address_${i}" autocomplete="off" name="driver[${i}][address]" maxlength="200" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                        <!--<div id="add_additional_driver_${i}" class="col-auto text-right" data-controller="rent" data-rent-container="${i}">
                            <button class="btn btn-outline-primary" data-action="click->rent#addDriver">
                                Agregar Chofer
                            </button>
                        </div> -->
                    </div>
                `);
                }

                // const driverRef = $(`#driver_${i}`)
                // driverRef.append('<option selected="selected" value=""> -- Seleccione un chofer-- </option>')
                // for (let key of Object.keys(drivers)) {
                //     let option = new Option(drivers[key], key, false, false);
                //     driverRef.append(option)
                // }
                // driverRef.select2({
                //     theme: "bootstrap",
                //     language: "es"
                // });

                const countryRef = $(`#country_driver_${i}`)
                countryRef.append('<option selected="selected" value=""> -- Seleccione un país-- </option>')
                for (let country of Object.keys(countries)) {
                    let option = new Option(countries[country], country, false, false);
                    countryRef.append(option);
                }
                countryRef.select2({
                    theme: "bootstrap",
                    language: "es"
                });
            }
            //Validando fecha de nacimiento del chofer
            // $('.birth_date_datepicker').datepicker({
            //     dateFormat: "dd/mm/yy",
            //     changeMonth: true,
            //     changeYear: true,
            //     numberOfMonths: 1,
            //     showButtonPanel: true,
            //     yearRange: '1920:2121',
            //     maxDate: new Date()
            // });
            //$(".birth_date_datepicker").val((maxDate.getDate()) + '/' + (maxDate.getMonth() + 1) + '/' + (maxDate.getFullYear()));

            //Validando fecha de emision de la licencia del chofer
            $('.license_date_datepicker').datepicker({
                dateFormat: "dd/mm/yy",
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 1,
                showButtonPanel: true,
                yearRange: '1920:2121',
                maxDate: new Date(yearsDate)
            });
            //$(".license_date_datepicker").val((yearsDate.getDate()) + '/' + (yearsDate.getMonth() + 1) + '/' + (yearsDate.getFullYear()));
        } else {
            $('.additional_drivers_section').fadeOut("slow");
        }
    }

    /**
     * FUNCIÓN QUE SE USA EN LAS DIFERENTES OPERACIONES DE LA RENTA.
     * TODO VERIFICAR LO QEU HACE LA FUNCIÓN PARA DESCRIBIR MÁS LA MISMA.
     * @param e
     */
    addDriver(e) {
        e.preventDefault();
        let container = this.data.get("container");
        $(`#add_additional_driver_${container}`).empty().append(`
            <button class="btn btn-outline-primary" data-action="click->rent#hideForm">
                No agregar Chofer
            </button>
        `)

        // Disabled input and button add more additional driver.
        $(`#driver_${container}`).prop('disabled', true)
        $(`#driver_location_${container}`).prop('disabled', true);

        $(`.driver_${container}_container`).append(`
            <div class="form-group mt-3 mb-3 row driver_${container}_form">
                <div class="col">
                    <div style="position: relative;">
                        <div class="fieldset-header">
                            <h4 id="technical_sheet">Chofer adicional ${container}</h4>
                        </div>
                        <div class="fieldset-body-extend p-4">
                            <div class="row">
                                <!-- Identidad -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                  <label class="form-label required">Identidad</label>
                                  <div data-controller="rent" 
                                       data-rent-additional_driver_container="${container}">
                                    <input class="form-control" placeholder="Identidad" id="identity_card_${container}"  
                                           name="driver[${container}][identity_card]" required data-action="change->rent#getAdditionalDriverData"/>
                                  </div>
                                </div>
                                <!-- Name -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                  <label class="form-label required">Nombre</label>
                                    <input class="form-control" id="name_driver_${container}" name="driver[${container}][name]" required placeholder="Nombre" />
                                </div>
                                <!-- Apellido -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                    <label class="form-label required">Apellidos</label>
                                    <input class="form-control" placeholder="Apellidos" id="last_name_${container}" name="driver[${container}][last_name]" required />
                                </div>
                                <!-- Telefono -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                  <label class="form-label">Teléfono</label>
                                    <input class="form-control" placeholder="Telefono" id="phone_${container}" name="driver[${container}][phone]" />
                                </div>
                            </div>
                            <div class="row">
                                <!-- Fecha de nacimiento -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                    <label class="form-label required">Fecha de nacimiento</label>
                                    <input id="birth_date_${container}" name="driver[${container}][birth_date]" class="form-control birth_date_datepicker" required autocomplete="off" readonly="true" />
                                </div>
                                <!-- Licencia -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                  <label class="form-label required">Licencia</label>
                                    <input class="form-control" placeholder="Licencia" id="driver_license_${container}" name="driver[${container}][license]" required />
                                    <label class="form-check form-check-inline" style="margin-right: 0px;">
                                        <input class="form-check-input" type="checkbox" value="0" name="driver[${container}][permanent]" id="driver_permanent_${container}">
                                        <span class="form-check-label">¿Permanente?</span>
                                    </label>
                                </div>
                                <!-- Fecha de vencimiento -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                    <label class="form-label required">Fecha de vencimiento</label>
                                    <input id="due_date_${container}"
                                        name="driver[${container}][due_date]" 
                                        class="form-control due_datepicker" 
                                        required 
                                        autocomplete="off" 
                                        readonly="true" />
                                </div>
                                <!-- Pais -->
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                    <label class="form-label required">Nacionalidad</label>
                                    <select id="country_driver_${container}" name="driver[${container}][country_driver_id]" class="c2ec_select form-select"></select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-12 col-xs-12">
                                    <label class="form-label">Dirección</label>
                                    <input class="form-control" placeholder="Dirección" id="address_${container}" name="driver[${container}][address]" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        `);

        $('.birth_date_datepicker').datepicker({
            maxDate: "-21Y",
            changeMonth: true,
            changeYear: true,
        });

        $('.due_datepicker').datepicker({
            minDate: new Date(),
            changeMonth: true,
            changeYear: true,
        })

        const date = new Date();
        $(".due_datepicker").val((date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear()))
        $(".birth_date_datepicker").val((date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() - 21))

        const countryRef = $(`#country_driver_${container}`)

        // Find all countries.
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            url: '/admin/countries/get_all_countries?format=json',
            success: function (res) {
                if (res.countries) {
                    for (let country of res.countries) {
                        let option = new Option(country.es_name, country.id, false, false);
                        countryRef.append(option);
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });

        countryRef.select2({
            theme: "bootstrap",
            language: "es"
        });
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL IMPORTE DE LA RENTA SEGÚN LO QUE SE PONGA EL LA TARIFA Y LOS DÍAS DE LA RENTA.
     * RENTA = TRARIFA * DÍAS
     * @param e
     */
    updateRentImport(e){
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let tariff = $("#amounts_rent_tariff_day").val();
        let days = $("#text_days").text();
        // Calcular segun tipo de calculo de la tarifa
        switch (tariff_calc_type) {
            case 'by_full_range':
                $('#amounts_rent_import').val(this.myToFixed(parseFloat(tariff), 2)).trigger('change');
                break;
            default:
                $('#amounts_rent_import').val(this.myToFixed(tariff * days, 2)).trigger('change');
                break;
        }
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL IMPORTE DEL CONDUCTOR ADICIONAL SEGÚN LO QUE SE PONGA EL LA TARIFA Y LOS DÍAS
     * @param e
     */
    updateDriverImport(e){
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let tariff = parseFloat($("#amounts_drivers_day").val());
        let days = parseInt($("#text_days").text());
        let amounts_drivers = parseFloat($("#amounts_drivers").val()); // cantidad de choferes a cobrar
        // Calcular segun tipo de calculo de la tarifa
        switch (tariff_calc_type) {
            case 'by_full_range':
                $('#drivers_import').val(this.myToFixed(tariff * amounts_drivers, 2)).trigger('change');
                break;
            default:
                $('#drivers_import').val(this.myToFixed(tariff * days * amounts_drivers , 2)).trigger('change');
                break;
        }
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL IMPORTE DEL RELEVO DE RESPONSABILIDAD SEGÚN LA TARIFA Y LOS DÍAS DE LA RENTA
     * RELEVO DE RESPONSABILIDAD = TARIFA DEL RELEVO DE RESPONSABILIDAD * CANTIDAD DE DÍAS
     * @param e
     */
    liabilityReleaseRentImport(e){
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let tariff = $("#amounts_liability_release_tariff_day").val();
        let days = $("#text_days").text();
        switch (tariff_calc_type) {
            case 'by_full_range':
                $('#liability_release_import').val(this.myToFixed(parseFloat(tariff), 2)).trigger('change');
                break;
            default:
                $('#liability_release_import').val(this.myToFixed(tariff * days, 2)).trigger('change');
                break;
        }
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL IMPORTE DEL SUPLEMENTO DEL RELEVO DE RESPONSABILIDAD SEGÚN LA TARÍFA Y LOS DÍAS DE LA RENTA.
     * SUPLEMENTO DEL RELEVO DE RESPONSABILIDAD = TARIFA * CANTIDAD DE DÍAS
     * @param e
     */
    updateReliefSupplementImport(e){
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let tariff = $("#relief_supplement_tariff").val();
        let days = $("#text_days").text();
        switch (tariff_calc_type) {
            case 'by_full_range':
                $('#relief_supplement_import').val(this.myToFixed(parseFloat(tariff), 2)).trigger('change');
                break;
            default:
                $('#relief_supplement_import').val(this.myToFixed(tariff * days, 2)).trigger('change');
                break;
        }
    }

    /**
     * FUNCIÓN QUE ACTUALIZA LOS SIGUIENTES ANTICIPOS:
     * EL ANTICIPO COBRADO AL MOMENTO
     * EL ANTICIPO
     * EL TOTAL
     * @param e
     */
    updateTotalImport(e){
        //ACTUALIZAR TOTAL DE IMPORTE
        //RENTA
        let rent = $('#amounts_rent_import').val();
        if(rent === '' || rent === undefined || rent === null){
            rent = 0.0
        }
        //SEGURO
        let insurance = $('#liability_release_import').val();
        if(insurance === '' || insurance === undefined || insurance === null){
            insurance = 0.0
        }
        //COMBUSTIBLE
        let fuel_imp = $('#amounts_fuel').val();
        if(fuel_imp === '' || fuel_imp === undefined || fuel_imp === null){
            fuel_imp = 0.0
        }
        //OFICINA
        let office = $('#amounts_office').val();
        if(office === '' || office === undefined || office === null){
            office = 0.0
        }
        //DROP OFF
        let drop_off = $('#amounts_drop_off').val();
        if(drop_off === '' || drop_off === undefined || drop_off === null){
            drop_off = 0.0
        }
        //SUPLEMENTO DE RELEVO DE RESPONSABILIDAD
        let relief_supplement = $('#relief_supplement_import').val();
        if(relief_supplement === '' || relief_supplement === undefined || relief_supplement === null){
            relief_supplement = 0.0
        }
        //CHOFERES ADICIONALES
        let drivers = $("#drivers_import").val()
        if(drivers === '' || drivers === undefined || drivers === null){
            drivers = 0.0
        }
        // OTROS
        let others = $("#amounts_other_payments").val()
        if(others === '' || others === undefined || others === null){
            others = 0.0
        }
        //Anticipo Cobrado al momento : Es la sumatoria de todos los importes que se cobren en el momento de la apertura.
        // POR CONCEPTO LA RENTA NUNCA SE COBRA AL MOMENTO
        let total_im = parseFloat(rent) + parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(drivers) + parseFloat(others) + parseFloat(drop_off) + parseFloat(relief_supplement)
        //$("#rent_import") => TOTAL: En la apertura siempre va a ser igual el anticipo.
        $("#rent_import").text(this.myToFixed(total_im, 2));
        // $("#total_rent_import").val(this.myToFixed(total_im, 2));
        // $("#span_reservation_price") => ESTO ES EL ANTICIPO RESERVADO
        //rent_charge ES EL TOTAL QUE SE COBRA - ANTICIPO RESERVADO
        let rent_charge = this.myToFixed(parseFloat(total_im) - parseFloat($("#span_reservation_price").val()), 2);
        if($("#reservation_voucher").val() == 'true' || $("#reservation_voucher").val() == true){
            //SI ES VOUCHER BUSCO QUE TRAE COBRADO LA RESERVA
            //RENTA
            let rent_voucher = 0.00;
            let refund_rent_voucher = 0.00
            if($('#reservation_rent_voucher').val() == '1'){
                rent_voucher = parseFloat($('#reservation_rent_collected').val());
                if(rent_voucher > parseFloat(rent)){
                    refund_rent_voucher = parseFloat(rent_voucher) - parseFloat(rent)
                }
            }
            //SEGURO
            let insurance_voucher = 0.00;
            let refund_insurance_voucher = 0.00
            let to_collect_insurance_voucher = 0.00
            if($('#liability_release_voucher').val() == '1'){
                insurance_voucher = $('#reservation_liability_release_collected').val();
                if(parseFloat(insurance_voucher) > parseFloat(insurance)){
                    refund_insurance_voucher = parseFloat(insurance_voucher) - parseFloat(insurance)
                }
            }else{
                to_collect_insurance_voucher = parseFloat(insurance)
            }

            //SUPLEMENTO DEL SEGURO
            let relief_voucher = 0.00;
            let refund_relief_voucher = 0.00
            let to_collect_relief_voucher = 0.00
            if($('#relief_supplement_voucher').val() == '1'){
                relief_voucher = $('#reservation_relief_supplement_collected').val();
                if(parseFloat(relief_voucher) > parseFloat(relief_supplement)){
                    refund_relief_voucher = parseFloat(relief_voucher) - parseFloat(relief_supplement)
                }
            }else{
                to_collect_relief_voucher = parseFloat(relief_supplement)
            }

            //COMBUSTIBLE
            let fuel_voucher = 0.00;
            let refund_fuel_voucher = 0.00;
            let to_collect_fuel_voucher = 0.00;
            if($('#reservation_fuel_voucher').val() == '1'){
                fuel_voucher = $('#reservation_fuel_collected').val();
                if(parseFloat(fuel_voucher) > parseFloat(fuel_imp)){
                    refund_fuel_voucher = parseFloat(fuel_voucher) - parseFloat(fuel_imp)
                }
            }else{
                to_collect_fuel_voucher = parseFloat(fuel_imp)
            }
            //OFICINA
            let office_voucher = 0.00;
            let refund_office_voucher = 0.00;
            let to_collect_office_voucher = 0.00;
            if($('#reservation_office_voucher').val() == '1'){
                office_voucher = $('#reservation_office_collected').val();
                if(parseFloat(office_voucher) > parseFloat(office)){
                    refund_office_voucher = parseFloat(office_voucher) - parseFloat(office)
                }
            }else{
                to_collect_office_voucher = parseFloat(office)
            }
            //DROP OFF
            let drop_off_voucher = 0.00;
            let refund_drop_off_voucher = 0.00;
            let to_collect_drop_off_voucher = 0.00;
            if($('#reservation_drop_off_voucher').val() == '1'){
                drop_off_voucher = $('#reservation_drop_off_collected').val();
                if(parseFloat(drop_off_voucher) > parseFloat(drop_off)){
                    refund_drop_off_voucher = parseFloat(drop_off_voucher) - parseFloat(drop_off)
                }
            }else{
                to_collect_drop_off_voucher = parseFloat(drop_off)
            }
            //CHOFERES ADICIONALES
            let drivers_voucher = 0.00;
            let refund_drivers_voucher = 0.00;
            let to_collect_drivers_voucher = 0.00;
            if($("#reservation_additional_driver_voucher").val() == '1'){
                drivers_voucher = $("#reservation_additional_driver_collected").val();
                if(parseFloat(drivers_voucher) > parseFloat(drivers)){
                    refund_drivers_voucher = parseFloat(drivers_voucher) - parseFloat(drivers)
                }else{
                    to_collect_drivers_voucher = parseFloat(drivers) - parseFloat(drivers_voucher)
                }
            }else{
                to_collect_drivers_voucher = parseFloat(drivers)
            }
            //TOTAL DE VOUCHER
            let total_refund = parseFloat(refund_rent_voucher) + parseFloat(refund_insurance_voucher) + parseFloat(refund_office_voucher) + parseFloat(refund_drivers_voucher) + parseFloat(refund_drop_off_voucher) + parseFloat(refund_fuel_voucher) + parseFloat(refund_relief_voucher)
            let to_collect = parseFloat(to_collect_insurance_voucher) + parseFloat(to_collect_fuel_voucher) + parseFloat(to_collect_drivers_voucher) + parseFloat(to_collect_office_voucher) + parseFloat(to_collect_drop_off_voucher) + parseFloat(others) + parseFloat(to_collect_relief_voucher)

            $("#rent_credit").val(total_refund);
            $("#rent_charge").val(to_collect);
            $("#moment_card").val(to_collect);
            updateMostUsePaysInputs();
            // updatePaymentFormReleaseMoney($("#moment_card"));
        }else{
            // $("#rent_charge") => ANTICIPO COBRADO AL MOMENTO
            // ("#rent_credit") =>  A DEVOLVER
            if (rent_charge > 0){
                $("#rent_charge").val(rent_charge);
                $("#moment_card").val(rent_charge);
                updateMostUsePaysInputs();
                // updatePaymentFormReleaseMoney($("#moment_card"));
                $("#rent_credit").val(0.00);
                //$("#total_credit").val(0.00 );
            }else{
                $("#rent_charge").val(0.00);
                $("#moment_card").val(0.0);
                $("#rent_credit").val(rent_charge * -1 );
                //$("#total_credit").val(rent_charge * -1);
            }
        }
        //ANTICIPO
        // SI ES UNA RENTA DE VAOUCHER EL ANTICIPO ES EL ANTICIPO COBRADO AL MOMENTO,
        // SI NO ES DE VOUCHER EL ANTICIPO ES LA SUMA DE LA RESERVA + EL ANTICIPO COBRADO AL MOMENTO.
        if($("#reservation_voucher").val() !== '' || $("#reservation_voucher").val() !== undefined || $("#reservation_voucher").val() !== null){
            if($("#reservation_voucher").val() === 'true' || $("#reservation_voucher").val() === true){
                let rent_type = $('#nom_rent_type_id option').filter(':selected');
                let advance = $("#rent_charge").val();
                // Chequear variable de configuracion en el tipo de renta para reservas voucher
                if (rent_type.attr('payment_total_rent_voucher') === 'true') {
                    let voucher = parseFloat($('#reservation_price').val()); // Cobrado en la reserva
                    let r_charge = parseFloat($("#rent_charge").val()); // Cobrar al momento
                    $('#voucher').val(myToFixed(voucher + r_charge,2)); // Va para voucher lo cobrado al momento
                    // Reset all field
                    $('#rent_advance').val(0.00);
                    $('#rent_import').val(0.00);
                    $('#moment_card').val(0.00);
                    $('#rent_credit').val(0.00);
                    $('#rent_charge').val(0.00);
                } else
                    $("#rent_advance").val(advance);
            }else{
                let advance_payment =parseFloat( $("#rent_charge").val());
                let advance_reserved = parseFloat($("#span_reservation_price").val());
                let advance = (advance_payment + advance_reserved);
                $("#rent_advance").val(this.myToFixed(advance, 2));
            }
        }
        //TOTAL ES LO IGUAL AL ANTICIPO
        $("#rent_import").val($("#rent_advance").val());

        // Chequear alerta de choferes adicionales, Combustible y Pagos
        alert_additional_driver();
        alert_amounts_fuel();
        checkAdvancePaymentAtMoment();
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL IMPORTE SEGÚN LA CANTIDAD DE LISTROS A COBRAR, ES UN PARÁMETRO QUE SE PONE EN EL TIPO DE RENTA.
     * @param e
     */
    updateFuelImport(e){
        // console.log('-------------------------------------- updateFuelImport')
        let tariff_calc_type = $('#reservation_tariff_calc_type').val();
        let tariff = $("#amounts_fuel_day").val();
        let fuel_amount = $("#fuel_amount").val();
        switch (tariff_calc_type) {
            case 'by_full_range':
                $('#import_fuel').val(0.00);
                break;
            default:
                $('#import_fuel').val((tariff * fuel_amount).toFixed(4) );
                break;
        }
    }

    /**
     * TODO VERIFICAR LO QEU HACE LA FUNCIÓN PARA DESCRIBIR MÁS LA MISMA.
     * @param e
     */
    returnFuel(e){
        if($('#return_fuel').is(":checked") === true) {
            $("#div_return_fuel").show();
        }else{
            $("#div_return_fuel").hide();
        }
    }

    /**
     * TODO VERIFICAR LO QEU HACE LA FUNCIÓN PARA DESCRIBIR MÁS LA MISMA.
     * @param e
     */
    traveledMileage(e){
        let out_mileage = parseFloat($("#out_mileage").text());
        let mileage = $("#mileage").val();
        $('#traveled_mileage').val((mileage - out_mileage).toFixed(4) );
    }

    /**
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param e
     */
    hideForm(e) {
        e.preventDefault();
        const container = this.data.get('container');
        $(`#driver_${container}`).prop('disabled', false);
        $(`#driver_location_${container}`).prop('disabled', false);
        $(`.driver_${container}_form`).fadeOut('slow', function () {
            $(this).remove();
        });
        $(`#add_additional_driver_${container}`).empty().append(`
             <button class="btn btn-outline-primary" data-action="click->rent#addDriver">
                Agregar Chofer
            </button>
        `)
    }

    /**
     * FUNCIÓN PARA PONER EL CAMPO DE LICENCIA DE LOS CLIENTES DISABLED O NO SI SE MARCA LA LICENCIA COMO PERMANENTE O NO
     * @param e
     */
    licencePermanent(e){
        if($('#client_permanent')[0].checked){
            $('#due_date').prop('required', false);
            $('#due_date').prop('disabled', true);
            let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));// para tener el valor de la suma de todas las formas de pago del formulario
            let release_money_default_value = parseFloat($('#release_money').attr("data-value"));
            if((release_money_default_value - inputs_sum) == 0 || $('#release_money').val() == 0)
                $('#save_rent').removeClass('disabled');
        }else{
            $('#due_date').prop('required', true);
            $('#due_date').prop('disabled', false);
            this.isDueDateByRentValid();
        }
    }

    /**
     * Función que busca los datos de un chófer adicional para llenar los valores si lo encuentra.
     *
     * @param e
     */
    getAdditionalDriverData(e) {
        // todo cambiar la url q busca los datos.
        e.preventDefault();
        let container = this.data.get('additional_driver_container');
        let rent_type = $('#nom_rent_type_id').val();
        let to_date = $('#to_date').val();
        let amounts_drivers_input = 0;
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/company/ser/configurations/list/additional-drivers/get_additional_driver_data',
            data: {identity_card: e.target.value, rent_type: rent_type, rent_to_date: to_date},
            success: function (res) {
                if( res !== undefined){
                    let additional_driver = res.additional_driver;
                    if(res.error === true){
                        $(`#identity_card_${container}`).val("").trigger('change');
                        toastr_message('error', res.error_msg);
                    }else if(additional_driver !== null){
                        $(`#name_driver_${container}`).val(additional_driver.name);
                        $(`#identity_card_${container}`).val(additional_driver.identity);
                        $(`#last_name_${container}`).val(additional_driver.last_name);
                        $(`#birth_date_${container}`).val(res.driver_birth_date);
                        $(`#phone_${container}`).val(additional_driver.phone);
                        $(`#driver_license_${container}`).val(additional_driver.driving_license);
                        $(`#due_date_${container}`).val(res.driver_expiration_driving_license);
                        $(`#license_date${container}`).val(res.driver_license_date);
                        $(`#country_driver_${container}`).val(additional_driver.nom_country_id).trigger('change');

                        // Edad del chofer
                        if (res.age !== undefined) $(`#driver_age_${container}`).text(res.age)
                        if (res.invalid_age === true) $(`#birth_date_${container}`).val('');
                        if (res.error === false && res.warning_msg_age !== undefined && res.warning_msg_age !== '') toastr_message('warning', res.warning_msg_age);
                        // Fecha de vencimiento de la licencia
                        if (res.invalid_due_date === true) $(`#due_date_${container}`).val('');
                        if (res.error === false && res.warning_msg_due_date !== undefined && res.warning_msg_due_date !== '') toastr_message('warning', res.warning_msg_due_date);

                    }
                    if (res.error === false && res.warning_msg !== undefined && res.warning_msg !== '') {
                        toastr_message('warning', res.warning_msg);
                    }
                }
                if($(`#identity_card_${container}`).val() === ""){
                    $(`#name_driver_${container}`).prop('required',false);
                    $(`#last_name_${container}`).prop('required',false);
                    $(`#birth_date_${container}`).prop('required',false);
                    $(`#driver_license_${container}`).prop('required',false);
                    $(`#country_driver_${container}`).prop('required',false);
                }else{
                    $(`#name_driver_${container}`).prop('required',true);
                    $(`#last_name_${container}`).prop('required',true);
                    $(`#birth_date_${container}`).prop('required',true);
                    $(`#driver_license_${container}`).prop('required',true);
                    $(`#country_driver_${container}`).prop('required',true);
                }
                //Poner de forma automática la cantidad a cobrar.
                // Contando cantidad de choferes especificados en el formulario
                $.each($('.identity_driver'), function( index, input ) {
                    if($(this).val !== undefined && $(this).val().length > 0){
                        amounts_drivers_input = amounts_drivers_input + 1
                    }
                });
                // Poner de forma automática la cantidad de chóferes a cobrar.
                let rent_type = $('#nom_rent_type_id option').filter(':selected');
                let number_additional_driver = parseInt(rent_type.attr('number_additional_driver'));
                let drivers_to_charges = parseInt($('#drivers_to_charges').val());
                let free_drivers = number_additional_driver - drivers_to_charges
                let amount_to_charge = 0;
                // Los gratis se asignan al inicio, por eso si es mayor la cantidad de choferes gratis que la cantidad
                // especificda por el usuario, la cantidad a cobrar es cero.
                if(free_drivers >= amounts_drivers_input){
                    amount_to_charge = 0;
                }else{
                    amount_to_charge = amounts_drivers_input - free_drivers
                }
                $('#amounts_drivers').val(amount_to_charge).trigger('click');
                // Quitar la alerta de driver de la reserva si se llega al importe
                alert_additional_driver();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            }
        });
    }

    check_driver_alert(e){
        $('#amounts_drivers').trigger('click');
        alert_additional_driver();
    }

    check_fuel_alert(){
        alert_amounts_fuel();
    }

    /**
     * FUNCIÓN QUE ACTUALIZA EL ANTICIPO Y EL  TOTAL SEGÚN EL ANTICIPO COBRADO AL MOMENTO
     **/
    updateAdvance(e){
        //ANTICIPO
        // SI ES UNA RENTA DE VAOUCHER EL ANTICIPO ES EL ANTICIPO COBRADO AL MOMENTO,
        // SI NO ES DE VOUCHER EL ANTICIPO ES LA SUMA DE LA RESERVA + EL ANTICIPO COBRADO AL MOMENTO.
        if($("#reservation_voucher").val() !== '' || $("#reservation_voucher").val() !== undefined || $("#reservation_voucher").val() !== null){
            if($("#reservation_voucher").val() == 'true' || $("#reservation_voucher").val() == true){
                let advance = $("#rent_charge").val();
                $("#rent_advance").val(advance);
            }else{
                let advance_payment = parseFloat( $("#rent_charge").val());
                let advance_reserved = parseFloat($("#span_reservation_price").val());
                let advance = (advance_payment + advance_reserved);
                $("#rent_advance").val(this.myToFixed(advance, 2));
            }
        }
        //TOTAL ES LO IGUAL AL ANTICIPO
        $("#rent_import").val($("#rent_advance").val());
        checkAdvancePaymentAtMoment();
    }

    /**
     * FUNCIÓN QUE BÚSCA LA RESERVA EN COME2E Y LLENA TODOS LOS VALORES SEGÚN LA RESERVA.
     **/
    getRentByReference(e) {
        e.preventDefault();
        let url = this.data.get("url");
        let validate_date = this.data.get("validate_date");
        let rent_type = $('#nom_rent_type_id option').filter(':selected');
        let rent_by_hour = rent_type.attr('rent_by_hour');
        if ($('#references').val()) {
            $.blockUI({ message:false });
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {id: $('#references').val(), type: $('#nom_rent_type_id').val(), validate_date: validate_date},
                success: function (res) {
                    let reservation = res;
                    $.unblockUI();
                    // console.log(reservation);
                    if (reservation.status == 'OK') {
                        // Mostrando mensaje warnning del proceso de obtencion de la reserva
                        if (reservation.warning_msg !== 'OK')
                            alert(reservation.warning_msg);

                        // Mostrando mensaje de alerta sobre el chequeo de los choferes en mas de una renta activa
                        if (reservation.check_driver_msg !== undefined)
                            alert(reservation.check_driver_msg);

                        // Chequeando cambios de categorias respecto a la reserva
                        if(reservation.changed_category === true){
                            alert("PRECAUCIÓN: La categoría del vehículo seleccionado para la renta es diferente a la categoría previamente reservada.");
                            document.getElementById("show_alert").innerHTML = `<div class='alert alert-warning alert-dismissible fade show' role="alert"> PRECAUCIÓN: La categoría del vehículo seleccionado para la renta es diferente a la categoría previamente reservada.
                                                                                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                                  <span aria-hidden="true">&times;</span></button></div>`;
                            $('#category_change_user').show();
                            $('#autorize_user').prop('required', true);
                        }else{
                            $('#category_change_user').hide();
                            $('#autorize_user').prop('required', false);
                        }

                        //Mostrando mensaje de precaucion para cuando es un tipo de renta por hora
                        $('.warning_message').remove();
                        if (rent_by_hour === 'true' && reservation.product_time_type === 'hour') {
                            $('#drop_off_time').val(reservation.drop_off_time_calc);
                            $("#warning_message").after(`<div class='alert alert-warning alert-dismissible fade show warning_message' role="alert">
                                                         <strong>Precuación: </strong> Tipo de reserva por hora.
                                                         <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                          <span aria-hidden="true">&times;</span>
                                                         </button>
                                                        </div>`);
                            // Permitir editar la hora de entrega
                            $('#drop_off_time').prop('readonly', false);
                        }
                        // Chequeando proximo mantenimiento
                        checkNextMtto();
                        // --------------------------------------------------------------------------------------------
                        $('#references').prop('readonly',true);
                        $('#nom_rent_type_id option:not(:selected)').prop('disabled', true);
                        $("#rent_form").fadeToggle("slow", "linear");
                        $("#rent_form").show();
                        $("#save_rent").show();
                        $("#btn-back").removeClass('ml-auto');
                        $('#references').val(reservation.reference);
                        $('#s_reference').text(reservation.reference);
                        $('#client_identification').text(reservation.passport);
                        // Datos de la reserva
                        $('#reservation_name').val(reservation.client_first_name);
                        $('#reservation_last_name').val(reservation.client_last_name);
                        $('#reservation_email').val(reservation.client_email);
                        $('#reservation_county').val(reservation.country);
                        $('#reservation_address').val(reservation.address);
                        $('#reservation_from_date').val(reservation.from_date);
                        $('#reservation_hour_from_date').val(reservation.pick_up_time);
                        $('#reservation_to_date').val(reservation.to_date);
                        $('#reservation_hour_to_date').val(reservation.drop_off_time);
                        $('#reservation_drop_off_time_calc').val(reservation.drop_off_time_calc);
                        $('#reservation_pick_up_office_code').val(reservation.pick_up_location);
                        $('#reservation_pick_up_office').val(reservation.pick_up_location_name);
                        $('#reservation_drop_off_office').val(reservation.drop_off_location_name);
                        $('#reservation_drop_off_office_code').val(reservation.drop_off_location);
                        $('#reservation_days').val(reservation.days);
                        $('#reservation_product_time_type').val(reservation.product_time_type);
                        $('#reservation_tariff').val(reservation.tariff);
                        $('#reservation_tariff_calc_type').val(reservation.tariff_calc_type);
                        $('#reservation_agency').val(reservation.agency);
                        $('#reservation_contract').val(reservation.contract);
                        $('#reservation_modality').val(reservation.modality);
                        $('#reservation_price').val(reservation.sale_price_amount);
                        $('#reservation_price_currency').val(reservation.sale_price_currency);
                        $('#reservation_gateway_collect').val(reservation.merchant);
                        $('#reservation_driver_collect').val(reservation.payment_additional_driver);
                        $('#reservation_exchange_rate').val(reservation.exchange_rate);
                        $('#reservation_voucher').val(reservation.voucher);
                        $('#rent_type_driver_tariff').val(reservation.rent_type_driver_tariff);
                        $('#r_name').text(reservation.client_name);
                        $('#r_country').text(reservation.country_name);
                        $('#r_email').text(reservation.client_email);
                        $('#r_address').text(reservation.address);
                        $('#r_pick_up').text(reservation.from_date + ' ' + reservation.pick_up_time);
                        $('#r_pick_up_place').text(reservation.pick_up_location_name);
                        $('#r_drop_off').text(reservation.to_date + ' ' + reservation.drop_off_time);
                        $('#r_drop_off_place').text(reservation.drop_off_location_name);
                        $('#r_days').text(reservation.days);
                        $('#product_time_type_name').text(reservation.product_time_type_name);
                        $('#product_time_type_name2').text(reservation.product_time_type_name);
                        $('#text_days').text(reservation.days);
                        $('#amounts_days').val(reservation.days);
                        $('#r_modality').text(reservation.modality);
                        $('#modality_name').val(reservation.modality);
                        $('#text_modality').text(reservation.modality);
                        $('#r_tariff').text(reservation.tariff);
                        $('#text_tariff').text((reservation.agency === null) ? '' : reservation.agency + ' (' + reservation.contract + ');' + reservation.tariff);
                        $('#tariff_name').val(reservation.tariff);
                        $('#r_price').text(reservation.sale_price);
                        $('#from_date').val(reservation.from_date);
                        $('#to_date').val(reservation.to_date);
                        $("#to_date").datepicker('option', 'maxDate', $('#to_date').val());
                        $('#r_product_name').text(reservation.product_name);
                        $('#r_product_code').text(reservation.product_code);
                        $('#r_product_api').text(reservation.product_api_key);
                        $('#r_product_description').text(reservation.product_description);
                        $('#r_comment').text(reservation.comment);
                        //----------------------------------------------------------------------------------------------

                        //Fechas y Horas.
                        // La hora de recogida se pone según la hora en la que el funcionario hace la renta de forma automática.
                        //$('[name="ui-timepicker-pick_up_time"]').val(reservation.pick_up_time).trigger('change');
                        $('[name="ui-timepicker-drop_off_time"]').val(reservation.drop_off_time).trigger('change');
                        //$('#drop_off_time').val(reservation.drop_off_time).trigger('change').prop('disabled', true);
                        // Se permite cambiar las horas
                        $('[name= "ui-timepicker-pick_up_time"]').prop('disabled', true);
                        //$('[name="ui-timepicker-drop_off_time"]').prop('disabled', true);

                        //$("[value='" + reservation.drop_off_time + "']", $('#drop_off_time')).prop('selected', true);
                        // $('#drop_off_location_id').val(reservation.drop_off_location_id);
                        $("[value='" + reservation.drop_off_location_id + "']", $('#drop_off_location_id')).prop('selected', true);
                        $('#drop_off_location_id').trigger('change');

                        // Datos del clíente
                        let client = reservation.client;
                        $(".new_client").show();
                        if (client !== null) {
                            /**
                             * Seleccionando del identificador de personas esl
                             * name
                             * first_name
                             * address
                             * passport
                             * birth_date
                             * */
                            // $('#name').val(client.name);
                            // $('#first_name').val(client.last_name);
                            // $('#address').val(client.address);
                            // $('#passport').val(client.passport);
                            // $('#birth_date').val(reservation.client_birth_date);
                            //$('#last_name').val(client.last_name);
                            //$('#driver_license').val(client.driver_license);
                            $('#code').val(client.code);
                            $('#phone').val(client.phone);
                            $('#client_email').val(reservation.client_email);
                            $('#license_date').val(reservation.client_license_date);
                            $('#observation').val(client.observation);
                            $('#customer_id').val(client.customer_id);
                            $('#category').val(client.category);
                            $('#user').val(client.user);
                            $('#business_id').val(client.business_id);
                            // ver como hacer con los pasaportes.
                            //$('#nom_country_id').val(client.nom_country_id).trigger('change');
                            //$('#nationality').val(client.nationality_id).trigger('change');
                            $('#authorized').val(client.authorized);
                            $('#distinguished').val(client.distinguished);
                            $('#technical_sheet').text('Cliente registrado');
                            //Validando fecha de la licencia al cargar un cliente registrado en el proceso del get_referencia_by_rent
                            if (client.permanent){
                                $('#client_permanent').prop('checked', client.permanent);
                                $('#due_date').prop('disabled', true);
                            } else if (reservation.client_due_date === null) {
                                alert('ERROR: No se encontró registrada la fecha de vencimiento de la licencia del cliente, por favor, actualice el campo para activar el botón del contrato.');
                                $('#save_rent').addClass('disabled');
                            } else if (reservation.to_date !== null) {
                                let ac_due_date = reservation.client_due_date.split('/');
                                let c_due_date = new Date(ac_due_date[1] + '/' + ac_due_date[0] + '/' + ac_due_date[2]);
                                let ar_to_date = reservation.to_date.split('/');
                                let r_to_date = new Date(ar_to_date[1] + '/' + ar_to_date[0] + '/' + ar_to_date[2]);
                                if (c_due_date <= r_to_date) {
                                    alert('ERROR: La fecha de la licencia del cliente registrado (' + reservation.client_due_date + ') está vencida o se encuentra dentro del período que dura la renta. Por favor, actualice el campo para activar el botón del contrato.');
                                    $('#save_rent').addClass('disabled');
                                } else {
                                    let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));// para tener el valor de la suma de todas las formas de pago del formulario
                                    let release_money_default_value = parseFloat($('#release_money').attr("data-value"));
                                    if((release_money_default_value - inputs_sum) == 0 || $('#release_money').val() == 0)
                                        $('#save_rent').removeClass('disabled');
                                    $('#due_date').val(reservation.client_due_date);
                                }
                            }
                        }
                        else {
                            /**
                             * Seleccionando del identificador de personas esl
                             * name
                             * first_name
                             * address
                             * passport
                             * birth_date
                             * */
                            //$('#name').val(reservation.client_first_name);
                            //$('#first_name').val(reservation.client_last_name);
                            // $('#address').val(reservation.address);
                            //$('#passport').val(reservation.passport);
                            //$('#nom_country_id').val(reservation.country_id);
                            //$('#nationality').val(reservation.country_id);
                            //$("[value='" + reservation.country_id + "']", $('#nom_country_id')).prop('selected', true);
                            //$('#nom_country_id').trigger('change');
                            //$('#nationality').trigger('change');
                            //$('#last_name').val(reservation.client_last_name);
                            $('#client_email').val(reservation.client_email);
                            $("#nom_client_id").prop('disabled', true);
                            $('#technical_sheet').text('Nuevo cliente');

                            //Para los nuevos clientes notificar el cambio de la fecha de vencimiento de la licencia
                            //alert('Noticia: Por favor, actualice la fecha de vencimiento de la licencia del nuevo cliente para activar el botón del contrato.');
                            $('#save_rent').addClass('disabled');
                        }
                        $('#idcheck_passport').val(reservation.passport);
                        $('#reservation_to_date').val(reservation.to_date);

                        //---------------------------------------------------------------------------------------------
                        updatePriceSer(reservation); // Span con valores calculados por el SER y Come2E
                        updatePriceCome2e(reservation);
                        $('#ser_tariff_id').val(reservation.ser_tariff_id);
                        $('#ser_service_modality_id').val(reservation.ser_service_modality_id);
                        //---------------------------------------------------------------------------------------------
                        /**
                         * Pagos por conceptos, si es VOUCHER se toman los datos de la reserva y no se edita,
                         * sino se le permite al funcionario editar y definir los campos.
                         * */
                        let amounts_rent_import = $('#amounts_rent_import');
                        let amounts_rent_tariff_day = $('#amounts_rent_tariff_day');
                        let liability_release_import = $('#liability_release_import');
                        let amounts_liability_release_tariff_day = $('#amounts_liability_release_tariff_day');
                        let relief_supplement_import = $('#relief_supplement_import');
                        let relief_supplement_tariff = $('#relief_supplement_tariff');
                        let amounts_office = $('#amounts_office');
                        let amounts_drop_off = $('#amounts_drop_off');
                        let amounts_fuel = $('#amounts_fuel');
                        let amounts_other_payments = $('#amounts_other_payments');
                        if (reservation.voucher) {
                            // RENTA
                            if(reservation.payment_rent !== undefined &&  reservation.payment_rent !== 0){
                                amounts_rent_import.val(reservation.payment_rent);
                                amounts_rent_import.attr('readonly', true);
                                $('#reservation_rent_voucher').val(1);
                            }
                            if(reservation.payment_rent_tariff !== undefined &&  reservation.payment_rent_tariff !== 0){
                                amounts_rent_tariff_day.val(reservation.payment_rent_tariff);
                                amounts_rent_tariff_day.attr('readonly', true);
                            }

                            // RELEVO DE RESPONSABILIDAD
                            if(reservation.payment_liability_release !== undefined &&  reservation.payment_liability_release !== 0){
                                liability_release_import.val(reservation.payment_liability_release);
                                liability_release_import.attr('readonly', true);
                            }
                            if(reservation.payment_liability_release_tariff !== undefined &&  reservation.payment_liability_release_tariff !== 0){
                                amounts_liability_release_tariff_day.val(reservation.payment_liability_release_tariff);
                                amounts_liability_release_tariff_day.attr('readonly', true);
                                $('#liability_release_voucher').val(1);
                            }

                            // SUPLEMENTO DE RELEVO DE RESPONSABILIDAD
                            if(reservation.payment_relief_supplement_tariff !== undefined && reservation.payment_relief_supplement_tariff !== 0){
                                relief_supplement_tariff.val(reservation.payment_relief_supplement_tariff);
                                relief_supplement_tariff.attr('readonly', true);
                                $('#relief_supplement_voucher').val(1);
                            }
                            if(reservation.payment_relief_supplement !== undefined &&  reservation.payment_relief_supplement !== 0){
                                relief_supplement_import.val(reservation.payment_relief_supplement);
                                relief_supplement_import.attr('readonly', true);
                            }

                            // CARGO POR OFICINA
                            if(reservation.payment_office_supplement !== undefined &&  reservation.payment_office_supplement !== 0){
                                amounts_office.val(reservation.payment_office_supplement);
                                amounts_office.attr('readonly', true);
                                $('#reservation_office_voucher').val(1);
                            }

                            // DROP OFF (ENTREGAR EN OTRA OFICINA QUE NO ES LA QUE SE RECOGE EL VEHÍCULO)
                            if(reservation.payment_drop_off !== undefined &&  reservation.payment_drop_off !== 0){
                                amounts_drop_off.val(reservation.payment_drop_off);
                                amounts_drop_off.attr('readonly', true);
                                $('#reservation_drop_off_voucher').val(1);
                            }

                            // COMBUSTIBLE
                            amounts_fuel.val(reservation.fuel_amount);
                            amounts_fuel.attr('readonly', true);
                            if(reservation.payment_initial_fuel !== undefined && reservation.payment_initial_fuel !== 0) {
                                $('#reservation_fuel_voucher').val(1);
                            }

                            // CHOFERES ADICIONALES
                            $('#amounts_drivers_day').val(reservation.driver_price.toFixed(2));

                            // OTROS PAGOS
                            amounts_other_payments.val(reservation.payment_other);
                            if(reservation.payment_other !== undefined &&  reservation.payment_other !== 0){
                                amounts_other_payments.attr('readonly', true);
                                $('#reservation_other_payments_voucher').val(1);
                            }
                        } else {
                            // Editar tarifas e importes calculables
                            amounts_rent_import.val(null); // --- RENTA
                            liability_release_import.val(null);  // --- RELEVO
                            relief_supplement_import.val(null);  // --- SUPLEMENTO RELEVO
                            //-- CONDUCTOR ADICIONAL
                            $('#drivers_import').val(null)
                            $('#drivers_import').attr('readonly', false);
                            // --- COMBUSTIBLE
                            amounts_fuel.val(null); // Poniendolo en null porque en el vista se le setea un valor por default
                            amounts_fuel.attr('readonly', false);
                        }
                        // Actualizando componentes ocultos reservation collected
                        $('#reservation_rent_collected').val(reservation.payment_rent); //RENTA
                        $('#reservation_liability_release_collected').val(reservation.payment_liability_release); //RELEVO
                        $('#reservation_relief_supplement_collected').val(reservation.payment_relief_supplement); //SUPLEMENTO
                        $('#reservation_office_collected').val(reservation.payment_office_supplement); //OFICINA
                        $('#reservation_drop_off_collected').val(reservation.payment_drop_off); // DROP-OFF
                        $('#reservation_fuel_collected').val(reservation.payment_initial_fuel); //COMBUSTIBLE
                        $('#reservation_other_payments_collected').val(reservation.payment_other); // OTROS COBROS
                        $('#reservation_additional_driver_collected').val(reservation.payment_additional_driver); // CONDUCTOR ADICIONAL

                        // Alerta de cobro por choferes adicionale de la reserva
                        $('#drivers_to_charges').val(reservation.drivers_to_charges);
                        if(reservation.payment_additional_driver > 0) {
                            $('#alert_additional_driver').show();
                            $('#reservation_additional_driver_voucher').val(1);
                        }else{
                            $('#alert_additional_driver').hide();
                        }
                        // Alerta de cobro de combustible, comparacion importe combustible cobrado en la reserva contra el realmente dado en la renta
                        if (reservation.payment_initial_fuel > reservation.fuel_amount)
                            $('#alert_initial_fuel').show();
                        else
                            $('#alert_initial_fuel').hide();

                        // importe de la renta
                        let rent = $.isNumeric(amounts_rent_import.val()) ? amounts_rent_import.val() : 0.00;
                        // importe del seguro
                        let insurance = $.isNumeric(liability_release_import.val()) ? liability_release_import.val() : 0.00;
                        // importe del suplemento del seguro
                        let supplement_insurance = $.isNumeric(relief_supplement_import.val()) ? relief_supplement_import.val() : 0.00;
                        // importe de gasolina
                        let fuel_imp = $.isNumeric(amounts_fuel.val()) ? amounts_fuel.val() : 0.00;
                        // importe por oficina
                        let office = $.isNumeric(amounts_office.val()) ? amounts_office.val() : 0.00;
                        // importe por drop off
                        let drop_off = $.isNumeric(amounts_drop_off.val()) ? amounts_drop_off.val() : 0.00;

                        //TOTALES
                        // importe total, de la renta sumando todos los conceptos
                        let total = $('#rent_import').text();
                        //let total_im = parseFloat(total) + parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent)
                        let total_im = parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent) + parseFloat(drop_off) + parseFloat(supplement_insurance)
                        if (total_im <= 0) {
                            $("#rent_import").text(reservation.sale_price_amount);
                            $("#total_rent_import").val(reservation.sale_price_amount);
                            $("#rent_first_import").val(reservation.sale_price_amount);
                        } else {
                            $("#rent_import").text(total_im);
                            $("#total_rent_import").val(total_im);
                            $("#rent_first_import").val(total_im);
                        }

                        // ANTICIPO RESERVADO
                        // importe de la reserva
                        if (reservation.prepaid === true) {
                            $("#span_reservation_price").val(reservation.sale_price_amount);
                        } else {
                            $("#span_reservation_price").val(0);
                        }
                        // MONTO A COBRAR Y A DEVOLVER
                        // lo que no se ha cobrado por pasarela o en la reserva y se cobra a parte.
                        // EL MONTO A COBRAR ES EL IMPORTE TOTAL COBRADO MENOS LO QUE SE COBRÓ EN LA RESERVA
                        let rent_charge = parseFloat(total_im) - reservation.sale_price_amount
                        // Si el monto a cobrar es menor que 0 hay que guardar un excedente que el
                        // cliente puede consumir guardar como un crédito o devolver.
                        // anticipo cobrado
                        // A devolver es el importe total - (cobro anticipado + cobro en ese momento)
                        let pre_pay = parseFloat($("#span_reservation_price").val()).toFixed(2);
                        if (rent_charge > 0) {
                            $("#rent_charge").val(rent_charge.toFixed(2));
                            $("#rent_credit").val(0.00);
                            $("#total_credit").val(0.00);
                        } else {
                            $("#rent_charge").val(0.00);
                            $("#rent_credit").val((total_im - pre_pay).toFixed(2) * -1);
                            $("#total_credit").val((total_im - pre_pay).toFixed(2) * -1);
                        }
                        //----------------------------------------------------------------------------------------------
                        // COBROS - Motrar seccion de cobros para cuando existen cobros al momento
                        //----------------------------------------------------------------------------------------------
                        //$("#payments").show();
                        let payment_form_code = $("#payment_form_code");
                        payment_form_code.empty();
                        payment_form_code.append('<option selected="selected" value="">Seleccione forma de pago</option>')
                        $.each(reservation.nom_payment_form, function (index, fp) {
                            $("#payment_form_code").append(
                                $("<option>")
                                    .text(fp.name)
                                    .attr("value", fp.code)
                            );
                        });
                        checkAdvancePaymentAtMoment();
                        //----------------------------------------------------------------------------------------------
                        // ANTICIPO
                        // SI ES UNA RENTA DE VOUCHER EL ANTICIPO ES EL ANTICIPO COBRADO AL MOMENTO,
                        // SI NO ES DE VOUCHER EL ANTICIPO ES LA SUMA DE LA RESERVA + EL ANTICIPO COBRADO AL MOMENTO.
                        if(reservation.voucher !== undefined && (reservation.voucher === true || reservation.voucher === 'true')){
                            let advance_payment = $("#rent_charge").val();
                            $("#rent_advance").val(advance_payment);
                        }else{
                            let advance_payment =parseFloat( $("#rent_charge").val());
                            let advance_reserved = parseFloat($("#span_reservation_price").val());
                            let advance = (+(Math.round(+((advance_payment + advance_reserved) + 'e' + 2)) + 'e' + -2)).toFixed(2);
                            $("#rent_advance").val(advance);
                        }

                        //TOTAL ES LO IGUAL AL ANTICIPO
                        $("#rent_import").val($("#rent_advance").val());

                        // FORMAS DE PAGO
                        switch(reservation.payment_form) {
                            case 'check':
                                $('#cheque').val(reservation.sale_price_amount);
                                // updatePaymentFormReleaseMoney($('#cheque'));
                                //$('#cheque_currency').val(reservation.price_currency).trigger('change');
                                break;
                            case 'voucher':
                                $('#voucher').val(reservation.sale_price_amount);
                                // updatePaymentFormReleaseMoney($('#voucher'));
                                //$('#voucher_currency').val(reservation.price_currency).trigger('change');
                                break;
                            case 'cash':
                                $('#cash').val(reservation.sale_price_amount);
                                //$('#cash').val(0.00);
                                //$('#moment_card').val(reservation.sale_price_amount);
                                // updatePaymentFormReleaseMoney($('#moment_card'));
                                //$('#cash_currency').val(reservation.price_currency).trigger('change');
                                break;
                            default:
                                // PAGADO POR TARJETA EN ANTICIPO
                                if(reservation.prepaid === true){
                                    $('#card').val(reservation.sale_price_amount);
                                    // updatePaymentFormReleaseMoney($('#card'));
                                }else{
                                    $('#card').val(0.00);
                                }
                            //$('#card_currency').val(reservation.price_currency).trigger('change');

                        }

                        // MONTO COBRADO AL MOMENTO
                        // SI EXISTE UN PRE PAGO SE COBRA AL MOMENTO LA DIFERENCIA
                        if(reservation.prepaid === true){
                            if(rent_charge > 0){
                                $("#moment_card").val(rent_charge.toFixed(2));
                                // updatePaymentFormReleaseMoney($("#moment_card"));
                            }
                        }else{
                            $("#moment_card").val(total_im);
                            // updatePaymentFormReleaseMoney($("#moment_card"));
                        }

                        $('#amounts_drivers').attr('max', reservation.max_drivers )

                        // $('#exampleModalLong').modal('show');
                        // Poniendo el comentario de la reserva solo para los que no son de la agencia.
                        if (!reservation.voucher)
                            $('#observation').text(reservation.comment);

                        if (reservation.show_pick_up_location_alert){
                            alert('Reserva de otra OV: ' + reservation.pick_up_location_name);
                        }
                        eventUpdatePaymentsFormReserve();
                    }
                    else {
                        // $('#references').val('');
                        $('#references').prop('readonly',false);
                        $('#nom_rent_type_id option:not(:selected)').prop('disabled', false);
                        $("#rent_form").hide();
                        $("#save_rent").hide();
                        alert('Error: ' + reservation.error_msg);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    updatePrices(e){
        let tariff_id = $('#ser_tariff_id').val();
        let service_modality_id = $('#ser_service_modality_id').val();
        let url = this.data.get('prices_url');
        let vehicle_id = this.data.get('vehicle-id');
        let from_date = $('#from_date').val();
        let to_date = $('#to_date').val();
        let pick_up_time = $('#pick_up_time').val();
        let drop_off_time = $('#drop_off_time').val();
        let drop_off_location = $('#drop_off_location_id').val();
        let rent_type_id = $('#nom_rent_type_id').val();
        let additional_drivers = $('#amounts_drivers').val();
        let root_reference = $('#root_reference').val();
        let birth_date = $('#birth_date').val();

        $.blockUI({ message:false });
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: { tariff_id: tariff_id,
                    service_modality_id: service_modality_id,
                    from_date: from_date,
                    to_date: to_date,
                    rent_type_id: rent_type_id,
                    vehicle_id: vehicle_id,
                    pick_up_time: pick_up_time,
                    drop_off_time: drop_off_time,
                    drop_off_location_id: drop_off_location,
                    additional_drivers: additional_drivers,
                    root_reference: root_reference,
                    birth_date: birth_date
            },
            success: function (res) {
                $.unblockUI();
                if (res.status === 'OK') {
                    // $('#text_days').text(res.data.days_ser);
                    // $('#amounts_days').val(res.data.days_ser);
                    updatePriceSer(res.data);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }
    /**
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param e
     */
    // updateAditionalDriverImport(e) {
    //     let tariff = $("#amounts_drivers_day").val();
    //     let days = $("#text_days").text();
    //     let amount = $("#amounts_drivers").val();
    //     $("#drivers_import").val(tariff * days * amount)
    //     //ACTUALIZAR TOTAL DE IMPORTE
    //     //renta
    //     let rent = $('#amounts_rent_import').val();
    //     //seguro
    //     let insurance = $('#liability_release_import').val();
    //     //combustible
    //     let fuel_imp = $('#amounts_fuel').val();
    //     //oficina
    //     let office = $('#amounts_office').val();
    //     //drop off
    //     let drop_off = $('#amounts_drop_off').val();
    //     //suplemento del relevo
    //     let relief_supplement = $('#relief_supplement_import').val();
    //     //choferes
    //     let drivers = $("#drivers_import").val()
    //     let total_im = parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent) + parseFloat(drivers) + parseFloat(drop_off) + parseFloat(relief_supplement)
    //     $("#rent_import").text(this.myToFixed(total_im,2));
    //     $("#total_rent_import").val(this.myToFixed(total_im, 2));
    //     let rent_charge = parseFloat(total_im) - parseFloat($("#span_reservation_price").val())
    //     $("#rent_charge").val(rent_charge.toFixed(2));
    //     $("#moment_card").val(rent_charge.toFixed(2));
    //     // updatePaymentFormReleaseMoney($("#moment_card"));
    // }

    /**
     * FUNCIÓN QUE VALIDA SI SE PUEDE HACER UNA RENTA O NO, SEGÚN LA FECHA DE VENCIMIENTO DE LA LICENCIA DEL CLIENTE.
     * SI LA FECHA DE VENCIMIENTO ES MENOR QUE LA FECHA QUE SE TERMINA LA RENTA ENTONCES NO SE PUEDE HACER LA MISMA.
     */
    isDueDateByRentValid() {
        let a_client_due_date = $('#due_date').val().split('/');
        let c_due_date = new Date(a_client_due_date[1] + '/' + a_client_due_date[0] + '/' + a_client_due_date[2]);
        let a_reservation_to_date = $('#to_date').val().split('/');
        let r_to_date = new Date(a_reservation_to_date[1] + '/' + a_reservation_to_date[0] + '/' + a_reservation_to_date[2]);
        if (c_due_date <= r_to_date){
            toastr_message('error','La fecha de vencimiento de la licencia del cliente está vencida o se encuentra dentro del período que dura la renta. Por favor, actualice el campo para activar el botón de crear contrato.', 'Licencia inválida');
            $('#due_date').val('')
            $('#save_rent').addClass('disabled');
        } else{
            let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));// para tener el valor de la suma de todas las formas de pago del formulario
            let release_money_default_value = parseFloat($('#release_money').attr("data-value"));
            if((release_money_default_value - inputs_sum) == 0 || $('#release_money').val() == 0)
                $('#save_rent').removeClass('disabled');
        }
    }

    /**
     * FUNCIÓN QUE VERIFICA SI UN CLIENTE TIENE UNA RENTA EN EJECUCIÓN O ES NO GRATO.
     * @param e
     */
    checkClientInRentExecution(e){
        const passport = $("#passport").val();
        let url_check = this.data.get("url_check");
        let rent_type = $('#nom_rent_type_id').val();
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url_check,
            data: {identification: passport, rent_type: rent_type},
            success: function (res) {
                if (res !== undefined) {
                    if (res.status === 'ERROR') {
                        alert("ERROR: " + res.error_msg);
                        $('#passport').val('');
                    }
                    if (res === 'OK' && res.warning_msg !== undefined && res.warning_msg !== ''){
                        toastr_message('warning', res.warning_msg);
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * FUNCIÓN QUE COBRA LA CATIDAD DE COMBUSTIBLE SEGÚN LO CONFIGURADO EN EL TIPO DE RENTA, EN LA VARIABLE
     * CANTIDAD DE COMBUSTIBLE GRATIS "amount_free_fuel"
     * @param e
     */
    updateFuelImport(e){
        let rent_type = $("#nom_rent_type_id").val();
        let vehicle = this.data.get("vehicle");
        let url = this.data.get("url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: rent_type, vehicle: vehicle},
            success: function (res) {
                $('#amounts_fuel').val(res.total).trigger('click').trigger('change');
                $('#text_free_fuel').text(res.free_fuel);
                $('#text_fuel').text(res.vehicle_fuel);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * FUNCIÓN QUE PERMITE CHEUQEAR  LAS VARIABLES DE CONFIGURACIÓN DEL NOMENCLADOR TIPO DE RENTA.
     * @param e
     */
    checkConfigNomRentType(e){
        // console.log('---------------------- checkConfigNomRentType')
        e.preventDefault();
        let vehicle_id = this.data.get("vehicle");
        let vehicle_business_id = this.data.get("vehicle_business_id");
        let hire_point_business_id = this.data.get("hire_point_business_id");
        let offices_by_vehicle_url = this.data.get("officesByVehicleUrl");
        let all_offices_url = this.data.get("officesUrl");
        // Atributos del tipo de renta seleccionado
        let rent_type = $('#nom_rent_type_id option').filter(':selected');
        let rent_vehicles_my_business = rent_type.attr('rent_vehicles_my_business');
        let drop_off_only_veh_business = rent_type.attr('drop_off_location_only_veh_business');
        let edit_amount_fuel = rent_type.attr('edit_amount_fuel');
        let request_previous_rent_reference = rent_type.attr('request_previous_rent_reference');
        let request_voucher = rent_type.attr('request_voucher');
        let reservation_voucher = $('#reservation_voucher').val();

        if (rent_type.val() !== "") {
            // Permite rentar vehiculos de mi sucursal
            if (rent_vehicles_my_business === "false") {
                if(hire_point_business_id === vehicle_business_id){
                    let msg = `!!ERROR¡¡: No permitido, el vehículo seleccionado pertenece a la misma sucursal del Punto de Renta. El tipo de renta ${rent_type.text()} no admite rentar vehículos de su propia sucursal.`;
                    document.getElementById("show_alert").innerHTML = `<div class='alert alert-danger alert-dismissible fade show' role="alert">${msg}
                                                                                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                                  <span aria-hidden="true">&times;</span></button></div>`;
                    // alert(vehicle_id);
                    // alert("ERROR: No permitido, el vehículo seleccionado pertenece a la misma sucursal del Punto de Renta. " +
                    //     "El tipo de renta << " + rent_type.text() + " >> no admite rentar vehículos de su propia sucursal.");

                    $('#nom_rent_type_id').val(null).trigger('change');
                    $('#references').val('');
                    $("#rent_form").hide();
                    $("#save_rent").hide();
                    return;
                }
            }
            //Permite rentar rentas continuadas con voucher confirmado
            if(request_voucher === "true"){
                $.blockUI({ message:false });
                let l_reference = $('#references').val();
                if(l_reference){
                    jQuery.ajax({
                        type: "GET",
                        url: `${location.origin}/hire_point/get-reservation-data/${l_reference}.json`,
                        data: {l_reference},
                        success: function (data){
                            let reservation = data;
                            $.unblockUI();
                            if(reservation.voucher == false){
                                let msg = `!!ERROR¡¡: No permitido, el tipo de renta ${rent_type.text()} no admite reservaciones sin voucher`;
                                document.getElementById("show_alert").innerHTML = `<div class='alert alert-danger alert-dismissible fade show' role="alert">${msg}
                                                                                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                                  <span aria-hidden="true">&times;</span></button></div>`;
                                $('#nom_rent_type_id').val(null).trigger('change');
                                $('#references').val('');
                                $("#rent_form").hide();
                                $("#save_rent").hide();
                                return;
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            $.unblockUI();
                            ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                        },
                    });
                }
            }
            // Punto de entrega del vehiculo solo en las oficinas de la sucursal del vehiculo.
            if (drop_off_only_veh_business === "true") {
                this.ajaxDropOffLocation(offices_by_vehicle_url, vehicle_id);
            } else if (drop_off_only_veh_business === "false") {
                this.ajaxDropOffLocation(all_offices_url, null); //Obtener todos los puntos de renta
            }
            //Mosrar formulario para las rentas de continuidad
            if(request_previous_rent_reference === "true"){
                document.getElementById('show_root_reference').style.display = "inline-block";
            }else{
                document.getElementById('show_root_reference').style.display = "none";
            }
            // Permite modificar la cantidad de combustible a cobrar de la renta
            if(edit_amount_fuel === "true"){
                $("#div_fuel_to_collect").show();
                $("#fuel_to_collect").prop('required', true);
            }else if (edit_amount_fuel === "false"){
                $("#div_fuel_to_collect").hide();
                $("#fuel_to_collect").prop('required', false);
                $("#fuel_to_collect").val(null);
            }
            this.ajaxPaymentFormsByRentType(rent_type.val())
        }
    }

    /**
     * FUNCIÓN QUE SE ENCUENTRA USANDO en checkConfigNomRentType.
     * @param url
     * @param id
     */
    ajaxDropOffLocation(url, id){
        $.blockUI({ message:false });
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: id},
            success: function (res) {
                $.unblockUI();
                $("#drop_off_location_id").empty();
                $("#drop_off_location_id").append('<option selected="selected" value=""> Seleccione el lugar de entrega </option>')
                $.each(res.hire_points, function (index, hire_point) {
                    $("#drop_off_location_id").append(
                        $("<option>")
                            .text(hire_point.name)
                            .attr("value", hire_point.id)
                    );
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * Actualiza el importe del combustibe según la cantidad de combustible a cobrar especificado por el usuario.
     * @param e
     */
    updateFuelImportToCollect(e){
        let fuel_to_collect = parseFloat($('#fuel_to_collect').val());
        let tariff = parseFloat($("#amounts_fuel_day").val());
        $('#text_fuel').text(fuel_to_collect);

        // quitar el combustible gratis
        let free_fuel = parseFloat($("#text_free_fuel").text());
        let total_fuel_to_collect =  fuel_to_collect - free_fuel;
        if (fuel_to_collect < free_fuel) {
            total_fuel_to_collect = 0;
        }
        $('#amounts_fuel').val(this.myToFixed(total_fuel_to_collect * tariff, 2));
        this.updateTotalImport(e);

    }

    /**
     * FUNCIÓN QUE NO SE ENCUENTRA USANDO EN EL MOMENTO SEGÚN LA BÚSQUEDA.
     * TODO VERIFICAR SI SE USA O NO, PARA SI NO SE USA ELIMINARLA EN PRÓXIMAS VERSIONES.
     * @param e
     */
    onSelectChange(e){
        e.preventDefault();
        e.stopPropagation();
        $('#nom_rent_type_id').on('change',function (){
            document.getElementById("show_alert").innerHTML = '';
        });
    }

    /**
     * FUNCIÓN QUE BÚSCA EN EL IDENTIFICADOR DE PERSONAS POR CARNET DE IDENTIDAD Y LLENA LOS DATOS SEGÚN LO ENCONTRADO.
     * @param e
     */
    idcheckCheckByDni(e){
        e.preventDefault();
        let url = this.data.get("url");
        let rent_type = $('#nom_rent_type_id').val();
        $.blockUI({ message:false });
        if ($('#references').val()) {
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {passport: $('#idcheck_ci').val(), rent_type: rent_type},
                success: function (res){
                    $.unblockUI();
                    if(res.status === 'OK'){
                        $('#idcheck_name').text(res.data.name);
                        $('#idcheck_surname').text(res.data.surname);
                        $('#idcheck_birthday').text(res.data.birthday);
                        $('#idcheck_age').text(res.data.age);
                        $('#idcheck_nationality').text(res.data.nationality);
                        $('#idcheck_passed_away').text(res.data.passed_away);
                        $('#idcheck_address').text(res.data.address);
                        let image = new Image();
                        image.src = 'data:image/png;base64,'+ res.data.photo;
                        $('#idcheck_image').empty();
                        $('#idcheck_image').prepend(image);
                        //console.log(res.data.name);
                        $('#name').val(res.data.name);
                        $('#first_name').val(res.data.surname);
                        $('#address').val(res.data.address);
                        $('#passport').val(res.data.passport);
                        $('#birth_date').val(res.data.birthday);
                        $('#driver_license').val(res.data.passport);
                        $('#nom_country_id').val(res.data.country);
                        $('#nationality').val(res.data.ser_nationality);
                        $("[value='" + res.data.ser_nationality + "']", $('#nom_country_id')).prop('selected', true);
                        $('#nom_country_id').trigger('change');
                        $('#nationality').trigger('change');
                        $("#birth_date").prop("readonly",true);
                        // Comprobar el cliente, que no tenga otra renta o sea no grato
                        if (res.data.can_rent !== undefined && res.data.can_rent === 'NO'){
                            alert("ERROR: " + res.data.msg_code);
                            $('#passport').val('');
                        }
                        //Notificar si es chofer de otra renta
                        if (res.data.cant_rent === 'YES' && res.data.check_driver_msg !== undefined && res.data.check_driver_msg !== ''){
                            toastr_message('warnning', res.data.check_driver_msg);
                        }
                    }else{
                        if(res.status_code == '200' || res.status_code == 200 ){
                            $('#passport').val('');
                        }else{
                            alert("¡ALERTA: Existe problemas con el identificador de personas, usted debe revisar cuidadosamente los datos del cliente!");
                            $('#name').val($('#reservation_name').val());
                            $('#first_name').val( $('#reservation_last_name').val());
                            $('#address').val($('#reservation_address').val());
                            $('#passport').val($('#client_identification').text());
                            $("#birth_date").prop("readonly",false);
                        }
                    }
                    // Chequear deuda del cliente
                    if ($('#passport').val().length > 0) {
                        checkClientDebitBalance(e);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                    $('#name').val($('#reservation_name').val());
                    $('#first_name').val( $('#reservation_last_name').val());
                    $('#address').val($('#reservation_address').val());
                    $('#passport').val($('#client_identification').text());
                    $("#birth_date").prop("readonly",false);
                },
            });
        }
    }

    /**
     * FUNCIÓN QUE BUSCA EN EL IDENTIDICADOR DE PERSONAS POR EL PASAPORTE Y LLENA LOS DATOS SEGÚN LOS ENCONTRADO.
     * @param e
     */
    idcheckCheckByPassport(e){
        e.preventDefault();
        let url = this.data.get("url");
        let rent_type = $('#nom_rent_type_id').val();
        $('.human').text('');
        $('.human-picture').text('¿?');
        $('#check_0').prop("checked", false);
        $('#check_1').prop("checked", false);
        $('#check_2').prop("checked", false);
        $.blockUI({ message:false });
        if ($('#references').val()) {
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: {passport: $('#idcheck_passport').val(), rent_type: rent_type},
                success: function (res){
                    $.unblockUI();
                    if(res.status === 'OK'){
                        //console.log(res.data.human_being);
                        $(res.data.human_being).each(function (index, item) {
                            $(`#idcheck_passport_${index}`).text(item.passport);
                            $(`#idcheck_name_${index}`).text(item.name);
                            $(`#idcheck_surname_${index}`).text(item.surname);
                            $(`#idcheck_birthday_${index}`).text(item.birthday);
                            $(`#idcheck_age_${index}`).text(item.age);
                            $(`#idcheck_nationality_${index}`).text(item.nationality);
                            $(`#idcheck_passed_away_${index}`).text(item.cp);
                            $(`#idcheck_address_${index}`).text(item.address);
                            let image = new Image();
                            image.src = 'data:image/png;base64,'+ item.photo;
                            $(`#idcheck_image_${index}`).empty();
                            $(`#idcheck_image_${index}`).prepend(image);
                        });
                        $("#birth_date").prop("readonly",true);
                        if(res.data.human_being.length == 1){
                            $('#check_0').prop("checked", true);
                            // $('#passport').val(res.data.passport);
                            //let decodedStringAtoB = atob(res.data.photo);
                            //console.log(decodedStringAtoB);
                            $('#name').val(res.data.name);
                            $('#first_name').val(res.data.surname);
                            $('#passport').val(res.data.passport);
                            $('#birth_date').val(res.data.birthday);
                            // $('#nom_country_id').val(res.data.country);
                            // $('#nationality').val(res.data.ser_nationality);
                            // $("[value='" + res.data.ser_nationality + "']", $('#nom_country_id')).prop('selected', true);
                            // $('#nom_country_id').trigger('change');
                            // $('#nationality').trigger('change');
                        }
                        //Comprobar el cliente, que no tenga otra renta o sea no grato, o no esté en el país
                        if (res.data.can_rent !== undefined && res.data.can_rent === 'NO'){
                            alert("ERROR: " + res.data.msg_code);
                            $('#passport').val('');
                        }
                        //Notificar si es chofer de otra renta
                        if (res.data.cant_rent === 'YES' && res.data.check_driver_msg !== undefined && res.data.check_driver_msg !== ''){
                            toastr_message('warnning', res.data.check_driver_msg);
                        }
                    }else{
                        alert("ERROR: " + res.error_msg);
                        if(res.status_code == '200' || res.status_code == 200 ){
                            $('#passport').val('');
                        }else{
                            alert("¡ALERTA: Existe problemas con el identificador de personas, usted debe revisar cuidadosamente los datos del cliente!");
                            $('#name').val($('#reservation_name').val());
                            $('#first_name').val( $('#reservation_last_name').val());
                            $('#address').val($('#reservation_address').val());
                            $('#passport').val($('#client_identification').text());
                            $("#birth_date").prop("readonly",false);
                        }
                    }
                    // Chequear deuda del cliente
                    if ($('#passport').val().length > 0) {
                       checkClientDebitBalance(e);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                    $('#name').val($('#reservation_name').val());
                    $('#first_name').val( $('#reservation_last_name').val());
                    $('#address').val($('#reservation_address').val());
                    $("#birth_date").prop("readonly",false);
                    $('#passport').val($('#client_identification').text());
                    checkClientDebitBalance(e);
                },
            });
        }
    }

    /**
     * FUNCIÓN QUE CUANDO SE BÚSCA POR PASAPORTE Y SE ENCUENTRAN DOS O MÁS PERSONAS CON EL MISMO PASAPORTE,
     * EL FUNCIONARIO SELECCIONE EL QUE TENGA AL FRENTE PARA REALIZAR LA RENTA.
     * @param e
     */
    updatePerson(e){
        let check_id = this.data.get("id");
        let url = this.data.get('url')
        let rent_type = $('#nom_rent_type_id').val();
        // Cuando se marca la persona que se desea rentar
        if(document.getElementById(`check_${check_id}`).checked){
            jQuery.ajax({
                async: true,
                evalScripts: true,
                type: "POST",
                contentType: "application/x-www-form-urlencoded",
                url: url,
                data: { passport: $(`#idcheck_passport_${check_id}`).text(),
                    age: $(`#idcheck_age_${check_id}`).text(),
                    address: $(`#idcheck_address_${check_id}`).text(),
                    rent_type: rent_type },
                success: function (res) {
                    //Comprobar el cliente, que no tenga otra renta o sea no grato, o no esté en el país
                    if (res.data.can_rent !== undefined && res.data.can_rent === 'NO'){
                        alert("ERROR: " + res.data.msg_code);
                        $('#passport').val('');
                        $('#name').val('');
                        $('#first_name').val('');
                        $('#birth_date').val('');
                    }else{
                        let passport =  $(`#idcheck_passport_${check_id}`).text();
                        let name = $(`#idcheck_name_${check_id}`).text();
                        let surname = $(`#idcheck_surname_${check_id}`).text();
                        let birthday = $(`#idcheck_birthday_${check_id}`).text();
                        $('#passport').val(passport);
                        $('#name').val(name);
                        $('#first_name').val(surname);
                        $('#birth_date').val(birthday);
                        $("#birth_date").prop("readonly",true);
                        //Notificar si es chofer de otra renta
                        if (res.data.cant_rent === 'YES' && res.data.check_driver_msg !== undefined && res.data.check_driver_msg !== ''){
                            toastr_message('warnning', res.data.check_driver_msg);
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $.unblockUI();
                    $("#birth_date").prop("readonly",false);
                    ajaxErrorMessages(jqXHR, textStatus, errorThrown);
                },
            });
        }else{
            $('#passport').val('');
            $('#name').val('');
            $('#first_name').val('');
            $('#birth_date').val('');
        }

        // $('#name').val('');
        // $('#name').val(res.data.name);
        // $('#first_name').val('');
        // $('#first_name').val(res.data.surname);
        // $('#passport').val('');
        // $('#passport').val(res.data.passport);
        // $('#birth_date').val('');
        // $('#birth_date').val(res.data.birthday);
    };

    ajaxPaymentFormsByRentType(nom){
        $.blockUI({ message:false });
        jQuery.ajax({
            async:true,
            evalScripts: true,
            type: 'GET',
            contentType: "application/x-www-form-urlencoded",
            url: `${location.origin}/hire_point/get-payment-form-by-rent-type/${nom}.json`,
            data: {rent_type: nom},
            success: function (pays){
                $.unblockUI();
                if(pays.payment_forms.length > 0){
                    let moment_card = $('#moment_card');
                    let card = $('#card');
                    let cash = $('#cash');
                    let voucher = $('#voucher');
                    let cheque = $('#cheque');
                    let other = $('#other');
                    let release_money = $('#release_money');

                    for(var i = 0; i < pays.payment_forms.length ;i++){
                        if(pays.payment_forms[i].code === 'EFE')
                            cash.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TM')
                            moment_card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'VOU')
                            voucher.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TAN')
                            card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'CHT')
                            cheque.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'OTR')
                            other.removeAttr("disabled");
                        // console.log("formas de pago definidas: " + pays.payment_forms[i].code);
                    }
                } else {
                    let msg = `ERROR: El tipo de renta seleccionado no tiene formas de pagos configuradas.`;
                    $("#show_alert").append(`<div class='alert alert-danger alert-dismissible fade show' role="alert">${msg}
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span></button></div>`)
                    $('#nom_rent_type_id').val(null).trigger('change');
                    $("#rent_form").hide();
                    $("#save_rent").hide();
                    // console.log("EL TIPO DE RENTA NO TIENE FORMAS DE PAGO DEFINIDAS");
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    };

    updateReleaseMoneyValue(e){
        e.preventDefault();
        let moment_card = $('#moment_card');
        let card = $('#card');
        let cash = $('#cash');
        let voucher = $('#voucher');
        let cheque = $('#cheque');
        let other = $('#other');
        let inputs_defaults_sum = 0;
        let input = this.data.get("inputId");
        let objInput = $(`#${input}`);
        let release_money = $('#release_money');
        let release_data_value = release_money.attr("data-value");

        let inputs_sum = (parseFloat($('#cash').val()) + parseFloat($('#card').val()) + parseFloat($('#moment_card').val()) + parseFloat($('#cheque').val()) + parseFloat($('#voucher').val()) + parseFloat($('#other').val()));
        if(moment_card.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(moment_card.attr("data-value"));
        if(card.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(card.attr("data-value"));
        if(cash.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(cash.attr("data-value"));
        if(voucher.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(voucher.attr("data-value"));
        if(cheque.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(cheque.attr("data-value"));
        if(other.attr("data-value"))
            inputs_defaults_sum = inputs_defaults_sum + parseFloat(other.attr("data-value"));
        // debugger;
        // if(((inputs_sum) + parseFloat(release_money.val()))-1 <= parseFloat(inputs_defaults_sum)){
        //     objInput.attr("max",(inputs_defaults_sum - inputs_sum));
        // }else{
        //     objInput.attr("max",objInput.val());
        // }
        //objInput.attr("max",(parseFloat(release_data_value) - inputs_sum));
        if(!objInput.attr("disabled")){
            let difference =parseFloat(moment_card.val()) - parseFloat(moment_card.attr("data-value"));
            release_money.val(this.myToFixed((parseFloat(inputs_defaults_sum) + difference) - parseFloat(inputs_sum),2));

            if(inputs_sum == 0){
                release_money.val(parseFloat(release_data_value));
            }
            if(inputs_sum == inputs_defaults_sum){
                release_money.val(0.00);
            }
            if(inputs_sum == (inputs_defaults_sum + difference)){
                release_money.val(0.00);
            }
            if((parseFloat(release_data_value)  - inputs_sum) == 0 || release_money.val() == 0){
                $('#save_rent').removeClass("disabled");
            }else{
                $('#save_rent').addClass("disabled");
            }
        }
    }

    /**
     * FUNCIÓN QUE SE USA EN EL CHECK DE QUE EL FUNCIONARIO CHEQUEA EL MISMO A LA PERSONA,
     * DEBE SER PARA CUANDO EL IDCHECK NO FUNCIONE
     * @param e
     */
    checkbyofficial(e){
        if($('#check_by_official').is(":checked") === true){
            let regex = /^[a-zA-Z0-9]{3,15}$/g;
            $('#name').val($('#reservation_name').val());
            $('#first_name').val( $('#reservation_last_name').val());
            $('#address').val($('#reservation_address').val());
            $('#passport').val($('#client_identification').text());
            $("#birth_date").prop("readonly",false);
            if (regex.test($('#client_identification').text()) == false) {
                toastr_message('warning', 'Pasaporte o carnet de identidad no válido.', "Pasaporte/C.Identidad");
            }
            // Chequear deuda del cliente mediante la accion click del componente
            checkClientDebitBalance(e);
        }else{
            $('#name').val('');
            $('#first_name').val( '');
            $('#address').val('');
            $('#passport').val('');
            $("#birth_date").prop("readonly",true);
        }
    }

    /**
     * CHEQUEAR PROXIMO MANTENIMIENTO
     * */
    checkNextMtto(e){
        checkNextMtto();
    }
}


import {Controller} from "stimulus";
import $ from "jquery";

export default class extends Controller {
    connect() {

    }
    toggleSaveButton() {
        if( $('.operation-confirm').prop('checked') ){
            $('.save-button').prop('disabled', false)
        } else {
            $('.save-button').prop('disabled', true)
        }
    }

    undoStolentoggleSaveButton(e){
        if( $('.operation-confirm').prop('checked') && ($('#default_place_parking').prop('checked') || $('#default_place_deposit').prop('checked') || $('#default_place_workshop').prop('checked') ) ){
            $('.save-button').prop('disabled', false)
        } else {
            $('.save-button').prop('disabled', true)
        }
    }

    toggleParking(e){
        if( $('#default_place_parking').prop('checked') ){
            $('#parking_id').prop('disabled', false)
            $('#deposit_id').prop('disabled', true)
            $('#nom_workshop_id').prop('disabled', true)
        } else {
            $('#parking_id').prop('disabled', true)
        }
    }

    toggleDeposit(e){
        if( $('#default_place_deposit').prop('checked') ){
            $('#deposit_id').prop('disabled', false)
            $('#nom_workshop_id').prop('disabled', true)
            $('#parking_id').prop('disabled', true)
        } else {
            $('#deposit_id').prop('disabled', true)
        }
    }

    toggleWorkShop(e){
        if( $('#default_place_workshop').prop('checked') ){
            $('#nom_workshop_id').prop('disabled', false)
            $('#parking_id').prop('disabled', true)
            $('#deposit_id').prop('disabled', true)
        } else {
            $('#nom_workshop_id').prop('disabled', true)
        }
    }
    // Economy Reports

    selectAllVouchers(e) {
        $('#check_all_rent').change(function() {
            let rent_items =  $('.rent_items');
            if($(this).is(':checked')){
                rent_items.prop('checked', true);
                rent_items.prop('disabled', true);
                $('#btn-create_lot').removeClass('disabled');
            }else {
                rent_items.prop('checked', false);
                rent_items.prop('disabled', false);
                $('#btn-create_lot').addClass('disabled');
            }
        })
    }

    enableBtnCreateLot(e){
        if($('.rent_items').is(':checked')){
            $('#btn-create_lot').removeClass('disabled');
        }else {
            $('#btn-create_lot').addClass('disabled');
        }
    }
    // prefacturar lotes

    selectAllLots(e) {
        $('#check_all_lots').change(function() {
            let rent_items =  $('.lots_items');
            if($(this).is(':checked')){
                rent_items.prop('checked', true);
                rent_items.prop('disabled', true);
                $('#btn-pre-billing_lot').removeClass('disabled');
            }else {
                rent_items.prop('checked', false);
                rent_items.prop('disabled', false);
                $('#btn-pre-billing_lot').addClass('disabled');
            }
        })
    }

    enableBtnPreBilling(e){
        if($('.lots_items').is(':checked')){
            $('#btn-pre-billing_lot').removeClass('disabled');
        }else {
            $('#btn-pre-billing_lot').addClass('disabled');
        }
    }

    enableBtnReady(e){
        if($('.rent_items').is(':checked')){
            $('#btn_ready').removeClass('disabled');
        }else {
            $('#btn_ready').addClass('disabled');
        }
    }
    selectAllReadyLots(e) {
        $('#check_all_lots').change(function() {
            let rent_items = $('.rent_items');
            if($(this).is(':checked')){
                rent_items.prop('checked', true);
                rent_items.prop('disabled', true);
                $('#btn_ready').removeClass('disabled');
            }else {
                rent_items.prop('checked', false);
                rent_items.prop('disabled', false);
                $('#btn_ready').addClass('disabled');
            }
        })
    }

    enableBtnArhiveBilling(e){
        if($('.billing_items').is(':checked')){
            $('#btn-ready_to_archive').removeClass('disabled');
        }else {
            $('#btn-ready_to_archive').addClass('disabled');
        }
    }

}
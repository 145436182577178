import {Controller} from "stimulus"
import {myToFixed} from "./globalFunctions_controller"
import $ from "jquery";

// Connects to data-controller="income-adjustment"
export default class extends Controller {
    $rentIncomes;
    $incomeTotal;
    $incomeTotalDisabled;
    $directBilling;
    $directBillingDisabled;
    $paymentMethodTotal

    connect() {
        this.$rentIncomes = $('.rent-income');
        this.$incomeTotal = $('#rent_income_adjustment_income_total');
        this.$incomeTotalDisabled = $('#rent_income_adjustment_income_total_disabled');
        this.$paymentMethodTotal = $('#rent_income_adjustment_payment_method_total');
        this.$directBilling = $('#rent_income_adjustment_direct_billing');
        this.$directBillingDisabled = $('#rent_income_adjustment_direct_billing_disabled');

        this.$incomeTotalDisabled.val(this.calcTotalIncomes());
        this.$directBillingDisabled.val(this.calcDirectBilling());

        $('input, select').on('input', function () {
            let submit = $('#submitBtn');
            if ($(this).val() !== $(this).prop('defaultValue')) {
                $(this).addClass('bg-green-lt');
                submit.prop('disabled', false)
            } else {
                $(this).removeClass('bg-green-lt');
                submit.prop('disabled', true);
            }
        });

        this.$rentIncomes.on('change', () => {
            this.$incomeTotal.val(this.calcTotalIncomes());
            this.$incomeTotalDisabled.val(this.calcTotalIncomes());
            this.$directBilling.val(this.calcDirectBilling());
            this.$directBillingDisabled.val(this.calcDirectBilling());
        });
    }

    calcTotalIncomes() {
        let sum = 0;
        this.$rentIncomes.each(i => {
            sum += parseFloat(this.$rentIncomes[i].value)
        });
        return sum.toFixed(2);
    }

    calcDirectBilling() {
        return myToFixed(this.$incomeTotalDisabled.val() - this.$paymentMethodTotal.val(), 2);
    }
}

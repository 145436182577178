import {Controller} from "stimulus";
import $ from "jquery";
import {atob} from "buffer";
require('block-ui/jquery.blockUI');

function isEmpty(value) {
    return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
}
function ajaxErrorMessages(jqXHR, textStatus, errorThrown){
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intente nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intente nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        // responseText
        // $.ajax({
        //     async: true,
        //     evalScripts:true,
        //     type: "POST",
        //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
        //     data: {error: jqXHR.responseText}
        // });
        alert(message);
    }
}
function dirty_change(el) {
    let price_str = $(el).data('price');
    let val_str = $(el).val();
    let price = parseFloat(price_str);
    let val = parseFloat(val_str);
    if (price_str != val_str && price != val) {
        $(el).addClass('dirty-change');
    } else {
        $(el).removeClass('dirty-change');
    }
}
export default class extends Controller {
    connect() {
        /**
         * Función que cuando cambia los imput le pone la clase.
         */
        $('#prices-sheet .price-rule input').on('input', function () {
            dirty_change(this);
        });

        $('#prices-sheet .price-rule input').on('change', function () {
            dirty_change(this);
        });

    }

    /**
     * Refresh de los campos que tienen algún cambio
     * @param e
     */
    refresh(e){
        let code = this.data.get("code");
       let  rules = $('#' + code + ' .price-rule input');
        rules.each(function (idx, el) {
            var input = $(el);
            var price = input.data('price');
            input.val(price);
        });
        rules.removeClass('dirty-change errors');
    }

    /**
     * ACTALIZA LOS DÍAS RENTAS DE UN CONTRATO, DE UN MONTO Y UNA FECHA
     * @param e
     */
    saveDropOffPrice(e) {
        let url = this.data.get("url");
        let code = this.data.get("code");
        let changed_rules = $('#'+code + ' .price-rule input.dirty-change');
        console.log(code);
        console.log(changed_rules.serialize());
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: changed_rules.serialize(),
            success: function (response) {
                if (response.success) {
                    changed_rules.each(function (idx, el) {
                        var input = $(el);
                        var new_price = input.val().trim();

                        input.data('price', new_price);
                    });

                    changed_rules.removeClass('dirty-change');
                    changed_rules.removeClass('errors');
                } else {
                    var list = response.list;

                    changed_rules.each(function (idx, el) {
                        var input = $(el);
                        var code = input.data('code');
                        var new_price = input.val().trim();

                        if (list.indexOf(code) > -1) {
                            input.addClass('errors');
                        } else {
                            input.data('price', new_price);
                            input.removeClass('dirty-change');
                            input.removeClass('errors');
                        }
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                changed_rules.addClass('errors');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    /**
     * ACTALIZA TODOS LOS PRECIOS DEL DROP OFF DEL FORMULARIO
     * @param e
     */
    saveAll(e) {
        let url = this.data.get("url");
        let code = this.data.get("code");
        let changed_rules = $(code + ' :input.dirty-change');
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: changed_rules.serialize(),
            success: function (response) {
                if (response.success) {
                    changed_rules.each(function (idx, el) {
                        var input = $(el);
                        var new_price = input.val().trim();
                        input.data('price', new_price);
                    });
                    changed_rules.removeClass('dirty-change');
                    changed_rules.removeClass('errors');
                } else {
                    var list = response.list;
                    changed_rules.each(function (idx, el) {
                        var input = $(el);
                        var code = input.data('code');
                        var new_price = input.val().trim();

                        if (list.indexOf(code) > -1) {
                            input.addClass('errors');
                        } else {
                            input.data('price', new_price);
                            input.removeClass('dirty-change');
                            input.removeClass('errors');
                        }
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                changed_rules.addClass('errors');
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }
}

import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    connect() {
        $('#available_vehicle').select2({
            theme: "bootstrap",
            language: "es"
        });

        // $("#available_vehicle").on('select2:select', function () {
        //     let event = new Event('change', {bubbles: true}) // fire a native event
        //     this.dispatchEvent(event);
        // });
    }

    updateCarData(e){
        let vehicle = $('#available_vehicle').val();
        let vehicle_url = this.data.get("url");
        let rent_date = this.data.get("rent");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: vehicle_url,
            data: {id: vehicle},
            success: function (res) {
                $("#change_mileage").val(0);
                $("#change_fuel").val(0);
                $("#change_mtto").val('');
                $('#vehicle_data').text('');
                $('#change_manufactured_year').text('');
                $('#change_business_code').text('');
                $('#change_basic_business_code').text('');
                $('#change-vehicle-mileage').text('');
                $('#change-mtto').text('');
                $('#change-ficav').text('');
                if (res.humanize_status == 'Sin fecha'|| res.humanize_status == null){
                    alert('ERROR: El vehículo se encuentra sin fecha FICAV, o no lo ha seleccionado correctamente. Por favor, seleccione otro auto.');
                    $('#available_vehicle').val(null).trigger('change');
                    $('#btn-extent').addClass('disabled');
                }else{
                    let a_ficav = res.humanize_status.split('/'); //Para las fechas cortas el formato string es mm/dd/yyyy
                    let ficav_date = new Date(a_ficav[1] + '/' + a_ficav[0] + '/' + a_ficav[2]);
                    let rent_to_date = new Date(rent_date);
                    if (ficav_date <= rent_to_date){
                        alert('ERROR: La fecha FICAV ('+ res.humanize_status+') del nuevo vehículo seleccionado está vencida o vence en el tiempo que dura la renta. Por favor, seleccione otro vehículo disponible.');
                        $('#available_vehicle').val(null).trigger('change');
                        $('#btn-extent').addClass('disabled');
                    }else{
                        $("#change_mileage").val(res.mileage);
                        $("#change_fuel").val(res.fuel);
                        $("#change_mtto").val(res.mtto);
                        $('#vehicle_data').text(res.vehicle);
                        $('#change_manufactured_year').text(res.manufactured_year);
                        $('#change_business_code').text(res.business_code);
                        $('#change_basic_business_code').text(res.basic_business_code);
                        $('#change-vehicle-mileage').text(res.mileage);
                        $('#change-mtto').text(res.mtto);
                        $('#change-ficav').text(res.humanize_status);
                        // Proximo mantenimiento maximo segun configuracion del kilometraje maximo
                        if ($.isNumeric(res.max_mileage)) {
                            $('#change_mtto').prop('max', res.max_mileage);
                        }else{
                            $('#change_mtto').removeAttr('max');
                        }
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    checkCategoryChange(e){
        let vehicle = $('#available_vehicle').val();
        let liquidate_vehicle_code = this.data.get("old");
        let vehicle_url = this.data.get("url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: vehicle_url,
            data: {id: vehicle},
            success: function (result){
                if(result.vehicle_category !== liquidate_vehicle_code){
                    alert("El vehículo seleccionado tiene una categoría comercial diferente a la del vehículo a liquidar en esta operación. Verifique dicha información.");
                    $('#category_change_user').show();
                    $('#autorize_user').prop('required', true);
                }else{
                    $('#category_change_user').hide();
                    $('#autorize_user').prop('required', false);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }
}

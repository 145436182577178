import {Controller} from "stimulus";
import $ from "jquery";

export default class extends Controller {
    connect() {  }

    toggleFormRent(e){
        let rent_type = $('#nom_rent_type_id option').filter(':selected');
        if (rent_type.val() !== ""){
           // Variables de configuracion a cargar
            let driver_price = parseFloat(rent_type.attr('driver_price'));
            let drivers_to_charges = parseInt(rent_type.attr('drivers_to_charges'));
            let max_number_days = parseInt(rent_type.attr('max_number_days'));

            // Dias de renta
            $('#text_days').text(max_number_days);
            $('#amounts_days').val(max_number_days);

            // Actualizando el to_date de la renta de acuerdo al numero de dias configurado
            let to_date = new Date(this.data.get('today'));
            to_date.setDate(to_date.getDate() + max_number_days);
            $('#to_date').val($.datepicker.formatDate('dd/mm/yy', to_date));
            $("#to_date").datepicker('option', 'maxDate', $.datepicker.formatDate('dd/mm/yy', to_date));

            // Importe por choferes
            $('#amounts_drivers_day').val(driver_price)
            $('#drivers_to_charges').val(drivers_to_charges);
            $("#drivers_import").val(0.0); //Seteando su valor a 0, se reconstruye la seccion de choferes con cada cambio de tipo de renta

            // Mostrar los componentes del formulario
            $("#rent_form").show();
            $("#div_next_maintenance").show();
            $("#save_rent").show();
            $("#btn-back").removeClass('ml-auto');
        } else {
            $("#rent_form").hide();
            $("#div_next_maintenance").hide();
            $("#div_fuel_to_collect").hide();
            $("#save_rent").hide();
        }
    }
}